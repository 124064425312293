import services from 'services/services';
import * as types from '../../types';

// Import helpers
import { checkFetchErrors } from '../../helpers_actions/convert_helpers';

// ******************** FETCH ARTICLE GALLERY FILES TO EDIT ********************
export const fetchArticleGallery = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_ARTICLE_GALLERY_FILES_LOADING,
			payload: true
		});

		const response = await services.get(
			`/assets/${id}/list?model_type=article&collection=gallery`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_ARTICLE_GALLERY_FILES_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ARTICLE_GALLERY_FILES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH ARTICLE ASSET GROUP ********************
export const fetchArticleAssetGroups = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_ARTICLE_ASSET_GROUP_LOADING,
			payload: true
		});

		const url = `/asset_groups/list?model_id=${id}&with_assets=1`;

		const {
			data: { data }
		} = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_ARTICLE_ASSET_GROUP_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ARTICLE_ASSET_GROUP_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
