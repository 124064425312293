import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import GradientBg from './GradientBg';
import { CoverImage, Wrapper, InfoContainer, StyledLink } from './style';
import Share from './Share';

// HELPERS
import { checkProductSubtype, getImage, getRedirectUrl } from '../helpers';

// Import variables
import {
	CONTAINER_SCREEN_PERCENT,
	PRODUCT_IMAGES_TYPES
} from '../helpers/variables';

const { COVER } = PRODUCT_IMAGES_TYPES;

export function Layout({ children, type, id }) {
	const { t } = useTranslation();

	const { images, subtype } = useSelector(
		({ product_preview }) => product_preview.data
	);

	const { isArticle } = checkProductSubtype(subtype);

	const productCover = getImage({ images, type: COVER });

	const redirectPath = getRedirectUrl({ type, id });

	return (
		<>
			{!isArticle && (
				<>
					<CoverImage cover={productCover} aria-hidden="true" />
					<GradientBg containerScreenPercent={CONTAINER_SCREEN_PERCENT} />
				</>
			)}
			<Wrapper isArticle={isArticle}>
				<InfoContainer>
					<StyledLink to={redirectPath} className="btn btn-primary btn-lg">
						<i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>{' '}
						{t('product_preview:buttons.preview_redirect')}
					</StyledLink>
					{children}
				</InfoContainer>
				<Share />
			</Wrapper>
		</>
	);
}

Layout.propTypes = {
	children: PropTypes.element.isRequired,
	type: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired
};
