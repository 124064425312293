// Import variables
import { DATE_NAMES } from 'components/helpers/variables';

// Import helpers
import { convertItemDateProperties } from 'components/helpers/convert';

export const convertResourcesDate = (resources) => {
	const isResourcesHaveDateProperties = DATE_NAMES.some((dateName) =>
		resources?.hasOwnProperty(dateName)
	);

	const isResourcesHaveDataWithDateProperties = DATE_NAMES.some((dateName) =>
		resources?.data?.hasOwnProperty(dateName)
	);

	if (isResourcesHaveDateProperties) {
		const convertedResources = convertItemDateProperties({
			item: resources
		});

		return convertedResources;
	} else if (isResourcesHaveDataWithDateProperties) {
		const convertedData = convertItemDateProperties({
			item: resources.data
		});

		return { ...resources, data: convertedData };
	}

	return resources;
};
