import React from 'react';
import { func, bool, object } from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { Modal } from 'antd';

/**
 * Renders cell with delete action, contains modal to confirm delete
 */
const DeleteCell = ({ handleSelectItem, canEditVideo, record }) => {
	const { t } = useTranslation();

	if (!canEditVideo) return null;

	// shows modal to confirm delete. On OK it calls function to delete that is in Context
	const confirmModal = () =>
		Modal.confirm({
			title: t(`common:delete`),
			content: `${t(`messages:modal.delete_confirm_message`)} ${t(
				`names:notification_names.subtitle`
			)}`,
			okText: t('common:buttons.ok'),
			cancelText: t('common:buttons.cancel'),
			onOk: () => handleSelectItem(record.uuid)
		});

	return (
		<button onClick={confirmModal} className="btn btn-xs btn-primary">
			<i className="fa fa-trash" />
		</button>
	);
};

DeleteCell.propTypes = {
	handleSelectItem: func,
	canEditVideo: bool,
	record: object
};

export default DeleteCell;
