import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import components
import { Wraper } from 'components/common/layout';

// Import actions
import { fetchReportsUrl } from 'store/actions';

// Import styles
import { FrameWrapper } from './styles';

const Reports = () => {
	const dispatch = useDispatch();
	const reports = useSelector(({ reports }) => reports);

	const { url, error } = reports;

	useEffect(() => {
		fetchReportsUrl()(dispatch);
		//eslint-disable-next-line
	}, []);

	return (
		<Wraper error={error}>
			<FrameWrapper>
				<iframe
					title="redcarpet_reports_iframe"
					style={{
						width: '100%',
						height: '100%',
						border: 'none'
					}}
					src={url}
					allowFullScreen
					sandbox="allow-scripts allow-same-origin"
				/>
			</FrameWrapper>
		</Wraper>
	);
};

export default Reports;
