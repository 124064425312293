// PRODUCT TYPES
export const PRODUCTS_TYPES = {
	ALL: 'all',
	VOD: 'vod',
	AUDIO: 'audio',
	ARTICLE: 'article',
	LIVE_EVENT: 'live_event',
	SERIES: 'series',
	COLLECTION: 'collection',
	PACKET: 'main',
	SEASON: 'season',
	EPISODE: 'episode',
	PROVISION: 'provision',
	CHANNEL: 'channel',
	BANNER: 'banner'
};

// Product image types
export const PRODUCT_IMAGES_TYPES = {
	COVER: 'cover',
	POSTER: 'poster',
	LOGO: 'logo',
	COVER_LARGE: 'cover_large',
	TITLE: 'title'
};

//Description line height limit
export const DESCRIPTION_LINE_HEIGHT_LIMIT = {
	LINE_HEIGHT_DESKTOP: 36,
	LINE_HEIGHT_MOBILE: 21
};

export const CONTAINER_SCREEN_PERCENT = 100;

//CUSTOM SLIDER ARROWS INVOICE_TYPES
export const SLIDER_ARROW_TYPES = {
	PREV: 'prev',
	NEXT: 'next'
};
