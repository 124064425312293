import i18n from 'i18next';
import moment from 'moment';

// Import helpers
import { GLOBAL_PRODUCT_TYPES } from 'components/helpers/variables';
import { PRODUCTS_TYPES } from './variables';

import {
	fetchVodDetails,
	fetchSeries,
	fetchArticle,
	fetchEventLive,
	fetchVodTitle,
	fetchArticleTitle,
	fetchEventLiveTitle,
	fetchVodCover,
	fetchArticleCover,
	fetchEventLiveCover,
	fetchVodPoster,
	fetchArticlePoster,
	fetchEventLivePoster,
	fetchVodGallery,
	fetchArticleGallery,
	fetchEventLiveGallery
} from 'store/actions';

export const getFetchDetailsAction = (type) => {
	switch (type) {
		case GLOBAL_PRODUCT_TYPES.VOD:
			return fetchVodDetails;

		case GLOBAL_PRODUCT_TYPES.SERIES:
			return fetchSeries;

		case GLOBAL_PRODUCT_TYPES.ARTICLE:
			return fetchArticle;

		case GLOBAL_PRODUCT_TYPES.EVENT_LIVE:
			return fetchEventLive;

		default:
			return fetchVodDetails;
	}
};

export const getFetchTitleAction = (type) => {
	switch (type) {
		case GLOBAL_PRODUCT_TYPES.ARTICLE:
			return fetchArticleTitle;

		case GLOBAL_PRODUCT_TYPES.EVENT_LIVE:
			return fetchEventLiveTitle;

		default:
			return fetchVodTitle;
	}
};

export const getFetchCoverAction = (type) => {
	switch (type) {
		case GLOBAL_PRODUCT_TYPES.ARTICLE:
			return fetchArticleCover;

		case GLOBAL_PRODUCT_TYPES.EVENT_LIVE:
			return fetchEventLiveCover;

		default:
			return fetchVodCover;
	}
};
export const getFetchPosterAction = (type) => {
	switch (type) {
		case GLOBAL_PRODUCT_TYPES.ARTICLE:
			return fetchArticlePoster;

		case GLOBAL_PRODUCT_TYPES.EVENT_LIVE:
			return fetchEventLivePoster;

		default:
			return fetchVodPoster;
	}
};

export const getFetchGalleryAction = (type) => {
	switch (type) {
		case GLOBAL_PRODUCT_TYPES.ARTICLE:
			return fetchArticleGallery;

		case GLOBAL_PRODUCT_TYPES.EVENT_LIVE:
			return fetchEventLiveGallery;

		default:
			return fetchVodGallery;
	}
};

export const getNameState = (type) => {
	switch (type) {
		case GLOBAL_PRODUCT_TYPES.ARTICLE:
			return 'articles';

		case GLOBAL_PRODUCT_TYPES.EVENT_LIVE:
			return 'events_live';

		default:
			return 'vod';
	}
};

const {
	VOD,
	AUDIO,
	ARTICLE,
	LIVE_EVENT,
	SEASON,
	EPISODE,
	SERIES
} = PRODUCTS_TYPES;

export const getProductTypeAlias = ({
	type,
	is_audio = false,
	context = {}
}) => {
	const contextIsAudio = context?.is_audio;
	let audioDataSource = contextIsAudio;

	// == is intentionally used. It checks for both undefined and null
	if (contextIsAudio == null) {
		audioDataSource = is_audio;
	}

	const isAudioType = type === VOD && audioDataSource;

	const productType = isAudioType ? AUDIO : type;

	return i18n.t(`product_preview:product_type_alias.${productType}`);
};

export const checkProductSubtype = (subtype) => {
	const isArticle = subtype === ARTICLE;
	const isVod = subtype === VOD;
	const isLiveEvent = subtype === LIVE_EVENT;
	const isSeason = subtype === SEASON;
	const isEpisode = subtype === EPISODE;
	const isSeries = subtype === SERIES;

	return {
		isArticle,
		isVod,
		isLiveEvent,
		isSeason,
		isEpisode,
		isSeries
	};
};

export const getImage = ({ images, type }) => {
	if (
		typeof images === 'object' &&
		images &&
		images[type].data &&
		images[type].data.length > 0 &&
		images[type].data[0].url
	) {
		return images[type].data[0].url;
	}

	return '';
};

export const getProductSubcategories = (subcategory) => {
	return subcategory?.length && subcategory.slice(0, 3);
};

export function getDurationHoursAndMinutes(duration) {
	const minutes = moment.duration(duration, 'seconds').minutes();
	const hours = moment.duration(duration, 'seconds').hours();

	return { durationHours: hours, durationMinutes: minutes };
}

const metadataOrder = [
	{ name: 'sound' },
	{ name: 'subtitles' },
	{ name: 'actors' },
	{ name: 'writers' },
	{ name: 'directors' },
	{ name: 'producers' },
	{ name: 'dramaturgy' },
	{ name: 'libretto' },
	{ name: 'choreography' },
	{ name: 'lead' },
	{ name: 'curatorial_care' },
	{ name: 'authors' },
	{ name: 'conducting' }
];

export const getFilteredMetadata = ({ metadata }) => {
	// concat metadata and additional_metadata
	const data = { ...metadata, ...metadata?.additional_metadata };

	const prepareData = ({ name, ...rest }) => {
		const value = data[name];
		const isArrayType = Array.isArray(value);
		return { name, value, isArrayType, ...rest };
	};

	// sort metadata according to the order indicated
	// filter those metadata that have a value and return first 5 elements
	const filteredMetadata = metadataOrder
		.map(prepareData)
		.filter(({ value, isArrayType }) => (isArrayType ? value.length : value))
		.slice(0, 5);

	return filteredMetadata;
};

export const getRedirectUrl = ({ type, id }) => {
	const { VOD, ARTICLE, EVENT_LIVE, SERIES } = GLOBAL_PRODUCT_TYPES;

	switch (type) {
		case VOD:
			return `/panel/vod/vod/edit/${id}/metadata`;

		case SERIES:
			return `/panel/vod/series/edit/${id}/metadata`;

		case ARTICLE:
			return `/panel/articles/edit/${id}/metadata`;

		case EVENT_LIVE:
			return `/panel/events_live/edit/${id}/metadata`;

		default:
			return `/panel/vod/vod/edit/${id}/metadata`;
	}
};
