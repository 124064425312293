import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { checkIsAssetButtonHidden } from '../helpers';

const TranscodeButton = ({
	transcodeAsset,
	record,
	videoType,
	checkedPermissions
}) => {
	// send transcode request and update asset status
	const handleTranscode = async () => {
		await transcodeAsset(record.id, videoType);
	};

	const { uploaded_at } = record;
	const { canActivateProduct } = checkedPermissions;

	const isButtonHidden = checkIsAssetButtonHidden({
		uploaded_at,
		canActivateProduct
	});

	return (
		!isButtonHidden && (
			<button onClick={handleTranscode} className="btn btn-xs btn-primary">
				<i className="fa fa-exchange" />
			</button>
		)
	);
};

TranscodeButton.propTypes = {
	transcodeAsset: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
	videoType: PropTypes.string.isRequired
};

export default TranscodeButton;
