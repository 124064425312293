import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	limits: [],
	isLoaded: true,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH PRODUCT_GLOBAL_LIMIT ******************
			case types.FETCH_PRODUCT_GLOBAL_LIMIT_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_PRODUCT_GLOBAL_LIMIT_SUCCESS:
				draft.limits = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_PRODUCT_GLOBAL_LIMIT_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
