import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { InputLabel } from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<Field
			disabled={submitting}
			name="name"
			type="text"
			placeholder={t('tags:fields.name')}
			label={t('tags:fields.name')}
			id="name"
			requiredField={true}
			component={InputLabel}
			parse={(value) => value}
		/>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	checkedPermissions: PropTypes.object.isRequired
};

export default Fields;
