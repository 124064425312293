// Import translation
import i18n from 'i18next';

export const defaultFieldValues = {
	active: false
};

export const BASE_FIELDS = ({ isEdit, checkedPermissions }) => [
	{
		name: 'title',
		label: i18n.t('banners:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'description',
		label: i18n.t('banners:fields.description'),
		type: 'textarea'
	},
	{
		name: 'active',
		label: i18n.t('banners:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct
	},
	{
		name: 'group',
		label: i18n.t('banners:fields.group'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'url_pc',
		label: i18n.t('banners:fields.url_pc'),
		type: 'text'
	},
	{
		name: 'url_mobile',
		label: i18n.t('banners:fields.url_mobile'),
		type: 'text'
	},
	{
		name: 'created_at',
		label: i18n.t('banners:fields.created_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		label: i18n.t('banners:fields.updated_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	}
];
