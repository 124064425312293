import React from 'react';
import { Link } from 'react-router-dom';

// Import helpers
import { convertPromotionValue } from './helpers';
// Import translation
import i18n from 'i18next';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

const renderPromotionType = (promotion_type) => {
	switch (promotion_type) {
		case 'percentage':
			return i18n.t('promotions:form:select_values:promotion_type.percentage');
		case 'discount':
			return i18n.t('promotions:form:select_values:promotion_type.discount');
		case 'static_price':
			return i18n.t(
				'promotions:form:select_values:promotion_type.static_price'
			);

		default:
			return promotion_type;
	}
};

/* ------------- TO DO -----------------
Determine if we need columns: 
- "users_specification" 
- "products_specification"
for promotions table.

commented code can be removed If columns are not needed
----------------------------------------
*/

// const renderUsersSpecification = (users_specification) => {
// 	switch (users_specification) {
// 		case 'all_users':
// 			return i18n.t(
// 				'promotions:form:select_values:user_specification.all_users'
// 			);
// 		case 'packets_list':
// 			return i18n.t(
// 				'promotions:form:select_values:user_specification.packets_list'
// 			);
// 		case 'users_list':
// 			return i18n.t(
// 				'promotions:form:select_values:user_specification.users_list'
// 			);

// 		default:
// 			return users_specification;
// 	}
// };

// const renderProductSpecification = (products_specification) => {
// 	switch (products_specification) {
// 		case 'all_products':
// 			return i18n.t(
// 				'promotions:form:select_values:product_specification.all_products'
// 			);
// 		case 'products_list':
// 			return i18n.t(
// 				'promotions:form:select_values:product_specification.products_list'
// 			);

// 		default:
// 			return products_specification;
// 	}
// };

const renderPromotionTrigger = (trigger) => {
	switch (trigger) {
		case 'auto':
			return i18n.t('promotions:form:select_values:trigger.auto');
		case 'code':
			return i18n.t('promotions:form:select_values:trigger.code');

		default:
			return trigger;
	}
};
export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('promotions:table_columns.active'),
		accessor: 'active',
		width: 75,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => ActiveIcon({ active })
	},
	{
		Header: i18n.t('promotions:table_columns.name'),
		accessor: 'name',
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => <Link to={`/panel/promotions/edit/${id}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('promotions:table_columns.description'),
		accessor: 'description'
	},
	{
		Header: i18n.t('promotions:table_columns.promotion_type'),
		accessor: 'promotion_type',
		width: 140,
		Cell: ({
			row: {
				_original: { promotion_type }
			}
		}) => renderPromotionType(promotion_type)
	},
	{
		Header: i18n.t('promotions:table_columns.promotion_value'),
		accessor: 'promotion_value',
		width: 130,
		Cell: ({
			row: {
				_original: { promotion_type, promotion_value }
			}
		}) => convertPromotionValue(promotion_type, promotion_value)
	},
	{
		Header: i18n.t('promotions:table_columns.since'),
		accessor: 'since'
	},
	{
		Header: i18n.t('promotions:table_columns.till'),
		accessor: 'till'
	},
	// {
	// 	Header: i18n.t('promotions:table_columns.users_specification'),
	// 	accessor: 'users_specification',
	// 	Cell: ({
	// 		row: {
	// 			_original: { users_specification }
	// 		}
	// 	}) => renderUsersSpecification(users_specification)
	// },
	// {
	// 	Header: i18n.t('promotions:table_columns.products_specification'),
	// 	accessor: 'products_specification',
	// 	Cell: ({
	// 		row: {
	// 			_original: { products_specification }
	// 		}
	// 	}) => renderProductSpecification(products_specification)
	// },
	{
		Header: i18n.t('promotions:table_columns.trigger'),
		accessor: 'trigger',
		Cell: ({
			row: {
				_original: { trigger }
			}
		}) => renderPromotionTrigger(trigger)
	},
	{
		Header: i18n.t('promotions:table_columns.code'),
		accessor: 'code'
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
