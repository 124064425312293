import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
// Import router
import { BrowserRouter } from 'react-router-dom';

// Import react redux
import { Provider } from 'react-redux';

// Import react query provider
import ReactQueryProvider from './ReactQueryProvider';

// Import store
import store from './store/store';

// Import translations
import './i18n';

// Import vendors js
import './vendors/vendors';

// Import styles
import './scss/main.scss';

// Import components
import App from './App';

const Root = () => (
	<Provider store={store}>
		<ReactQueryProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ReactQueryProvider>
	</Provider>
);

if (process.env.NODE_ENV !== 'development') {
	console.log = () => {};
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
