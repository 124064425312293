import i18n from 'i18next';

// Import helpers
import { slug_info } from './helpers';
import { getCharactersNumberWithoutSpace } from 'components/helpers/characters_counter';

export const BASE_FIELDS = ({ checkedPermissions, currentValues }) => [
	{
		name: 'name',
		label: i18n.t('institutions:fields.name'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'active',
		label: i18n.t('institutions:fields.active'),
		type: 'checkbox',
		defaultValue: false,
		isVisible: checkedPermissions.canActivate
	},
	/* canActive in is_main is intended, so as not to change all permissions, permissions need to be expanded in the future */
	{
		name: 'is_main',
		label: i18n.t('institutions:fields.is_main'),
		type: 'checkbox',
		required: false,
		isVisible: checkedPermissions.canActivate
	},
	{
		name: 'description',
		label: i18n.t('institutions:fields.description'),
		type: 'editor',
		requiredField: true,
		disabledAddingImage: true,
		disabledAddingMedia: true,
		warningInfo:
			getCharactersNumberWithoutSpace(currentValues?.description) > 300,
		fieldInfo: i18n.t('institutions:fields_info.description')
	},
	{
		name: 'url',
		label: i18n.t('institutions:fields.url'),
		type: 'text'
	},
	{
		name: 'slug',
		label: i18n.t('institutions:fields.slug'),
		type: 'text',
		requiredField: true,
		info: slug_info
	},
	{
		name: 'contact',
		label: i18n.t('institutions:fields.contact'),
		type: 'text'
	},
	{
		name: 'additional_data.statute_alias',
		label: i18n.t('institutions:fields.additional_data.statute_alias'),
		type: 'select',
		disabled: !checkedPermissions.canActivate
	}
];

export const SOCIAL_MEDIA_FIELDS = [
	{
		name: 'additional_data.facebook',
		label: i18n.t('institutions:fields.additional_data.facebook'),
		type: 'text'
	},
	{
		name: 'additional_data.instagram',
		label: i18n.t('institutions:fields.additional_data.instagram'),
		type: 'text'
	},
	{
		name: 'additional_data.twitter',
		label: i18n.t('institutions:fields.additional_data.twitter'),
		type: 'text'
	},
	{
		name: 'additional_data.tiktok',
		label: i18n.t('institutions:fields.additional_data.tiktok'),
		type: 'text'
	},
	{
		name: 'additional_data.pinterest',
		label: i18n.t('institutions:fields.additional_data.pinterest'),
		type: 'text'
	}
];
