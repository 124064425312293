import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setActivityQuery,
	fetchActivities,
	createFiltersSuggestions,
	dictionaryModelTypes
} from 'store/actions';

// Import columns
import { createColumns } from './activity_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Activity = ({
	error,
	setActivityQuery,
	fetchActivities,
	resources,
	createFiltersSuggestions,
	dictionaryModelTypes,
	filters
}) => {
	// Actions to fetch data for filters
	const filterActions = {
		dictionaryModelTypes
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters
	});

	const { columns } = resources;
	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="activity"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchActivities}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setActivityQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Activity.propTypes = {
	error: PropTypes.bool.isRequired,
	setActivityQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchActivities: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired
};

const mapStateToProps = ({ activity, dictionary }) => {
	return {
		error: activity.table.error,
		resources: activity.table,
		filters: { model_type: dictionary.model_types }
	};
};

export default connect(mapStateToProps, {
	setActivityQuery,
	fetchActivities,
	createFiltersSuggestions,
	dictionaryModelTypes
})(Activity);
