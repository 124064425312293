import React from 'react';
import i18n from 'i18next';

// Import components
import VodCreateForm from 'components/views/vod/vod_create/vod_create_form/VodCreateForm';

export const vodCreateTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <VodCreateForm />
	}
];
