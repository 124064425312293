import React from 'react';
import i18n from 'i18next';

// Import components
import ArticlesForm from 'components/views/articles/articles_form/ArticlesForm';
import ArticlesImages from 'components/views/articles/articles_file/ArticlesImages';
import ArticleMaterials from 'components/views/articles/common/materials/ArticleMaterials';
import ArticlesAvailability from 'components/views/articles/articles_availability/ArticlesAvailability';
import ArticlesPrices from 'components/views/articles/articles_prices/ArticlesPrices';
import Payments from 'components/views/payments/Payments';
import ArticlesUploadMaterial from 'components/views/articles/common/materials/ArticlesUploadMaterial';
import ArticlesPacketsAssigned from 'components/views/articles/articles_packets_assigned/ArticlesPacketsAssigned';
import ProductVisibility from 'components/utilities/product_visibility/table/ProductVisibility';
import ProductMetricFormOther from 'components/utilities/product_metric/other_type_product/ProductMetricFormOther';
import ActivityProduct from 'components/views/activity_product/ActivityProduct';
import ProductGlobalLimit from 'components/utilities/product_global_limit/ProductGlobalLimit';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { ARTICLES_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { articlesTranslationValidate } from 'components/utilities/content_translation/validation';

// Import variables
import {
	GLOBAL_LIMIT_PRODUCT_TYPES,
	GLOBAL_PRODUCT_TYPES
} from 'components/helpers/variables';
import { METRIC_PRODUCT_TYPES } from 'components/utilities/product_metric/helpers/helpers';

const { ARTICLE } = GLOBAL_PRODUCT_TYPES;

const articlesBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <ArticlesForm />
	},
	{
		name: i18n.t('common:tabs.metric'),
		path: 'metric',
		disableOnCreate: true,
		component: (
			<ProductMetricFormOther productTypeData={METRIC_PRODUCT_TYPES.ARTICLES} />
		)
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <ArticlesImages />
	},
	{
		name: i18n.t('common:tabs.upload_materials'),
		path: 'upload_materials',
		disableOnCreate: true,
		component: <ArticlesUploadMaterial />,
		isDisabled: !(process.env.REACT_APP_UPLOAD_MATERIALS_TAB === 'true')
	},
	{
		name: i18n.t('common:tabs.materials'),
		path: 'materials',
		disableOnCreate: true,
		component: <ArticleMaterials />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <ArticlesAvailability />
	},
	{
		name: i18n.t('common:tabs.visibility'),
		path: 'visibility',
		disableOnCreate: true,
		component: <ProductVisibility productType={ARTICLE} />
	},
	{
		name: i18n.t('common:tabs.prices'),
		path: 'prices',
		disableOnCreate: true,
		component: <ArticlesPrices />
	},
	{
		name: i18n.t('common:tabs.payments'),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.packets_assigned'),
		path: 'packets_assigned',
		disableOnCreate: true,
		component: <ArticlesPacketsAssigned />
	},
	{
		name: i18n.t('common:tabs.activity'),
		path: 'activity_product',
		disableOnCreate: true,
		component: <ActivityProduct />
	},
	{
		name: i18n.t('common:tabs.global_limit'),
		path: 'global_limit',
		disableOnCreate: true,
		component: (
			<ProductGlobalLimit productType={GLOBAL_LIMIT_PRODUCT_TYPES.ARTICLE} />
		)
	}
];

export const articlesTabs = (contentTranslationLanguages) => [
	...articlesBasicTabs,
	...generateContentTranslationsTabs(
		ARTICLES_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		articlesTranslationValidate
	)
];
