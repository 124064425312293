import { combineReducers } from 'redux';
// Import reducers
import table from './institutions_panel_reducer';
import form from './institutions_form_reducer';
import files from './institutions_files_reducer';

export default combineReducers({
	table,
	form,
	files
});
