// ----------- BLUEONLINE ROLES -----------
export const BLUE_ONLINE_ROLES = {
	ADMINISTRATOR: 'ADMINISTRATOR',
	REDACTOR: 'REDACTOR',
	EDITOR: 'EDITOR',
	HELP_DESK: 'HELP_DESK'
};

export const mockRoles = ['ADMINISTRATOR', 'REDACTOR', 'EDITOR', 'HELP_DESK'];

// ----------- VECTRA ROLES -----------
// list of all user roles
export const allRoles = {
	CMS_WRITE: 'CMS_WRITE',
	CMS_READ: 'CMS_READ',
	CRM_WRITE: 'CRM_WRITE',
	CRM_READ: 'CRM_READ',
	DATA_WRITE: 'DATA_WRITE',
	DATA_READ: 'DATA_READ',
	PORTAL_WRITE: 'PORTAL_WRITE',
	PORTAL_READ: 'PORTAL_READ',
	INGESTION_WRITE: 'INGESTION_WRITE',
	INGESTION_READ: 'INGESTION_READ',
	ADMIN_WRITE: 'ADMIN_WRITE',
	ADMIN_READ: 'ADMIN_READ',
	VIDEO_WRITE: 'VIDEO_WRITE',
	VIDEO_READ: 'VIDEO_READ',
	SUPPORT_WRITE: 'SUPPORT_WRITE'
};

// specific roles
export const cms = ['CMS_WRITE', 'CMS_READ'];
export const crm = ['CRM_WRITE', 'CRM_READ'];
export const data = ['DATA_WRITE', 'DATA_READ'];
export const portal = ['PORTAL_WRITE', 'PORTAL_READ'];
export const ingestion = ['INGESTION_WRITE', 'INGESTION_READ'];
export const admin = ['ADMIN_WRITE', 'ADMIN_READ'];
export const video = ['VIDEO_WRITE', 'VIDEO_READ'];
export const support = ['SUPPORT_WRITE'];

// all roles grouped in single object
export const allRolesGrouped = {
	cms,
	crm,
	data,
	portal,
	ingestion,
	admin,
	video,
	support
};

// ----------- CKF ROLES -----------
export const CKF_ROLES = ['ADMINISTRATOR', 'REDACTOR', 'EDITOR', 'HELP_DESK'];

// Mock roles for auth
// export const mockRoles = [
// 	'CMS_WRITE',
// 	'CMS_READ',
// 	'CRM_WRITE',
// 	'CRM_READ',
// 	'DATA_WRITE',
// 	'DATA_READ',
// 	'PORTAL_WRITE',
// 	'PORTAL_READ',
// 	'INGESTION_WRITE',
// 	'INGESTION_READ',
// 	'ADMIN_WRITE',
// 	'ADMIN_READ',
// 	'VIDEO_WRITE',
// 	'VIDEO_READ',
// 	'SUPPORT_WRITE'
// ];

// ---------------------------------
