import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setTagsQuery,
	fetchTags,
	deleteTag,
	createFiltersSuggestions
} from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './tags_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Tags = ({
	error,
	setTagsQuery,
	fetchTags,
	deleteTag,
	resources,
	createFiltersSuggestions,
	filters
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	// execute filters logic
	useFilterLogic({
		createFiltersSuggestions,
		filters
	});

	return (
		// Dispatch fetchTags, deleteTag action in tags
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="tags"
			addLinkText={t('tags:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchTags}
			deleteItemAction={deleteTag}
			notificationDeleteSuccessTxt={t('tags:notification.delete_success')}
			notificationDeleteErrorTxt={t('tags:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setTagsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Tags.propTypes = {
	error: PropTypes.bool.isRequired,
	setTagsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchTags: PropTypes.func.isRequired,
	deleteTag: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object
};

const mapStateToProps = ({ tags: { table } }) => {
	return {
		error: table.error,
		resources: table,
		filters: {}
	};
};

export default connect(mapStateToProps, {
	setTagsQuery,
	fetchTags,
	deleteTag,
	createFiltersSuggestions
})(Tags);
