import React, { Fragment } from 'react';
import { array, string } from 'prop-types';

// Import styles
import { Item } from './styles';

const ElementsList = ({ typeName, elements }) => {
	return elements.slice(0, 5).map((item, index) => (
		<Fragment key={`${typeName}-${item}`}>
			<Item>{item}</Item>
			{index !== elements.length - 1 && ', '}
		</Fragment>
	));
};

ElementsList.propTypes = {
	typeName: string.isRequired,
	elements: array.isRequired
};

export default ElementsList;
