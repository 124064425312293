import React from 'react';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { node } from 'prop-types';

const queryCache = new QueryCache();

const overrides = {
	queryCache,
	defaultOptions: {
		queries: {
			retry: 0,
			refetchOnWindowFocus: false
		}
	}
};

const queryClient = new QueryClient(overrides);

const ReactQueryProvider = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	);
};

ReactQueryProvider.propTypes = {
	children: node.isRequired
};

export default ReactQueryProvider;
