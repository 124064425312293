import React from 'react';

// Import translation
import i18n from 'i18next';

export const label_info = <div>{i18n.t('sections:label_info')}</div>;

export const type_info = (
	<>
		<div>{i18n.t('sections:type_info')}</div>
	</>
);

export const types = [
	{
		name: i18n.t('sections:fields.types.large'),
		id: 'large'
	},
	{
		name: i18n.t('sections:fields.types.carousel'),
		id: 'carousel'
	},
	{
		name: i18n.t('sections:fields.types.schedule'),
		id: 'schedule'
	},
	{
		name: i18n.t('sections:fields.types.promoted'),
		id: 'promoted'
	},
	{
		name: i18n.t('sections:fields.types.banner'),
		id: 'banner'
	},
	{
		name: i18n.t('sections:fields.types.high_banner'),
		id: 'high_banner'
	},
	{
		name: i18n.t('sections:fields.types.virtual'),
		id: 'virtual'
	},
	{
		name: i18n.t('sections:fields.types.normal_cover'),
		id: 'normal_cover'
	},
	{
		name: i18n.t('sections:fields.types.normal'),
		id: 'normal'
	}
	// deprecated in backend
	// {
	// 	name: i18n.t('sections:fields.types.live_epg'),
	// 	id: 'live_epg'
	// },
];

export const addTranslation = (resourse) =>
	resourse.map((item) => ({
		...item,
		name: i18n.t(`sections:fields.algorithm_id.${item.name}`)
	}));
