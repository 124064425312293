import React from 'react';
import PropTypes from 'prop-types';
import { sortableElement } from 'react-sortable-hoc';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { getImgUrl } from '../helpers/index';

// Import variables
import { GLOBAL_PRODUCT_TYPES } from 'components/helpers/variables';

const { VOD, AUDIO } = GLOBAL_PRODUCT_TYPES;

const SortableItem = sortableElement(
	({ value, type, img, handleRemoveItem, id, isAudio }) => {
		const { t } = useTranslation();
		const handleRemove = () => handleRemoveItem(id);
		const imgUrl = getImgUrl(img);

		const vodType = isAudio ? AUDIO : VOD;
		const productType = type === VOD ? vodType : type;

		return (
			<li className="sortable-list__li">
				<span className="item">
					{imgUrl && (
						<span className="item__img">
							<img className="img-fluid" src={imgUrl} alt={value} />
						</span>
					)}
					<button className="item__delete" onClick={handleRemove} type="button">
						<span className="btn-txt">
							{t('common:delete')}{' '}
							<i className="fa fa-trash-o" aria-hidden="true"></i>
						</span>
						<span className="btn-icon">
							<i className="fa fa-times" aria-hidden="true"></i>
						</span>
					</button>
					<span className="item__title">{value}</span>
					<span className="item__category">{productType}</span>
				</span>
			</li>
		);
	}
);

SortableItem.propTypes = {
	value: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	handleRemoveItem: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired
};

export default SortableItem;
