import _ from 'lodash';

// Import actions
import {
	fetchVodDetails,
	fetchSeries,
	fetchSeason,
	fetchEpisode,
	fetchEventLive,
	fetchArticle
} from 'store/actions';

export const METRIC_PRODUCT_TYPES = {
	VOD: {
		name: 'vod',
		submitData: {
			submitAPI: 'vod',
			apiSlug: 'update',
			redirectPath: 'vod'
		},
		action: fetchVodDetails,
		reduxPath: { type: 'vod', formType: 'form' }
	},
	SERIES: {
		name: 'series',
		submitData: {
			submitAPI: 'vod/series',
			apiSlug: 'update',
			redirectPath: 'vod/series'
		},
		action: fetchSeries,
		reduxPath: { type: 'vod', formType: 'series_form' }
	},
	SEASON: {
		name: 'season',
		submitData: {
			submitAPI: 'vod/season',
			apiSlug: 'update',
			redirectPath: 'vod/season'
		},
		action: fetchSeason,
		reduxPath: { type: 'vod', formType: 'season_form' }
	},
	EPISODE: {
		name: 'episode',
		submitData: {
			submitAPI: 'vod/episodes',
			apiSlug: 'update',
			redirectPath: 'vod/episode'
		},
		action: fetchEpisode,
		reduxPath: { type: 'vod', formType: 'episode_form' }
	},
	EVENT_LIVE: {
		name: 'event_live',
		submitData: {
			submitAPI: 'products/liveevents',
			apiSlug: '',
			redirectPath: 'events_live'
		},
		action: fetchEventLive,
		reduxPath: { type: 'events_live', formType: 'form' }
	},
	ARTICLES: {
		name: 'articles',
		submitData: {
			submitAPI: 'products/articles',
			apiSlug: '',
			redirectPath: 'articles'
		},
		action: fetchArticle,
		reduxPath: { type: 'articles', formType: 'form' }
	}
};

// function prepares data in proper format for csv file. Instead of gibberish from
// API request it sets nice filtered and translated data
export const prepareMetricCSVData = ({ data, additionalResources }) => {
	const { fieldsData } = additionalResources;

	const fieldsToNameLabelMap = fieldsData.map(({ name, label }) => ({
		name,
		label
	}));

	const fieldsToNamesMap = fieldsData.map((field) => field.name);

	// filter only existing data based on metric fields names
	const existingDataByFieldsNames = _.pick(data, fieldsToNamesMap);

	const preparedData = fieldsToNameLabelMap.map(({ name, label }) => {
		return {
			label,
			data: _.get(existingDataByFieldsNames, name) ?? ''
		};
	});

	return preparedData;
};

// authors key is nested differently in data structure, depending on product
export const prepareAuthorsFieldName = (productTypeName) => {
	switch (productTypeName) {
		case METRIC_PRODUCT_TYPES.ARTICLES.name:
			return 'data.authors';

		default:
			return 'additional_metadata.authors';
	}
};
