import styled, { css } from 'styled-components';

// HELPERS
import { customMinResponsive, productTextColor, size } from '../../../styles';

export const InfoContainer = styled.div`
	font-size: 1.2rem;
	line-height: 1.4rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 80rem;
			font-size: 1.8rem;
			line-height: 2.5rem;
		`
	)}
`;

export const Title = styled.span`
	color: ${({ theme }) => theme.graySemiLight};
	padding-right: 0.8rem;
	white-space: nowrap;
`;

export const InfoElementContainer = styled.div`
	display: flex;
	margin-bottom: 6px;

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: 4px;
		`
	)}
`;

export const Item = styled.span`
	cursor: default;
	${productTextColor}
`;
