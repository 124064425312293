import React from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { bool, number } from 'prop-types';

// Import styles
import { Container, Text } from './styles';

// Import helpers
import { checkProductSubtype } from '../../helpers';

const Description = ({ maxHeight, isColumnsGrid }) => {
	const { metadata, description, subtype } = useSelector(
		({ product_preview }) => product_preview.data
	);

	const summaryLong = metadata?.summary_long || description;

	if (!summaryLong) return null;

	const { isArticle } = checkProductSubtype(subtype);

	return (
		<Container
			maxHeight={maxHeight}
			isColumnsGrid={isColumnsGrid}
			isArticle={isArticle}
		>
			<Text isLightBackground={isArticle}>{ReactHtmlParser(summaryLong)}</Text>
		</Container>
	);
};

Description.propTypes = {
	maxHeight: number,
	isColumnsGrid: bool
};

export default Description;
