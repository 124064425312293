import i18n from 'i18next';

export const defaultFieldValues = {
	genres: [],
	slides: '',
	promoted: false,
	active: false,
	subscriber_local_limitation: false,
	adult: false,
	summary_short: '',
	summary_long: ''
};

export const BASE_FIELDS = (checkedPermissions) => [
	{
		name: 'active',
		label: i18n.t('channels:system:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct,
		fieldInfo: i18n.t('channels:form:fields_info.active')
	},
	{
		name: 'external_id',
		label: i18n.t('channels:form:fields.external_uid'),
		type: 'text',
		requiredField: true
	},

	{
		name: 'title',
		label: i18n.t('channels:form:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'summary_long',
		label: i18n.t('channels:form:fields.description'),
		type: 'textarea'
	},
	{
		name: 'summary_short',
		label: i18n.t('channels:form:fields.description'),
		type: 'textarea'
	},
	{
		name: 'genres',
		label: i18n.t('channels:form:fields.genres'),
		type: 'multi-select'
	},
	{
		name: 'data.slides',
		label: i18n.t('channels:form:fields.slides'),
		type: 'text'
	},
	{
		name: 'data.deep_link',
		label: i18n.t('channels:form:fields.deep_link'),
		type: 'text'
	},
	{
		name: 'rating',
		label: i18n.t('channels:form:fields.rating'),
		type: 'multi-select',
		mode: 'single',
		requiredField: true
	},
	{
		name: 'data.adult',
		label: i18n.t('channels:form:fields.adult'),
		type: 'checkbox'
	},
	{
		name: 'data.visible_secondary',
		label: i18n.t('channels:system:fields.visible_secondary'),
		type: 'checkbox'
	},
	{
		name: 'data.visible_at_home',
		label: i18n.t('channels:system:fields.visible_at_home'),
		type: 'checkbox'
	},
	{
		name: 'data.casts',
		label: i18n.t('channels:system:fields.casts'),
		type: 'multi-select'
	},
	{
		name: 'platforms',
		label: i18n.t('channels:system:fields.platforms'),
		type: 'multi-select'
	},
	{
		name: 'data.rank',
		label: i18n.t('channels:system:fields.rank'),
		type: 'number',
		requiredField: true
	},
	{
		name: 'data.subscriber_local_limitation',
		label: i18n.t('channels:system:fields.subscriber_local_limitation'),
		type: 'checkbox'
	},
	{
		name: 'data.parallel_streams_limit',
		label: i18n.t('channels:system:fields.parallel_streams_limit'),
		type: 'number'
	}
];
