import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import helpers
import { assetGroupsTabs, getCancelButtonPath } from './helpers';

const AssetGroupsTabs = () => {
	const { languages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);
	const { productSubtype, model_id } = useParams();

	const cancelButtonPath = getCancelButtonPath({ productSubtype, model_id });

	return <Tabs tabs={assetGroupsTabs(languages, cancelButtonPath)} />;
};

export default AssetGroupsTabs;
