import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';
import { convertRolesToValuePairs, removeUselessRoles } from './helpers';

// ******************** FETCH OPERATOR ROLES ********************
export const fetchOperatorRoles = () => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_OPERATORS_ROLES_LOADING,
			payload: true
		});

		const { data } = await services.get(`/operator/listRoles`);

		const filteredRoles = removeUselessRoles(data);
		const payload = convertRolesToValuePairs(filteredRoles);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_OPERATORS_ROLES_SUCCESS,
			payload
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_OPERATORS_ROLES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH OPERATOR TO EDIT ********************
export const fetchOperator = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_OPERATOR_LOADING,
			payload: true
		});

		const response = await services.get(`/operator/${id}`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_OPERATOR_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_OPERATOR_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** FETCH INSTITUTIONS - TABLE DATA ********************
export const fetchOperatorInstitutions = () => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_OPERATORS_INSTITUTIONS_LOADING,
			payload: true
		});

		const url = '/productsgroup/list';

		const {
			data: { data }
		} = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_OPERATORS_INSTITUTIONS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_OPERATORS_INSTITUTIONS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
