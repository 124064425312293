import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import {
	InputLabel,
	TinyTextEditor,
	DatePickerLabel
} from 'components/common/inputs';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Field
				disabled={submitting}
				name="action"
				type="text"
				placeholder={t('agreements_versions:fields.action')}
				label={t('agreements_versions:fields.action')}
				id="action"
				required={true}
				component={InputLabel}
				parse={(value) => value}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={submitting}
				name="description"
				type="textarea"
				id="description"
				required={true}
				component={TinyTextEditor}
			/>
			<div className="hr-line-dashed">&nbsp;</div>

			<Field
				disabled={submitting}
				name="applicable_from"
				type="text"
				placeholder={t('agreements_versions:fields.applicable_from')}
				label={t('agreements_versions:fields.applicable_from')}
				id="applicable_from"
				required={true}
				showTime={true}
				component={DatePickerLabel}
			/>
			<div className="hr-line-dashed">&nbsp;</div>
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
