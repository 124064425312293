// -------------------- GET UPLOAD MATERIALS URL --------------------
export const GET_UPLOAD_MATERIALS_URL_LOADING =
	'GET_UPLOAD_MATERIALS_URL_LOADING';
export const GET_UPLOAD_MATERIALS_URL_SUCCESS =
	'GET_UPLOAD_MATERIALS_URL_SUCCESS';
export const GET_UPLOAD_MATERIALS_URL_ERROR = 'GET_UPLOAD_MATERIALS_URL_ERROR';
// -------------------- GET UPLOADED MATERIALS --------------------
export const GET_UPLOADED_MATERIALS_LOADING = 'GET_UPLOADED_MATERIALS_LOADING';
export const GET_UPLOADED_MATERIALS_SUCCESS = 'GET_UPLOADED_MATERIALS_SUCCESS';
export const GET_UPLOADED_MATERIALS_ERROR = 'GET_UPLOADED_MATERIALS_ERROR';
// -------------------- CLEAR UPLOADED MATERIALS TABLE --------------------
export const CLEAR_UPLOAD_MATERIALS_TABLE = 'CLEAR_UPLOAD_MATERIALS_TABLE';
