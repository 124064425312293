import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { withTranslation } from 'react-i18next';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Col from 'reactstrap/lib/Col';

// Import helpers
import { init_options } from './helpers';
import { REQUIRED_MARK } from 'components/helpers/variables';

class TinyTextEditor extends Component {
	// state = { content: '', characterCountWithoutSpaces: 0 };
	state = { content: '' };

	componentDidUpdate(prevProps) {
		const { input } = this.props;
		if (prevProps.input.value !== input.value) {
			// Check if prev input value is not equal to current input value
			this.setState({ content: input.value });
		}
	}

	handleEditorChange = (content, editor) => {
		// const { getCharacterCountWithoutSpaces } = editor.plugins.wordcount.body;
		// const characterCountWithoutSpaces = getCharacterCountWithoutSpaces();

		// this.setState({ characterCountWithoutSpaces });

		const { input } = this.props;
		// Update state
		this.setState({ content });
		// Update input value
		input.onChange(content);
	};

	render() {
		const {
			t,
			input,
			id,
			meta: { touched, error, submitError },
			labelColumn,
			inputColumn,
			label,
			requiredField,
			disabledAddingImage,
			disabledAddingMedia,
			disabled,
			warningInfo,
			fieldInfo
		} = this.props;

		const { characterCountWithoutSpaces } = this.state;

		const renderFieldInfo = () => {
			if (touched && (error || submitError)) return null;
			if (fieldInfo) {
				return (
					<span className="input-info m-b-sm">
						<i className="fa fa-info-circle m-r-sm"></i>
						{fieldInfo}
					</span>
				);
			}
		};

		return (
			<FormGroup
				data-testid={`form-group-${input.name}`}
				row
				className={`${touched && error && 'has-error'}`}
			>
				<Label
					for={id}
					sm={labelColumn}
					className="col-form-label col-form-label-lg"
				>
					{label} {requiredField && REQUIRED_MARK}
				</Label>
				<Col sm={inputColumn}>
					<Editor
						apiKey={process.env.REACT_APP_TINYMCE_KEY}
						value={this.state.content}
						init={init_options({
							disabledAddingImage,
							disabledAddingMedia,
							disabled
						})}
						onEditorChange={this.handleEditorChange}
						disabled={disabled}
					/>

					<input {...input} type="hidden" />

					{touched && (error || submitError) && (
						<span
							data-testid={`input-error-${input.name}`}
							className="input-error"
						>
							{error || submitError}
						</span>
					)}
					<span className="d-block">
						{t('common:characters_count')}: {characterCountWithoutSpaces}
					</span>
					{warningInfo && (
						<span className="input-warning">
							{t('common:form.fields.validation.value_too_long')}
						</span>
					)}
					{renderFieldInfo()}
				</Col>
			</FormGroup>
		);
	}
}

TinyTextEditor.defaultProps = {
	labelColumn: 2,
	inputColumn: 10,
	fieldInfo: '',
	warningInfo: false,
	requiredField: false
};

TinyTextEditor.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	children: PropTypes.object,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string,
	requiredField: PropTypes.bool
};

export default withTranslation()(TinyTextEditor);
