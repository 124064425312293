import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import logic
import useSubscribersButtons from './useSubscribersButtons';

// Import components
import { Button } from 'reactstrap';

const SubscribersButtons = ({ subscriberData, isFirebaseSubscriber }) => {
	const { t } = useTranslation();

	const {
		suspendSubscriber,
		resendResetPassword,
		resendApprovalEmail,
		hideResendApprovalEmailButton,
		suspendSubscriberButtonTitle
	} = useSubscribersButtons(subscriberData);

	return (
		<div className="d-flex justify-content-end">
			<Button className="m-l-sm" onClick={suspendSubscriber}>
				{suspendSubscriberButtonTitle}
			</Button>
			{!isFirebaseSubscriber && (
				<>
					<Button className="m-l-sm" onClick={resendResetPassword}>
						{t('subscribers:buttons.resend_reset_password')}
					</Button>
					<>
						{!hideResendApprovalEmailButton && (
							<Button className="m-l-sm" onClick={resendApprovalEmail}>
								{t('subscribers:buttons.resend_approval_email')}
							</Button>
						)}
					</>
				</>
			)}
		</div>
	);
};

SubscribersButtons.propTypes = {
	subscriberData: PropTypes.object.isRequired,
	isFirebaseSubscriber: PropTypes.bool.isRequired
};

export default SubscribersButtons;
