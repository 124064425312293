import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import helpers
import {
	modelOptions,
	getPeriodUnits,
	getPaymentMethods,
	checkLimits
} from '../helpers';

// Import variables
import { CORE_PATHS } from 'components/helpers/variables';

const { PASSES } = CORE_PATHS;

const useFields = () => {
	const { t } = useTranslation();
	const { type } = useParams();

	const { period_unit } = useSelector(({ dictionary }) => dictionary);

	const { change } = useForm();
	const {
		values: { base_amount }
	} = useFormState();

	const isPass = type === PASSES;

	const periodUnits = getPeriodUnits(period_unit);

	const paymentMethods = useMemo(() => getPaymentMethods(type), [type]);

	const limitLabel = isPass
		? t('prices:fields.usage_limit')
		: t('prices:fields.views_limit');

	const selectResources = {
		period_unit: periodUnits,
		since_first_access: modelOptions,
		payment_method: paymentMethods
	};

	const showLimits = checkLimits(type);

	const handleChangePriceNetto = () => {
		// Change value of price netto to price brutto
		const parsedPrice = parseFloat(base_amount * 1.23).toFixed(2);

		change('price', parsedPrice);
	};

	useEffect(() => {
		handleChangePriceNetto();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [base_amount]);

	return {
		limitLabel,
		selectResources,
		showLimits
	};
};

export default useFields;
