import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import actions
import {
	fetchMenuItem,
	fetchSectionsList,
	fetchProductsGenresList,
	fetchSectionGroupList
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import logic
import useMenuForm from './useMenuForm';

const MenuForm = ({ error, isLoaded, fetchMenuItem, ...restProps }) => {
	const { t } = useTranslation();

	const {
		sections,
		productsGenres,
		sectionGroup,
		onSubmit,
		formInitialValues,
		fetchFormResources,
		redirectPath,
		parent_id,
		isSubmenu
	} = useMenuForm(restProps);

	return (
		// Dispatch fetchMenuItem actions in in main_form
		<FormTemplate
			fetchFormResources={fetchFormResources}
			fetchResourceToEdit={fetchMenuItem}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={formInitialValues}
					decorators={decorators}
					validate={validate}
					onSubmit={onSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								parentId={parent_id}
								sections={sections}
								productsGenres={productsGenres}
								sectionGroup={sectionGroup}
								isSubmenu={isSubmenu}
							/>

							<FormButtons
								isButtonDisabled={submitting || error}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

MenuForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchMenuItem: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	isSubmenu: PropTypes.bool,
	fetchSectionsList: PropTypes.func.isRequired,
	fetchProductsGenresList: PropTypes.func.isRequired,
	fetchSectionGroupList: PropTypes.func.isRequired,
	sectionsList: PropTypes.array.isRequired,
	productsGenresList: PropTypes.array.isRequired,
	sectionGroupList: PropTypes.array.isRequired
};

const mapStateToProps = ({
	menu: { form },
	dictionary: { sectionsList, productsGenresList, sectionGroupList }
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		sectionsList,
		productsGenresList,
		sectionGroupList
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchMenuItem,
		fetchSectionsList,
		fetchProductsGenresList,
		fetchSectionGroupList
	}),
	withRouter
)(MenuForm);
