import moment from 'moment';
import 'moment-duration-format';
import i18n from 'i18next';

// Import variables
import {
	DURATION_CONVERT_TYPES,
	MATERIALS_COLLECTION_TYPES,
	DATE_FORMATS,
	DATE_NAMES
} from './variables';

// convert Date to  yyyy-mm-dd format
export const convertDateFormat = (date) =>
	`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

// Convert names to ids
export const convertNameToIDs = (resources, values) =>
	values.map((item) => resources.find((resource) => resource.name === item).id);

// Capitalize letter
export const capitalizeText = (text) =>
	text.charAt(0).toUpperCase() + text.slice(1).replace('_', ' ');

// Convert files
export const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

// Convert from grosz to zloty
export const convertPrice = (value) => {
	return value ? (parseFloat(value) * 0.01).toFixed(2) : 0;
};

// Convert from zloty to grosz
export const convertPriceToCents = (value) => {
	// parseInt is used because we need to send a number to API not a string
	return value ? parseInt((parseFloat(value) * 100).toFixed(0), 10) : 0;
};

// get table type based on url. Returns string with type
export const getTableType = (pathname) => {
	const pathnameArray = pathname.split('/');
	const tableType = pathnameArray[pathnameArray.length - 1];
	return tableType;
};

// get location type based on url. Returns string with type
export const getLocationType = (pathname) => {
	const pathnameArray = pathname.split('/');
	const locationType = pathnameArray[2];

	return locationType;
};

// get location subtype based on url. Returns string with type
export const getLocationSubtype = (pathname) => {
	const pathnameArray = pathname.split('/');
	const locationType = pathnameArray[3];
	return locationType;
};

// check if value is an int
export const checkIsInt = (value) => Number.isInteger(Number(value));

// convert external_ids to table
export const convertPacketExternalIds = (value) => [`${value}`];

// convert product duration time
export const convertDurationTime = ({ duration, type }) => {
	const { HMS_TO_SECONDS, SECONDS_TO_HMS } = DURATION_CONVERT_TYPES;
	const durationParsed = parseInt(duration);

	switch (type) {
		case HMS_TO_SECONDS: {
			// duration can be formatted as string or as moment dependent on
			// duration field modifications (if edited by field, it's moment else it's string)
			const formattedDuration = moment.isMoment(duration)
				? duration
				: moment.duration(duration);

			// Get hms from created data
			const hms = formattedDuration.format('HH:mm:ss').split(':');

			// Convert hms to seconds
			const seconds = +hms[0] * 60 * 60 + +hms[1] * 60 + +hms[2];

			// Seconds can be NaN when we get null to convert
			return seconds || 0;
		}

		case SECONDS_TO_HMS:
			const seconds = moment.duration(duration, 'seconds');

			// Change seconds to hh:mm:ss
			const formatted = seconds.format('HH:mm:ss', { trim: false });

			return formatted;

		default:
			return durationParsed || 0;
	}
};

export const getMaterialCollectionType = (locationType) => {
	switch (locationType) {
		case 'audio':
			return MATERIALS_COLLECTION_TYPES.AUDIO;

		default:
			return MATERIALS_COLLECTION_TYPES.VIDEO;
	}
};

/**
 * Convert array of data to array of objects with name and value keys
 * @param {array} array - array of data to convert
 * @param {string} nameKey - name of name key (value for name)
 * @param {string} valueKey - name of value key (value for value)
 * @returns {array} - [{name: 'item[nameKey]', value: item[valueKey]}, ...]
 */
export const convertArrayToValuePairsByKeyNames = (
	array,
	nameKey,
	valueKey,
	labelKey
) => {
	return array?.map((item) => {
		const name = item[nameKey] || item[0];
		const value = item[valueKey] || item[1];
		const label = item[labelKey] || '';

		const nameValue = label ? `${name} (${label})` : name;

		return { name: nameValue, value };
	});
};

export const convertValueToString = (array) =>
	array?.map((item) => {
		const value = item.value.toString();
		return { ...item, value };
	});

export const convertUTCDateToLocalDate = (date) =>
	moment
		.utc(date)
		.local()
		.format(DATE_FORMATS.LOCALE_DATE_FORMAT);

export const convertLocalDateToUTC = (date) =>
	moment(date)
		.utc()
		.format(DATE_FORMATS.UTC_DATE_FORMAT);

export const convertItemDateProperties = ({ item, isUTC = false }) => {
	let itemData = { ...item };

	// apply date conversion for all properties listed in datesToConvert array
	// gives ability to seamlessly add or remove data keys that are needed for conversion
	DATE_NAMES.forEach((dateName) => {
		if (item[dateName]) {
			const convertedDate = isUTC
				? convertUTCDateToLocalDate(item[dateName])
				: convertLocalDateToUTC(item[dateName]);
			itemData = { ...itemData, [dateName]: convertedDate };
		}
	});

	return itemData;
};

export const convertPriceModel = (value) =>
	value
		? i18n.t('prices:models.since_first_access')
		: i18n.t('prices:models.since_time_purchase');
