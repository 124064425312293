import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('agreements:table_columns.name'),
		accessor: 'name',
		Cell: ({
			row: {
				_original: { id, name }
			}
		}) => <Link to={`/panel/agreements/edit/${id}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('agreements:table_columns.requirements'),
		accessor: 'obligatory',
		Cell: ({
			row: {
				_original: { obligatory }
			}
		}) => <span>{obligatory === true ? 'Tak' : 'Nie'}</span>
	},
	{
		Header: i18n.t('agreements:table_columns.type'),
		accessor: 'type',
		Cell: ({
			row: {
				_original: { type }
			}
		}) => <span>{i18n.t(`agreements:types.${type}`)}</span>
	},
	{
		Header: i18n.t('agreements:table_columns.rank'),
		accessor: 'rank'
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
