import i18n from 'i18next';

// Import variables
import { CKF_ROLES } from 'components/helpers/roles_variables';

export const removeUselessRoles = (data) =>
	data.filter(({ name }) => CKF_ROLES.includes(name));

export const convertRolesToValuePairs = (data) =>
	data.map((item) => ({
		...item,
		name: i18n.t(`common:roles.${item.name}`),
		value: item.name
	}));
