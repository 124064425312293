import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'login',
		type: 'text',
		placeholder: i18n.t('subscribers:fields.login'),
		label: i18n.t('subscribers:fields.login'),
		requiredField: true,
		disabled: true
	},
	{
		name: 'provider',
		type: 'text',
		placeholder: i18n.t('subscribers:fields.provider'),
		label: i18n.t('subscribers:fields.provider'),
		disabled: true
	},
	{
		name: 'parental_control_pin',
		type: 'text',
		placeholder: i18n.t('subscribers:fields.parental_control_pin'),
		label: i18n.t('subscribers:fields.parental_control_pin'),
		requiredField: true
	},
	{
		name: 'hide_unavailable_products',
		type: 'checkbox',
		placeholder: i18n.t('subscribers:fields.hide_unavailable_products'),
		label: i18n.t('subscribers:fields.hide_unavailable_products')
	}
	/* ====== We don't need below fields in this project ====== */

	// {
	// 	name: 'payment_pin',
	// 	type: 'text',
	// 	placeholder: i18n.t('subscribers:fields.payment_pin'),
	// 	label: i18n.t('subscribers:fields.payment_pin'),
	// 	requiredField: true
	// },
	// {
	// 	name: 'account_pin',
	// 	type: 'text',
	// 	placeholder: i18n.t('subscribers:fields.account_pin'),
	// 	label: i18n.t('subscribers:fields.account_pin'),
	// 	requiredField: true
	// },
	// {
	// 	name: 'adult_access_pin',
	// 	type: 'text',
	// 	placeholder: i18n.t('subscribers:fields.adult_access_pin'),
	// 	label: i18n.t('subscribers:fields.adult_access_pin'),
	// 	requiredField: true
	// },
	// {
	// 	name: 'credits_limit',
	// 	type: 'number',
	// 	placeholder: i18n.t('subscribers:fields.credits_limit'),
	// 	label: i18n.t('subscribers:fields.credits_limit'),
	// 	requiredFields: true
	// 	disabled: true,
	// },
	// {
	// 	name: 'npvr_storage_limit',
	// 	type: 'number',
	// 	placeholder: i18n.t('subscribers:fields.npvr_storage_limit'),
	// 	label: i18n.t('subscribers:fields.npvr_storage_limit'),
	// 	requiredField: true
	// },
];
