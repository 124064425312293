import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';

// Import helrpes
import { submenuTabs } from './helpers';

const SubmenuTabs = () => {
	const { languages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);

	return <Tabs tabs={submenuTabs(languages)} />;
};

export default SubmenuTabs;
