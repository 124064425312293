import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';

// Import defaultValues
import { defaultFieldValues, BASE_FIELDS } from './fields/input_fields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import CSVForm from 'components/utilities/csv/form/CSVForm';

// Import helpers
import { convertDurationTime } from 'components/helpers/convert';
import { convertDataToIDS } from 'components/helpers/genres_helpers/helpers';
import { prepareMetricCSVData } from '../helpers/helpers';

// Import variables
import { DURATION_CONVERT_TYPES } from 'components/helpers/variables';
import { METRIC_PRODUCT_TYPES } from 'components/utilities/product_metric/helpers/helpers';

const { HMS_TO_SECONDS, SECONDS_TO_HMS } = DURATION_CONVERT_TYPES;

const ProductMetricFormOther = ({ history, productTypeData }) => {
	const { t } = useTranslation();

	// state change needed to trigger refetch data in Form Templates
	const [formToggle, setFormToggle] = useState(true);

	const { submitData, action, reduxPath, name } = productTypeData;

	// REDUX
	const dispatch = useDispatch();
	const { edit: initialValues, error, isLoaded } = useSelector(
		(state) => state[reduxPath.type][reduxPath.formType]
	);

	const {
		data,
		genres,
		tags,
		additional_data,
		additional_metadata
	} = initialValues;
	// const additionalData = initialValues?.metadata?.additional_data;

	const prepareAdditionalData = () => {
		if (additional_data?.length === 0 || !additional_data) {
			return defaultFieldValues.additional_data;
		} else {
			return additional_data;
		}
	};
	const prepareAdditionalMetadata = () => {
		if (additional_metadata?.length === 0 || !additional_metadata) {
			return defaultFieldValues.additional_metadata;
		} else {
			return additional_metadata;
		}
	};

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { data } = values;

		// convert duration from sec to min
		const durationInSeconds = convertDurationTime({
			duration: data.duration,
			type: HMS_TO_SECONDS
		});

		// selected genres mapped to array of ids
		const genresIds = convertDataToIDS(genres);

		// selected tags mapped to array of ids
		const tagsIds = convertDataToIDS(tags);

		// those fields does not apply to ariticle product
		if (name === METRIC_PRODUCT_TYPES.ARTICLES.name) {
			delete values.data.directors;
			delete values.data.actors;
			delete values.data.duration;
			delete values.year;
			delete values.data.writers;
		}

		// resources to submit
		const resources = {
			...initialValues,
			...values,
			genres_ids: genresIds,
			data: {
				...data,
				duration: durationInSeconds
			},
			tags_ids: tagsIds
		};

		// duration must be deleted here because event live have duration
		// but articles have not
		if (name === METRIC_PRODUCT_TYPES.ARTICLES.name) {
			delete resources.data.duration;
		}

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: submitData.submitAPI,
			redirectPath: submitData.redirectPath,
			apiSlug: submitData.apiSlug
		});

		setFormToggle((prev) => !prev);
	};

	// convert duration from sec to hms
	const durationInHms = convertDurationTime({
		duration: data.duration,
		type: SECONDS_TO_HMS
	});

	// form initial values
	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		data: {
			...data,
			duration: durationInHms
		},
		additional_data: prepareAdditionalData(),
		additional_metadata: prepareAdditionalMetadata()
	};

	const handleFetchResources = (...rest) => action(...rest)(dispatch);

	return (
		// Dispatch fetchVodDetails actions in vod_form
		<FormTemplate
			fetchResourceToEdit={handleFetchResources}
			error={error}
			isLoaded={isLoaded}
			triggerDataRefetch={formToggle}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, dirty }) => (
						<>
							<CSVForm
								data={formInitialValues}
								loading={!isLoaded}
								dirty={dirty}
								prepareDataFunction={prepareMetricCSVData}
								additionalResources={{ fieldsData: BASE_FIELDS(name) }}
							/>
							<form onSubmit={handleSubmit} decorators={decorators}>
								<Fields
									isEdit={isEdit}
									submitting={submitting}
									checkedPermissions={checkedPermissions}
									productTypeName={name}
								/>
								<FormButtons
									isButtonDisabled={submitting || error}
									path="vod"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						</>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ProductMetricFormOther.propTypes = {
	history: PropTypes.object.isRequired,
	productTypeData: PropTypes.object.isRequired
};

export default withRouter(ProductMetricFormOther);
