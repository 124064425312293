import React from 'react';
import i18n from 'i18next';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { PACKETS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Import components
import PacketsForm from '../../packets_form/PacketsForm';
import PacketsImages from 'components/views/packets/packets_file/PacketsImages';
import PacketsProducts from '../../packets_products/PacketsProducts';
import PacketsPrices from 'components/views/packets/packets_prices/PacketsPrices';
import PacketsAvailability from 'components/views/packets/packets_availability/PacketsAvailability';
import ProductVisibility from 'components/utilities/product_visibility/table/ProductVisibility';
import Payments from 'components/views/payments/Payments';
import CollectionPacketsAssigned from 'components/views/packets/packets_assigned/CollectionPacketsAssigned';
import ActivityProduct from 'components/views/activity_product/ActivityProduct';

// Import variables
import { GLOBAL_PRODUCT_TYPES } from 'components/helpers/variables';

// Validate
import { packetsTranslationValidate } from 'components/utilities/content_translation/validation';

export const packetsBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <PacketsForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <PacketsImages />
	},
	{
		name: i18n.t('common:tabs.products'),
		path: 'products',
		disableOnCreate: true,
		component: <PacketsProducts />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <PacketsAvailability />
	},
	{
		name: i18n.t('common:tabs.visibility'),
		path: 'visibility',
		disableOnCreate: true,
		component: <ProductVisibility productType={GLOBAL_PRODUCT_TYPES.PACKET} />
	},
	{
		name: i18n.t('common:tabs.prices'),
		path: 'prices',
		disableOnCreate: true,
		component: <PacketsPrices />
	},
	{
		name: i18n.t('common:tabs.payments'),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.packets_assigned'),
		path: 'packets_assigned',
		disableOnCreate: true,
		component: <CollectionPacketsAssigned />
	},
	{
		name: i18n.t('common:tabs.activity'),
		path: 'activity_product',
		disableOnCreate: true,
		component: <ActivityProduct />
	}
];

export const packetsTabs = (contentTranslationLanguages) => [
	...packetsBasicTabs,
	...generateContentTranslationsTabs(
		PACKETS_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		packetsTranslationValidate
	)
];
