import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	roles: [],
	edit: {},
	isLoaded: false,
	error: false,
	institutions: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** FETCH OPERATOR ROLES ******************
			case types.FETCH_OPERATORS_ROLES_LOADING:
				return { ...INITIAL_STATE };

			case types.FETCH_OPERATORS_ROLES_SUCCESS:
				draft.roles = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_OPERATORS_ROLES_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			// *************** FETCH OPERATOR INSTITUTIONS ***************
			case types.FETCH_OPERATORS_INSTITUTIONS_LOADING:
				draft.isLoaded = false;
				break;

			case types.FETCH_OPERATORS_INSTITUTIONS_SUCCESS:
				draft.institutions = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_OPERATORS_INSTITUTIONS_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			// ****************** FETCH OPERATOR ******************
			case types.FETCH_OPERATOR_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_OPERATOR_SUCCESS:
				draft.edit = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_OPERATOR_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
