import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import select options
import { select_operator } from './select_type_options';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ providersIds, submitting }) => {
	const selectResources = {
		provider_id: providersIds,
		operator: select_operator
	};

	return (
		<React.Fragment>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	providersIds: PropTypes.array,
	submitting: PropTypes.bool.isRequired
};

export default Fields;
