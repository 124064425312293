import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

const InputLabelLogin = ({
	label,
	input,
	meta: { touched, error },
	requiredField = false,
	...inputProps
}) => (
	<FormGroup className={`${touched && error ? 'has-error' : ''} text-left`}>
		<Label className="login__label" for={input.id}>
			{label} {requiredField && REQUIRED_MARK}
		</Label>
		<Input
			invalid={touched && error ? true : false}
			{...input}
			{...inputProps}
		/>
		{touched && error && (
			<span data-testid={`${input.name}-error`} className="input-error d-block">
				{error}
			</span>
		)}
	</FormGroup>
);

InputLabelLogin.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	meta: PropTypes.object,
	requiredField: PropTypes.bool
};

export default InputLabelLogin;
