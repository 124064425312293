import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchMainProducts } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import ProductsSelect from 'components/utilities/products_select/ProductsSelect';

// Import products tabs
import {
	vod,
	audio,
	channel,
	provisioning,
	collections,
	liveEvents
} from 'components/utilities/products_select/helpers/tabs';

const MainProducts = (props) => {
	const { t } = useTranslation();
	const { data, error, isLoaded, fetchMainProducts } = props;
	const tabs = [vod, audio, liveEvents, channel, provisioning, collections];

	const createSubmitData = (selectedData) => {
		const selection = { products: [] };

		selection.products = selectedData.map((item, index) => {
			let itemType = item.type;
			// change packet subtype (provision | collection) to type (packet)
			if (item.type === 'provision' || item.type === 'collection')
				itemType = 'packet';

			return {
				uuid: item.uuid,
				order: index + 1,
				type: itemType
			};
		});

		return selection;
	};

	return (
		<ProductsSelect
			api="packet"
			title={t('common:tabs.products')}
			tabs={tabs}
			openModalText={t('common:products.add_products')}
			buttonSubmitText={t('common:buttons.submit')}
			notificationName="products"
			initialData={data}
			error={error}
			isLoaded={isLoaded}
			fetchResourcesAction={fetchMainProducts}
			createSubmitData={createSubmitData}
		/>
	);
};

MainProducts.propTypes = {
	data: PropTypes.array.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	fetchMainProducts: PropTypes.func.isRequired
};

const mapStateToProps = ({ main: { products } }) => ({
	data: products.data,
	isLoaded: products.isLoaded,
	error: products.error
});

export default connect(mapStateToProps, { fetchMainProducts })(MainProducts);
