import styled, { css } from 'styled-components';

// Import helpers
import { customMinResponsive, productTextColor, size } from '../../../styles';

const LINE_HEIGHT_MOBILE = 2.1;
const LINE_HEIGHT_DESKTOP = 3.6;

export const Container = styled.div`
	width: 100%;
	z-index: 2;
	overflow: auto;
	max-height: ${24 * LINE_HEIGHT_MOBILE}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	position: relative;

	${customMinResponsive(
		size.medium,
		css`
			padding-right: ${({ isArticle }) => (isArticle ? '8.5vw' : 'unset')};
			max-height: ${({ maxHeight, isColumnsGrid }) =>
				isColumnsGrid ? `${maxHeight}px` : `${LINE_HEIGHT_DESKTOP * 24}rem`};
		`
	)}

	${({ theme, isNotAvailable }) =>
		isNotAvailable &&
		css`
			max-height: ${LINE_HEIGHT_MOBILE * 5}rem !important;
			overflow: hidden;

			${customMinResponsive(
				size.medium,
				css`
					max-height: ${LINE_HEIGHT_DESKTOP * 5}rem !important;
				`
			)}

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(
					180deg,
					transparent 0%,
					rgba(237, 236, 237, 0.5) 20%,
					${theme.product.lightBackground.background} 95%
				);

				z-index: 10;
			}
		`}

	

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.white};
		border-radius: 5px;
		height: 10%;
		width: 1px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 5px;
		background-color: #515151;
	}
`;

export const Text = styled.div`
	line-height: ${LINE_HEIGHT_MOBILE}rem;
	font-size: 1.4rem;
	font-weight: ${({ theme }) => theme.font.normal} !important;
	z-index: 2;
	overflow: hidden;
	transition: height ease 300ms;
	padding-right: 1rem;
	${productTextColor}

	${customMinResponsive(
		size.medium,
		css`
			line-height: ${LINE_HEIGHT_DESKTOP}rem;
			font-size: 2.4rem !important;
		`
	)}
`;
