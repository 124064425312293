import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	setEpisodesQuery,
	fetchEpisodes,
	fetchSeason,
	detachEpisode,
	setParentEpisodeId
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './episodes_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import hooks
import useAudioLocation from 'hooks/useAudioLocation';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import EpisodesModalWrapper from './episodes_modal_form/EpisodesModalWrapper';
import ModalForm from './episodes_modal_form/ModalForm';
import FilterButton from 'components/common/buttons/FilterButton';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const Episodes = ({
	error,
	setEpisodesQuery,
	fetchEpisodes,
	fetchSeason,
	detachEpisode,
	setParentEpisodeId,
	resources,
	match: {
		params: { id }
	}
}) => {
	useEffect(() => {
		// set parent season id in store
		setParentEpisodeId(id);
		fetchSeason(id);
		// eslint-disable-next-line
	}, []);
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	const { t } = useTranslation();
	const { isAudio } = useAudioLocation();

	const { columns } = resources;

	return (
		// Dispatch fetchEpisodes action in episodes_table
		<PanelPageTemplate
			itemId={id}
			isPageHeadingVisible={false}
			error={error}
			type="vod/episode"
			typeText="episode"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchEpisodes}
			resourcesForFetchAction={{ isAudio }}
			deleteItemAction={detachEpisode} //detach episode instead of delete
		>
			<React.Fragment>
				{!isReadOnly && (
					<EpisodesModalWrapper>
						{({ on, toggle }) => (
							<ModalForm
								title={t('vod:buttons.add_episodes')}
								visible={on}
								toggle={toggle}
								parentID={id}
							/>
						)}
					</EpisodesModalWrapper>
				)}
				<div className="d-flex justify-content-end">
					<Search setQueryValue={setEpisodesQuery} />
					<FilterButton tableColumns={columns} />
				</div>
			</React.Fragment>
		</PanelPageTemplate>
	);
};

Episodes.propTypes = {
	error: PropTypes.bool.isRequired,
	setEpisodesQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchEpisodes: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ vod: { episodes_table } }) => {
	return {
		error: episodes_table.error,
		resources: episodes_table
	};
};

export default compose(
	connect(mapStateToProps, {
		setEpisodesQuery,
		fetchEpisodes,
		fetchSeason,
		detachEpisode,
		setParentEpisodeId
	}),
	withRouter
)(Episodes);
