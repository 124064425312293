import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import helpers
import { renderFields } from 'components/utilities/form/renderFields';

// Import variables
import { BLUE_ONLINE_ROLES } from 'components/helpers/roles_variables';

const { REDACTOR } = BLUE_ONLINE_ROLES;

const Fields = ({ currentValues, submitting, roles, isEdit, institutions }) => {
	const selectResources = {
		roles,
		products_group_uuid: institutions
	};

	const allowInstitutionClear = currentValues?.roles?.includes(REDACTOR);

	return (
		<>
			{BASE_FIELDS({ isEdit, allowInstitutionClear }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	roles: PropTypes.array.isRequired,
	isEdit: PropTypes.bool.isRequired,
	currentValues: PropTypes.object
};

export default Fields;
