import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import helpers
import {
	checkProductSubtype,
	getDurationHoursAndMinutes,
	getProductSubcategories
} from '../../helpers';

// Import components
import AgeRatingIcon from './AgeRatingIcon';
import { BasicContainer, Divider, Text } from './styles';

export function Basic() {
	const { t } = useTranslation();

	const { isLoaded } = useSelector(({ product_preview }) => product_preview);
	const {
		subtype,
		metadata: { rating, year, duration, sub_category, release_description }
	} = useSelector(({ product_preview }) => product_preview.data);

	const subcategories = getProductSubcategories(sub_category);

	const { durationHours, durationMinutes } = getDurationHoursAndMinutes(
		duration
	);

	const { isLiveEvent } = checkProductSubtype(subtype);

	return (
		<>
			{isLoaded ? (
				<BasicContainer>
					<AgeRatingIcon rating={rating} />
					{isLiveEvent && release_description && (
						<>
							<Divider />
							<Text>{release_description}</Text>
						</>
					)}

					{!isLiveEvent && (
						<>
							{subcategories?.length && (
								<>
									<Divider />
									<Text>{subcategories.join(', ')}</Text>
								</>
							)}

							{!!year && (
								<>
									<Divider />
									<Text>{year}</Text>
								</>
							)}
						</>
					)}

					{(!!durationHours || !!durationMinutes) && (
						<>
							<Divider />
							<Text>
								{!!durationHours &&
									`${durationHours} ${t('product_preview:hours')}`}
								{!!durationMinutes &&
									`${durationMinutes} ${t('product_preview:minutes')}`}
							</Text>
						</>
					)}
				</BasicContainer>
			) : null}
		</>
	);
}
