export const select_types = [
	{
		name: 'hls'
	},
	{
		name: 'dash'
	},
	{
		name: 'ss'
	},
	{
		name: 'multicast'
	}
];
