// Import variables
import {
	SYSTEM_TYPES_FROM_ENV,
	SYSTEM_TYPES
} from 'components/helpers/variables';

export const replaceStrDots = (str) => str.replace(/\./g, '|');
export const replaceStr = (str, replace = ' ') => str.replace(/\|/g, replace);

export const convertFormData = (values) =>
	Object.entries(values).reduce(
		(prev, [name, value]) => {
			const key = replaceStr(name, '.');
			prev.items.push({ key, value });
			return prev;
		},
		{ items: [] }
	);

// check if OBOX is the only available system (is user currently in obox env)
export const isOboxSystem = SYSTEM_TYPES_FROM_ENV[0] === SYSTEM_TYPES.obox;
