import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Import helpers
import NAV_LINKS from './helpers/nav_links';
import { isNavItemRendered } from './helpers/helpers';
import showCategoryTitle from './helpers/links_category';

// Import components
import NavLinkItem from './NavLinkItem';

const Navigation = ({ userRoles }) => {
	const navLinksToRender = useMemo(
		() =>
			NAV_LINKS.filter((link) =>
				isNavItemRendered(userRoles, link.permissions)
			),
		[userRoles]
	);

	return (
		<nav
			className="navbar-default navbar-static-side navigation"
			role="navigation"
		>
			<ul className="nav metismenu" id="side-menu">
				<li className="nav-header">
					<div className="logo-element">BLUE ONLINE</div>
				</li>

				{navLinksToRender.map((link, index) => {
					const categoryTitle = showCategoryTitle(navLinksToRender, index);

					return (
						<React.Fragment key={index}>
							{categoryTitle && (
								<li className="nav__item nav__item--section">
									{' '}
									<h2 className="heading__nav">{categoryTitle}</h2>
								</li>
							)}

							<NavLinkItem link={link} />
						</React.Fragment>
					);
				})}
			</ul>
		</nav>
	);
};

const mapStateToProps = ({ auth: { authenticate } }) => ({
	userRoles: authenticate.roles
});

Navigation.propTypes = {
	user: PropTypes.shape({
		name: PropTypes.string,
		avatar: PropTypes.string,
		roles: PropTypes.object
	})
};

export default connect(mapStateToProps, null, undefined, { pure: false })(
	Navigation
);
