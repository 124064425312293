import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPromotionUsersList } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import helpers
import { convertUsersToArray } from './helpers';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

const PromotionsUsersList = ({
	history,
	fetchPromotionUsersList,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	const formInitialValues = {
		...defaultFieldValues,
		...initialValues
	};

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const resources = {
			logins: convertUsersToArray(values['logins'])
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'promotions',
			apiSlug: 'users',
			redirectPath: 'promotions'
		});
	};

	return (
		// Dispatch fetchPromotionUsersList actions in in promotions_form
		<FormTemplate
			fetchResourceToEdit={fetchPromotionUsersList}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					decorators={decorators}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								isEdit={isEdit}
								currentValues={values}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="promotions"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

PromotionsUsersList.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchPromotionUsersList: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ promotions: { users_list } }) => {
	return {
		initialValues: users_list.edit,
		error: users_list.error,
		isLoaded: users_list.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchPromotionUsersList }),
	withRouter
)(PromotionsUsersList);
