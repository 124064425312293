import React from 'react';
import i18n from 'i18next';

// Import components
import InstitutionsForm from '../../institutions_form/InstitutionsForm';
import InstitutionsImages from '../../institutions_images/InstitutionsImages';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { INSTITUTIONS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { institutionsTranslationValidate } from 'components/utilities/content_translation/validation';

export const institutionsBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <InstitutionsForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <InstitutionsImages />
	}
];

export const institutionsTabs = (contentTranslationLanguages) => [
	...institutionsBasicTabs,
	...generateContentTranslationsTabs(
		INSTITUTIONS_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		institutionsTranslationValidate
	)
];
