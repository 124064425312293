import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH PASS TO EDIT ********************
export const fetchPass = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PASS_LOADING,
			payload: true
		});

		const response = await services.get(`/products/passes/${id}`);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_PASS_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_PASS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
