import i18next from 'i18next';

export const select_types = [
	{
		name: i18next.t('agreements:types.LOGIN'),
		value: 'LOGIN'
	},
	{
		name: i18next.t('agreements:types.PAYMENT'),
		value: 'PAYMENT'
	},
	{
		name: i18next.t('agreements:types.SUBSCRIBER'),
		value: 'SUBSCRIBER'
	},
	{
		name: i18next.t('agreements:types.REGISTRATION'),
		value: 'REGISTRATION'
	},
	{
		name: i18next.t('agreements:types.RODO'),
		value: 'RODO'
	},
	{
		name: i18next.t('agreements:types.MARKETING'),
		value: 'MARKETING'
	},
	{
		name: i18next.t('agreements:types.COOKIES'),
		value: 'COOKIES'
	}
];
