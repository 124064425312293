// Import translation
import i18n from 'i18next';

// Import helpers
import { getCharactersNumberWithoutSpace } from 'components/helpers/characters_counter';

export const defaultFieldValues = {
	type: 'collection',
	free_vod: false,
	external_ids: [],
	url: '',
	active: false,
	slug: null,
	visible_primary: true,
	visible_secondary: true
};

export const BASE_FIELDS = ({ isEdit, checkedPermissions, currentValues }) => [
	{
		name: 'type',
		label: i18n.t('packets:fields.type'),
		type: 'select',
		requiredField: true,
		disabled: true
	},
	{
		name: 'products_group_uuid',
		label: i18n.t('packets:fields.products_group_uuid'),
		type: 'select',
		required: true,
		disabled: !isEdit || !checkedPermissions.canActivate
	},
	{
		name: 'external_ids',
		label: i18n.t('packets:fields.external_id'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'title',
		label: i18n.t('packets:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'description',
		label: i18n.t('packets:fields.description'),
		type: 'textarea',
		requiredField: true
	},
	{
		name: 'short_description',
		label: i18n.t('packets:fields.short_description'),
		type: 'textarea',
		fieldInfo: i18n.t('packets:fields_info.short_description'),
		requiredField: true,
		warningInfo:
			getCharactersNumberWithoutSpace(currentValues?.short_description) > 200
	},
	{
		name: 'order',
		label: i18n.t('packets:fields.order'),
		type: 'number',
		requiredField: true
	},
	{
		name: 'active',
		label: i18n.t('packets:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct
	},
	{
		name: 'created_at',
		label: i18n.t('packets:fields.created_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'updated_at',
		label: i18n.t('packets:fields.updated_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	}
];
