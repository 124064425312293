import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import select options
import {
	handicap_helper_type,
	rating_category_recommendation,
	checkboxValues
} from 'components/utilities/product_metric/common_select_values/select_type_options';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import helpers
import { prepareAuthorsFieldName } from 'components/utilities/product_metric/helpers/helpers';

const Fields = ({ submitting, productTypeName }) => {
	const selectResources = {
		'data.directors': [],
		'data.actors': [],
		'additional_data.handicap_helper_type': handicap_helper_type,
		'data.writers': [],
		'additional_data.rating_category_recommendation': rating_category_recommendation,
		'additional_data.payment': checkboxValues,
		'additional_data.exclusive_license': checkboxValues,
		[`${prepareAuthorsFieldName(productTypeName)}`]: [],
		'additional_metadata.lead': [],
		'additional_metadata.dramaturgy': [],
		'additional_metadata.composition': []
	};

	return (
		<React.Fragment>
			<div className="hr-line-solid">&nbsp;</div>
			{BASE_FIELDS(productTypeName).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	productTypeName: PropTypes.string.isRequired
};

export default Fields;
