import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reverseMergeCategory } from 'store/actions';

// Import columns
import { createColumns } from './categories_children_columns';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import utilities
import { messageConfirmHandler } from 'components/utilities/message';

// Import components
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import ReactTable from 'react-table';
import { Empty } from 'antd';

/**
 * Functional component responsible for rendering children (merged) categories of edited parent category
 */
const CategoriesChildrenTable = ({ resources, reverseMergeCategory }) => {
	const { t } = useTranslation();
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	// show confirmation modal. Dispatch action
	const handleReverseMerge = (childCategoryId) => {
		messageConfirmHandler(
			t('categories:reverse_merge_modal.reverse_merge_title'),
			t('categories:reverse_merge_modal.reverse_merge_message'),
			childCategoryId,
			reverseMergeCategory,
			'confirm'
		);
	};

	return (
		<Wraper lg="12" error={false}>
			<Ibox error={false} className="m-b-lg">
				<IboxTitle title={t('categories:children_table.title')}></IboxTitle>
				<IboxContentBase>
					{resources?.length > 0 ? (
						<ReactTable
							data={resources}
							columns={createColumns(handleReverseMerge, isReadOnly)}
							pageSize={resources.length}
							showPagination={false}
						/>
					) : (
						<Empty description={t('common:no_data')} className="m-t-md" />
					)}
				</IboxContentBase>
			</Ibox>
		</Wraper>
	);
};

CategoriesChildrenTable.propTypes = {
	resources: PropTypes.array.isRequired,
	reverseMergeCategory: PropTypes.func.isRequired
};

const mapStateToProps = ({ categories: { form } }) => ({
	resources: form.edit.children
});

export default compose(
	connect(mapStateToProps, {
		reverseMergeCategory
	}),
	withRouter
)(CategoriesChildrenTable);
