import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchIngesterAssets,
	setIngesterAssetsQuery,
	refreshIngesterAssets,
	fetchIngesterStats,
	createFiltersSuggestions
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import columns
import { createColumns } from './ingester_assets_columns';

// Import helpers
import { ASSETS } from 'components/helpers/variables';
import { assetsType } from 'components/helpers/dictionary';

// Import utilities
import Search from 'components/utilities/search/Search';
import MultiProgessBar from 'components/views/ingester/helpers/progress_bar/MultiProgressBar';
import StatsInfo from 'components/views/ingester/helpers/stats_info/StatsInfo';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const IngesterAssets = ({
	error,
	resources,
	fetchIngesterAssets,
	setIngesterAssetsQuery,
	refreshIngesterAssets,
	fetchIngesterStats,
	createFiltersSuggestions,
	stats
}) => {
	useEffect(() => {
		fetchIngesterStats();
		// eslint-disable-next-line
	}, []);

	// pass filters suggestions data to redux
	const filters = { type: assetsType };
	// execute filters logic
	useFilterLogic({
		createFiltersSuggestions,
		filters
	});

	const { total, finished } = stats;

	const { columns } = resources;

	return (
		// Dispatch fetchIngesterAssets action in ingester_panel
		<PanelPageTemplate
			error={error}
			type="ingester_assets"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchIngesterAssets}
		>
			<>
				<MultiProgessBar total={total} finished={finished} />
				<StatsInfo
					refreshTable={refreshIngesterAssets}
					refreshStats={fetchIngesterStats}
					statsType={ASSETS}
				/>

				<div className="d-flex justify-content-end">
					<Search
						setQueryValue={setIngesterAssetsQuery}
						value={resources.options.filters.query}
					/>
					<FilterButton tableColumns={columns} />
				</div>
			</>
		</PanelPageTemplate>
	);
};

IngesterAssets.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	setIngesterAssetsQuery: PropTypes.func.isRequired,
	fetchIngesterAssets: PropTypes.func.isRequired,
	refreshIngesterAssets: PropTypes.func.isRequired,
	fetchIngesterStats: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	stats: PropTypes.object.isRequired
};

const mapStateToProps = ({
	ingester: {
		assets_table,
		stats: { assets }
	}
}) => {
	return {
		error: assets_table.error,
		resources: assets_table,
		stats: assets
	};
};

export default connect(mapStateToProps, {
	fetchIngesterAssets,
	setIngesterAssetsQuery,
	refreshIngesterAssets,
	fetchIngesterStats,
	createFiltersSuggestions
})(IngesterAssets);
