import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	setActivityProductQuery,
	setActivityProduct,
	fetchActivitiesProduct,
	clearPanelState
} from 'store/actions';

// Import columns
import { createColumns } from './activity_product_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';
import { getLocationType } from 'components/helpers/convert';
import { convertProductType } from 'components/common/templates/helpers';

const ActivityProduct = ({
	error,
	setActivityProductQuery,
	setActivityProduct,
	fetchActivitiesProduct,
	resources,
	clearPanelState
}) => {
	const { id } = useParams();
	const { pathname } = useLocation();
	const locationType = getLocationType(pathname);

	const type = convertProductType(locationType);

	useEffect(() => {
		clearPanelState();
		setActivityProduct(id, type);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, type]);

	const { columns } = resources;
	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="activity"
			resources={resources}
			columns={createColumns}
			links
			fetchResourcesAction={fetchActivitiesProduct}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setActivityProductQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

ActivityProduct.propTypes = {
	error: PropTypes.bool.isRequired,
	setActivityProductQuery: PropTypes.func.isRequired,
	setActivityProduct: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchActivitiesProduct: PropTypes.func.isRequired
};

const mapStateToProps = ({ activity_product, dictionary }) => {
	return {
		error: activity_product.table.error,
		resources: activity_product.table
	};
};

export default connect(mapStateToProps, {
	setActivityProductQuery,
	setActivityProduct,
	fetchActivitiesProduct,
	clearPanelState
})(ActivityProduct);
