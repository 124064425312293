// Import translation
import i18n from 'i18next';

export default ({ title }) => {
	const errors = {};
	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('channels:form:validation.title');
	}

	return errors;
};
