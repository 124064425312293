// ******************** INSTITUTIONS DATA TABLE ********************
export const FETCH_INSTITUTIONS_LOADING = 'FETCH_INSTITUTIONS_LOADING';
export const FETCH_INSTITUTIONS_SUCCESS = 'FETCH_INSTITUTIONS_SUCCESS';
export const FETCH_INSTITUTIONS_ERROR = 'FETCH_INSTITUTIONS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_INSTITUTIONS_SEARCH_QUERY = 'SET_OPERATORS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_INSTITUTIONS_TABLE_SORTING = 'SAVE_OPERATORS_TABLE_SORTING';

// ******************** OPERATOR ********************
export const FETCH_INSTITUTION_LOADING = 'FETCH_OPERATOR_LOADING';
export const FETCH_INSTITUTION_SUCCESS = 'FETCH_OPERATOR_SUCCESS';
export const FETCH_INSTITUTION_ERROR = 'FETCH_OPERATOR_ERROR';

// ******************** FILES ********************
// -------------------- LOGO --------------------
export const FETCH_INSTITUTION_LOGO_FILES_LOADING =
	'FETCH_INSTITUTION_LOGO_FILES_LOADING';
export const FETCH_INSTITUTION_LOGO_FILES_SUCCESS =
	'FETCH_INSTITUTION_LOGO_FILES_SUCCESS';
export const FETCH_INSTITUTION_LOGO_FILES_ERROR =
	'FETCH_INSTITUTION_LOGO_FILES_ERROR';
// -------------------- COVER --------------------
export const FETCH_INSTITUTION_COVER_FILES_LOADING =
	'FETCH_INSTITUTION_COVER_FILES_LOADING';
export const FETCH_INSTITUTION_COVER_FILES_SUCCESS =
	'FETCH_INSTITUTION_COVER_FILES_SUCCESS';
export const FETCH_INSTITUTION_COVER_FILES_ERROR =
	'FETCH_INSTITUTION_COVER_FILES_ERROR';
