import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	productTextColor,
	customMinResponsive,
	size
} from '../../styles';

export const Container = styled.div`
	margin-bottom: 1.1rem;
	${flex({ jc: 'start' })}
	flex-wrap: wrap;

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: 1.4rem;
		`
	)}
`;

export const Text = styled.span`
	font-size: 1.2rem;
	text-transform: uppercase;
	${productTextColor}

	${customMinResponsive(
		size.medium,
		css`
			font-size: 1.6rem;
			letter-spacing: 1.45px;
		`
	)}
`;

export const Divider = styled.span`
	display: inline-block;
	height: 1.2rem;
	width: 2px;
	border-radius: 4px;
	margin: 0 0.5rem;
	z-index: 2;
	opacity: ${({ opacity }) => opacity || 1};

	${customMinResponsive(
		size.medium,
		css`
			height: 1.6rem;
			margin: 0 1rem;
		`
	)}

	${({ theme, isLightBackground }) =>
		isLightBackground
			? css`
					background-color: ${theme.product.lightBackground.color};
			  `
			: css`
					background-color: ${theme.product.coverBackground.color};
			  `};
`;
