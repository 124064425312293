import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';

// Import columns
import { createColumns } from './subtitles_columns';

// Import components
import SubtitlesHeader from './components/SubtitlesHeader';
import { FetchError } from 'components/common/layout';

// Import helpers
import { locale } from 'components/helpers/table';

// Import custom hook
import useMaterialSubtitlesTable from './useMaterialSubtitlesTable';
import useAuthCheck from 'hooks/useAuthCheck';

const MaterialSubtitlesTable = ({
	videoId,
	match: {
		params: { id }
	}
}) => {
	// check if user have rights to create and edit forms
	const { canEditVideo } = useAuthCheck();

	const {
		deleteSubtitle,
		data,
		fetchError,
		subtitleLocationType
	} = useMaterialSubtitlesTable(videoId);

	const subtitlesHeader = () => (
		<SubtitlesHeader
			productId={id}
			videoId={videoId}
			canEditVideo={canEditVideo}
			subtitleLocationType={subtitleLocationType}
		/>
	);

	return (
		<>
			{fetchError && <FetchError />}
			<Table
				rowKey={(record) => record.uuid}
				columns={createColumns(deleteSubtitle, canEditVideo)}
				title={subtitlesHeader}
				bordered
				dataSource={data}
				pagination={false}
				size="small"
				className="m-b-lg subtitle-table"
				locale={locale}
			/>
		</>
	);
};

MaterialSubtitlesTable.propTypes = {
	videoId: PropTypes.number.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

export default withRouter(MaterialSubtitlesTable);
