import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	url: '',
	isLoaded: false,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.FETCH_REPORTS_URL_LOADING:
				return { ...INITIAL_STATE };

			case types.FETCH_REPORTS_URL_SUCCESS:
				draft.url = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_REPORTS_URL_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
