import React, { useState, createContext } from 'react';
import services from 'services/services';
import { message } from 'antd';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';

// Import translation
import { useTranslation } from 'react-i18next';

export const MaterialsContext = createContext();

export const MaterialsProvider = ({
	children,
	resources,
	updateMaterials,
	updateMaterialsError,
	deleteMaterials,
	deleteMaterialsError
}) => {
	const { t } = useTranslation();
	// context state to determine if modal should be visible or not
	const [isVisible, setIsVisible] = useState(false);
	// flag to determine if we are showing edit form or create form
	const [isEdit, setIsEdit] = useState(false);
	// asset id that is currently being edited
	const [assetId, setAssetId] = useState(null);
	const [isWatermarkChecked, setIsWatermarkChecked] = useState(
		resources.use_watermark
	);

	// show and hide form modal
	const toggleFormModal = () => setIsVisible(!isVisible);

	// sets flag for if we are editing or creating asset
	const toggleEdit = () => {
		setIsEdit(!isEdit);
	};

	// update local data in redux without making server request
	const updateData = (data, type, productId) => {
		updateMaterials(data, type, productId);
	};

	// deletes asset from product. Sends request and starts an action. Shows notification.
	const deleteAsset = async (id, type) => {
		try {
			const url = `/player/video/asset/${id}/delete`;
			await services.delete(url);
			deleteMaterials(id, type);
			notificationHandler(
				t('messages:notifications.deleted'),
				`${t('messages:notifications.successfuly_deleted')} ${t(
					'names:notification_names.asset'
				)} `
			);
		} catch (error) {
			deleteMaterialsError(error);
		}
	};

	// opens edit modal, retrieves asset id an calls function to change edit flag to on
	const editAsset = (id) => {
		toggleEdit();
		toggleFormModal();
		setAssetId(id);
	};

	const video_type = resources.type;

	const handleChangeUseWatermark = async () => {
		try {
			const url = `/player/video/${resources.id}/update`;
			await services.post(url, {
				use_watermark: !isWatermarkChecked,
				video_type
			});
			notificationHandler(
				t('messages:notifications.edited'),
				`${t('messages:notifications.successfuly_edited')} ${t(
					'names:notification_names.watermark'
				)} `
			);
			setIsWatermarkChecked(!isWatermarkChecked);
		} catch (error) {
			message.error(t('common:materials_table.watermark_change.change_error'));
		}
	};

	return (
		<MaterialsContext.Provider
			value={{
				isVisible,
				isEdit,
				toggleFormModal,
				toggleEdit,
				resources,
				updateData,
				deleteAsset,
				assetId,
				setAssetId,
				editAsset,
				updateMaterialsError,
				isWatermarkChecked,
				handleChangeUseWatermark
			}}
		>
			{children}
		</MaterialsContext.Provider>
	);
};
