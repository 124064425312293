import i18n from 'i18next';

export const ASSETS_INPUT_FIELDS_DATA = ({ cancelButtonPath }) => ({
	submitUrl: 'assets',
	fetchUrl: 'assets',
	fetchUrlSlug: 'details',
	fetchQuery: 'with_metadata=1',
	cancelButtonPath,
	hasMetadata: false,
	defautlFieldValues: {
		metadata: {
			description: '',
			source: '',
			title: ''
		}
	},
	inputFields: [
		{
			name: 'metadata.title',
			label: i18n.t('asset_groups:fields.title'),
			type: 'text',
			required: true
		},
		{
			name: 'metadata.description',
			label: i18n.t('asset_groups:fields.description'),
			type: 'text',
			required: true
		},
		{
			name: 'metadata.source',
			label: i18n.t('asset_groups:fields.source'),
			type: 'text',
			required: false
		}
	]
});
