import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import select options
import { select_passes_types } from './select_type_options';

const Fields = ({
	submitting,
	isEdit,
	checkedPermissions,
	paymentsModelsList,
	institutions,
	promotions
}) => {
	const selectResources = {
		type: select_passes_types,
		payment_models: paymentsModelsList,
		products_group_uuid: institutions,
		'data.promotion_id': promotions
	};

	return (
		<React.Fragment>
			{BASE_FIELDS({
				isEdit,
				checkedPermissions
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	paymentsModelsList: PropTypes.array,
	institutions: PropTypes.array.isRequired,
	promotions: PropTypes.array.isRequired,
	checkedPermissions: PropTypes.object.isRequired
};

export default Fields;
