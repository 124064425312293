import i18n from 'i18next';

export const PASSES_INPUT_FIELDS_DATA = {
	submitUrl: 'products/passes',
	fetchUrl: 'products/passes',
	cancelButtonPath: 'passes',
	hasMetadata: false,
	defautlFieldValues: {
		title: '',
		summary_short: ''
	},
	inputFields: [
		{
			name: 'title',
			label: i18n.t('passes:fields.title'),
			type: 'text',
			requiredField: true
		},
		{
			name: 'summary_short',
			label: i18n.t('passes:fields.summary_short'),
			type: 'textarea',
			requiredField: true
		}
	]
};
