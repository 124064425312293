import React from 'react';
import PropTypes from 'prop-types';

// Import translations
import { useTranslation } from 'react-i18next';

// Import hooks
import useUploadMaterialsTable from './useUploadMaterialsTable';

// Import columns
import { createColumns } from './upload_colmuns';

// Import helpers
import { locale } from 'components/helpers/table';

// Import components
import { Table } from 'antd';

import { STATUS_TYPES } from 'components/helpers/variables';
import usePermissionsCheck from 'hooks/usePermissionsCheck';

const { loading } = STATUS_TYPES;

const UploadMaterialsTable = ({
	modelType,
	transcodeMain,
	transcodePreview
}) => {
	const { t } = useTranslation();
	const {
		tableResources,
		tableStatus,
		dataChange,
		transcodeAsset,
		isAudio
	} = useUploadMaterialsTable({ modelType });

	const { checkedPermissions } = usePermissionsCheck();

	return (
		<div className="pb-4 my-5">
			<div className="d-flex justify-content-between my-3">
				<h5 className="title__upload mb-4">
					{t('upload_materials:upload_table_title')}
				</h5>
			</div>
			<Table
				rowKey={(record) => record.id}
				dataSource={tableResources}
				columns={createColumns({
					transcodeAsset,
					transcodeMain,
					transcodePreview,
					isAudio,
					checkedPermissions
				})}
				bordered
				pagination={false}
				size="small"
				scroll={{ x: true }}
				className="m-b-lg material-table"
				locale={locale}
				onChange={dataChange}
				loading={tableStatus === loading}
			/>
		</div>
	);
};

UploadMaterialsTable.propTypes = {
	modelType: PropTypes.string.isRequired,
	transcodeMain: PropTypes.bool.isRequired,
	transcodePreview: PropTypes.bool.isRequired
};

export default UploadMaterialsTable;
