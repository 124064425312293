import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import components
import RefreshButton from 'components/views/ingester/helpers/RefreshButton';
import StatNumber from 'components/views/ingester/helpers/StatNumber';
import { Row, Col } from 'reactstrap';

/**
 * This function component is responsible for rendering ingester stats,
 * uses hooks to dispatch and get data from redux store.
 * @param {func} refreshTable - function passed to RefreshButton that refreshes table data in Redux
 * @param {func} refreshStats - function passed to RefreshButton that refreshes stats in Redux
 * @param {string} statsType - type of stats (assets | encoding)
 * @returns {*}
 */
const StatsInfo = ({ refreshTable, refreshStats, statsType }) => {
	const { queued, in_progress: inProgress } = useSelector(
		(state) => state.ingester.stats[statsType]
	);

	return (
		<Row className="m-b-md">
			<Col className="d-flex align-items-center">
				<StatNumber number={queued} color={'danger'} label={'Queued'} />
			</Col>
			<Col className="d-flex align-items-center">
				<StatNumber
					number={inProgress}
					color={'warning'}
					label={'In Progress'}
				/>
			</Col>
			<Col className="text-right">
				<RefreshButton
					refreshTable={refreshTable}
					refreshStats={refreshStats}
				/>
			</Col>
		</Row>
	);
};

StatsInfo.propTypes = {
	refreshTable: PropTypes.func.isRequired,
	refreshStats: PropTypes.func.isRequired,
	statsType: PropTypes.string.isRequired
};

export default StatsInfo;
