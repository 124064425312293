import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import actions
import { fetchAffiliatePartner } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const AffiliatePartnersMetadataForm = ({
	history,
	fetchAffiliatePartner,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const resources = { ...values, active: +values.active };

		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'affiliate'
		});
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchAffiliatePartner}
			error={error}
			isLoaded={isLoaded}
			iboxContentTitle={t('affiliate_partners:form_title.metadata')}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<Form
					initialValues={initialValues}
					validate={validate}
					decorators={decorators}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								currentValues={values}
								submitting={submitting}
								checkedPermissions={checkedPermissions}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="affiliate_partners"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

AffiliatePartnersMetadataForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchAffiliatePartner: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ affiliate_partners: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchAffiliatePartner
	}),
	withRouter
)(AffiliatePartnersMetadataForm);
