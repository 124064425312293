import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useUpload from './useUpload';

// Import React FilePond
import { FilePond } from 'react-filepond';

// Import components
import { Prompt } from 'react-router-dom';

const Upload = ({ modelType, fileTypeSelectorVisible }) => {
	const { t } = useTranslation();
	const {
		files,
		uploadComplete,
		uploadUrl,
		getUploadUrl,
		updateFiles,
		isPromptVisible,
		collectionsTypes,
		handleChangeCollection
	} = useUpload(modelType);

	return (
		<div className="my-5">
			{fileTypeSelectorVisible && (
				<>
					<h5 className="title__upload mb-4">
						{t('upload_materials:upload_material_type')}
					</h5>
					<Radio.Group
						defaultValue="video"
						onChange={handleChangeCollection}
						className="mb-4"
					>
						{collectionsTypes.map((item, index) => (
							<Radio.Button key={index} value={item}>
								{item}
							</Radio.Button>
						))}
					</Radio.Group>
				</>
			)}
			<h5 className="title__upload mb-4">
				{t('upload_materials:upload_title')}
			</h5>
			<FilePond
				files={files}
				onupdatefiles={updateFiles}
				allowMultiple={false}
				server={{
					url: uploadUrl,
					process: (
						fieldName,
						file,
						metadata,
						load,
						error,
						progress,
						abort,
						transfer,
						options
					) => {
						// fieldName is the name of the input field
						// file is the actual file object to send
						const formData = new FormData();
						const data = { hello: 'world' };
						const blob = new Blob([JSON.stringify(data, null, 2)], {
							type: 'application/json'
						});
						formData.append('test', blob);
						formData.append(fieldName, file, file.name);
						const request = new XMLHttpRequest();
						request.open('POST', uploadUrl);
						// Should call the progress method to update the progress to 100% before calling load
						// Setting computable to false switches the loading indicator to infinite mode
						request.upload.onprogress = (e) => {
							progress(e.lengthComputable, e.loaded, e.total);
						};
						// Should call the load method when done and pass the returned server file id
						// this server file id is then used later on when reverting or restoring a file
						// so your server knows which file to return without exposing that info to the client
						request.onload = function() {
							if (request.status >= 200 && request.status < 300) {
								// the load method accepts either a string (id) or an object
								load(request.responseText);
							} else {
								// Can call the error method if something is wrong, should exit after
								error('oh no');
							}
						};
						request.send(formData);
						// Should expose an abort method so the request can be cancelled
						return {
							abort: () => {
								// This function is entered if the user has tapped the cancel button
								request.abort();
								// Let FilePond know the request has been cancelled
								abort();
							}
						};
					}
				}}
				allowRevert={false}
				chunkUploads={false}
				onaddfilestart={getUploadUrl}
				onprocessfile={uploadComplete}
				name="file"
				labelIdle={t('upload_materials:upload_labels.idle')}
				labelFileProcessing={t(
					'upload_materials:upload_labels.file_processing'
				)}
				labelFileProcessingComplete={t(
					'upload_materials:upload_labels.file_processing_complete'
				)}
				labelFileProcessingAborted={t(
					'upload_materials:upload_labels.file_processing_aborted'
				)}
				labelFileProcessingError={t(
					'upload_materials:upload_labels.file_processing_error'
				)}
				labelTapToCancel={t('upload_materials:upload_labels.tap_to_cancel')}
				labelTapToRetry={t('upload_materials:upload_labels.tap_to_retry')}
				credits={null}
			/>
			<Prompt
				when={isPromptVisible}
				message={t('messages:unsavedFileUpload')}
			/>
		</div>
	);
};

Upload.propTypes = {
	modelType: PropTypes.string.isRequired,
	fileTypeSelectorVisible: PropTypes.bool.isRequired
};

export default Upload;
