import { GLOBAL_PRODUCT_TYPES } from 'components/helpers/variables';

// Get data from selected and convert them to selectedData format
export const getNewSelectedData = (
	tabs,
	selected,
	selectedData,
	idSelector
) => {
	const newSelected = tabs.reduce((acc, tabItem) => {
		if (selected[tabItem.type]) {
			acc = [...acc, ...selected[tabItem.type]];
		}
		return acc;
	}, []);

	// checking if each item from select already exist in selectedData
	const newSelectedData = newSelected.filter(
		(newItem) =>
			selectedData.findIndex(
				(item) => item[idSelector] === newItem[idSelector]
			) === -1
	);

	return newSelectedData;
};

// Convert data from selected to select format
export const dataToSelect = (selected, type, idSelector, titleSelector) => {
	let selectedTabData = [];
	if (selected[type]) {
		selectedTabData = selected[type].map((item) => ({
			key: item[idSelector],
			label: item[titleSelector]
		}));
	}

	return selectedTabData;
};

// Convert data from select format to selected
export const dataToSelected = (value, data, idSelector) => {
	const newSelected = value.reduce((acc, { key }) => {
		const item = data.find((item) => item[idSelector] === key);

		return [...acc, item];
	}, []);

	return newSelected;
};

// chekc and get src
export const getImgUrl = (img) => {
	if (img) {
		if (img.poster && img.poster.length) {
			return img.poster[0].url;
		} else if (img.logo && img.logo.length) {
			return img.logo[0].url;
		} else if (img.cover && img.cover.length) {
			return img.cover[0].url;
		} else if (img.length) {
			return img[0].url;
		}
	}

	return null;
};

// compare if arrays are the same
export const compareArrays = (array, updatedArray) =>
	array.length === updatedArray.length &&
	array.every((value, index) => value === updatedArray[index]);

// generate link to proper product in section preview grid
export const generateProductLink = (item) => {
	const { type, subtype, uuid } = item;

	const {
		CHANNEL,
		VOD,
		ARTICLE,
		LIVE_EVENT,
		BANNER,
		PASS,
		PACKET
	} = GLOBAL_PRODUCT_TYPES;

	switch (type) {
		case CHANNEL:
			return `/panel/channels/edit/${uuid}/metadata`;

		case VOD:
			return `/panel/vod/${subtype}/edit/${uuid}/metadata`;

		case ARTICLE:
			return `/panel/articles/edit/${uuid}/metadata`;

		case LIVE_EVENT:
			return `/panel/events_live/edit/${uuid}/metadata`;

		case BANNER:
			return `/panel/banners/edit/${uuid}/metadata`;

		case PASS:
			return `/panel/passes/edit/${uuid}/metadata`;

		// added with only collection in mind (if more packet subtypes will be needed (main | provisioning) expand switch)
		case PACKET:
			return `/panel/packets/edit/${uuid}/metadata`;

		default:
			return '';
	}
};

const getAdditionalItemData = ({ tag, item }) => {
	const itemTag = item[tag];

	const isPaymentModelsEmpty = tag === 'payment_models' && !itemTag.length;
	const isSubTypeVod = tag === 'subtype' && itemTag === 'vod';
	const isAudio = item['is_audio'];

	if (!itemTag || isPaymentModelsEmpty) {
		return null;
	}

	if (isSubTypeVod) {
		return isAudio ? 'audio' : 'video';
	}

	return itemTag;
};

// render additional data next to vod title based on provided array
export const renderAdditionalLabelData = (array, item) =>
	array
		.map((tag) => getAdditionalItemData({ tag, item }))
		.filter((item) => item !== null)
		.join(' | ');

export const PROVIDER = 'provider';
export const INSTITUTION = 'institution';
