import React from 'react';

// Import translation
import i18n from 'i18next';

export const columns = [
	{
		title: i18n.t('global_limit:table_columns.limit_type'),
		dataIndex: 'limit_type',
		key: 'limit_type',
		render: (limit_type) => (
			<span>{i18n.t(`global_limit:types.${limit_type}`)}</span>
		)
	},
	{
		title: i18n.t('global_limit:table_columns.limit_size'),
		dataIndex: 'limit_size',
		key: 'limit_size',
		render: (limit_size) => <span>{limit_size ?? i18n.t('common:none')}</span>
	},
	{
		title: i18n.t('global_limit:table_columns.stats_count'),
		dataIndex: 'stats_count',
		key: 'stats_count',
		render: (stats_count) => <span>{stats_count ?? i18n.t('common:none')}</span>
	}
];
