// Import translation
import i18n from 'i18next';

export default ({ title, summary_short }) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('epg:validation.title_required');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('epg:validation.title_length');
	}

	// ------------------ Description_short ------------------
	if (!summary_short) {
		errors.description_short = i18n.t(
			'epg:validation.description_short_required'
		);
	}

	return errors;
};
