import React from 'react';
import { useSelector } from 'react-redux';

// Import styles
import { Container, Description } from './styles';

// Import helpers
import { checkProductSubtype } from 'components/views/product_preview/helpers';

const SummaryShort = () => {
	const { metadata, subtype, short_description } = useSelector(
		({ product_preview }) => product_preview.data
	);

	const summaryShort = metadata?.summary_short || short_description;

	if (!summaryShort) return null;

	const { isArticle } = checkProductSubtype(subtype);

	return (
		<Container>
			<Description isLightBackground={isArticle}>{summaryShort}</Description>
		</Container>
	);
};

export default SummaryShort;
