// ******************** ACTIVITYS PRODUCT DATA TABLE ********************
export const FETCH_ACTIVITIES_PRODUCT_LOADING =
	'FETCH_ACTIVITIES_PRODUCT_LOADING';
export const FETCH_ACTIVITIES_PRODUCT_SUCCESS =
	'FETCH_ACTIVITIES_PRODUCT_SUCCESS';
export const FETCH_ACTIVITIES_PRODUCT_ERROR = 'FETCH_ACTIVITIES_PRODUCT_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_ACTIVITY_PRODUCT_SEARCH_QUERY =
	'SET_ACTIVITY_PRODUCT_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_ACTIVITY_PRODUCT_TABLE_SORTING =
	'SAVE_ACTIVITY_PRODUCT_TABLE_SORTING';

// ******************** ACTIVITY OF PRODUCT ********************
export const FETCH_ACTIVITY_PRODUCT_LOADING = 'FETCH_ACTIVITY_PRODUCT_LOADING';
export const FETCH_ACTIVITY_PRODUCT_SUCCESS = 'FETCH_ACTIVITY_PRODUCT_SUCCESS';
export const FETCH_ACTIVITY_PRODUCT_ERROR = 'FETCH_ACTIVITY_PRODUCT_ERROR';

// ******************** SET ACTIVITY PRODUCT ********************
export const SET_ACTIVITY_PRODUCT = 'SET_ACTIVITY_PRODUCT';
