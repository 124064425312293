import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH ARTICLE MATERIALS ********************
export const fetchArticleMaterials = (uuid) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_ARTICLE_MATERIALS_LOADING,
			payload: true
		});

		const response = await services.get(
			`/player/${uuid}/list?with_assets=true`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_ARTICLE_MATERIALS_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ARTICLE_MATERIALS_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
// ******************** UPDATE / DELETE ARTICLE MATERIALS ********************
export const updateArticleMaterials = (data, type, productId) => ({
	type: types.UPDATE_ARTICLE_MATERIALS,
	payload: { data, type, productId }
});

export const updateArticleMaterialsError = (error) => ({
	type: types.UPDATE_ARTICLE_MATERIALS_ERROR,
	payload: error
});

export const deleteArticleMaterials = (id, type) => ({
	type: types.DELETE_ARTICLE_MATERIALS,
	payload: { id, type }
});

export const deleteArticleMaterialsError = (error) => ({
	type: types.DELETE_ARTICLE_MATERIALS_ERROR,
	payload: error
});

// ******************** ADD VIDEO TO ARTICLE ********************
export const addVideoToArticle = (uuid, videoType) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.ADD_VIDEO_TO_ARTICLE_LOADING,
			payload: true
		});

		const resources = {
			product_uuid: uuid,
			product_type: 'article',
			video_type: videoType
		};

		const response = await services.post(`/player/video/create`, resources);

		// add empty asset array to response data
		response.data.assets = [];

		// Dispatch an action with data
		dispatch({
			type: types.ADD_VIDEO_TO_ARTICLE_SUCCESS,
			payload: { data: response.data, videoType }
		});
	} catch (error) {
		dispatch({
			type: types.ADD_VIDEO_TO_ARTICLE_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
