import React from 'react';
import PropTypes from 'prop-types';
import { StyledHtmlContainer, StyledHtmlText, StyledButton } from './styles';
import DOMPurify from 'dompurify';

const TinyTextEditorPlaceholder = (props) => {
	const { handleEdit, input: dirtyHtml } = props;

	const cleanHtml = DOMPurify.sanitize(dirtyHtml.value);

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleEdit();
			event.preventDefault();
		}
	};

	return (
		<StyledButton type="button" onKeyDown={handleKeyDown} onClick={handleEdit}>
			<StyledHtmlContainer role="button">
				<StyledHtmlText dangerouslySetInnerHTML={{ __html: cleanHtml }} />
			</StyledHtmlContainer>
		</StyledButton>
	);
};

TinyTextEditorPlaceholder.propTypes = {
	handleEdit: PropTypes.func,
	handleLeaveEdition: PropTypes.func,
	input: PropTypes.object.isRequired
};

export default TinyTextEditorPlaceholder;
