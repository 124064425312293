import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import styles
import {
	HrLine,
	ShareSection,
	ShareText,
	StyledFacebookShareButton,
	StyledTwitterShareButton,
	IconFacebook,
	IconTwitter
} from './styles';

// Import helpers
import { checkProductSubtype } from '../../helpers';

const Share = () => {
	const { t } = useTranslation();

	const { subtype } = useSelector(
		({ product_preview }) => product_preview.data
	);

	const { isArticle } = checkProductSubtype(subtype);

	const lightBackground = `${isArticle}`;

	return (
		<>
			<HrLine isLightBackground={isArticle} />
			<ShareSection>
				<ShareText isLightBackground={isArticle}>
					{t('common:share')}:{' '}
				</ShareText>
				<StyledFacebookShareButton light={lightBackground}>
					<IconFacebook aria-hidden="true" />
				</StyledFacebookShareButton>
				<StyledTwitterShareButton light={lightBackground}>
					<IconTwitter aria-hidden="true" />
				</StyledTwitterShareButton>
			</ShareSection>
		</>
	);
};

export default Share;
