import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col, Input } from 'reactstrap';

const InputTextPreview = ({
	input,
	label,
	id,
	labelColumn = '3',
	inputColumn = '9',
	resize = 'vertical',
	rows = '3',
	meta: { touched, error },
	...inputProps
}) => (
	<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
		<Label
			for={id}
			sm={labelColumn}
			className="col-form-label col-form-label-lg"
		>
			{label}
		</Label>
		<Col sm={inputColumn}>
			<Input
				invalid={touched && error ? true : false}
				rows={rows}
				id={id}
				style={{ resize }}
				{...input}
				{...inputProps}
				type="textarea"
			/>
			{touched && error && <span className="input-error d-block">{error}</span>}
		</Col>
	</FormGroup>
);

InputTextPreview.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.string,
	inputColumn: PropTypes.string,
	resize: PropTypes.string,
	rows: PropTypes.string,
	meta: PropTypes.object
};

export default InputTextPreview;
