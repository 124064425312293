// Import translation
import i18n from 'i18next';

export default ({
	title,
	summary_short,
	products_group_uuid,
	data: { promotion_id }
}) => {
	const errors = { data: {} };

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('passes:validation.title');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('articles:validation.title_length');
	}
	// ------------------ Summary short ------------------
	if (!summary_short) {
		errors.summary_short = i18n.t('passes:validation.summary_short');
	}
	// ------------------ Promotion ------------------
	if (!promotion_id) {
		errors.data.promotion_id = i18n.t('passes:validation.promotion');
	}
	// ------------------ Institution ------------------
	if (!products_group_uuid) {
		errors.products_group_uuid = i18n.t('passes:validation.institution');
	}

	return errors;
};
