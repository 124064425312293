import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

// Import api
import { QUERY_INGESTER_PRODUCTS_ASSETS } from 'components/helpers/api/ingester';

const useIngesterProductAssets = () => {
	const { id } = useParams();
	const queryClient = useQueryClient();

	const queryKey = `${QUERY_INGESTER_PRODUCTS_ASSETS}_${id}`;

	const handleRefreshData = () => queryClient.invalidateQueries({ queryKey });

	return { id, queryKey, refreshData: handleRefreshData };
};

export default useIngesterProductAssets;
