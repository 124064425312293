// Import translation
import i18n from 'i18next';

export const passesTranslationValidate = ({ title, summary_short }) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('passes:validation.title');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('passes:validation.title_length');
	}
	// ------------------ Summary short ------------------
	if (!summary_short) {
		errors.summary_short = i18n.t('passes:validation.summary_short');
	}
	return errors;
};
