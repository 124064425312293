import React from 'react';
import { useSelector } from 'react-redux';

// Import helpers
import { checkProductSubtype, getImage } from '../../helpers';

// Import variables
import { PRODUCT_IMAGES_TYPES } from '../../helpers/variables';

// Import styles
import { Root, Image, TitleText } from './styles';

const { TITLE } = PRODUCT_IMAGES_TYPES;

const Title = () => {
	const { images, metadata, title, subtype } = useSelector(
		({ product_preview }) => product_preview.data
	);

	const imageUrl = getImage({ images, type: TITLE });

	const hideTitle = metadata?.hide_title;
	const productTitle = metadata?.title || title;

	const { isArticle } = checkProductSubtype(subtype);

	return (
		<Root imageUrl={imageUrl} isArticle={isArticle}>
			{// Render title image. If image is missing, render title text if it's not deactivated.
			//Otherwise don't render anything
			!!imageUrl ? (
				<Image imageUrl={imageUrl} />
			) : (
				!hideTitle && (
					<TitleText isLightBackground={isArticle}>{productTitle}</TitleText>
				)
			)}
		</Root>
	);
};

export default Title;
