// Import translation
import i18n from 'i18next';

export default ({ name, email }) => {
	const errors = {};

	// ------------------ name ------------------
	if (!name) {
		errors.name = i18n.t('affiliate_partners:validation.name');
	}

	// ------------------ email ------------------
	if (!email) {
		errors.email = i18n.t('affiliate_partners:validation.email');
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
		errors.email = i18n.t('affiliate_partners:validation.email_wrong');
	}

	return errors;
};
