import React from 'react';
import { Tag } from 'antd';

// Import components
import { getStatusColor } from 'components/helpers';

const Status = ({ encodings }) => {
	const encoding = encodings?.data?.at(-1);

	if (!encoding) return null;

	const { status } = encoding;

	const color = getStatusColor(status);

	return <Tag color={color}>{status.toUpperCase()}</Tag>;
};

export default Status;
