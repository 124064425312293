import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	fetchPayments,
	setPaymentsQuery,
	setPaymentIdType,
	setPaymentIdSubtype,
	dictionaryPaymentMethods,
	createFiltersSuggestions,
	fetchInstitutionsList,
	fetchProviders
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import columns
import { createColumns } from './payments_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import helpers
import { PAYMENT_STATUS_OPTIONS } from './helpers/select_type_options';

const Payments = ({
	error,
	resources,
	fetchPayments,
	setPaymentsQuery,
	setPaymentIdType,
	setPaymentIdSubtype,
	dictionaryPaymentMethods,
	createFiltersSuggestions,
	fetchProviders,
	fetchInstitutionsList,
	filters,
	location: { pathname },
	match: {
		params: { id }
	}
}) => {
	// checks what data in table we want to acces (payments | vod payments | user payments)
	const locationType = pathname.split('/')[2];
	// sets location subtype if avalible
	const locationSubtype =
		locationType === 'vod' ? pathname.split('/')[3] : 'vod';

	useEffect(() => {
		// set payment id type in store (payments | vod payments | user payments)
		setPaymentIdType(locationType);
		// set payment id subtype in store (vod | series | season | episode)
		setPaymentIdSubtype(locationSubtype);
		// eslint-disable-next-line
	}, []);

	// Actions to fetch data for filters
	const filterActions = {
		dictionaryPaymentMethods,
		fetchProviders,
		fetchInstitutionsList
	};

	const paymentsFilters = { ...filters, statusPayment: PAYMENT_STATUS_OPTIONS };

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters: paymentsFilters
	});

	const { columns } = resources;

	return (
		// Dispatch fetchPayments action in payments table
		<PanelPageTemplate
			itemId={id} // here it can be either id of vod or subscriber
			error={error}
			type="payments"
			resources={resources}
			columns={() => createColumns({ subtype: locationSubtype })} // argument subtypes helps create proper link to vod edit form
			fetchResourcesAction={fetchPayments}
			defaultSorted={[{ id: 'created_at', desc: true }]}
		>
			<div className="d-flex justify-content-end">
				<Search setQueryValue={setPaymentsQuery} />
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Payments.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchPayments: PropTypes.func.isRequired,
	fetchInstitutionsList: PropTypes.func.isRequired,
	setPaymentsQuery: PropTypes.func.isRequired,
	setPaymentIdType: PropTypes.func.isRequired,
	setPaymentIdSubtype: PropTypes.func.isRequired,
	dictionaryPaymentMethods: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	filters: PropTypes.object,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	})
};

const mapStateToProps = ({
	payments: { table },
	dictionary: { paymentMethods },
	providers,
	dictionary
}) => ({
	error: table.error,
	resources: table,
	filters: {
		payment_method: paymentMethods,
		providers: providers.table.data,
		institutions: dictionary.institutionsList
	}
});

export default compose(
	connect(mapStateToProps, {
		fetchPayments,
		setPaymentsQuery,
		setPaymentIdType,
		setPaymentIdSubtype,
		dictionaryPaymentMethods,
		createFiltersSuggestions,
		fetchProviders,
		fetchInstitutionsList
	}),
	withRouter
)(Payments);
