import React from 'react';
import { useSelector } from 'react-redux';

// Import styles
import { ShortDescription } from './styles';

// Import helpers
import { checkProductSubtype } from '../../helpers';

const SubShortDescription = () => {
	const {
		subtype,
		metadata: { sub_summary_short }
	} = useSelector(({ product_preview }) => product_preview.data);

	if (!sub_summary_short) {
		return null;
	}

	const { isArticle } = checkProductSubtype(subtype);

	return (
		<ShortDescription isLightBackground={isArticle}>
			{sub_summary_short}
		</ShortDescription>
	);
};

export default SubShortDescription;
