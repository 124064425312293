import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchAssetGroup } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import MetadataFields from 'components/views/asset_groups/metadata_fields/MetadataFields';

const AssetGroupsForm = ({
	history,
	fetchAssetGroup,
	error,
	isLoaded,
	initialValues,
	match: {
		params: { model_type, model_id }
	},
	cancelButtonPath
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const resources = {
			...values,
			name: values.metadata.title,
			model_type,
			model_id,
			order: 0,
			type: 'GRAPHIC_2D',
			free: true
		};

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'asset_groups',
			redirectPath: cancelButtonPath,
			notificationName: 'asset/groups',
			redirectToPath: true
		});
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchAssetGroup}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={initialValues}
					validate={validate}
					decorators={decorators}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<MetadataFields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path={cancelButtonPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

AssetGroupsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchAssetGroup: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ asset_groups: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchAssetGroup }),
	withRouter
)(AssetGroupsForm);
