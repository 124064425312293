import React from 'react';
import { useTranslation } from 'react-i18next';
import { oneOfType, string, array, bool } from 'prop-types';

// Import Components
import InfoGroup from './InfoGroup';
import ElementsList from './ElementsList';

// Import styles
import { Item } from './styles';

const DetailsItem = ({ name, value, isArrayType }) => {
	const { t } = useTranslation();

	return (
		<InfoGroup
			title={t(`product_preview:metadata.${name}`)}
			tooltipText={value}
			shouldEllipsis={!isArrayType}
		>
			{isArrayType ? (
				<ElementsList typeName={name} elements={value} />
			) : (
				<Item>{value}</Item>
			)}
		</InfoGroup>
	);
};

DetailsItem.propTypes = {
	name: string.isRequired,
	value: oneOfType([string, array]).isRequired,
	isArrayType: bool.isRequired
};

export default DetailsItem;
