// Import translation
import i18n from 'i18next';

// check if the pin.length === 4 and contains only numbers
export const checkPinFormat = (pin) => {
	const isValid = pin.toString().length === 4 && pin.match(/^\d+$/);
	return isValid;
};

// Check if inappropriate content types are valid
export const validateInappropiateContent = (inappropriate_content) => {
	let errorMsg = '';

	const checboxArray = Object.entries(inappropriate_content).filter(
		([_, value]) => value === true
	);

	if (inappropriate_content.lack === true && checboxArray.length > 1) {
		errorMsg = i18n.t(
			'common:form.fields.validation.inappropriate_content.lack_true'
		);
	}

	if (inappropriate_content.lack === false && checboxArray.length === 0) {
		errorMsg = i18n.t(
			'common:form.fields.validation.inappropriate_content.lack_false'
		);
	}

	return errorMsg;
};
