import { useLocation } from 'react-router-dom';
import { getLocationType } from 'components/helpers/convert';

const useAudioLocation = () => {
	const { pathname } = useLocation();

	const locationType = getLocationType(pathname);
	const isAudio = locationType === 'audio';

	return { locationType, isAudio };
};

export default useAudioLocation;
