import React from 'react';
import i18n from 'i18next';

// Import tabs component
import SubscribersForm from '../../subscribers_form/SubscribersForm';
import SubscribersDevices from '../tabs/SubscribersDevices';
import SubscribersPackets from '../../subscribers_packets/SubscribersPackets';
import Payments from 'components/views/payments/Payments';
import SubscribersRegisterForm from '../../subscribers_register_data/SubscribersRegisterForm';

export const subscribersTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <SubscribersForm />
	},
	{
		name: i18n.t('common:tabs.user_data'),
		path: 'user_data',
		disableOnCreate: false,
		component: <SubscribersRegisterForm />
	},
	{
		name: i18n.t('common:tabs.devices'),
		path: 'devices',
		disableOnCreate: true,
		component: <SubscribersDevices />
	},
	{
		name: i18n.t('subscribers:tabs.packets'),
		path: 'packets',
		disableOnCreate: true,
		component: <SubscribersPackets />
	},
	{
		name: i18n.t('common:tabs.payments'),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	}
	// {
	// 	name: i18n.t('common:tabs.tracking'),
	// 	path: 'tracking',
	// 	disableOnCreate: true,
	// 	component: <Trackings />
	// }
];
