export const sliderOptions = {
	dots: false,
	infinite: false,
	slidesToShow: 2,
	slidesToScroll: 1,
	initialSlide: 0,
	rows: 1,
	speed: 0,
	fade: true
};

export const DESCRIPTION_HEIGHT = 60;

export const getAssetInfo = ({ galleryMetadata, metadata }) => {
	const author = metadata?.author || galleryMetadata?.author;
	const description = metadata?.description || galleryMetadata?.description;
	const title = metadata?.title || galleryMetadata?.title;
	const source = metadata?.source || galleryMetadata?.source;

	return { author, description, source, title };
};

export const getDataSortedById = (data) =>
	data.sort((a, b) => (a.id > b.id ? 1 : -1));
