import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Import utilities
import { messageConfirmDeleteHandler } from 'components/utilities/message';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';
import usePermissionsCheck from 'hooks/usePermissionsCheck';

// Import components
import {
	Ibox,
	IboxTitle,
	IboxTools,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import PanelPageTable from './PanelPageTable';

const PanelPageTemplate = React.memo((props) => {
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();
	// check if user have rights to add content
	const {
		checkedPermissions: { canCreateContent }
	} = usePermissionsCheck();

	const createLinkParams = {
		pathname: `/panel/${props.type}/create/metadata`,
		...props.linkParams
	};

	// Handle select item to delete
	const handleDeleteItem = (id, resources) =>
		// Show confirmation dialog
		// Dispatch a delete action
		messageConfirmDeleteHandler(
			props.typeText ?? resources?.type ?? props.type,
			id,
			props.deleteItemAction,
			resources
		);

	return (
		<React.Fragment>
			<Wraper error={props.error}>
				<Ibox error={false} className="m-b-lg">
					{props.addLinkText && !isReadOnly && canCreateContent && (
						<IboxTitle>
							<IboxTools>
								<Link
									data-testid={`create-${props.type}-link`}
									className="btn__link"
									to={createLinkParams}
								>
									{props.addLinkText}
								</Link>
							</IboxTools>
						</IboxTitle>
					)}
					<IboxContentBase>
						<React.Fragment>
							{props.children}
							{props.isReactTableVisible && (
								<PanelPageTable
									itemId={props.itemId}
									isShow={true}
									resources={props.resources}
									columns={props.columns(handleDeleteItem, isReadOnly)}
									fetchResourcesAction={props.fetchResourcesAction}
									resourcesForFetchAction={props.resourcesForFetchAction}
									notificationDeleteSuccessTxt={
										props.notificationDeleteSuccessTxt
									}
									notificationDeleteErrorTxt={props.notificationDeleteErrorTxt}
									getTrProps={props.getTrProps}
									defaultSorted={props.defaultSorted}
									tableType={props.type}
									isMassChangeTable={props.isMassChangeTable}
									searchColumn={props.searchColumn}
								/>
							)}
						</React.Fragment>
					</IboxContentBase>
				</Ibox>
			</Wraper>
		</React.Fragment>
	);
});

PanelPageTemplate.defaultProps = {
	deleteItemAction: () => {},
	notificationDeleteSuccessTxt: '',
	notificationDeleteErrorTxt: '',
	isReactTableVisible: true,
	itemId: null,
	isPageHeadingVisible: true,
	breadcrumbs: [],
	title: '',
	linkParams: {},
	typeText: null,
	getTrProps: () => ({}),
	defaultSorted: [],
	isMassChangeTable: false,
	searchColumn: ''
};

PanelPageTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	error: PropTypes.bool.isRequired,
	breadcrumbs: PropTypes.array.isRequired,
	children: PropTypes.object,
	type: PropTypes.string.isRequired,
	addLinkText: PropTypes.string,
	resources: PropTypes.object.isRequired,
	columns: PropTypes.func.isRequired,
	fetchResourcesAction: PropTypes.func.isRequired,
	resourcesForFetchAction: PropTypes.any,
	deleteItemAction: PropTypes.func.isRequired,
	notificationDeleteSuccessTxt: PropTypes.string,
	notificationDeleteErrorTxt: PropTypes.string,
	isReactTableVisible: PropTypes.bool,
	linkParams: PropTypes.object,
	getTrProps: PropTypes.func,
	itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	defaultSorted: PropTypes.array,
	isMassChangeTable: PropTypes.bool,
	searchColumn: PropTypes.string
};

export default PanelPageTemplate;
