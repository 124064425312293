import React from 'react';
import PropTypes from 'prop-types';

// Import styled components
import { ArrowController } from './styles';

// Import icons
import { ReactComponent as IconArrowLeft } from '../../assets/icons/arrow_xl_l.svg';
import { ReactComponent as IconArrowRight } from '../../assets/icons/arrow_xl_r.svg';

//Import variables
import { SLIDER_ARROW_TYPES } from '../../helpers/variables';

const { PREV } = SLIDER_ARROW_TYPES;

const SliderArrow = ({ type, ...rest }) => {
	const isPrevArrow = type === PREV;

	return (
		<ArrowController isPrevArrow={isPrevArrow} {...rest}>
			{isPrevArrow ? <IconArrowLeft /> : <IconArrowRight />}
		</ArrowController>
	);
};

SliderArrow.propTypes = {
	type: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

export default SliderArrow;
