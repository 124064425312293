import React from 'react';
import { string, bool } from 'prop-types';
import { useSelector } from 'react-redux';

// Import styles
import { Text, Divider } from './styles';
import { checkProductSubtype } from '../../helpers';

// Import helpers

const InfoItem = ({ text = '', showDivider = true }) => {
	const { subtype } = useSelector(
		({ product_preview }) => product_preview.data
	);

	if (!text) return null;

	const { isArticle } = checkProductSubtype(subtype);

	return (
		<>
			{showDivider && <Divider isLightBackground={isArticle} />}
			<Text isLightBackground={isArticle}>{text}</Text>
		</>
	);
};

InfoItem.propTypes = {
	text: string,
	showDivider: bool
};

export default InfoItem;
