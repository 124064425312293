import i18n from 'i18next';

// To do: this inputs are for examples, please contact with project maneger
export const ARTICLES_FILTER_FIELDS = [
	{
		name: 'title',
		label: i18n.t('filters:fields.title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'products_group_uuid',
		label: i18n.t('filters:fields.products_group_name'),
		type: 'custom-names',
		required: false,
		mode: 'multiple',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'rating',
		label: i18n.t('filters:fields.rating'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'payment_models',
		label: i18n.t('filters:fields.payment_models'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'created_at',
		label: i18n.t('filters:fields.created_at'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'updated_at',
		label: i18n.t('filters:fields.updated_at'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'category',
		label: i18n.t('filters:fields.category'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'sub_category',
		label: i18n.t('filters:fields.sub_category'),
		type: 'multi-select',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9
	}
];
