import styled, { css } from 'styled-components';
import * as polished from 'polished';
import { Link } from 'react-router-dom';

// HELPERS
import { customResponsive, customMinResponsive, size } from '../../styles';
import { CONTAINER_SCREEN_PERCENT } from '../../helpers/variables';

export const CoverImage = styled.div.attrs(
	({ cover, theme: { black, productDetails } }) => ({
		style: {
			backgroundImage: cover ? `url(${cover})` : 'none',
			backgroundColor: cover ? black : productDetails.noCover
		}
	})
)`
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center top;
	background-size: cover;
	width: 100%;
	position: absolute;
	top: 0;
	height: 65vh;

	${customMinResponsive(
		size.medium,
		css`
			height: ${CONTAINER_SCREEN_PERCENT}vh;
		`
	)}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${({ theme }) => polished.rgba(theme.black, 0.16)};
	}
`;

export const Wrapper = styled.div`
	padding-top: ${({ isArticle }) => (isArticle ? '13.4rem' : '11.5rem')};
	padding-left: 1.7rem;
	padding-bottom: 2rem;

	${({ isArticle }) =>
		isArticle &&
		css`
			background-color: #edeced;
		`}

	${customMinResponsive(
		size.medium,
		css`
			padding-top: ${({ isArticle }) => (isArticle ? '16.3rem' : '20.9rem')};
			padding-right: ${({ isArticle }) => (isArticle ? '34.4vw' : '0')};
			padding-left: ${({ isArticle }) => (isArticle ? '10.1vw' : '4rem')};
		`
	)}
`;

export const InfoContainer = styled.div`
	position: relative;
	z-index: 2;
`;

export const PosterImage = styled.img`
	position: absolute;
	top: 15%;
	right: 20%;
	width: 240px;
	height: 350px;
	z-index: 2;

	${customMinResponsive(
		1500,
		css`
			width: 350px;
			height: 515px;
		`
	)}

	${customResponsive(
		1100,
		css`
			right: 10%;
		`
	)}

	${customResponsive(
		1000,
		css`
			right: 5%;
		`
	)}
`;

export const StyledLink = styled(Link)`
	position: fixed;
	top: 2rem;
	right: 2rem;
	display: flex;
	align-items: center;
	z-index: 9999;
`;
