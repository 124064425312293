import React, { useRef, useState, useEffect } from 'react';

export const useTinyTexEditorField = ({
	input,
	fieldInfo = null,
	meta: { touched, error, submitError }
}) => {
	const editorRef = useRef(null);

	const [characterCount, setCharacterCount] = useState(0);
	const [isInitialized, setIsInitialized] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const handleEdit = () => {
		setIsEditing(true);
	};

	useEffect(() => {
		charactersCount();
	}, [input.value]);

	const charactersCount = () => {
		if (editorRef.current) {
			const characters = editorRef.current.plugins.wordcount.body.getCharacterCountWithoutSpaces();
			setCharacterCount(characters);
		}
	};

	const focusEditorAtEnd = () => {
		if (editorRef.current) {
			const editor = editorRef.current;
			const selection = editor.selection;

			editor.focus();
			selection.select(editor.getBody(), true);
			selection.collapse(false);
			selection.setCursorLocation(editor.getBody(), editor.getContent().length);
		}
	};

	const handleInit = (evt, editor) => {
		setIsInitialized(true);
		editorRef.current = editor;
		charactersCount();
		focusEditorAtEnd();
	};

	const handleEditorChange = (content) => {
		// Update input value
		input.onChange(content);
	};

	// Render field info if there aren't any errors
	const renderFieldInfo = () => {
		if (touched && (error || submitError)) return null;
		if (fieldInfo) {
			return (
				<span className="input-info m-b-sm">
					<i className="fa fa-info-circle m-r-sm"></i>
					{fieldInfo}
				</span>
			);
		}
	};
	return {
		handleEditorChange,
		handleEdit,
		handleInit,
		renderFieldInfo,
		characterCount,
		isInitialized,
		isEditing
	};
};
