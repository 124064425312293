export const getCharactersNumberWithoutSpace = (text) => {
	if (text) {
		const textWithoutSpaceAndHTML = text
			.replace(/\s/g, '')
			.replace(/&#{0,1}[a-z0-9]+;/gi, '')
			.replace(/<[^>]*>/g, '');

		return textWithoutSpaceAndHTML.length;
	}
};
