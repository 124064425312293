import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = () => [
	{
		Header: i18n.t('institutions:table_columns.name'),
		accessor: 'name',
		Cell: ({
			row: {
				_original: { uuid, name }
			}
		}) => <Link to={`/panel/institutions/edit/${uuid}/metadata`}>{name}</Link>
	},
	{
		Header: i18n.t('institutions:table_columns.contact'),
		accessor: 'contact'
	},
	{
		Header: i18n.t('institutions:table_columns.slug'),
		accessor: 'slug'
	},
	{
		Header: i18n.t('institutions:table_columns.active'),
		accessor: 'active',
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => ActiveIcon({ active })
	},
	{
		Header: i18n.t('institutions:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('institutions:table_columns.updated_at'),
		accessor: 'updated_at'
	}
];
