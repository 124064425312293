import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	fetchArticleMaterials,
	updateArticleMaterials,
	updateArticleMaterialsError,
	deleteArticleMaterials,
	deleteArticleMaterialsError,
	addVideoToArticle
} from 'store/actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import helpers
import { ARTICLE_MATERIALS_LIMIT } from 'components/helpers/variables';

// Import columns
import { createColumns } from 'components/utilities/table/materials_table/materials_columns';

// Import components
import MaterialsTable from 'components/utilities/table/materials_table/MaterialsTable';
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import { MaterialSubtitlesTable } from 'components/utilities';
import { Empty } from 'antd';
import VodAddMaterialsButtons from 'components/common/buttons/add_materials_buttons/VodAddMaterialsButtons';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

const ArticleMaterials = ({
	fetchArticleMaterials,
	updateArticleMaterials,
	updateArticleMaterialsError,
	deleteArticleMaterials,
	deleteArticleMaterialsError,
	addVideoToArticle,
	error,
	isLoaded,
	main,
	preview,
	match
}) => {
	// fetch ARTICLE materials on mount
	const uuid = match.params.id;
	React.useEffect(() => {
		// Dispatch fetchArticleMaterials actions in article_materials
		fetchArticleMaterials(uuid);
		// eslint-disable-next-line
	}, []);

	const { canEditVideo } = useAuthCheck;

	const { t } = useTranslation();

	// disable Add Main video button if there is already main video created
	const isMainDisabled = main.length !== 0;

	return (
		<Wraper lg="12" error={error}>
			<>
				{canEditVideo && (
					<VodAddMaterialsButtons
						isLoaded={isLoaded}
						isMainDisabled={isMainDisabled}
						uuid={uuid}
						addVideoToVod={addVideoToArticle}
					/>
				)}
				{isLoaded &&
					(main.length || preview.length ? (
						<>
							{main.map((resource) => (
								<Ibox key={resource.id}>
									<IboxTitle title={t('common:materials_table.main_title')} />
									<IboxContentBase isLoaded={isLoaded}>
										<div className="h-150">
											<MaterialsTable
												columns={createColumns}
												resources={resource}
												updateMaterials={updateArticleMaterials}
												updateMaterialsError={updateArticleMaterialsError}
												deleteMaterials={deleteArticleMaterials}
												deleteMaterialsError={deleteArticleMaterialsError}
												materialsLimit={ARTICLE_MATERIALS_LIMIT}
											/>
											<MaterialSubtitlesTable videoId={resource.id} />
										</div>
									</IboxContentBase>
								</Ibox>
							))}
						</>
					) : (
						<Empty description={t('common:no_data')} className="m-t-md" />
					))}
			</>
		</Wraper>
	);
};

const mapStateToProps = ({ articles: { materials } }) => ({
	error: materials.error,
	isLoaded: materials.isLoaded,
	main: materials.main.data,
	preview: materials.preview.data
});

ArticleMaterials.propTypes = {
	fetchArticleMaterials: PropTypes.func.isRequired,
	updateArticleMaterials: PropTypes.func.isRequired,
	updateArticleMaterialsError: PropTypes.func.isRequired,
	deleteArticleMaterials: PropTypes.func.isRequired,
	deleteArticleMaterialsError: PropTypes.func.isRequired,
	addVideoToArticle: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	main: PropTypes.array.isRequired,
	preview: PropTypes.array.isRequired,
	match: PropTypes.object.isRequired
};

export default compose(
	connect(mapStateToProps, {
		fetchArticleMaterials,
		updateArticleMaterials,
		updateArticleMaterialsError,
		deleteArticleMaterials,
		deleteArticleMaterialsError,
		addVideoToArticle
	}),
	withRouter
)(ArticleMaterials);
