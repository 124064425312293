import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const ImagePreviewModal = ({
	previewVisible,
	previewImage,
	closeModal,
	collection
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			visible={previewVisible}
			title={t(`common:images.${collection}`)}
			footer={null}
			onCancel={closeModal}
		>
			<img alt="Logo" style={{ width: '100%' }} src={previewImage} />
		</Modal>
	);
};

ImagePreviewModal.propTypes = {
	previewVisible: PropTypes.bool.isRequired,
	previewImage: PropTypes.string.isRequired,
	closeModal: PropTypes.func.isRequired,
	collection: PropTypes.string.isRequired
};

export default ImagePreviewModal;
