import React from 'react';

// Import Authorization
import PermissionsAuthorization from 'components/HOC/PermissionsAuthorization';

// Import permissions
import * as permissions from 'components/routes/helpers/permissions';

// ***************** Products *****************

// --- Promotions ---
import Promotions from 'components/views/promotions/Promotions';
import PromotionsTabs from 'components/views/promotions/promotions_tabs/PromotionsTabs';

// --- Channels ---
import Channels from 'components/views/channels/Channels';
import ChannelsTabs from 'components/views/channels/channels_tabs/ChannelsTabs';
import EPGTabs from 'components/views/channels/channels_tabs/epg/epg_tabs/EPGTabs';

// --- Articles ---
import Articles from 'components/views/articles/Articles';
import ArticlesTabs from 'components/views/articles/articles_tabs/ArticlesTabs';

// --- Events Live ---
import EventsLive from 'components/views/events_live/EventsLive';
import EventsLiveTabs from 'components/views/events_live/events_live_tabs/EventsLiveTabs';

// --- Provisioning ---
import Provisioning from 'components/views/provisioning/Provisioning';
import ProvisioningTabs from 'components/views/provisioning/provisioning_tabs/ProvisioningTabs';

// --- Passes ---
import Passes from 'components/views/passes/Passes';
import PassesTabs from 'components/views/passes/passes_tabs/PassesTabs';

// --- Packets ---
import Packets from 'components/views/packets/Packets';
import PacketsTabs from 'components/views/packets/packets_tabs/PacketsTabs';

// --- Main ---
import Main from 'components/views/main/Main';
import MainTabs from 'components/views/main/main_tabs/MainTabs';

// --- Menu ---
import Menu from 'components/views/menu/Menu';
import MenuTabs from 'components/views/menu/menu_tabs/MenuTabs';
import SubmenuTabs from 'components/views/menu/submenu_tabs/SubmenuTabs';

// --- Availability ---
import AvailabilityFormTemplate from 'components/common/templates/availability_form_template/AvailabilityFormTemplate';

// --- Visibility ---
import VisibilityFormTemplate from 'components/common/templates/visibility_form_template/VisibilityFormTemplate';

// --- Price ---
import PriceFormTemplate from 'components/common/templates/price_form_template/PriceFormTemplate';

// -- Material Subtitles ---
import { MaterialSubtitlesForm } from 'components/utilities';

// --- Limits ---
import Limits from 'components/views/limits/Limits';
import LimitsTabs from 'components/views/limits/limits_tabs/LimitsTabs';

// --- Payments ---
import Payments from 'components/views/payments/Payments';

// --- Reports ---
import Reports from 'components/views/reports/Reports';

// ***************** Users *****************
// --- Subscribers ---
import Subscribers from 'components/views/subscribers/Subscribers';
import SubscribersTabs from 'components/views/subscribers/subscribers_tabs/SubscribersTabs';
// --- Devices ---
import Devices from 'components/views/devices/Devices';

// ***************** CONTENT *****************
// --- Categories ---
import Categories from 'components/views/categories/Categories';
import CategoriesTabs from 'components/views/categories/categories_tabs/CategoriesTabs';
// --- Documents ---
import Documents from 'components/views/documents/Documents';
import DocumentsTabs from 'components/views/documents/documents_tabs/DocumentsTabs';
// --- Agreements ---
import Agreements from 'components/views/agreements/Agreements';
import AgreementsTabs from 'components/views/agreements/agreements_tabs/AgreementsTabs';
import AgreementsVersionsTabs from 'components/views/agreements/agreements_versions/agreements_versions_tabs/AgreementsVersionsTabs';
// --- VOD ---
import Vod from 'components/views/vod/Vod';
import VodTabs from 'components/views/vod/vod_tabs/VodTabs';
import VodCreateTabs from 'components/views/vod/vod_create/vod_create_tabs/VodCreateTabs';
// --- VOD Series---
import SeriesTabs from 'components/views/vod/vod_series/series_tabs/SeriesTabs';
// --- VOD Seasons---
import SeasonsTabs from 'components/views/vod/vod_seasons/seasons_tabs/SeasonsTabs';
// --- VOD Episodes---
import EpisodesTabs from 'components/views/vod/vod_episodes/episodes_tabs/EpisodesTabs';
// --- Sections groups ---
import SectionsGroups from 'components/views/sections_groups/SectionsGroups';
import SectionsGroupsTabs from 'components/views/sections_groups/sections_groups_tabs/SectionsGroupsTabs';
// --- Section ---
import Sections from 'components/views/sections/Sections';
import SectionsTabs from 'components/views/sections/sections_tabs/SectionsTabs';
// --- Banners ---
import Banners from 'components/views/banners/Banners';
import BannersTabs from 'components/views/banners/banners_tabs/BannersTabs';
// --- Mass changes ---
import MassChanges from 'components/views/mass_changes/MassChanges';
// --- Asset Groups ---
import AssetGroupsTabs from 'components/views/asset_groups/asset_groups_tabs/AssetGroupsTabs';
import AssetTabs from 'components/views/asset_groups/asset_tabs/AssetTabs';
// --- Tags ---
import Tags from 'components/views/tags/Tags';
import TagsTabs from 'components/views/tags/tags_tabs/TagsTabs';

// ***************** Ingester *****************
// --- Assets ---
import IngesterAssets from 'components/views/ingester/ingester_assets/IngesterAssets';
// --- Encoding ---
import IngesterEncoding from 'components/views/ingester/ingester_encoding/IngesterEncoding';
// --- Video Assets Params ---
import VideoAssetsParams from 'components/views/ingester/video_assets_params/VideoAssetsParams';
import VideoAssetsParamsForm from 'components/views/ingester/video_assets_params/video_assets_params_form/VideoAssetsParamsForm';

// ***************** Administration *****************
// --- Institutions ---
import Institutions from 'components/views/institutions/Institutions';
import InstitutionsTabs from 'components/views/institutions/institutions_tabs/InstitutionsTabs';
// --- Institutions ---
import AffiliatePartners from 'components/views/affiliate_partners/AffiliatePartners';
import AffiliatePartnersTabs from 'components/views/affiliate_partners/affiliate_partners_tabs/AffiliatePartnersTabs';
// --- Operators ---
import Operators from 'components/views/operators/Operators';
import OperatorsTabs from 'components/views/operators/operators_tabs/OperatorsTabs';
// --- Geoblock Groups  ---
import GeoblockGroups from 'components/views/geoblock_groups/GeoblockGroups';
import GeoblockGroupsTabs from 'components/views/geoblock_groups/geoblock_groups_tabs/GeoblockGroupsTabs';
// --- Notifications ---
import NotificationsForm from 'components/views/notifications/notifications_form/NotificationsForm';
// --- Activity ---
import Activity from 'components/views/activity/Activity';
import ActivityForm from 'components/views/activity/activity_form/ActivityForm';

// --- Settings ---
import SettingsTabs from 'components/views/settings/settings_tabs/SettingsTabs';

// --- Configuration ---
import ConfigurationTabs from 'components/views/configuration/configuration_tabs/ConfigurationTabs';

const ROUTES_PATHS = [
	// ------ Availability ------
	{
		path: '/panel/:type/:id/availability/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AVAILABILITY}
				component={AvailabilityFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AVAILABILITY
	},
	{
		path: '/panel/:type/:productID/availability/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AVAILABILITY}
				component={AvailabilityFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AVAILABILITY
	},

	// ------ Visibility ------
	{
		path: '/panel/:type/:id/visibility/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VISIBILITY}
				component={VisibilityFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VISIBILITY
	},
	{
		path: '/panel/:type/:productID/visibility/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VISIBILITY}
				component={VisibilityFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VISIBILITY
	},

	// ------ Prices ------
	{
		path: '/panel/:type/:id/price/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PRICES}
				component={PriceFormTemplate}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PRICES
	},

	// ***************** Products *****************
	// ------ Promotions ------
	{
		path: '/panel/promotions/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROMOTIONS}
				component={PromotionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROMOTIONS
	},
	{
		path: '/panel/promotions/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROMOTIONS}
				component={PromotionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROMOTIONS
	},
	{
		path: '/panel/promotions',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROMOTIONS}
				component={Promotions}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROMOTIONS
	},
	// ------ Channels ------
	{
		path: '/panel/channels/:channelId/epg/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CHANNELS}
				component={EPGTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CHANNELS
	},
	{
		path: '/panel/channels/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CHANNELS}
				component={ChannelsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CHANNELS
	},
	{
		path: '/panel/channels/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CHANNELS}
				component={ChannelsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CHANNELS
	},
	{
		path: '/panel/channels',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CHANNELS}
				component={Channels}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CHANNELS
	},

	// ------ Articles ------
	{
		path: '/panel/articles/:articleId/materials/:id/subtitles/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ARTICLES}
				component={MaterialSubtitlesForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ARTICLES
	},
	{
		path: '/panel/articles/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ARTICLES}
				component={ArticlesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ARTICLES
	},
	{
		path: '/panel/articles/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ARTICLES}
				component={ArticlesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ARTICLES
	},
	{
		path: '/panel/articles',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ARTICLES}
				component={Articles}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ARTICLES
	},

	// ------ Events Live ------
	{
		path: '/panel/events_live/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.EVENTS_LIVE}
				component={EventsLiveTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.EVENTS_LIVE
	},
	{
		path: '/panel/events_live/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.EVENTS_LIVE}
				component={EventsLiveTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.EVENTS_LIVE
	},
	{
		path: '/panel/events_live',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.EVENTS_LIVE}
				component={EventsLive}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.EVENTS_LIVE
	},

	// ------ Provisioning ------
	{
		path: '/panel/provisioning/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROVISIONING}
				component={ProvisioningTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROVISIONING
	},
	{
		path: '/panel/provisioning/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROVISIONING}
				component={ProvisioningTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROVISIONING
	},
	{
		path: '/panel/provisioning',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PROVISIONING}
				component={Provisioning}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PROVISIONING
	},

	// ------ Collections (old packets) ------
	{
		path: '/panel/packets/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.COLLECTIONS}
				component={PacketsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.COLLECTIONS
	},
	{
		path: '/panel/packets/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.COLLECTIONS}
				component={PacketsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.COLLECTIONS
	},
	{
		path: '/panel/packets',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.COLLECTIONS}
				component={Packets}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.COLLECTIONS
	},

	// ------ Passes ------
	{
		path: '/panel/passes/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PASSES}
				component={PassesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PASSES
	},
	{
		path: '/panel/passes/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PASSES}
				component={PassesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PASSES
	},
	{
		path: '/panel/passes',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PASSES}
				component={Passes}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PASSES
	},

	// ------ Packets (old Main) ------
	{
		path: '/panel/main/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PACKETS}
				component={MainTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PACKETS
	},
	{
		path: '/panel/main/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PACKETS}
				component={MainTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PACKETS
	},
	{
		path: '/panel/main',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PACKETS}
				component={Main}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PACKETS
	},

	// ------ Menu ------

	{
		path: '/panel/menus/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.MENU}
				component={MenuTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.MENU
	},
	{
		path: '/panel/menus/:parent_id/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.MENU}
				component={SubmenuTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.MENU
	},
	{
		path: '/panel/menus/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.MENU}
				component={MenuTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.MENU
	},
	{
		path: '/panel/menus/:parent_id/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.MENU}
				component={SubmenuTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.MENU
	},
	{
		path: '/panel/menus',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.MENU}
				component={Menu}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.MENU
	},

	// ------ Limits ------
	{
		path: '/panel/limits/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.LIMITS}
				component={LimitsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.LIMITS
	},
	{
		path: '/panel/limits/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.LIMITS}
				component={LimitsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.LIMITS
	},
	{
		path: '/panel/limits',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.LIMITS}
				component={Limits}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.LIMITS
	},

	// ------ Data Studio Reports ------
	{
		path: '/panel/reports',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.REPORTS}
				component={Reports}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.REPORTS
	},

	// ------ Payments ------
	{
		path: '/panel/payments',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.PAYMENTS}
				component={Payments}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.PAYMENTS
	},

	// ***************** Users *****************
	// ------ Subscribers ------
	{
		path: '/panel/subscribers/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SUBSCRIBERS}
				component={SubscribersTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SUBSCRIBERS
	},
	{
		path: '/panel/subscribers',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SUBSCRIBERS}
				component={Subscribers}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SUBSCRIBERS
	},
	{
		path: '/panel/devices',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.DEVICES}
				component={Devices}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.DEVICES
	},

	// ***************** CONTENT *****************
	// ------ Categories -----
	{
		path: '/panel/categories/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CATEGORIES}
				component={CategoriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CATEGORIES
	},
	{
		path: '/panel/categories/:type/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CATEGORIES}
				component={CategoriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CATEGORIES
	},
	{
		path: '/panel/categories',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CATEGORIES}
				component={Categories}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CATEGORIES
	},
	// ------ Documents ------
	{
		path: '/panel/documents/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.DOCUMENTS}
				component={DocumentsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.DOCUMENTS
	},
	{
		path: '/panel/documents/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.DOCUMENTS}
				component={DocumentsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.DOCUMENTS
	},
	{
		path: '/panel/documents',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.DOCUMENTS}
				component={Documents}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.DOCUMENTS
	},
	// ------ Agreements ------
	{
		path: '/panel/agreements/:agreementId/versions/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={AgreementsVersionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	{
		path: '/panel/agreements/:agreementId/versions/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={AgreementsVersionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	{
		path: '/panel/agreements/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={AgreementsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	{
		path: '/panel/agreements/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={AgreementsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	{
		path: '/panel/agreements',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AGREEMENTS}
				component={Agreements}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AGREEMENTS
	},
	// ------ VOD Series------
	{
		path: '/panel/vod/series/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_SERIES}
				component={SeriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_SERIES
	},
	{
		path: '/panel/vod/series/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_SERIES}
				component={SeriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_SERIES
	},
	// ------ VOD Seasons------
	{
		path: '/panel/vod/season/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_SEASONS}
				component={SeasonsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_SEASONS
	},
	{
		path: '/panel/vod/season/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_SEASONS}
				component={SeasonsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_SEASONS
	},
	// ------ VOD Episodes------
	{
		path: '/panel/vod/episode/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD_EPISODES}
				component={EpisodesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD_EPISODES
	},
	// ------ VOD ------
	{
		path: '/panel/vod/:vodId/materials/:id/subtitles/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD}
				component={MaterialSubtitlesForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD
	},
	{
		path: '/panel/vod/vod/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD}
				component={VodTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD
	},
	{
		path: '/panel/vod/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD}
				component={VodCreateTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD
	},
	{
		path: '/panel/vod',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VOD}
				component={Vod}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VOD
	},
	// ------ Audio Series------
	{
		path: '/panel/audio/series/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AUDIO_SERIES}
				component={SeriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AUDIO_SERIES
	},
	{
		path: '/panel/audio/series/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AUDIO_SERIES}
				component={SeriesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AUDIO_SERIES
	},
	// ------ Audio Seasons------
	{
		path: '/panel/audio/season/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AUDIO_SEASONS}
				component={SeasonsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AUDIO_SEASONS
	},
	{
		path: '/panel/audio/season/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AUDIO_SEASONS}
				component={SeasonsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AUDIO_SEASONS
	},
	// ------ Audio Episodes------
	{
		path: '/panel/audio/episode/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AUDIO_EPISODES}
				component={EpisodesTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AUDIO_EPISODES
	},
	// ------ Audio ------
	{
		path: '/panel/audio/:vodId/materials/:id/subtitles/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AUDIO}
				component={MaterialSubtitlesForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AUDIO
	},
	{
		path: '/panel/audio/vod/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AUDIO}
				component={VodTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AUDIO
	},
	{
		path: '/panel/audio/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AUDIO}
				component={VodCreateTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AUDIO
	},
	{
		path: '/panel/audio',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AUDIO}
				component={Vod}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AUDIO
	},
	// ------ Section groups ------
	{
		path: '/panel/sections_groups/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTION_GROUPS}
				component={SectionsGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTION_GROUPS
	},
	{
		path: '/panel/sections_groups/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTION_GROUPS}
				component={SectionsGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTION_GROUPS
	},
	{
		path: '/panel/sections_groups',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTION_GROUPS}
				component={SectionsGroups}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTION_GROUPS
	},
	// ------ Sections ------
	{
		path: '/panel/sections/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTIONS}
				component={SectionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTIONS
	},
	{
		path: '/panel/sections/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTIONS}
				component={SectionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTIONS
	},
	{
		path: '/panel/sections',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SECTIONS}
				component={Sections}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SECTIONS
	},
	// ------ Banners ------
	{
		path: '/panel/banners/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.BANNERS}
				component={BannersTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.BANNERS
	},
	{
		path: '/panel/banners/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.BANNERS}
				component={BannersTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.BANNERS
	},
	{
		path: '/panel/banners',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.BANNERS}
				component={Banners}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.BANNERS
	},
	// ------ Asset Groups ------
	{
		path: '/panel/asset_groups/:model_type/:productSubtype/:model_id/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ASSET_GROUPS}
				component={AssetGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ASSET_GROUPS
	},
	{
		path: '/panel/asset_groups/:model_type/:productSubtype/:model_id/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ASSET_GROUPS}
				component={AssetGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ASSET_GROUPS
	},
	{
		path:
			'/panel/asset/:model_type/:productSubtype/:model_id/edit/:collection/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ASSET_GROUPS}
				component={AssetTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ASSET_GROUPS
	},
	// ------ Mass changes ------
	{
		path: '/panel/mass_changes',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.MASS_CHANGES}
				component={MassChanges}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.MASS_CHANGES
	},
	// ------ Tags ------
	{
		path: '/panel/tags/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.TAGS}
				component={TagsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.TAGS
	},
	{
		path: '/panel/tags/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.TAGS}
				component={TagsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.TAGS
	},
	{
		path: '/panel/tags',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.TAGS}
				component={Tags}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.TAGS
	},
	// ***************** Ingester *****************
	// ------ Assets ------
	{
		path: '/panel/ingester/assets',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.INGESTER_ASSETS}
				component={IngesterAssets}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.INGESTER_ASSETS
	},
	// ------ Encoding ------
	{
		path: '/panel/ingester/encoding',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.INGESTER_ENCODING}
				component={IngesterEncoding}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.INGESTER_ENCODING
	},
	// ------ Video assets params ------
	{
		path: '/panel/ingester/video-assets-params/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VIDEO_ASSETS_PARAMS}
				component={VideoAssetsParamsForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VIDEO_ASSETS_PARAMS
	},
	{
		path: '/panel/ingester/video-assets-params/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VIDEO_ASSETS_PARAMS}
				component={VideoAssetsParamsForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VIDEO_ASSETS_PARAMS
	},
	{
		path: '/panel/ingester/video-assets-params',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.VIDEO_ASSETS_PARAMS}
				component={VideoAssetsParams}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.VIDEO_ASSETS_PARAMS
	},

	// ***************** Administration *****************
	// ------ Institutions ------
	{
		path: '/panel/institutions/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.INSTITUTIONS}
				component={InstitutionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.INSTITUTIONS
	},
	{
		path: '/panel/institutions/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.INSTITUTIONS}
				component={InstitutionsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.INSTITUTIONS
	},
	{
		path: '/panel/institutions',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.INSTITUTIONS}
				component={Institutions}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.INSTITUTIONS
	},
	// ------ AFFILIATE PARTNERS ------
	{
		path: '/panel/affiliate_partners/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AFFILIATE_PARTNERS}
				component={AffiliatePartnersTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AFFILIATE_PARTNERS
	},
	{
		path: '/panel/affiliate_partners/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AFFILIATE_PARTNERS}
				component={AffiliatePartnersTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AFFILIATE_PARTNERS
	},
	{
		path: '/panel/affiliate_partners',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.AFFILIATE_PARTNERS}
				component={AffiliatePartners}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.AFFILIATE_PARTNERS
	},
	// ------ Operators ------
	{
		path: '/panel/operators/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.OPERATORS}
				component={OperatorsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.OPERATORS
	},
	{
		path: '/panel/operators/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.OPERATORS}
				component={OperatorsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.OPERATORS
	},
	{
		path: '/panel/operators',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.OPERATORS}
				component={Operators}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.OPERATORS
	},
	// ------ GeoblockGroups ------
	{
		path: '/panel/geoblock_groups/edit/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.GEOLOCK}
				component={GeoblockGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.GEOLOCK
	},
	{
		path: '/panel/geoblock_groups/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.GEOLOCK}
				component={GeoblockGroupsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.GEOLOCK
	},
	{
		path: '/panel/geoblock_groups',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.GEOLOCK}
				component={GeoblockGroups}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.GEOLOCK
	},
	// ------ Trackings ------
	// {
	// 	path: '/panel/trackings',
	// 	component: (routerProps) => (
	// 		<PermissionsAuthorization
	// 			{...permissions.TRACKINGS}
	// 			component={Trackings}
	// 			routerProps={routerProps}
	// 		/>
	// 	),
	// 	permissions: permissions.TRACKINGS
	// },
	// ------ Activity ------
	{
		path: '/panel/activity/:id',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ACTIVITY}
				component={ActivityForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ACTIVITY
	},
	{
		path: '/panel/activity',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.ACTIVITY}
				component={Activity}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.ACTIVITY
	},

	// ------ Settings ------
	{
		path: '/panel/settings',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.SETTINGS}
				component={SettingsTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.SETTINGS
	},

	// ------ Configuration ------
	{
		path: '/panel/configuration',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.CONFIGURATION}
				component={ConfigurationTabs}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.CONFIGURATION
	},

	// ------Notifications ------
	{
		path: '/panel/notifications/create',
		component: (routerProps) => (
			<PermissionsAuthorization
				{...permissions.NOTIFICATIONS}
				component={NotificationsForm}
				routerProps={routerProps}
			/>
		),
		permissions: permissions.NOTIFICATIONS
	}
];

export default ROUTES_PATHS;
