import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPassAvailability, deletePassAvailability } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './passes_availability_columns';

// Import components
import { TableTemplate } from 'components/common/templates';

const PassesAvailability = ({
	error,
	fetchPassAvailability,
	deletePassAvailability,
	resources,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	return (
		<TableTemplate
			itemId={id}
			error={error}
			type={`passes/${id}/availability`}
			typeText="availability"
			addLinkText={t('availability:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchPassAvailability}
			deleteItemAction={deletePassAvailability}
			notificationDeleteSuccessTxt={t(
				'availability:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('availability:notification.delete_error')}
		></TableTemplate>
	);
};

PassesAvailability.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchPassAvailability: PropTypes.func.isRequired,
	deletePassAvailability: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ passes: { availability } }) => ({
	error: availability.table.error,
	resources: availability.table
});

export default compose(
	connect(mapStateToProps, {
		fetchPassAvailability,
		deletePassAvailability
	}),
	withRouter
)(PassesAvailability);
