import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import {
	fetchEventLiveCover,
	fetchEventLivePoster,
	fetchEventLiveAssetGroups,
	fetchEventLiveTitle
} from 'store/actions';

// Import helpers
import {
	IMAGE_SIZE_INFO,
	PRODUCT_IMAGES_REQUIREMENTS
} from 'components/helpers/info_helpers/info_texts';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitleCollapse from 'components/utilities/info/InfoTitleCollapse';
import AssetGroups from 'components/views/asset_groups/AssetGroups';

// Import translation
import { useTranslation } from 'react-i18next';

const EventsLiveImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<>
			<InfoTitleCollapse
				title={IMAGE_SIZE_INFO}
				requirements={PRODUCT_IMAGES_REQUIREMENTS}
			/>
			<Image
				title={t('common:images.cover')}
				fetchResourcesAction={(id) => fetchEventLiveCover(id)(dispatch)}
				collectionType="cover"
				mediaType="live_event"
				storePathSelector="events_live"
				productSubtype="events_live"
			/>
			<Image
				title={t('common:images.poster')}
				fetchResourcesAction={(id) => fetchEventLivePoster(id)(dispatch)}
				collectionType="poster"
				mediaType="live_event"
				storePathSelector="events_live"
				productSubtype="events_live"
			/>
			<Image
				title={t('common:images.title')}
				fetchResourcesAction={(id) => fetchEventLiveTitle(id)(dispatch)}
				collectionType="title"
				mediaType="live_event"
				storePathSelector="events_live"
				productSubtype="events_live"
			/>
			<AssetGroups
				fetchResourcesAction={(id) => fetchEventLiveAssetGroups(id)(dispatch)}
				mediaType="live_event"
				storePathSelector="events_live"
				productSubtype="events_live"
			/>
		</>
	);
};

export default EventsLiveImages;
