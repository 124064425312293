// Import translation
import i18n from 'i18next';

export const vod = {
	title: i18n.t('common:products_tabs.vod'),
	apiUrl: '/vod/newList?is_audio=0&',
	type: 'vod'
};

export const audio = {
	title: i18n.t('common:products_tabs.audio'),
	apiUrl: '/vod/newList?is_audio=1&',
	type: 'audio'
};

export const channel = {
	title: i18n.t('common:products_tabs.live'),
	apiUrl: '/products/channels?',
	type: 'channel'
};

export const liveEvents = {
	title: i18n.t('common:products_tabs.events_live'),
	apiUrl: '/products/liveevents/list?',
	type: 'liveEvents'
};

export const section_assigned = {
	title: i18n.t('common:products_tabs.sections'),
	apiUrl: '/section/list?',
	type: 'sections'
};

export const banners = {
	title: i18n.t('common:products_tabs.banners'),
	apiUrl: '/section/banners/list?',
	type: 'banners'
};

export const articles = {
	title: i18n.t('common:products_tabs.articles'),
	apiUrl: '/products/articles/list?',
	type: 'articles'
};

export const providers = {
	title: i18n.t('common:products_tabs.institutions'),
	apiUrl: '/productsgroup/list?',
	type: 'provider'
};

export const provisioning = {
	title: i18n.t('common:products_tabs.provisioning'),
	apiUrl: 'packet/list?type=provision&',
	type: 'provisioning'
};

export const collections = {
	title: i18n.t('common:products_tabs.collections'),
	apiUrl: 'packet/list?type=collection&',
	type: 'collections'
};

export const packets = {
	title: i18n.t('common:products_tabs.main'),
	apiUrl: 'packet/list?type=main&',
	type: 'main'
};
