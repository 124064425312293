import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import ReactTable from 'react-table';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import services from 'services/services';

// Import columns
import { columns } from './columns';

// Import components
import { IboxContentTitle } from 'components/common/layout';

// Import hooks
import useIngesterProductAssets from './useIngesterProductAssets';

// Import api
import { GET_INGESTER_PRODUCTS_ASSETS_API } from 'components/helpers/api/ingester';

const AssetsList = () => {
	const { t } = useTranslation();
	const { id, queryKey, refreshData } = useIngesterProductAssets();

	const { data, isFetching, remove } = useQuery({
		queryKey,
		queryFn: async () => {
			const url = generatePath(GET_INGESTER_PRODUCTS_ASSETS_API, { id });
			const { data } = await services.get(url);
			return data?.data ?? [];
		}
	});

	useEffect(() => {
		return () => remove();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="pb-4 my-5">
			<IboxContentTitle
				isLoaded={!isFetching}
				title={t('upload_materials:ingester_assets_list')}
				classNameTitle="py-3 align-items-center"
				headerButtons={
					<Button onClick={refreshData} type="primary" disabled={isFetching}>
						{t('common:buttons.refresh')}
					</Button>
				}
			>
				<ReactTable
					data={data}
					columns={columns}
					showPagination={false}
					pageSize={data?.length || 5}
					className="-striped -highlight"
					noDataText={t('common:no_data')}
				/>
			</IboxContentTitle>
		</div>
	);
};

export default AssetsList;
