import i18n from 'i18next';
import { getCharactersNumberWithoutSpace } from 'components/helpers/characters_counter';

export const defaultFieldValues = {
	parent_uuid: null,
	title: '',
	summary_long: '',
	summary_short: '',
	rating: '0',
	data: {
		sub_title: '',
		sub_summary_long: '',
		sub_summary_short: '',
		hide_title: false,
		country: '',
		actors: [],
		actors_display: '',
		writers: [],
		writers_display: '',
		directors: [],
		directors_display: '',
		scene: '',
		duration: 0,
		release_description: '',
		release_year: 0,
		producers: [],
		producers_display: ''
	},
	category: '',
	sub_category: [],
	genres: [],
	active: false,
	type: 'live_event',
	subtype: 'live_event',
	products_group_uuid: null,
	free: false,
	additional_data: {
		agreement_inconsistent_with_the_regulations: false,
		agreement_offensive: false,
		agreement_pornographic: false,
		agreement_violence: false,
		agreement_hate: false,
		agreement_moral_standards: false,
		agreement_CKF_Warsaw: false,
		show_statute: true,
		inappropriate_content: {
			violence: false,
			sex: false,
			vulgarisms: false,
			drugs: false,
			lack: false
		},
		promoted_product: ''
	},
	additional_metadata: {
		sound: '',
		subtitles: '',
		dramaturgy: [],
		libretto: [],
		choreography: [],
		lead: [],
		curatorial_care: [],
		conducting: [],
		composition: [],
		authors: []
	}
};

export const BASE_FIELDS = ({
	isEdit,
	checkedPermissions,
	changeFieldValue,
	currentValues,
	isChatIdVisible,
	chatEnabledChange
}) => [
	{
		name: 'type',
		label: i18n.t('events_live:fields.type'),
		type: 'select',
		requiredField: true,
		disabled: true
	},
	{
		name: 'active',
		label: i18n.t('events_live:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct,
		fieldInfo: i18n.t('vod:form:fields_info.active')
	},
	{
		name: 'products_group_uuid',
		label: i18n.t('events_live:fields.products_group_uuid'),
		type: 'select',
		disabled: !isEdit || !checkedPermissions.canActivate
	},
	{
		name: 'title',
		label: i18n.t('events_live:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'summary_short',
		label: i18n.t('events_live:fields.summary_short'),
		type: 'textarea',
		fieldInfo: i18n.t('metric:form:fields_info.summary_short'),
		warningInfo:
			getCharactersNumberWithoutSpace(currentValues?.summary_short) > 300
	},
	{
		name: 'summary_long',
		label: i18n.t('events_live:fields.summary_long'),
		type: 'editor',
		requiredField: true,
		fieldInfo: i18n.t('metric:form:fields_info.summary_long'),
		disabledAddingImage: true,
		disabledAddingMedia: true,
		warningInfo:
			getCharactersNumberWithoutSpace(currentValues?.summary_long) > 1000
	},
	{
		name: 'data.sub_title',
		label: i18n.t('events_live:fields.sub_title'),
		type: 'text'
	},
	{
		name: 'data.sub_summary_short',
		label: i18n.t('events_live:fields.sub_summary_short'),
		type: 'textarea'
	},
	{
		name: 'data.sub_summary_long',
		label: i18n.t('events_live:fields.sub_summary_long'),
		type: 'editor',
		disabledAddingImage: true,
		disabledAddingMedia: true
	},
	{
		name: 'data.scene',
		label: i18n.t('events_live:fields.scene'),
		type: 'text'
	},
	{
		name: 'data.duration',
		label: i18n.t('events_live:fields.duration'),
		type: 'time',
		fieldInfo: i18n.t('vod:form:fields_info.duration')
	},
	{
		name: 'data.release_description',
		label: i18n.t('events_live:fields.release_description'),
		type: 'textarea'
	},
	{
		name: 'data.release_year',
		label: i18n.t('events_live:fields.release_year'),
		type: 'number'
	},
	{
		name: 'rating',
		label: i18n.t('events_live:fields.rating'),
		type: 'multi-select',
		mode: null,
		requiredField: true,
		fieldInfo: i18n.t('vod:form:fields_info.rating')
	},
	{
		name: 'data.country',
		label: i18n.t('events_live:fields.country'),
		type: 'text'
	},
	{
		name: 'additional_metadata.sound',
		label: i18n.t('common:form:fields.additional_metadata.sound'),
		type: 'text'
	},
	{
		name: 'additional_metadata.subtitles',
		label: i18n.t('common:form:fields.additional_metadata.subtitles'),
		type: 'text'
	},
	{
		name: 'data.actors',
		label: i18n.t('events_live:fields.actors'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'data.writers',
		label: i18n.t('events_live:fields.writers'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'data.directors',
		label: i18n.t('events_live:fields.directors'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'data.producers',
		label: i18n.t('events_live:fields.producers'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'additional_metadata.dramaturgy',
		label: i18n.t('common:form:fields.additional_metadata.dramaturgy'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'additional_metadata.libretto',
		label: i18n.t('common:form:fields.additional_metadata.libretto'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'additional_metadata.choreography',
		label: i18n.t('common:form:fields.additional_metadata.choreography'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'additional_metadata.lead',
		label: i18n.t('common:form:fields.additional_metadata.lead'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'additional_metadata.curatorial_care',
		label: i18n.t('common:form:fields.additional_metadata.curatorial_care'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'additional_metadata.authors',
		label: i18n.t('common:form:fields.additional_metadata.authors'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'additional_metadata.conducting',
		label: i18n.t('common:form:fields.additional_metadata.conducting'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'additional_metadata.composition',
		label: i18n.t('common:form:fields.additional_metadata.composition'),
		type: 'multi-select',
		mode: 'tags',
		dropdownStyle: { display: 'none' },
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'category',
		label: i18n.t('events_live:fields.category'),
		type: 'multi-select',
		mode: null,
		onChangeCallback: () => changeFieldValue('sub_category', []),
		requiredField: true
	},
	{
		name: 'sub_category',
		label: i18n.t('events_live:fields.sub_category'),
		type: 'multi-select',
		mode: 'multiple',
		disabled: !currentValues?.category,
		fieldInfo: i18n.t('common:form:fields_info.sub_category'),
		requiredField: true
	},
	{
		name: 'tags',
		label: i18n.t('vod:form:fields.tags'),
		type: 'multi-select',
		mode: 'multiple'
	},
	{
		name: 'genres',
		label: i18n.t('events_live:fields.genres'),
		type: 'multi-select',
		mode: 'multiple'
	},
	{
		name: 'payment_models',
		label: i18n.t('events_live:fields.payment_models'),
		type: 'multi-select',
		mode: 'multiple',
		disabled: true
	},
	{
		name: 'data.hide_title',
		label: i18n.t('events_live:fields.hide_title'),
		type: 'checkbox'
	},
	{
		name: 'free',
		label: i18n.t('events_live:fields.free'),
		type: 'checkbox'
	},
	{
		name: 'additional_data.show_statute',
		label: i18n.t('common:form:fields.additional_data.show_statute'),
		type: 'checkbox'
	},
	{
		name: 'data.created_at',
		label: i18n.t('events_live:fields.created_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'data.updated_at',
		label: i18n.t('events_live:fields.updated_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'data.chat_enabled',
		label: i18n.t('events_live:fields.chat_enabled'),
		type: 'checkbox',
		onChange: chatEnabledChange
	},
	{
		name: 'data.chat_id',
		label: i18n.t('events_live:fields.chat_id'),
		type: 'text',
		isVisible: isChatIdVisible
	}
];

export const AGREEMENTS_INAPPROPRIATE_CONTENT_FIELDS = [
	{
		name: 'additional_data.inappropriate_content.violence',
		label: i18n.t('common:form:fields.inappropriate_content.violence'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	},
	{
		name: 'additional_data.inappropriate_content.sex',
		label: i18n.t('common:form:fields.inappropriate_content.sex'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	},
	{
		name: 'additional_data.inappropriate_content.vulgarisms',
		label: i18n.t('common:form:fields.inappropriate_content.vulgarisms'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	},
	{
		name: 'additional_data.inappropriate_content.drugs',
		label: i18n.t('common:form:fields.inappropriate_content.drugs'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	},
	{
		name: 'additional_data.inappropriate_content.lack',
		label: i18n.t('common:form:fields.inappropriate_content.lack'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	}
];

export const PROMOTING_EUROPEAN_PRODUCTIONS_FIELDS = [
	{
		name: 'additional_data.promoted_product',
		label: i18n.t('vod:form:fields.promoted_product'),
		type: 'select',
		required: false,
		allowClear: true
	}
];
