export const convertDictionaryCountriesCodes = (array) => {
	return Object.entries(array).map((item) => {
		return {
			value: item[0],
			name: item[1]
		};
	});
};

export const convertInitialValues = ({ initialValues, geoblockGroups }) => {
	const { countries } = initialValues;

	const initalCountries = countries.map(({ code }) => code);

	/*
		TODO: the following code converts an array of countries into an array of groups

		const initalGeoblockGroups = geoblockGroups
			.filter((group) => group.id !== id)
			.filter(({ countries }) =>
				countries.every((code) => initalCountries.includes(code))
			)
			.map(({ id }) => id);
	*/

	return { initalCountries, initalGeoblockGroups: [] };
};
