// Import translation
import i18n from 'i18next';

// Import permissions
import * as permissions from 'components/routes/helpers/permissions';

const NAV_LINKS = [
	{
		category: 'products',
		path: '/panel/channels',
		icon: 'fa fa-tv',
		name: i18n.t('nav:name.channels'),
		permissions: permissions.CHANNELS.allowedRoles
	},
	{
		category: 'products',
		path: '/panel/vod',
		icon: 'fa fa-film',
		name: i18n.t('nav:name.vod'),
		permissions: permissions.VOD.allowedRoles
	},
	{
		category: 'products',
		path: '/panel/audio',
		icon: 'fa fa-music',
		name: i18n.t('nav:name.audio'),
		permissions: permissions.AUDIO.allowedRoles
	},
	{
		category: 'products',
		path: '/panel/articles',
		icon: 'fa fa-file-o',
		name: i18n.t('nav:name.articles'),
		permissions: permissions.ARTICLES.allowedRoles
	},
	{
		category: 'products',
		path: '/panel/events_live',
		icon: 'fa fa-star',
		name: i18n.t('nav:name.events_live'),
		permissions: permissions.EVENTS_LIVE.allowedRoles
	},
	{
		category: 'products',
		path: '/panel/packets',
		icon: 'fa fa-archive',
		name: i18n.t('nav:name.packets'),
		permissions: permissions.COLLECTIONS.allowedRoles
	},
	{
		category: 'products',
		path: '/panel/promotions',
		icon: 'fa fa-dollar',
		name: i18n.t('nav:name.promotions'),
		permissions: permissions.PROMOTIONS.allowedRoles
	},
	{
		category: 'products',
		path: '/panel/passes',
		icon: 'fa fa-ticket',
		name: i18n.t('nav:name.passes'),
		permissions: permissions.PASSES.allowedRoles
	},
	{
		category: 'products',
		path: '/panel/limits',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.limits'),
		permissions: permissions.LIMITS.allowedRoles
	},
	{
		category: 'users',
		path: '/panel/subscribers',
		icon: 'fa fa-user',
		name: i18n.t('nav:name.subscribers'),
		permissions: permissions.SUBSCRIBERS.allowedRoles
	},
	{
		category: 'users',
		path: '/panel/devices',
		icon: 'fa fa-tablet',
		name: i18n.t('nav:name.devices'),
		permissions: permissions.DEVICES.allowedRoles
	},
	{
		category: 'users',
		path: '/panel/provisioning',
		icon: 'fa fa-ticket',
		name: i18n.t('nav:name.provisioning'),
		permissions: permissions.PROVISIONING.allowedRoles
	},
	{
		category: 'analitics',
		path: '/panel/payments',
		icon: 'fa fa-money',
		name: i18n.t('nav:name.payments'),
		permissions: permissions.PAYMENTS.allowedRoles
	},
	// {
	// 	category: 'analitics',
	// 	path: '/panel/trackings',
	// 	icon: 'fa fa-clock-o',
	// 	name: i18n.t('nav:name.trackings'),
	// 	permissions: permissions.TRACKINGS.allowedRoles
	// },
	{
		title: '',
		path: '/panel/reports',
		icon: 'fa fa-bar-chart',
		name: i18n.t('nav:name.reports'),
		permissions: permissions.REPORTS.allowedRoles
	},
	{
		category: 'content',
		path: '/panel/categories',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.categories'),
		permissions: permissions.CATEGORIES.allowedRoles
	},
	{
		category: 'content',
		path: '/panel/tags',
		icon: 'fa fa-tags',
		name: i18n.t('nav:name.tags'),
		permissions: permissions.TAGS.allowedRoles
	},
	{
		category: 'content',
		path: '/panel/documents',
		icon: 'fa fa-file-text-o',
		name: i18n.t('nav:name.documents'),
		permissions: permissions.DOCUMENTS.allowedRoles
	},
	{
		category: 'content',
		path: '/panel/agreements',
		icon: 'fa fa-check-square-o',
		name: i18n.t('nav:name.agreements'),
		permissions: permissions.AGREEMENTS.allowedRoles
	},
	{
		category: 'content',
		path: '/panel/sections_groups',
		icon: 'fa fa-window-maximize',
		name: i18n.t('nav:name.sections_groups'),
		permissions: permissions.SECTION_GROUPS.allowedRoles
	},
	{
		category: 'content',
		path: '/panel/sections',
		icon: 'fa fa-list-alt',
		name: i18n.t('nav:name.sections'),
		permissions: permissions.SECTIONS.allowedRoles
	},
	{
		category: 'content',
		path: '/panel/banners',
		icon: 'fa fa-picture-o',
		name: i18n.t('nav:name.banners'),
		permissions: permissions.BANNERS.allowedRoles
	},
	{
		category: 'content',
		path: '/panel/main',
		icon: 'fa fa-ticket',
		name: i18n.t('nav:name.main'),
		permissions: permissions.PACKETS.allowedRoles
	},
	{
		category: 'content',
		path: '/panel/menus',
		icon: 'fa fa-bars',
		name: i18n.t('nav:name.menu'),
		permissions: permissions.MENU.allowedRoles
	},
	{
		category: 'ingester',
		path: '/panel/ingester/assets',
		icon: 'fa fa-exchange',
		name: i18n.t('nav:name.assets'),
		permissions: permissions.INGESTER_ASSETS.allowedRoles
	},
	{
		category: 'ingester',
		path: '/panel/ingester/encoding',
		icon: 'fa fa-code',
		name: i18n.t('nav:name.encoding'),
		permissions: permissions.INGESTER_ENCODING.allowedRoles
	},
	{
		category: 'ingester',
		path: '/panel/ingester/video-assets-params',
		icon: 'fa fa-gear',
		name: i18n.t('nav:name.video_assets_params'),
		permissions: permissions.VIDEO_ASSETS_PARAMS.allowedRoles
	},
	{
		category: 'administration',
		path: '/panel/institutions',
		icon: 'fa fa-building',
		name: i18n.t('nav:name.institutions'),
		permissions: permissions.INSTITUTIONS.allowedRoles
	},
	{
		category: 'administration',
		path: '/panel/affiliate_partners',
		icon: 'fa fa-handshake-o',
		name: i18n.t('nav:name.affiliate_partners'),
		permissions: permissions.AFFILIATE_PARTNERS.allowedRoles
	},
	{
		category: 'administration',
		path: '/panel/operators',
		icon: 'fa fa-user-plus',
		name: i18n.t('nav:name.operators'),
		permissions: permissions.OPERATORS.allowedRoles
	},
	{
		category: 'administration',
		path: '/panel/geoblock_groups',
		icon: 'fa fa-globe',
		name: i18n.t('nav:name.geolock'),
		permissions: permissions.GEOLOCK.allowedRoles
	},
	{
		category: 'administration',
		path: '/panel/notifications/create',
		icon: 'fa fa-bell',
		name: i18n.t('nav:name.notifications'),
		permissions: permissions.NOTIFICATIONS.allowedRoles
	},
	{
		category: 'administration',
		path: '/panel/activity',
		icon: 'fa fa-list',
		name: i18n.t('nav:name.activity'),
		permissions: permissions.ACTIVITY.allowedRoles
	},
	{
		category: 'administration',
		path: '/panel/settings/watermark',
		icon: 'fa fa-cogs',
		name: i18n.t('nav:name.settings'),
		permissions: permissions.SETTINGS.allowedRoles
	},
	{
		category: 'administration',
		path: '/panel/configuration/metadata',
		icon: 'fa fa-sliders',
		name: i18n.t('nav:name.configuration'),
		permissions: permissions.CONFIGURATION.allowedRoles
	}
];

export default NAV_LINKS;
