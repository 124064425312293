// switch translations for info under form fields. Info can change
// dynamically based on user input
export const switchInfo = (value, formSubSection) => {
	// Subsection of form - entry condition
	// Created becasue of repeaded form names and values
	if (formSubSection === 'entry_condition') {
		switch (value) {
			case 'inherited':
				return 'promotions:form:fields_info.entry_condition_inherited';
			case 'all_products':
				return 'promotions:form:fields_info.entry_condition_all_products';
			case 'products_list':
				return 'promotions:form:fields_info.entry_condition_products_list';

			default:
				return '';
		}
	}
	if (formSubSection === 'users_specification') {
		switch (value) {
			case 'all_users':
				return 'promotions:form:fields_info.entry_condition_users_specification_all_users';
			case 'packets_list':
				return 'promotions:form:fields_info.entry_condition_users_specification_packets_list';
			case 'users_list':
				return 'promotions:form:fields_info.entry_condition_users_specification_users_list';
			case 'products_list':
				return 'promotions:form:fields_info.entry_condition_users_specification_products_list';

			default:
				return 'promotions:form:fields_info.product_specification_list';
		}
	}
	switch (value) {
		// Users specification
		case 'all_users':
			return 'promotions:form:fields_info.users_specification_all';
		case 'packets_list':
			return 'promotions:form:fields_info.users_specification_packet';
		case 'users_list':
			return 'promotions:form:fields_info.users_specification_user';
		case 'all_products':
			return 'promotions:form:fields_info.product_specification_all';
		case 'products_list':
			return 'promotions:form:fields_info.product_specification_list';
		case 'auto':
			return 'promotions:form:fields_info.auto';
		case 'code':
			return 'promotions:form:fields_info.code';
		case 'percentage':
			return 'promotions:form:fields_info.percentage';
		case 'discount':
			return 'promotions:form:fields_info.discount';
		case 'static_price':
			return 'promotions:form:fields_info.static_price';

		default:
			return '';
	}
};
