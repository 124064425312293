import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Row, Col } from 'reactstrap';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import components
import {
	InputLabel,
	DatePickerLabel,
	TextareaLabel,
	CheckboxLabel
} from 'components/common/inputs';

const Fields = ({ photos, submitting, checkedPermissions }) => {
	const renderFields = (item, index) => {
		if (!item.hasOwnProperty('isVisible')) {
			item.isVisible = true;
		}

		switch (item.type) {
			case 'checkbox':
				return (
					item.isVisible && (
						<Field
							key={`${item.name}-${index}`}
							disabled={submitting || item.disabled}
							name={item.name}
							type="checkbox"
							placeholder={item.label}
							label={item.label}
							id={item.name}
							required={item.required}
							component={CheckboxLabel}
						/>
					)
				);

			case 'textarea':
				return (
					item.isVisible && (
						<Field
							key={`${item.name}-${index}`}
							disabled={item.disabled || submitting}
							name={item.name}
							type="textarea"
							placeholder={item.label}
							label={item.label}
							id={item.name}
							required={item.required}
							component={TextareaLabel}
						/>
					)
				);

			case 'date':
				return (
					item.isVisible && (
						<Field
							key={`${item.name}-${index}`}
							disabled={item.disabled || submitting}
							name={item.name}
							type="number"
							placeholder={item.label}
							label={item.label}
							id={item.name}
							showTime={item.showTime}
							required={item.required}
							component={DatePickerLabel}
						/>
					)
				);

			default:
				return (
					item.isVisible && (
						<Field
							key={`${item.name}-${index}`}
							disabled={item.disabled || submitting}
							name={item.name}
							type={item.type}
							placeholder={item.label}
							label={item.label}
							id={item.name}
							required={item.required}
							component={InputLabel}
						/>
					)
				);
		}
	};

	return (
		<React.Fragment>
			{BASE_FIELDS(checkedPermissions).map(renderFields)}

			{photos && (
				<Row>
					{photos.map(({ url, type }, index) => (
						<Col key={`${type}-${index}`} xl={2} lg={3} md={4} sm={4} xs={6}>
							<img
								className="img-fluid"
								src={url}
								alt="Preview"
								style={{ marginBottom: '1rem' }}
							/>
						</Col>
					))}
				</Row>
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	photos: PropTypes.array,
	checkedPermissions: PropTypes.object.isRequired
};

export default Fields;
