// ------------ PRODUCTS CATEGORY ------------
export const CATEGORY = {
	THEATRE: 'Teatr',
	FILM: 'Film',
	EXHIBITION: 'Wystawa',
	MUSIC: 'Muzyka',
	KIDS: 'Dzieci'
};
// ----------------------------------

// Products category options for select category field
export const select_products_category = [
	CATEGORY.THEATRE,
	CATEGORY.FILM,
	CATEGORY.EXHIBITION,
	CATEGORY.MUSIC,
	CATEGORY.KIDS
];

// Products subcategory options for multi-select sub_category field
export const select_products_sub_category = {
	[CATEGORY.THEATRE]: [
		'Przedstawienie',
		'Opera',
		'Teatr tańca',
		'Komedia',
		'Rozmowa',
		'Musical',
		'Podcast',
		'Warsztaty',
		'O teatrze',
		'Dla dzieci',
		'Live'
	],
	[CATEGORY.FILM]: [
		'Kino gatunku',
		'Dokument',
		'Rozmowa',
		'Podcast',
		'Warsztaty',
		'O filmie',
		'Dla dzieci',
		'Live'
	],
	[CATEGORY.EXHIBITION]: [
		'Malarstwo, rysunek',
		'Fotografia, grafika',
		'Sztuka przestrzenna',
		'Dizajn',
		'Architektura',
		'Nowe media',
		'Rozmowa',
		'Podcast',
		'Warsztaty',
		'O sztuce',
		'Dla dzieci',
		'Live'
	],
	[CATEGORY.MUSIC]: [
		'Koncerty',
		'Muzyka gatunkowa',
		'Teledyski',
		'Film muzyczny',
		'Rozmowa',
		'Podcast',
		'Warsztaty',
		'O muzyce',
		'Dla dzieci',
		'Live'
	],
	[CATEGORY.KIDS]: [
		'Kategoria 0-3',
		'Kategoria 4-6',
		'Kategoria 7-10',
		'Kategoria 11-13',
		'Kategoria 14-18',
		'Live'
	]
};

// Array with all subcategories without duplicates
export const allSubCategories = [
	...new Set(Object.values(select_products_sub_category).flat())
];
