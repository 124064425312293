import React from 'react';
import { useSelector } from 'react-redux';

// Import helpers
import { checkProductSubtype } from '../../helpers';

const useDescriptionSection = () => {
	// state
	const [sectionHeight, setSectionHeight] = React.useState(0);

	// redux state
	const {
		subtype,
		context,
		images: { gallery }
	} = useSelector(({ product_preview }) => product_preview.data);

	const isVideo = context?.has_video;
	const { isArticle } = checkProductSubtype(subtype);

	const isColumnsGrid = !isArticle && !!gallery?.data?.length;

	return {
		sectionHeight,
		setSectionHeight,
		isColumnsGrid,
		isArticle,
		isVideo
	};
};

export default useDescriptionSection;
