import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import utilities
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createColumns = () => [
	{
		Header: i18n.t('articles:packets_assigned:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { uuid, title }
			}
		}) => <Link to={`/panel/provisioning/edit/${uuid}/metadata`}>{title}</Link> //hardcoded provisioning - may be changed later
	},
	{
		Header: i18n.t('articles:packets_assigned:table_columns.short_description'),
		accessor: 'short_description',
		sortable: false
	},
	{
		Header: i18n.t('articles:packets_assigned:table_columns.free_vod'),
		accessor: 'free_vod',
		width: 100,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { free_vod }
			}
		}) => {
			// variable is created here to pass proper data format (bool instead of number)
			const isFreeVod = !!free_vod;
			return <ActiveIcon active={isFreeVod} />;
		}
	},
	{
		Header: i18n.t('articles:packets_assigned:table_columns.created_at'),
		accessor: 'created_at',
		width: 160
	},
	{
		Header: i18n.t('articles:packets_assigned:table_columns.updated_at'),
		accessor: 'updated_at',
		width: 160
	}
];
