import i18n from 'i18next';
import { getCharactersNumberWithoutSpace } from 'components/helpers/characters_counter';

export const defaultFieldValues = {
	active: false,
	parent_uuid: null,
	products_group_uuid: null,
	title: '',
	summary_long: '',
	summary_short: '',
	rating: '0',
	category: '',
	sub_category: [],
	genres: [],
	type: 'article',
	subtype: 'article',
	free: false,
	data: {
		authors: [],
		authors_display: '',
		sub_title: '',
		sub_summary_long: '',
		sub_summary_short: '',
		hide_title: false,
		title_org: '',
		content: ''
	},
	additional_data: {
		agreement_inconsistent_with_the_regulations: false,
		agreement_offensive: false,
		agreement_pornographic: false,
		agreement_violence: false,
		agreement_hate: false,
		agreement_moral_standards: false,
		agreement_CKF_Warsaw: false,
		show_statute: false
	}
};

export const BASE_FIELDS = ({
	isEdit,
	checkedPermissions,
	changeFieldValue,
	currentValues
}) => [
	{
		name: 'type',
		label: i18n.t('articles:fields.type'),
		type: 'select',
		requiredField: true,
		disabled: true
	},
	{
		name: 'active',
		label: i18n.t('articles:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct,
		fieldInfo: i18n.t('vod:form:fields_info.active')
	},
	{
		name: 'products_group_uuid',
		label: i18n.t('articles:fields.products_group_uuid'),
		type: 'select',
		disabled: !isEdit || !checkedPermissions.canActivate
	},
	{
		name: 'title',
		label: i18n.t('articles:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'summary_short',
		label: i18n.t('articles:fields.summary_short'),
		type: 'textarea',
		fieldInfo: i18n.t('metric:form:fields_info.summary_short'),
		warningInfo:
			getCharactersNumberWithoutSpace(currentValues?.summary_short) > 300
	},
	{
		name: 'summary_long',
		label: i18n.t('articles:fields.summary_long'),
		type: 'editor',
		requiredField: true,
		disabledAddingImage: true,
		disabledAddingMedia: true
	},
	{
		name: 'data.sub_title',
		label: i18n.t('articles:fields.sub_title'),
		type: 'text'
	},
	{
		name: 'data.sub_summary_short',
		label: i18n.t('articles:fields.sub_summary_short'),
		type: 'textarea'
	},
	{
		name: 'data.sub_summary_long',
		label: i18n.t('articles:fields.sub_summary_long'),
		type: 'editor',
		disabledAddingImage: true,
		disabledAddingMedia: true
	},
	{
		name: 'rating',
		label: i18n.t('articles:fields.rating'),
		type: 'multi-select',
		mode: null,
		requiredField: true,
		fieldInfo: i18n.t('vod:form:fields_info.rating')
	},
	{
		name: 'data.authors',
		label: i18n.t('articles:fields.authors'),
		type: 'multi-select',
		mode: 'tags',
		fieldInfo: i18n.t('common:form:fields_info.people_list')
	},
	{
		name: 'category',
		label: i18n.t('articles:fields.category'),
		type: 'multi-select',
		mode: null,
		onChangeCallback: () => changeFieldValue('sub_category', []),
		requiredField: true
	},
	{
		name: 'sub_category',
		label: i18n.t('articles:fields.sub_category'),
		type: 'multi-select',
		mode: 'multiple',
		disabled: !currentValues?.category,
		fieldInfo: i18n.t('common:form:fields_info.sub_category'),
		requiredField: true
	},
	{
		name: 'tags',
		label: i18n.t('vod:form:fields.tags'),
		type: 'multi-select',
		mode: 'multiple'
	},
	{
		name: 'genres',
		label: i18n.t('articles:fields.genres'),
		type: 'multi-select',
		mode: 'multiple'
	},
	{
		name: 'payment_models',
		label: i18n.t('articles:fields.payment_models'),
		type: 'multi-select',
		mode: 'multiple',
		disabled: true
	},
	{
		name: 'data.hide_title',
		label: i18n.t('articles:fields.hide_title'),
		type: 'checkbox'
	},
	{
		name: 'free',
		label: i18n.t('articles:fields.free'),
		type: 'checkbox'
	},
	{
		name: 'additional_data.show_statute',
		label: i18n.t('common:form:fields.additional_data.show_statute'),
		type: 'checkbox'
	},
	{
		name: 'data.created_at',
		label: i18n.t('articles:fields.created_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'data.updated_at',
		label: i18n.t('articles:fields.updated_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	}
];
