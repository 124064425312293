import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// Import redux actions
import { fetchProductGlobalLimit } from 'store/actions';

export const useProductGlobalLimit = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { limits } = useSelector(
		({ product_global_limit }) => product_global_limit
	);

	useEffect(() => {
		fetchProductGlobalLimit(id)(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { limits };
};
