import React from 'react';
import i18n from 'i18next';

// Import components
import MenuForm from 'components/views/menu/menu_form/MenuForm';

import Submenu from 'components/views/menu/submenu_table/Submenu';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { MENU_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { menuTranslationValidate } from 'components/utilities/content_translation/validation';

const menuBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <MenuForm />
	},
	{
		name: i18n.t('common:tabs.submenu'),
		path: 'submenu',
		disableOnCreate: true,
		component: <Submenu />
	}
];

export const menuTabs = ({ languages }) => [
	...menuBasicTabs,
	...generateContentTranslationsTabs(
		MENU_INPUT_FIELDS_DATA,
		languages,
		menuTranslationValidate
	)
];
