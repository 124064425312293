import { combineReducers } from 'redux';
// Import reducers
import table from './events_live_panel_reducer';
import form from './events_live_form_reducer';
import files from './events_live_files_reducer';
import materials from './events_live_materials_reducer';
import availability from './events_live_availabilty/events_live_availability_reducer';
import prices from './events_live_prices_reducer';
import packets_assigned from './events_live_packets_assigned_reducer';

export default combineReducers({
	table,
	form,
	files,
	materials,
	availability,
	prices,
	packets_assigned
});
