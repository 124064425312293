// Import translation
import i18n from 'i18next';

export const articlesTranslationValidate = ({ title, summary_long }) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('articles:validation.title');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('articles:validation.title_length');
	}
	// ------------------ Summary ------------------
	if (!summary_long) {
		errors.summary_long = i18n.t('articles:validation.summary_long');
	}
	return errors;
};
