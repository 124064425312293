import i18n from 'i18next';

export const ACTIVITY_FILTER_FIELDS = [
	{
		name: 'id',
		label: i18n.t('filters:fields.activity_id'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'email',
		label: i18n.t('filters:fields.operator_email'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'model_type',
		label: i18n.t('filters:fields.model_type'),
		type: 'custom-names',
		required: false,
		mode: '',
		showSearch: true,
		labelColumn: 3,
		inputColumn: 9,
		allowClear: true
	},
	{
		name: 'model_id',
		label: i18n.t('filters:fields.model_id'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'permission',
		label: i18n.t('filters:fields.permission'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'status_code',
		label: i18n.t('filters:fields.status_code'),
		type: 'number',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'range',
		label: i18n.t('filters:fields.range'),
		type: 'range',
		placeholder: [
			i18n.t('filters:fields.since'),
			i18n.t('filters:fields.till')
		],
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		showTime: true,
		rangeFormat: 'YYYY-MM-DD HH:mm:ss',
		style: { width: '100%' }
	}
];
