import React, { useEffect, useState } from 'react';
import { number, array, bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import {
	Informations,
	InfoBox,
	Divider,
	Author,
	Content,
	Title
} from '../styles';

// Import helpers
import services from 'services/services';
import { getAssetInfo } from '../helpers';

const Description = ({
	assets,
	index,
	assetsInGallery,
	isLightBackground,
	isFullscreen,
	galleryMetadata
}) => {
	const { t } = useTranslation();

	const [assetMetadata, setAssetMetadata] = useState(null);

	const { author, title, source } = getAssetInfo({
		metadata: assetMetadata,
		galleryMetadata
	});
	const { id } = assets[index];

	useEffect(() => {
		const fetchAssetDetails = async () => {
			const { data } = await services.get(
				`assets/${id}/details?with_metadata=1`
			);
			if (data) {
				const { metadata } = data;
				setAssetMetadata(metadata);
			}
		};

		fetchAssetDetails();
	}, [id]);

	const showAssetNumber = assetsInGallery > 1;

	return (
		<Informations
			isFullscreen={isFullscreen}
			isLightBackground={isLightBackground}
		>
			{title && <Title>{title}</Title>}
			<Content>
				<InfoBox>
					{showAssetNumber && (
						<>
							<span>
								{index + 1} / {assetsInGallery}
							</span>
						</>
					)}
				</InfoBox>

				<Author
					isLightBackground={isLightBackground}
					isFullscreen={isFullscreen}
				>
					{author && (
						<span>
							{t('product_preview:gallery.author')} {author}
						</span>
					)}
					{author && source && <Divider>/</Divider>}
					{source && <span>{source}</span>}
				</Author>
			</Content>
		</Informations>
	);
};

Description.propTypes = {
	assets: array,
	index: number,
	assetsInGallery: number,
	isLightBackground: bool,
	isFullscreen: bool,
	galleryMetadata: object
};

export default Description;
