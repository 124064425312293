// Import translation
import i18n from 'i18next';

//import helpers from
import { validateInappropiateContent } from 'components/helpers/validation_helpers';

export default ({ metadata, products_group_uuid }) => {
	const errors = { metadata: {} };

	// ------------------ Title ------------------
	if (!metadata.title) {
		errors.metadata.title = i18n.t('vod:form:validation.title');
	}

	// ------------------ Summary short ------------------
	if (!metadata.summary_short) {
		errors.metadata.summary_short = i18n.t('vod:form:validation.summary_short');
	}

	// ------------------ Rating ------------------
	// rating can be 0
	if (metadata.rating === undefined || metadata.rating === null) {
		errors.metadata.rating = i18n.t('vod:form:validation.rating');
	}

	// ------------------ category ------------------
	if (!metadata.category) {
		errors.metadata.category = i18n.t('events_live:validation.category');
	}

	// ------------------ sub_category ------------------
	if (!metadata.sub_category?.length) {
		errors.metadata.sub_category = i18n.t(
			'events_live:validation.sub_category'
		);
	}

	// ------------------ Institution ------------------
	if (!products_group_uuid) {
		errors.products_group_uuid = i18n.t('vod:form:validation.institution');
	}

	// ------------------ Inappropriate content ------------------
	const inappropriateContentError = validateInappropiateContent(
		metadata.additional_data.inappropriate_content
	);

	if (inappropriateContentError) {
		errors.metadata.additional_data = {
			...errors.metadata.additional_data,
			inappropriate_content: { lack: inappropriateContentError }
		};
	}

	return errors;
};
