// ******************** TABLE PANEL ********************
// -------------------- CHANNELS DATA TABLE --------------------
export const FETCH_CHANNELS_LOADING = 'FETCH_CHANNELS_LOADING';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_ERROR = 'FETCH_CHANNELS_ERROR';

// -------------------- SET SEARCH QUERY --------------------
export const SET_CHANNELS_SEARCH_QUERY = 'SET_CHANNELS_SEARCH_QUERY';

// -------------------- SAVE SORTING OPTIONS  --------------------
export const SAVE_CHANNELS_TABLE_SORTING = 'SAVE_CHANNELS_TABLE_SORTING';

// -------------------- DELETE CHANNEL --------------------
export const DELETE_CHANNEL_LOADING = 'DELETE_CHANNEL_LOADING';
export const DELETE_CHANNEL_SUCCESS = 'DELETE_CHANNEL_SUCCESS';
export const DELETE_CHANNEL_ERROR = 'DELETE_CHANNEL_ERROR';

// ******************** FORM ********************
// --------------------  CHANNEL CATEGORIES --------------------
export const FETCH_CHANNELS_CATEGORIES_LOADING =
	'FETCH_CHANNELS_CATEGORIES_LOADING';
export const FETCH_CHANNELS_CATEGORIES_SUCCESS =
	'FETCH_CHANNELS_CATEGORIES_SUCCESS';
export const FETCH_CHANNELS_CATEGORIES_ERROR =
	'FETCH_CHANNELS_CATEGORIES_ERROR';

// --------------------  CHANNEL --------------------
export const FETCH_CHANNEL_LOADING = 'FETCH_CHANNEL_LOADING';
export const FETCH_CHANNEL_SUCCESS = 'FETCH_CHANNEL_SUCCESS';
export const FETCH_CHANNEL_ERROR = 'FETCH_CHANNEL_ERROR';

// ******************** FILES ********************
// --------------------  LOGO --------------------
export const FETCH_CHANNEL_LOGO_FILES_LOADING =
	'FETCH_CHANNEL_LOGO_FILES_LOADING';
export const FETCH_CHANNEL_LOGO_FILES_SUCCESS =
	'FETCH_CHANNEL_LOGO_FILES_SUCCESS';
export const FETCH_CHANNEL_LOGO_FILES_ERROR = 'FETCH_CHANNEL_LOGO_FILES_ERROR';

// -------------------- LOGO_INVERTED --------------------
export const FETCH_CHANNEL_LOGO_INVERTED_FILES_LOADING =
	'FETCH_CHANNEL_LOGO_INVERTED_FILES_LOADING';
export const FETCH_CHANNEL_LOGO_INVERTED_FILES_SUCCESS =
	'FETCH_CHANNEL_LOGO_INVERTED_FILES_SUCCESS';
export const FETCH_CHANNEL_LOGO_INVERTED_FILES_ERROR =
	'FETCH_CHANNEL_LOGO_INVERTED_FILES_ERROR';

// ******************** MATERIALS ********************
// -------------------- FETCH --------------------
export const FETCH_CHANNEL_MATERIALS_LOADING =
	'FETCH_CHANNEL_MATERIALS_LOADING';
export const FETCH_CHANNEL_MATERIALS_SUCCESS =
	'FETCH_CHANNEL_MATERIALS_SUCCESS';
export const FETCH_CHANNEL_MATERIALS_ERROR = 'FETCH_CHANNEL_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const UPDATE_CHANNEL_MATERIALS = 'UPDATE_CHANNEL_MATERIALS';
export const UPDATE_CHANNEL_MATERIALS_ERROR = 'UPDATE_CHANNEL_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const DELETE_CHANNEL_MATERIALS = 'DELETE_CHANNEL_MATERIALS';
export const DELETE_CHANNEL_MATERIALS_ERROR = 'DELETE_CHANNEL_MATERIALS_ERROR';
// -------------------- ADD VIDEO TO CHANNEL --------------------
export const ADD_VIDEO_TO_CHANNEL_LOADING = 'ADD_VIDEO_TO_CHANNEL_LOADING';
export const ADD_VIDEO_TO_CHANNEL_SUCCESS = 'ADD_VIDEO_TO_CHANNEL_SUCCESS';
export const ADD_VIDEO_TO_CHANNEL_ERROR = 'ADD_VIDEO_TO_CHANNEL_ERROR';

// ******************** AVAILABILITY ********************
// --------------------  TABLE --------------------
// -------------------- FETCH TABLE --------------------
export const FETCH_CHANNEL_AVAILABILITY_TABLE_LOADING =
	'FETCH_CHANNEL_AVAILABILITY_TABLE_LOADING';
export const FETCH_CHANNEL_AVAILABILITY_TABLE_SUCCESS =
	'FETCH_CHANNEL_AVAILABILITY_TABLE_SUCCESS';
export const FETCH_CHANNEL_AVAILABILITY_TABLE_ERROR =
	'FETCH_CHANNEL_AVAILABILITY_TABLE_ERROR';

// -------------------- SAVE SORTING OPTIONS  --------------------
export const SAVE_CHANNEL_AVAILABILITY_TABLE_SORTING =
	'SAVE_CHANNEL_AVAILABILITY_TABLE_SORTING';

// -------------------- DELETE --------------------
export const DELETE_CHANNEL_AVAILABILITY_LOADING =
	'DELETE_CHANNEL_AVAILABILITY_LOADING';
export const DELETE_CHANNEL_AVAILABILITY_SUCCESS =
	'DELETE_CHANNEL_AVAILABILITY_SUCCESS';
export const DELETE_CHANNEL_AVAILABILITY_ERROR =
	'DELETE_CHANNEL_AVAILABILITY_ERROR';

// -------------------- FORM --------------------
// -------------------- FETCH FORM --------------------
export const FETCH_CHANNEL_AVAILABILITY_FORM_LOADING =
	'FETCH_CHANNEL_AVAILABILITY_FORM_LOADING';
export const FETCH_CHANNEL_AVAILABILITY_FORM_SUCCESS =
	'FETCH_CHANNEL_AVAILABILITY_FORM_SUCCESS';
export const FETCH_CHANNEL_AVAILABILITY_FORM_ERROR =
	'FETCH_CHANNEL_AVAILABILITY_FORM_ERROR';

// ******************** PRICES ********************
// -------------------- FETCH --------------------
export const FETCH_CHANNEL_PRICES_LOADING = 'FETCH_CHANNEL_PRICES_LOADING';
export const FETCH_CHANNEL_PRICES_SUCCESS = 'FETCH_CHANNEL_PRICES_SUCCESS';
export const FETCH_CHANNEL_PRICES_ERROR = 'FETCH_CHANNEL_PRICES_ERROR';

// -------------------- DELETE --------------------
export const DELETE_CHANNEL_PRICE_LOADING = 'DELETE_CHANNEL_PRICE_LOADING';
export const DELETE_CHANNEL_PRICE_SUCCESS = 'DELETE_CHANNEL_PRICE_SUCCESS';
export const DELETE_CHANNEL_PRICE_ERROR = 'DELETE_CHANNEL_PRICE_ERROR';

// ******************** SYSTEMS ********************
// -------------------- FETCH SYSTEM --------------------
export const FETCH_CHANNELS_SYSTEM_LOADING = 'FETCH_CHANNELS_SYSTEM_LOADING';
export const FETCH_CHANNELS_SYSTEM_SUCCESS = 'FETCH_CHANNELS_SYSTEM_SUCCESS';
export const FETCH_CHANNELS_SYSTEM_ERROR = 'FETCH_CHANNELS_SYSTEM_ERROR';

// ******************** PLATFORMS ********************
// -------------------- FETCH PLATFORMS --------------------
export const FETCH_CHANNELS_PLATFORMS_LOADING =
	'FETCH_CHANNELS_PLATFORMS_LOADING';
export const FETCH_CHANNELS_PLATFORMS_SUCCESS =
	'FETCH_CHANNELS_PLATFORMS_SUCCESS';
export const FETCH_CHANNELS_PLATFORMS_ERROR = 'FETCH_CHANNELS_PLATFORMS_ERROR';
