import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

// Import variables
import {
	BLACKOUT_TYPES,
	BLACKOUT_PLATFORMS
} from 'components/helpers/variables';

// render blocked icon
const renderAvailableItem = (available) =>
	available ? (
		<i
			className="fa fa-ban"
			style={{
				fontSize: '16px',
				color: '#ff4d4f'
			}}
			aria-hidden="true"
		/>
	) : null;

// -------- GENERATE COLUMNS DATA FOR EPG BLACKLISTS - HELPERS --------
export const generateBlackoutPlatforms = () =>
	BLACKOUT_PLATFORMS.map(({ name, value }) => ({
		Header: name,
		columns: generateBlackoutTypes(value)
	}));

// Generate base columns with headers
const generateBlackoutTypes = (platform) =>
	BLACKOUT_TYPES.map((type) => ({
		Header: i18n.t(`epg:table_columns.blackout_${type}`),
		accessor: `blackouts.blackout_${type}_${platform}`,
		width: 70,
		sortable: false,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({ row: { _original } }) => {
			return renderAvailableItem(_original[`blackout_${type}_${platform}`]);
		}
	}));

// BASIC TABLE COLUMNS
export const basicColumns = (channelId) => [
	{
		Header: i18n.t('epg:table_columns.name'),
		accessor: 'title',
		minWidth: 160,
		Cell: ({
			row: {
				_original: { uuid, title }
			}
		}) => (
			<Link to={`/panel/channels/${channelId}/epg/edit/${uuid}/metadata`}>
				{title}
			</Link>
		)
	},
	{
		Header: i18n.t('epg:table_columns.since'),
		accessor: 'since',
		minWidth: 160
	},
	{
		Header: i18n.t('epg:table_columns.till'),
		accessor: 'till',
		minWidth: 160
	},
	{
		Header: i18n.t('epg:table_columns.category'),
		accessor: 'category',
		sortable: false
	},
	{
		Header: i18n.t('epg:table_columns.star_rating'),
		accessor: 'star_rating',
		width: 70
	}
];

// merge basic columns and blacklist columns
export const createColumns = (channelId) => [
	...basicColumns(channelId),
	...generateBlackoutPlatforms()
];
