import { combineReducers } from 'redux';
// Import reducers
import auth from './auth/auth_reducer';
import channels from './channels/channels_reducer';
import subscribers from './subscribers/subscribers_reducer';
import operators from './operators/operators_reducer';
import settings from './settings/settings_reducer';
import documents from './documents/documents_reducer';
import agreements from './agreements/agreements_reducer';
import categories from './categories/categories_reducer';
import epg from './epg/epg_reducer';
import vod from './vod/vod_reducer';
import events_live from './events_live/events_live_reducer';
import articles from './articles/articles_reducer';
import provisioning from './provisioning/provisioning_reducer';
import packets from './packets/packets_reducer';
import main from './main/main_reducer';
import sections_groups from './sections_groups/sections_groups_reducer';
import sections from './sections/sections_reducer';
import geolocks from './geolocks/geolocks_reducer';
import banners from './banners/banners_reducer';
import limits from './limits/limits_reducer';
import devices from './devices/devices_reducer';
import previous_location from './location/location_reducer';
import ingester from './ingester/ingester_reducer';
import payments from './payments/payments_reducer';
import filters from './filters/filters_reducer';
import providers from './providers/providers_reducer';
import dictionary from './dictionary/dictionary_reducer';
import request_url from './request_url/request_url_reducer';
import csv from './csv/csv_reducer';
import software from './software/software_reducer';
import promotions from './promotions/promotions_reducer';
import content_translations from './contnent_translations/content_translations_reducer';
import institutions from './institutions/institutions_reducer';
import affiliate_partners from './affiliate_partners/affiliate_partners_reducer';
import asset_groups from './asset_groups/asset_groups_reducer';
import menu from './menu/menu_reducer';
import geoblock_groups from './geoblock_groups/geoblock_groups_reducer';
import product_visibility from './product_visibility/product_visibility_reducer';
import tags from './tags/tags_reducer';
import product_geoblock_groups from './product_geoblock_groups/product_geoblock_groups';
import activity from './activity/activity_reducer';
import product_preview from './product_preview/product_preview';
import product_upload_material from './product_upload_material/product_upload_material_reducer';
import activity_product from './activity_product/activity_product_reducer';
import configuration from './configuration/configuration_reducer';
import product_global_limit from './product_global_limit/product_global_limit';
import passes from './passes/passes_reducer';
import product_preview_files from './product_preview/product_preview_files';
import reports from './reports/reports_url_reducer';

const appReducer = combineReducers({
	previous_location,
	request_url,
	auth,
	channels,
	subscribers,
	categories,
	documents,
	agreements,
	operators,
	settings,
	epg,
	vod,
	events_live,
	articles,
	provisioning,
	packets,
	main,
	sections_groups,
	sections,
	geolocks,
	banners,
	limits,
	devices,
	// trackings,
	ingester,
	payments,
	filters,
	providers,
	dictionary,
	csv,
	software,
	promotions,
	content_translations,
	institutions,
	affiliate_partners,
	asset_groups,
	menu,
	geoblock_groups,
	product_visibility,
	tags,
	product_geoblock_groups,
	activity,
	product_preview,
	product_upload_material,
	activity_product,
	configuration,
	product_global_limit,
	passes,
	product_preview_files,
	reports
});

const rootReducer = (state, action) => {
	if (action.type === 'SIGN_OUT') {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
