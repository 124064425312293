/* =========== Asset statuses =========== */
export const SUCCEEDED = 'succeeded';
export const FAILED = 'failed';

export const checkIsAssetButtonHidden = ({
	uploaded_at,
	canActivateProduct
}) => {
	// we determine upload status of file based on uploaded_at
	const isFileUploaded = !!uploaded_at;

	// only admin can display buttons of assets
	return !isFileUploaded || !canActivateProduct;
};
