// Import helpers
import { convertDurationTime } from 'components/helpers/convert';
import { convertDataToNames } from 'components/helpers/genres_helpers/helpers';
import {
	convertInstitutionsToValuePairs,
	getInstitutionName
} from 'components/helpers/institutions_helpers/helpers';
import { DURATION_CONVERT_TYPES } from 'components/helpers/variables';

// Import defaultFieldValues
import { defaultFieldValues } from './fields/input_fields';

const { SECONDS_TO_HMS } = DURATION_CONVERT_TYPES;

export const getInitialValues = ({
	isEdit,
	initialValues,
	valuesFromSeries,
	institutionsList,
	isAudio
}) => {
	const {
		metadata,
		genres,
		subtype,
		is_audio,
		tags,
		products_group_uuid
	} = initialValues;

	const { duration, rating } = metadata;

	// array of genres names that will be passed to multi-select
	// both initial values and data for combobox
	const genresNames = convertDataToNames(genres);

	// array of tags names that will be passed to multi-select
	// both initial values and data for combobox
	const tagsNames = convertDataToNames(tags || []);

	// convert duration from sec to hms
	const durationInHms = convertDurationTime({
		duration,
		type: SECONDS_TO_HMS
	});

	// get institution name based on institution id
	const institutionsNames = convertInstitutionsToValuePairs(institutionsList);

	// If user creating season, products_group_uuid is fetch from series
	// If user editing season, products_group_uuid is fetch from season
	const productsGroupUuid =
		valuesFromSeries.products_group_uuid || products_group_uuid;

	// if we have products_group_uuid find institution on list,
	// otherwise select first institution from list
	const productGroupName = productsGroupUuid
		? getInstitutionName(valuesFromSeries.products_group_uuid, institutionsList)
		: institutionsNames[0];

	// If user creating season, category is fetch from metadata of series
	// If user editing season, category is fetch from metadata of season
	const category = !isEdit
		? metadata.category
		: valuesFromSeries.metadata.category;

	// If user creating season, sub_category is fetch from metadata of series
	// If user editing season, sub_category is fetch from metadata of season
	const subCategory = !isEdit
		? metadata.sub_category
		: valuesFromSeries.metadata.sub_category;

	// form initial values
	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		metadata: {
			...defaultFieldValues.metadata,
			...metadata,
			duration: durationInHms,
			category: category || '',
			sub_category: subCategory || '',
			rating: rating ? '' + rating : '0', //fast way to convert value to string
			additional_data: {
				...defaultFieldValues.metadata.additional_data,
				...metadata.additional_data
			},
			additional_metadata: {
				...defaultFieldValues.metadata.additional_metadata,
				...metadata.additional_metadata
			}
		},
		genres: genresNames,
		tags: tagsNames,
		products_group_uuid: productGroupName?.value,
		is_audio: is_audio || isAudio,
		subtype: subtype || defaultFieldValues.subtype
	};

	return formInitialValues;
};
