import React from 'react';
import i18n from 'i18next';

// Import components
import MenuForm from '../../menu_form/MenuForm';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { MENU_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { menuTranslationValidate } from 'components/utilities/content_translation/validation';

const submenuBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <MenuForm isSubmenu />
	}
];

export const submenuTabs = (languages) => [
	...submenuBasicTabs,
	...generateContentTranslationsTabs(
		MENU_INPUT_FIELDS_DATA,
		languages,
		menuTranslationValidate
	)
];
