import { useFormState, useForm } from 'react-final-form';

const useFields = () => {
	const {
		values: {
			data: { chat_enabled }
		}
	} = useFormState();
	const { change } = useForm();

	const isChatIdVisible = !!chat_enabled;

	const handleChatEnableChange = ({ target: { checked } }) => {
		change('data.chat_enabled', checked);
		change('data.chat_id', null);
	};

	return {
		isChatIdVisible,
		chatEnabledChange: handleChatEnableChange
	};
};

export default useFields;
