// Import translation
import i18n from 'i18next';

// Import variables
import { LINK_REGEX } from 'components/helpers/variables';
import { ELEMENT_TYPES } from './helpers';

const { LINK } = ELEMENT_TYPES;

export default ({ name, order, link, model_id, model_type, element_type }) => {
	const errors = {};

	if (!name) {
		errors.name = i18n.t('menu:validation.name');
	}

	if (order === undefined || order === null) {
		errors.order = i18n.t('menu:validation.order');
	}

	if (element_type === LINK) {
		if (!link) {
			errors.link = i18n.t('menu:validation.link');
		} else if (!LINK_REGEX.test(link)) {
			errors.link = i18n.t('menu:validation.link_error');
		}
	} else if (!model_id) {
		errors.model_id = i18n.t('menu:validation.model_id');
	}

	return errors;
};
