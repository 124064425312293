import React from 'react';
import i18n from 'i18next';

// Import components
import AssetGroupsForm from '../../asset_groups_form/AssetGroupsForm';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { ASSET_GROUPS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { assetGroupsTranslationValidate } from 'components/utilities/content_translation/validation';

export const assetGroupsTabs = (
	contentTranslationLanguages,
	cancelButtonPath
) => [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <AssetGroupsForm cancelButtonPath={cancelButtonPath} />
	},
	...generateContentTranslationsTabs(
		ASSET_GROUPS_INPUT_FIELDS_DATA({ cancelButtonPath }),
		contentTranslationLanguages,
		assetGroupsTranslationValidate
	)
];

export const getCancelButtonPath = ({ productSubtype, model_id }) => {
	const basePath = `${productSubtype}/edit/${model_id}/pictures`;

	switch (productSubtype) {
		case 'vod':
		case 'series':
		case 'season':
		case 'episode':
			return `vod/${basePath}`;
		case 'watermark':
			return 'settings/watermark';

		default:
			return basePath;
	}
};
