import _ from 'lodash';

// Import translation
import i18n from 'i18next';

export default ({ group_ids }) => {
	const errors = {};

	const hasGeoblickGroups = _.isArray(group_ids) ? group_ids.length : group_ids;

	// ------------------ group_ids ------------------
	if (!hasGeoblickGroups) {
		errors.group_ids = i18n.t('geoblock_groups:validation.group_ids');
	}

	return errors;
};
