import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// Import translation
import { withTranslation } from 'react-i18next';

// Import select table
import checkboxHOC from 'react-table/lib/hoc/selectTable';

// Import global helpers
import {
	createTableOptions,
	prepareDefaultSortedData
} from 'components/helpers/table';

// Import table helpers
import {
	toggleCurrentSelection,
	toggleAllItems
} from '../react_table_checkbox_hoc/chackbox_table_helpers/index';

// Import utilities
import CustomPagination from 'components/utilities/table/pagination/CustomPagination';

// Import component
import ControlButtons from './ControlButtons';

// Define checkbox table
const CheckboxTable = checkboxHOC(ReactTable);

class ReactMassChangesTable extends React.PureComponent {
	static propTypes = {
		fetchResourcesAction: PropTypes.func.isRequired,
		resources: PropTypes.shape({
			data: PropTypes.array,
			options: PropTypes.shape({
				pages: PropTypes.number,
				filters: PropTypes.object
			}),
			loading: PropTypes.bool
		}).isRequired,
		notificationUpdateSuccessTxt: PropTypes.string,
		notificationUpdateErrorTxt: PropTypes.string,
		itemId: PropTypes.number,
		defaultSorted: PropTypes.array,
		isReadOnly: PropTypes.bool.isRequired,
		tableType: PropTypes.string
	};

	static defaultProps = {
		selectItemColumns: [],
		defaultSorted: [],
		notificationUpdateSuccessTxt: '',
		notificationUpdateErrorTxt: '',
		itemId: null,
		tableType: ''
	};

	state = {
		selection: [],
		selectAll: false,
		defSorted: prepareDefaultSortedData(
			this.props.defaultSorted,
			this.props.resources?.options?.sorted
		)
	};

	// Fetch table data
	handleOnFetchData = async (state) => {
		const {
			fetchResourcesAction,
			itemId,
			resourcesForFetchAction,
			tableType,
			searchColumn,
			resources: {
				options: { sorted: reduxSorted }
			}
		} = this.props;

		// Create options
		const options = createTableOptions(
			state,
			reduxSorted,
			tableType,
			searchColumn
		);
		// Fetch resources
		// Dispatch an action
		fetchResourcesAction(options, itemId, resourcesForFetchAction);
		// Update local state with selectAll value set to false
		this.setState({ selectAll: false });
	};

	// Toggle selection
	toggleSelection = (key, shift, row) => {
		let { selection, selectAll } = this.state;
		// Get new selected items, and selectAll value
		const { newSelection, newSelectAll } = toggleCurrentSelection({
			row,
			selection: [...selection],
			selectAll
		});
		// Update state data
		this.setState({ selectAll: newSelectAll, selection: newSelection });
	};

	// Toggle all selection
	toggleAll = () => {
		const { selectAll, selection } = this.state;
		// Get wrapped table instance
		const wrappedInstance = this.refReactTable.getWrappedInstance();
		// Get all the records
		const currentRecords = wrappedInstance.getResolvedState().sortedData;
		// Get new selected items, and selectAll value
		const { newSelectAll, newSelection } = toggleAllItems({
			selection,
			selectAll,
			isSelectedHandler: this.isSelected,
			currentRecords
		});
		// Update state with data
		this.setState({ selectAll: newSelectAll, selection: newSelection });
	};

	// Check if current item is elected in the table
	isSelected = (uuid) =>
		this.state.selection.find((item) => item.uuid === uuid) ? true : false;

	// Clear all selection from the table
	clearAllSelection = () => this.setState({ selectAll: false, selection: [] });

	handleGetTrProp = (state, rowInfo, column) => {
		if (rowInfo?.row._original[`${this.props.keyType}`]) {
			return {
				className: '-mass_changes'
			};
		}

		return {};
	};

	handleGetTdProp = (state, rowInfo, column) => {
		const checkboxInput = column?.id === '_selector';
		const isElementDeleted = rowInfo?.row.status === 'deleted';

		const deletedElementStyle = {
			visibility: 'hidden'
		};

		if (checkboxInput && isElementDeleted) {
			return {
				style: deletedElementStyle
			};
		}

		return {};
	};

	render() {
		const { selection } = this.state;
		const {
			columns,
			t,
			resources: {
				data,
				options: { pages, filters, page, total_results: totalResults },
				loading
			}
		} = this.props;

		// Define page size
		const pageSize = data.length > 0 ? null : 4;

		const checkboxProps = {
			selectAll: this.state.selectAll,
			isSelected: this.isSelected,
			toggleSelection: this.toggleSelection,
			toggleAll: this.toggleAll,
			keyField: this.props.keyField,
			keyStyle: this.props.keyType,
			selectType: 'checkbox'
		};

		return (
			<div className="table-select">
				{!this.props.isReadOnly && (
					<ControlButtons
						selection={selection}
						redirectButtonTxt={t('mass_changes:control_buttons.redirect_btn')}
						clearButtonTxt={t('mass_changes:control_buttons.clear_btn')}
						clearAllSelection={this.clearAllSelection}
					/>
				)}

				<CheckboxTable
					PaginationComponent={(props) => (
						<CustomPagination tableProps={props} totalResults={totalResults} />
					)}
					ref={(refReactTable) => (this.refReactTable = refReactTable)}
					manual
					data={data}
					columns={[...columns]}
					pages={pages}
					page={page}
					filtered={filters}
					showPageSizeOptions={false}
					pageSize={pageSize}
					loading={loading}
					defaultSorted={this.state.defSorted}
					noDataText={t('common:no_data')}
					previousText={t('common:table_buttons.prev')}
					nextText={t('common:table_buttons.next')}
					className="-striped -select"
					onFetchData={this.handleOnFetchData}
					{...checkboxProps}
					getTrProps={this.handleGetTrProp}
					getTdProps={this.handleGetTdProp}
					showPagination={!!pages}
					showPaginationTop={!!pages}
				/>
			</div>
		);
	}
}

export default withTranslation()(ReactMassChangesTable);
