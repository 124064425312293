import React from 'react';

// Import styles
import { InfoContainer } from './styles';

// Import components
import DetailsItem from './DetailsItem';

// Import logic
import useDetails from './useDetails';

const Details = () => {
	const { filteredMetadata } = useDetails();

	return (
		<InfoContainer>
			{filteredMetadata.map((item) => (
				<DetailsItem key={item.name} {...item} />
			))}
		</InfoContainer>
	);
};

export default Details;
