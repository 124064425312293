import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import components
import CommonButton from './CommonButton';

// Import styles
import { PlayIcon } from './styles';

const PlayButton = () => {
	const { t } = useTranslation();

	const { is_audio: isAudio } = useSelector(
		({ product_preview }) => product_preview.data
	);

	const transKey = isAudio
		? 'product_preview:buttons.vod_buttons_listen'
		: 'product_preview:buttons.vod_buttons_play';

	return (
		<CommonButton>
			<PlayIcon />
			{t(transKey)}
		</CommonButton>
	);
};

export default PlayButton;
