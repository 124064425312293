import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setEventsLiveQuery,
	fetchEventsLive,
	deleteEventLive,
	createFiltersSuggestions,
	fetchInstitutionsList,
	dictionaryPaymentModels,
	dictionaryParentalControlRating
} from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './events_live_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import helpers
import {
	allSubCategories,
	select_products_category
} from 'components/helpers/products_category_sub_category_variables';

const EventsLive = ({
	error,
	setEventsLiveQuery,
	fetchEventsLive,
	deleteEventLive,
	resources,
	createFiltersSuggestions,
	fetchInstitutionsList,
	dictionaryPaymentModels,
	dictionaryParentalControlRating,
	filters
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	// Actions to fetch data for filters
	const filterActions = {
		fetchInstitutionsList,
		dictionaryParentalControlRating,
		dictionaryPaymentModels
	};

	const sub_category = allSubCategories;
	const category = select_products_category;

	const allFilters = { ...filters, sub_category, category };

	useFilterLogic({
		filterActions,
		filters: allFilters,
		createFiltersSuggestions
	});

	return (
		// Dispatch fetchEventsLive, deleteEventLive action in events_live_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="events_live"
			addLinkText={t('events_live:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchEventsLive}
			deleteItemAction={deleteEventLive}
			searchColumn="title"
			notificationDeleteSuccessTxt={t(
				'events_live:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t('events_live:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setEventsLiveQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

EventsLive.propTypes = {
	error: PropTypes.bool.isRequired,
	setEventsLiveQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchEventsLive: PropTypes.func.isRequired,
	deleteEventLive: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	fetchInstitutionsList: PropTypes.func.isRequired,
	dictionaryPaymentModels: PropTypes.func.isRequired,
	dictionaryParentalControlRating: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		error: state.events_live.table.error,
		resources: state.events_live.table,
		filters: {
			institutions: state.dictionary.institutionsList,
			rating: state.dictionary.parentalControlRating,
			payment_models: state.dictionary.paymentModels
		}
	};
};

export default connect(mapStateToProps, {
	setEventsLiveQuery,
	createFiltersSuggestions,
	fetchEventsLive,
	deleteEventLive,
	fetchInstitutionsList,
	dictionaryPaymentModels,
	dictionaryParentalControlRating
})(EventsLive);
