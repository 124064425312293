import { combineReducers } from 'redux';
// Import reducers
import table from './articles_panel_reducer';
import form from './articles_form_reducer';
import files from './articles_files_reducer';
import materials from './articles_materials_reducer';

import availability from './articles_availabilty/articles_availability_reducer';
import prices from './articles_prices_reducer';
import packets_assigned from './articles_packets_assigned_reducer';

export default combineReducers({
	table,
	form,
	files,
	materials,
	availability,
	prices,
	packets_assigned
});
