import React from 'react';
import i18n from 'i18next';

// Import components
import Status from './Status';

export const columns = [
	{
		Header: i18n.t('upload_materials:table_columns.video_type'),
		accessor: 'video_type',
		sortable: false,
		Cell: ({
			row: {
				_original: { video_type }
			}
		}) => i18n.t([`common:video_types.${video_type}`, video_type])
	},
	{
		Header: i18n.t('upload_materials:table_columns.assets_ids'),
		accessor: 'assets_ids',
		sortable: false,
		Cell: ({
			row: {
				_original: { assets_ids }
			}
		}) => assets_ids?.join(', ') ?? null
	},
	{
		Header: i18n.t('upload_materials:table_columns.status'),
		sortable: false,
		Cell: ({ row: { _original } }) => <Status encodings={_original.encodings} />
	},
	{
		Header: i18n.t('upload_materials:table_columns.created_at'),
		accessor: 'created_at',
		sortable: false
	},
	{
		Header: i18n.t('upload_materials:table_columns.updated_at'),
		accessor: 'updated_at',
		sortable: false
	}
];
