import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';

// Import helrpes
import { vodTabs } from './helpers/index';

const VodTabs = () => {
	const { languages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);

	return <Tabs tabs={vodTabs(languages)} />;
};

export default VodTabs;
