import i18n from 'i18next';

export const select_types = [
	{
		name: i18n.t('global_limit:types.views'),
		value: 'views'
	},
	{
		name: i18n.t('global_limit:types.purchases'),
		value: 'purchases'
	}
];
