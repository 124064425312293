// returns institution name based on provided id from institutions list
export const getInstitutionName = (institutionId, institutionsList) => {
	const institution = institutionsList.find(
		({ uuid }) => uuid === institutionId
	);

	return institution
		? { name: institution.name, value: institution.uuid }
		: null;
};

// convert institutions list to name, values pairs
export const convertInstitutionsToValuePairs = (institutionsList) => {
	let institutions = [];
	if (institutionsList.length > 0) {
		const mappedInstitutionsList = institutionsList.map(({ uuid, name }) => ({
			name,
			value: uuid
		}));
		return [...institutions, ...mappedInstitutionsList];
	} else {
		return institutions;
	}
};
