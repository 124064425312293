import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, CustomInput } from 'reactstrap';

const CheckboxLabelInverted = ({
	input,
	id,
	label,
	labelColumn = 11,
	inputColumn = 1,
	checkboxLabel = '',
	meta: { touched, error, submitError },
	fieldInfo = '',
	...inputProps
}) => {
	const isError = touched && (error || submitError);

	return (
		<FormGroup row className={`${touched && error && 'has-error'}`}>
			<Col sm={inputColumn}>
				<CustomInput
					className="m-t-xs m-l-md"
					{...input}
					{...inputProps}
					invalid={isError ? true : false}
					id={id}
					type="checkbox"
					label={checkboxLabel}
				/>
				{isError && (
					<div>
						<span
							data-testid={`input-error-${input.name}`}
							className="input-error d-block"
						>
							{error || submitError}
						</span>
					</div>
				)}
				{fieldInfo && !isError && (
					<span className="input-info m-b-sm">
						<i className="fa fa-info-circle m-r-sm"></i>
						{fieldInfo}
					</span>
				)}
			</Col>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label}
			</Label>
		</FormGroup>
	);
};

CheckboxLabelInverted.propTypes = {
	input: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	checkboxLabel: PropTypes.string,
	meta: PropTypes.object
};

export default CheckboxLabelInverted;
