import React from 'react';
import { func } from 'prop-types';

// Import components
import Gallery from './components/Gallery';

// Import logic
import { useProductGallery } from './hooks';

const ProductGallery = ({ onHeightChange }) => {
	const { smallGalleryIndex, setSmallGalleryIndex } = useProductGallery();

	return (
		<Gallery
			onHeightChange={onHeightChange}
			smallGalleryIndex={smallGalleryIndex}
			setSmallGalleryIndex={setSmallGalleryIndex}
		/>
	);
};

ProductGallery.propTypes = {
	onHeightChange: func
};

export default ProductGallery;
