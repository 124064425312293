import React from 'react';
import PropTypes from 'prop-types';

// Import select options
import { select_types } from './select_type_options';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import helpers
import { renderFields } from 'components/utilities/form/renderFields';

const Fields = ({ submitting }) => {
	const selectResources = {
		type: select_types
	};

	return (
		<>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
