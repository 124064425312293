import i18n from 'i18next';

// Import helpers
import { PAYMENT_TYPES, CORE_PATHS } from 'components/helpers/variables';

const { BLUEMEDIA, EXTERNAL_CFM } = PAYMENT_TYPES;
const { MAIN, ARTICLES, EXHIBITIONS, PACKET } = CORE_PATHS;

export const defaultFieldValues = {
	payment_method: BLUEMEDIA,
	period: '1',
	period_unit: 'day',
	since_first_access: false,
	views_limit: null,
	price: 0,
	base_amount: 0,
	margin_type: 'static_amount',
	margin_amount: 0
};

export const modelOptions = [
	{ name: i18n.t('prices:models.since_time_purchase'), value: false },
	{ name: i18n.t('prices:models.since_first_access'), value: true }
];

export const getPeriodUnits = (periodUnits) =>
	periodUnits.map(({ name, value }) => ({
		name: i18n.t(`prices:periods.${name.toLowerCase()}`),
		value
	}));

export const getPaymentMethods = (type) => {
	const isPacket = type === MAIN;

	return Object.values(PAYMENT_TYPES).map((value) => {
		const isExternal = value === EXTERNAL_CFM;
		const isSubscription = value.includes('subscription');
		const disabledOnProducts = isSubscription || isExternal;
		const disabledOnSubscriptions = !disabledOnProducts;

		return {
			name: i18n.t(`prices:payment_methods.${value}`),
			value,
			disabled: isPacket ? disabledOnSubscriptions : disabledOnProducts
		};
	});
};

export const checkLimits = (type) => {
	const paths = [MAIN, ARTICLES, EXHIBITIONS, PACKET];
	return !paths.includes(type);
};
