// Import translation
import i18n from 'i18next';

//import helpers from
import { validateInappropiateContent } from 'components/helpers/validation_helpers';

export default ({
	title,
	summary_long,
	rating,
	category,
	sub_category,
	products_group_uuid,
	additional_data
}) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('events_live:validation.title');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('events_live:validation.title_length');
	}

	// ------------------ summary_long ------------------
	if (!summary_long) {
		errors.summary_long = i18n.t('events_live:validation.summary_long');
	}

	// ------------------ Rating ------------------
	// rating can be 0
	if (rating === undefined || rating === null) {
		errors.rating = i18n.t('vod:form:validation.rating');
	}

	// ------------------ category ------------------
	if (!category) {
		errors.category = i18n.t('events_live:validation.category');
	}

	// ------------------ sub_category ------------------
	if (!sub_category?.length) {
		errors.sub_category = i18n.t('events_live:validation.sub_category');
	}

	// ------------------ Institution ------------------
	if (!products_group_uuid) {
		errors.products_group_uuid = i18n.t('events_live:validation.institution');
	}
	// ------------------ Inappropriate content ------------------
	const inappropriateContentError = validateInappropiateContent(
		additional_data.inappropriate_content
	);

	if (inappropriateContentError) {
		errors.additional_data = {
			...errors.additional_data,
			inappropriate_content: { lack: inappropriateContentError }
		};
	}

	return errors;
};
