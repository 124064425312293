import styled, { css } from 'styled-components';

// Import utilties
import { customMinResponsive, productTextColor, size } from '../../../styles';

// Import variables
import { DESCRIPTION_LINE_HEIGHT_LIMIT } from 'components/views/product_preview/helpers/variables';

const {
	LINE_HEIGHT_DESKTOP,
	LINE_HEIGHT_MOBILE
} = DESCRIPTION_LINE_HEIGHT_LIMIT;

export const Container = styled.article`
	z-index: 2;
	margin-right: 1.7rem;
	margin-bottom: 3.9rem;
	margin-top: ${({ marginTopMobile = 0 }) => `${marginTopMobile}rem`};

	${customMinResponsive(
		size.medium,
		css`
			width: 80rem;
			margin-right: 0;
			margin-bottom: 4.3rem;
			margin-top: ${({ marginTopDesktop = 0 }) => `${marginTopDesktop}rem`};
		`
	)}
`;

export const Description = styled.p`
	line-height: ${LINE_HEIGHT_MOBILE}px;
	max-height: ${LINE_HEIGHT_MOBILE * 5}px;
	font-size: 1.4rem;
	z-index: 2;
	overflow: hidden;
	transition: height ease 300ms;
	${productTextColor}

	${customMinResponsive(
		size.medium,
		css`
			font-size: 2.4rem;
			line-height: ${LINE_HEIGHT_DESKTOP}px;
			max-height: ${LINE_HEIGHT_DESKTOP * 5}px;
		`
	)}
`;
