import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const PromotionsProductsForm = ({
	productFormStore: { error, isLoaded, initialValues },
	history,
	fetchFormAction,
	productType
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { algorithm_config } = values;

		const resources = {
			...values,
			algorithm_config: algorithm_config || null
		};

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'promotions',
			apiSlug: `products/${productType}/algorithm/generate`,
			redirectPath: 'promotions'
		});
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchFormAction}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={{ ...initialValues }}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					decorators={decorators}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								isEdit={isEdit}
								currentValues={values}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="promotions"
								buttonText={t('common:buttons.assign')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

PromotionsProductsForm.propTypes = {
	history: PropTypes.object.isRequired,
	fetchFormAction: PropTypes.func.isRequired,
	productFormStore: PropTypes.shape({
		initialValues: PropTypes.object.isRequired,
		error: PropTypes.bool.isRequired,
		isLoaded: PropTypes.bool.isRequired
	})
};

export default withRouter(PromotionsProductsForm);
