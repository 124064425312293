import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	isLoaded: false,
	asset_group: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// **** FETCH PRODUCT PREVIEW ASSET GROUPS *****
			case types.FETCH_PRODUCT_PREVIEW_ASSET_GROUP_LOADING:
				draft.isLoaded = false;
				return;

			case types.FETCH_PRODUCT_PREVIEW_ASSET_GROUP_SUCCESS:
				draft.asset_group = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_PRODUCT_PREVIEW_ASSET_GROUP_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
