import i18n from 'i18next';

// Import helpers text
import { label_info, type_info } from './helpers';
import { algorithm_info } from 'components/helpers/algorithm';

export const defaultFieldValues = {
	active: false,
	live_epg: false,
	shuffle: false
};

export const BASE_FIELDS = (checkedPermissions) => [
	{
		name: 'name',
		label: i18n.t('sections:fields.name'),
		type: 'text',
		isEdit: true
	},
	{
		name: 'label',
		label: i18n.t('sections:fields.label'),
		type: 'text',
		requiredField: true,
		isEdit: true,
		info: label_info
	},
	{
		name: 'active',
		label: i18n.t('sections:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct
	},
	{
		name: 'algorithm_id',
		label: i18n.t('sections:fields.algorithm_id.title'),
		type: 'select',
		requiredField: true
	},
	{
		name: 'main_limit',
		label: i18n.t('sections:fields.main_limit'),
		type: 'number',
		requiredField: true
	},
	{
		name: 'type',
		label: i18n.t('sections:fields.type'),
		type: 'select',
		requiredField: true,
		isEdit: true,
		info: type_info
	},
	{
		name: 'algorithm_config',
		label: i18n.t('sections:fields.algorithm_config'),
		type: 'textarea',
		info: algorithm_info,
		modalWidth: 700
	},
	{
		name: 'live_epg',
		label: i18n.t('sections:fields.live_epg'),
		type: 'checkbox',
		fieldInfo: i18n.t('sections:fields_info.live_epg')
	},
	{
		name: 'shuffle',
		label: i18n.t('sections:fields.shuffle'),
		type: 'checkbox',
		isVisible: false
	}
];
