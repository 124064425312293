import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'firstname',
		type: 'text',
		placeholder: i18n.t('user_data:fields.firstname'),
		label: i18n.t('user_data:fields.firstname'),
		id: 'firstname',
		required: true
	},
	{
		name: 'lastname',
		type: 'text',
		placeholder: i18n.t('user_data:fields.lastname'),
		label: i18n.t('user_data:fields.lastname'),
		id: 'lastname',
		required: true
	},
	{
		name: 'country',
		type: 'text',
		placeholder: i18n.t('user_data:fields.country'),
		label: i18n.t('user_data:fields.country'),
		id: 'country',
		required: true
	},
	{
		name: 'birthdate',
		type: 'date',
		placeholder: i18n.t('user_data:fields.birthdate'),
		label: i18n.t('user_data:fields.birthdate'),
		id: 'birthdate'
	}
	// === This field is useless in this project ===
	// {
	// 	name: 'profile_name',
	// 	type: 'text',
	// 	placeholder: i18n.t('user_data:fields.profile_name'),
	// 	label: i18n.t('user_data:fields.profile_name'),
	// 	id: 'profile_name',
	// 	required: true
	// }
];
