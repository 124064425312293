// ******************** ARTICLES DATA TABLE ********************
export const FETCH_ARTICLES_LOADING = 'FETCH_ARTICLES_LOADING';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_ERROR = 'FETCH_ARTICLES_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_ARTICLES_SEARCH_QUERY = 'SET_ARTICLES_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_ARTICLES_TABLE_SORTING = 'SAVE_ARTICLES_TABLE_SORTING';

// ******************** DELETE ARTICLE ********************
export const DELETE_ARTICLE_LOADING = 'DELETE_ARTICLE_LOADING';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_ERROR = 'DELETE_ARTICLE_ERROR';

// ******************** ARTICLE ********************
export const FETCH_ARTICLE_LOADING = 'FETCH_ARTICLE_LOADING';
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_ERROR = 'FETCH_ARTICLE_ERROR';

// ******************** FILES ********************
// -------------------- COVER --------------------
export const FETCH_ARTICLE_COVER_FILES_LOADING =
	'FETCH_ARTICLE_COVER_FILES_LOADING';
export const FETCH_ARTICLE_COVER_FILES_SUCCESS =
	'FETCH_ARTICLE_COVER_FILES_SUCCESS';
export const FETCH_ARTICLE_COVER_FILES_ERROR =
	'FETCH_ARTICLE_COVER_FILES_ERROR';

// -------------------- POSTER --------------------
export const FETCH_ARTICLE_POSTER_FILES_LOADING =
	'FETCH_ARTICLE_POSTER_FILES_LOADING';
export const FETCH_ARTICLE_POSTER_FILES_SUCCESS =
	'FETCH_ARTICLE_POSTER_FILES_SUCCESS';
export const FETCH_ARTICLE_POSTER_FILES_ERROR =
	'FETCH_ARTICLE_POSTER_FILES_ERROR';

// -------------------- GALLERY --------------------
export const FETCH_ARTICLE_GALLERY_FILES_LOADING =
	'FETCH_ARTICLE_GALLERY_FILES_LOADING';
export const FETCH_ARTICLE_GALLERY_FILES_SUCCESS =
	'FETCH_ARTICLE_GALLERY_FILES_SUCCESS';
export const FETCH_ARTICLE_GALLERY_FILES_ERROR =
	'FETCH_ARTICLE_GALLERY_FILES_ERROR';

// -------------------- TITLE --------------------
export const FETCH_ARTICLE_TITLE_FILES_LOADING =
	'FETCH_ARTICLE_TITLE_FILES_LOADING';
export const FETCH_ARTICLE_TITLE_FILES_SUCCESS =
	'FETCH_ARTICLE_TITLE_FILES_SUCCESS';
export const FETCH_ARTICLE_TITLE_FILES_ERROR =
	'FETCH_ARTICLE_TITLE_FILES_ERROR';

// -------------------- ARTICLE --------------------
export const FETCH_ARTICLE_ASSET_GROUP_LOADING =
	'FETCH_ARTICLE_ASSET_GROUP_LOADING';
export const FETCH_ARTICLE_ASSET_GROUP_SUCCESS =
	'FETCH_ARTICLE_ASSET_GROUP_SUCCESS';
export const FETCH_ARTICLE_ASSET_GROUP_ERROR =
	'FETCH_ARTICLE_ASSET_GROUP_ERROR';

// ******************** MATERIALS ********************
// -------------------- FETCH --------------------
export const FETCH_ARTICLE_MATERIALS_LOADING =
	'FETCH_ARTICLE_MATERIALS_LOADING';
export const FETCH_ARTICLE_MATERIALS_SUCCESS =
	'FETCH_ARTICLE_MATERIALS_SUCCESS';
export const FETCH_ARTICLE_MATERIALS_ERROR = 'FETCH_ARTICLE_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const UPDATE_ARTICLE_MATERIALS = 'UPDATE_ARTICLE_MATERIALS';
export const UPDATE_ARTICLE_MATERIALS_ERROR = 'UPDATE_ARTICLE_MATERIALS_ERROR';
// -------------------- DELETE --------------------
export const DELETE_ARTICLE_MATERIALS = 'DELETE_ARTICLE_MATERIALS';
export const DELETE_ARTICLE_MATERIALS_ERROR = 'DELETE_ARTICLE_MATERIALS_ERROR';
// -------------------- ADD VIDEO TO ARTICLE --------------------
export const ADD_VIDEO_TO_ARTICLE_LOADING = 'ADD_VIDEO_TO_ARTICLE_LOADING';
export const ADD_VIDEO_TO_ARTICLE_SUCCESS = 'ADD_VIDEO_TO_ARTICLE_SUCCESS';
export const ADD_VIDEO_TO_ARTICLE_ERROR = 'ADD_VIDEO_TO_ARTICLE_ERROR';

// ******************** AVAILABILITY ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_ARTICLE_AVAILABILITY_TABLE_LOADING =
	'FETCH_ARTICLE_AVAILABILITY_TABLE_LOADING';
export const FETCH_ARTICLE_AVAILABILITY_TABLE_SUCCESS =
	'FETCH_ARTICLE_AVAILABILITY_TABLE_SUCCESS';
export const FETCH_ARTICLE_AVAILABILITY_TABLE_ERROR =
	'FETCH_ARTICLE_AVAILABILITY_TABLE_ERROR';

// -------------------- DELETE --------------------
export const DELETE_ARTICLE_AVAILABILITY_LOADING =
	'DELETE_ARTICLE_AVAILABILITY_LOADING';
export const DELETE_ARTICLE_AVAILABILITY_SUCCESS =
	'DELETE_ARTICLE_AVAILABILITY_SUCCESS';
export const DELETE_ARTICLE_AVAILABILITY_ERROR =
	'DELETE_ARTICLE_AVAILABILITY_ERROR';

// -------------------- FORM --------------------
// -------------------- FETCH --------------------
export const FETCH_ARTICLE_AVAILABILITY_FORM_LOADING =
	'FETCH_ARTICLE_AVAILABILITY_FORM_LOADING';
export const FETCH_ARTICLE_AVAILABILITY_FORM_SUCCESS =
	'FETCH_ARTICLE_AVAILABILITY_FORM_SUCCESS';
export const FETCH_ARTICLE_AVAILABILITY_FORM_ERROR =
	'FETCH_ARTICLE_AVAILABILITY_FORM_ERROR';

// ******************** PRICES ********************
// -------------------- FETCH --------------------
export const FETCH_ARTICLE_PRICES_LOADING = 'FETCH_ARTICLE_PRICES_LOADING';
export const FETCH_ARTICLE_PRICES_SUCCESS = 'FETCH_ARTICLE_PRICES_SUCCESS';
export const FETCH_ARTICLE_PRICES_ERROR = 'FETCH_ARTICLE_PRICES_ERROR';

// -------------------- DELETE --------------------
export const DELETE_ARTICLE_PRICE_LOADING = 'DELETE_ARTICLE_PRICE_LOADING';
export const DELETE_ARTICLE_PRICE_SUCCESS = 'DELETE_ARTICLE_PRICE_SUCCESS';
export const DELETE_ARTICLE_PRICE_ERROR = 'DELETE_ARTICLE_PRICE_ERROR';

// ******************** PACKETS ASSIGNED ********************
// -------------------- FETCH PACKETS ASSIGNED - TABLE DATA --------------------
export const FETCH_ARTICLE_PACKETS_ASSIGNED_LOADING =
	'FETCH_ARTICLE_PACKETS_ASSIGNED_LOADING';
export const FETCH_ARTICLE_PACKETS_ASSIGNED_SUCCESS =
	'FETCH_ARTICLE_PACKETS_ASSIGNED_SUCCESS';
export const FETCH_ARTICLE_PACKETS_ASSIGNED_ERROR =
	'FETCH_ARTICLE_PACKETS_ASSIGNED_ERROR';
export const FETCH_ARTICLE_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR =
	'FETCH_ARTICLE_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR';

// -------------------- SET SEARCH QUERY --------------------
export const SET_ARTICLE_PACKETS_ASSIGNED_SEARCH_QUERY =
	'SET_ARTICLE_PACKETS_ASSIGNED_SEARCH_QUERY';
