import React from 'react';

// Import components
import UploadMaterial from 'components/utilities/upload_material/UploadMaterial';

const EventsLiveUploadMaterial = () => {
	return (
		<UploadMaterial
			modelType="live_event"
			fileTypeSelectorVisible={false}
			transcodeMain={false}
			transcodePreview={true}
		/>
	);
};

export default EventsLiveUploadMaterial;
