import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	edit: {
		genres: [],
		sub_category: [],
		data: {
			authors: []
		},
		tags: []
	},
	isLoaded: false,
	error: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR ARTICLE STATE ******************
			case types.CLEAR_STATE:
				return { ...INITIAL_STATE };

			// ****************** FETCH ARTICLE ******************
			case types.FETCH_ARTICLE_LOADING:
				return { ...INITIAL_STATE };

			case types.FETCH_ARTICLE_SUCCESS:
				draft.edit = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_ARTICLE_ERROR:
				draft.isLoaded = true;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
