export const handicap_helper_type = [
	'brak',
	'audiodeskrypcja',
	'tłumacz migowy',
	'napisy dla osób niedosłyszących i niesłyszących'
];

export const rating_category_recommendation = [
	'Kategoria 0-3',
	'Kategoria 4-6',
	'Kategoria 7-10',
	'Kategoria 11-13',
	'Kategoria 14-18',
	'Kategoria 18+',
	'Dla wszystkich'
];

export const checkboxValues = ['tak', 'nie'];
