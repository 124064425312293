import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchAgreementsVersion } from 'store/actions';
import moment from 'moment';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const AgreementsVersionsForm = ({
	history,
	fetchAgreementsVersion,
	error,
	isLoaded,
	initialValues,
	match: {
		url,
		params: { agreementId }
	}
}) => {
	const { t } = useTranslation();
	const isCreate = url.includes('create');
	const redirectPath = `agreements/edit/${agreementId}/versions`;
	const api = isCreate
		? `agreements/${agreementId}/versions`
		: `agreements/versions`;

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { applicable_from } = values;

		const formattedApplicableFrom = moment(applicable_from).format(
			'YYYY-MM-DD HH:mm:ss'
		);

		const resources = {
			...values,
			agreement_id: agreementId,
			applicable_from: formattedApplicableFrom
		};
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api,
			notificationName: 'version',
			redirectPath
		});
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchAgreementsVersion}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={{ ...initialValues }}
					validate={validate}
					decorators={decorators}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

AgreementsVersionsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchAgreementsVersion: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	match: PropTypes.shape({
		url: PropTypes.string.isRequired,
		params: PropTypes.shape({
			agreementId: PropTypes.string.isRequired,
			id: PropTypes.string
		})
	})
};

const mapStateToProps = ({
	agreements: {
		versions: { form }
	}
}) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded
});

export default compose(
	connect(mapStateToProps, { fetchAgreementsVersion }),
	withRouter
)(AgreementsVersionsForm);
