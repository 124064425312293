import React from 'react';
import Slider from 'react-slick';
import { func, string, number, bool } from 'prop-types';

// Import logic
import { useGallery } from '../hooks';

// Import components
import GalleryItem from './GalleryItem';
import Description from './Description';

// Import helpers
import { sliderOptions } from '../helpers';

// Import styles
import { Container } from '../styles';

// Import variables
import { SLIDER_ARROW_TYPES } from 'components/views/product_preview/helpers/variables';
import SliderArrow from '../../SliderArrow/SliderArrow';

const { PREV, NEXT } = SLIDER_ARROW_TYPES;

const Gallery = (props) => {
	const { cursor, onItemClick, smallGalleryIndex, isFullscreen } = props;

	const {
		gallery,
		galleryRef,
		containerWidth,
		assetHeight,
		showGallery,
		currentIndex,
		setCurrentIndex,
		isArticle,
		metadata
	} = useGallery(props);

	if (!showGallery) return null;

	const { data } = gallery;

	const commonGalleriesProps = {
		assetsInGallery: data.length,
		isFullscreen,
		isLightBackground: isArticle,
		galleryMetadata: metadata
	};

	return (
		<Container
			ref={galleryRef}
			isFullscreen={isFullscreen}
			isLightBackground={isArticle}
		>
			{containerWidth && (
				<>
					<Slider
						{...sliderOptions}
						initialSlide={smallGalleryIndex}
						afterChange={setCurrentIndex}
						nextArrow={<SliderArrow type={NEXT} slideHeight={assetHeight} />}
						prevArrow={<SliderArrow type={PREV} slideHeight={assetHeight} />}
					>
						{data.map((item, index) => (
							<GalleryItem
								key={item.id}
								index={index}
								width={containerWidth}
								height={assetHeight}
								cursor={cursor}
								onItemClick={onItemClick}
								{...item}
								{...commonGalleriesProps}
							/>
						))}
					</Slider>
					<Description
						assets={data}
						index={currentIndex}
						isLightBackground={isArticle}
						{...commonGalleriesProps}
					/>
				</>
			)}
		</Container>
	);
};

Gallery.defaultProps = {
	onHeightChange: () => {},
	cursor: 'default',
	onItemClick: () => {},
	smallGalleryIndex: 0,
	setSmallGalleryIndex: () => {},
	isFullscreen: false
};

Gallery.propTypes = {
	onHeightChange: func,
	cursor: string,
	onItemClick: func,
	smallGalleryIndex: number,
	setSmallGalleryIndex: func,
	descriptionHeight: string,
	isFullscreen: bool
};

export default Gallery;
