import React from 'react';

// Import translation
import i18n from 'i18next';

// Import components
import TranscodeButton from './upload_buttons/TranscodeButton';
import UploadStatus from './UploadStatus';

export const createColumns = ({
	transcodeAsset,
	transcodeMain,
	transcodePreview,
	isAudio,
	checkedPermissions
}) => {
	const transcodeMainColumn = {
		title: i18n.t(
			`upload_materials:table_columns.${
				isAudio ? 'transcode_main_audio' : 'transcode_main'
			}`
		),
		dataIndex: 'transcode_main',
		align: 'center',
		width: 100,
		render: (text, record, index) => (
			<TranscodeButton
				record={record}
				transcodeAsset={transcodeAsset}
				videoType={'main'}
				checkedPermissions={checkedPermissions}
			/>
		)
	};

	const transcodePreviewColumn = {
		title: i18n.t('upload_materials:table_columns.transcode_preview'),
		dataIndex: 'transcode_preview',
		align: 'center',
		width: 100,
		render: (text, record, index) => (
			<TranscodeButton
				record={record}
				transcodeAsset={transcodeAsset}
				videoType={'preview'}
				checkedPermissions={checkedPermissions}
			/>
		)
	};

	const transcodeColumns = () => {
		let columns = [];

		if (transcodePreview) {
			columns.push(transcodePreviewColumn);
		}
		if (transcodeMain) {
			columns.push(transcodeMainColumn);
		}

		return columns;
	};

	let columns = [
		{
			title: i18n.t('upload_materials:table_columns.id'),
			dataIndex: 'id',
			width: 80,
			align: 'center'
		},
		{
			title: i18n.t('upload_materials:table_columns.filename'),
			dataIndex: 'filename'
		},
		{
			title: i18n.t('upload_materials:table_columns.url'),
			dataIndex: 'url'
		},
		{
			title: i18n.t('upload_materials:table_columns.created_at'),
			dataIndex: 'created_at'
		},
		{
			title: i18n.t('upload_materials:table_columns.upload_status'),
			dataIndex: 'upload_status',
			width: 100,
			render: (text, record, index) => (
				<UploadStatus uploaded_at={record.uploaded_at} />
			)
		},
		...transcodeColumns()
	];

	return columns;
};
