import i18n from 'i18next';

export const BASE_FIELDS = () => {
	return [
		{
			name: 'code_generator',
			label: i18n.t('affiliate_partners:fields.code_generator'),
			type: 'text-w-button-sync',
			buttonText: i18n.t('promotions:buttons.generate_code'),
			relatedField: 'code'
		},
		{
			name: 'code',
			label: i18n.t('affiliate_partners:fields.code'),
			required: true,
			fieldInfo: i18n.t('affiliate_partners:fields_info.code')
		}
	];
};
