import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import services from 'services/services';
import { Select, Alert, Spin } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const FetchMultiselectLabel = ({
	name,
	value,
	onChange,
	placeholder = '',
	url,
	...input
}) => {
	const { t } = useTranslation();

	// component state
	const [dataList, setDataList] = useState([]);
	const [isFetching, setIsFetching] = useState(false);
	const [isError, setIsError] = useState(false);

	// variable checks if values from proper requests are shown
	// important when there's slower internet connection
	let lastFetchId = 0;

	// fetching data to populate select combobox
	const fetchData = debounce(async (value) => {
		try {
			// function works after two letters are passed
			if (value.length >= 2) {
				lastFetchId += 1;
				const fetchId = lastFetchId;

				setDataList([]);
				setIsFetching(true);

				// Get data from server and add them to select list
				const {
					data: { data }
				} = await services.get(`${url}&columns[4][search][value]=${value}`);

				setDataList(data);
				setIsFetching(false);

				if (fetchId !== lastFetchId) return;
			} else {
				setDataList([]);
				setIsFetching(false);
			}
		} catch (error) {
			setDataList([]);
			setIsFetching(false);
			setIsError(true);
		}
	}, 800);

	const showSpinner = () =>
		isFetching ? (
			<div className="text-center">
				<Spin size="small" />
			</div>
		) : null;

	const showError = () => (
		<Alert
			showIcon
			type="error"
			description={t('common:errors.data_retrieve')}
			className="m-b-md animated fadeIn"
		/>
	);

	return (
		<React.Fragment>
			{isError && showError()}
			<Select
				{...input}
				onChange={onChange}
				mode="multiple"
				value={value || []}
				placeholder={placeholder}
				name={name}
				notFoundContent={showSpinner()}
				filterOption={false}
				onSearch={fetchData}
				style={{ width: '100%' }}
			>
				{dataList.map(({ title, uuid }) => (
					<Option key={uuid} value={uuid}>
						{title}
					</Option>
				))}
			</Select>
		</React.Fragment>
	);
};

FetchMultiselectLabel.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.array.isRequired
	]),
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired
};

export default FetchMultiselectLabel;
