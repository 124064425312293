// Import helpers
import { getLocationType } from 'components/helpers/convert';

// Import variables
import { CORE_PATHS } from 'components/helpers/variables';

const { VOD, AUDIO, ARTICLES } = CORE_PATHS;

const subtitlesLocations = [VOD, AUDIO, ARTICLES];

export const getSubtitleLocationType = (pathname) => {
	const locationType = getLocationType(pathname);
	const properLocation = subtitlesLocations.includes(locationType);

	return properLocation ? locationType : VOD;
};
