import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchPass,
	fetchInstitutionsList,
	fetchPromotionsList
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import InfoTitle from 'components/utilities/info/InfoTitle';

// Import helpers
import {
	getInstitutionName,
	convertInstitutionsToValuePairs
} from 'components/helpers/institutions_helpers/helpers';
import { getPromotionName } from './helpers';

const PassesForm = ({
	history,
	fetchPass,
	fetchInstitutionsList,
	error,
	isLoaded,
	initialValues,
	paymentsModelsList,
	institutionsList,
	fetchPromotionsList,
	promotionsList
}) => {
	const { t } = useTranslation();

	const { data, products_group_uuid, additional_data } = initialValues;

	const promotionId = data?.promotion_id;

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'products/passes',
			redirectPath: 'passes'
		});

	// get institution name based on institution id
	const institutionsNames = convertInstitutionsToValuePairs(institutionsList);
	// if we have products_group_uuid find institution on list,
	// otherwise select first institution from list
	const productGroupName = products_group_uuid
		? getInstitutionName(products_group_uuid, institutionsList)
		: institutionsNames[0];
	// if we have promotionId find promotion on list,
	// otherwise select first promotion from list
	const promotion =
		promotionId && getPromotionName(promotionId, promotionsList);

	// form initial values
	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		products_group_uuid: productGroupName?.value,
		data: {
			...defaultFieldValues.data,
			...data,
			promotion_id: promotion?.value || ''
		},
		additional_data: {
			...defaultFieldValues.additional_data,
			...additional_data
		}
	};

	return (
		<FormTemplate
			fetchFormResources={[fetchInstitutionsList, fetchPromotionsList]}
			fetchResourceToEdit={fetchPass}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<>
					<InfoTitle
						title={t('common:info.title_required_fields')}
						className="mb-5"
					/>
					<Form
						initialValues={formInitialValues}
						validate={validate}
						decorators={decorators}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Fields
									submitting={submitting}
									isEdit={isEdit}
									checkedPermissions={checkedPermissions}
									paymentsModelsList={paymentsModelsList}
									institutions={institutionsNames}
									promotions={promotionsList}
								/>
								<FormButtons
									isButtonDisabled={submitting || error}
									path="passes"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				</>
			)}
		</FormTemplate>
	);
};

PassesForm.propTypes = {
	history: PropTypes.object.isRequired,
	fetchPass: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	initialValues: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	paymentsModelsList: PropTypes.array.isRequired,
	institutionsList: PropTypes.array.isRequired,
	fetchInstitutionsList: PropTypes.func.isRequired,
	fetchPromotionsList: PropTypes.func.isRequired
};

const mapStateToProps = ({ passes: { form }, dictionary }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		paymentsModelsList: dictionary.paymentModels,
		institutionsList: dictionary.institutionsList,
		promotionsList: dictionary.promotionsList
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchPass,
		fetchInstitutionsList,
		fetchPromotionsList
	}),
	withRouter
)(PassesForm);
