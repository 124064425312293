import React from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

// Import styles
import { Description } from './styles';

// Import helpers
import { checkProductSubtype } from '../../helpers';

const SubDescription = () => {
	const {
		subtype,
		metadata: { sub_summary_long }
	} = useSelector(({ product_preview }) => product_preview.data);

	if (!sub_summary_long) {
		return null;
	}

	const { isArticle } = checkProductSubtype(subtype);

	return (
		<Description isLightBackground={isArticle}>
			{ReactHtmlParser(sub_summary_long)}
		</Description>
	);
};

export default SubDescription;
