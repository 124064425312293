// Import styles
import { colors } from './colors';
import { fonts } from '../fonts';
import { buttons } from './buttons';
import { pureOttTheme } from '../pure_ott_theme/pureOttTheme';

export const pureCkfTheme = {
	...pureOttTheme,
	...colors,
	...fonts,
	...buttons,

	button: {
		primaryVod: {
			color: colors.black,
			bgColor: 'rgba(255, 255, 255, 0.7)'
		},
		parental: {
			bgColor1: '#3182b5',
			bgColor2: '#2f599c'
		}
	},
	globalButtons: {
		primary: {
			color: colors.black,
			bgColor: colors.white,
			borderColor: colors.white,
			hoverBorderColor: colors.primaryBlueLight,
			focusBgColor: colors.primaryBlueLight
		},
		secondary: {
			color: colors.black,
			bgColor: colors.grayButton,
			borderColor: colors.grayButton,
			hoverBorderColor: colors.primaryBlueLight,
			focusBgColor: colors.primaryBlueLight
		}
	},
	body: {
		bg: {
			primary: colors.primaryBgBlack,
			secondary: colors.secondaryBgDarkBrown,
			third: '#edeced'
		}
	},
	general: {
		linkHover: colors.white
	},
	line: 'rgba(255, 255, 255, 0.2)',
	userProfileDropdown: {
		textColor: colors.grayLight,
		borderColor: colors.grayLight,
		selectBgColor: colors.transparent,
		listBg: '#23201d',
		selectedItemBg: 'rgba(255,255,255,0.2)'
	},
	searchBox: {
		bg: colors.blackBg,
		itemHoverBg: 'rgba(18, 16, 11, 0.8)',
		itemInfo: colors.graySemiLight,
		itemInfoHover: colors.white,
		itemTitleHover: colors.primaryBlueLight
	},
	heading: {
		first: {
			white: colors.white,
			primary: colors.white
		}
	},
	toggler: {
		bg: colors.graySemiLight,
		bgActive: colors.primaryBlueLight,
		control: colors.white
	},

	dashedLine: colors.grayMediumDarkTheme,
	loader: {
		loaderBg: '#000000b3',
		colorStart: colors.primaryBlueLight,
		colorMiddle: colors.white,
		colorEnd: colors.primaryBlueLight
	},

	activeGradientLine: {
		colorStart: colors.primaryBlueLight,
		colorEnd: colors.primaryBlueLight
	},
	popover: {
		bg: colors.blackBg,
		border: colors.graySemiLight,
		icon: colors.graySemiLight,
		iconActive: colors.primaryBlueLight
	},

	channels: {
		gridItemLinkHover: colors.white
	},
	epg: {
		grayLight: '#fbfafc',
		bg: '#151d29',
		bgHoverColorStart: colors.primaryBlueLight,
		bgHoverColorEnd: colors.primaryBlueLight,
		gridItemShadow: '#080C12',
		gridItemCategory: '#95A1AF',
		catchup: {
			bg: 'linear-gradient(to right, #2b323e 0, #2b323e)',
			bgHover: 'linear-gradient(to right, #f8a030 0, #e36845)'
		},
		calendarHover: colors.white
	},
	programDetails: {
		heading: colors.white,
		paragraph: '#e6e3df',
		name: '#808181',
		table: {
			row: {
				bg: '#161e29',
				hoverBg: '#224BA1'
			}
		}
	},
	allMovies: {
		breadcrumbs: {
			color: 'rgba(255, 255, 255, 0.7)'
		},
		menuButton: {
			color: '#636363'
		}
	},
	slider: {
		arrow: {
			color: '#95A1AF',
			sliderControllerBgColor: 'transparent',
			sliderControllerBgHoverColor: 'rgba(33, 64, 154, 0.8)'
		},
		available: {
			bgColorStart: '#46abe6',
			bgColorEnd: '#3562a5'
		}
	},

	landingPage: {
		registrationButton: {
			color: colors.white,
			bgContainerColor: '#31549b',
			borderColor: '#313131',
			bgDefault: {
				color1: '#31549b',
				color2: colors.blueAzureDark
			},
			bgHover: {
				color1: colors.blueAzureDark,
				color2: '#31549b'
			}
		},
		devices: {
			itemColor: colors.blueAzure
		}
	},
	product: {
		buttons: {
			primary: colors.white,
			secondary: '#B4B4B4'
		},
		remindersMenu: {
			background: '#12100b',
			borderColor: 'rgba(255, 255, 255, 0.15)'
		},
		lightBackground: {
			color: '#12100B',
			background: '#EDECED',
			link: '#12100B',
			linkHover: colors.primaryBlueLight
		},
		coverBackground: {
			color: colors.white,
			background: colors.black,
			link: colors.white,
			linkHover: colors.primaryBlueLight
		},
		articleBlackout: {
			background: '#d8d6d7'
		}
	},
	cookies: {
		background: '#12100b',
		borderColor: 'rgba(237, 236, 237, 0.15)',
		color: colors.white
	},
	nestedNav: {
		menuText: colors.graySemiLight,
		menuActiveText: colors.primaryBlueLight
	}
};
