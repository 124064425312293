// Import translation
import i18n from 'i18next';

export const packetsTranslationValidate = ({
	title,
	description,
	short_description
}) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('packets:validation.title');
	}

	// ------------------ Description ------------------
	if (!description) {
		errors.description = i18n.t('packets:validation.description');
	}

	// ------------------ Short Description ------------------
	if (!short_description) {
		errors.short_description = i18n.t('packets:validation.short_description');
	}

	return errors;
};
