import styled, { css } from 'styled-components';

// Import utilties
import { customMinResponsive, size } from '../../../styles';

export const Section = styled.div`
	margin-top: 2.1rem;
	margin-bottom: 3rem;
	display: grid;
	grid-template-columns: 100%;
	row-gap: 2.4rem;
	margin-right: 1.7rem;

	${customMinResponsive(
		size.medium,
		css`
			margin-right: ${({ isArticle }) => (isArticle ? '0' : '7.8rem')};
			margin-top: 4.6rem;
			row-gap: 0;
		`
	)}

	${({ isColumnsGrid }) =>
		isColumnsGrid &&
		customMinResponsive(
			size.medium,
			css`
				display: grid;
				grid-template-columns: 47% 53%;
				column-gap: 4rem;
			`
		)}
`;
