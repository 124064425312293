import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size, transitionMain } from '../../styles';

export const Root = styled.div`
	display: flex;
	flex: 0 0 auto;
	flex-wrap: wrap;
	margin-bottom: 1.6rem;
	min-height: ${({ minHeight }) => minHeight}rem;
	position: relative;

	${transitionMain}

	> * {
		margin-right: 1.4rem;
	}

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: ${({ isSeries }) => (isSeries ? 2.1 : 6.3)}rem;
		`
	)}
`;
