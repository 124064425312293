import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

// Import translation
import { withTranslation } from 'react-i18next';

// Import global helpers
import {
	createTableOptions,
	prepareDefaultSortedData
} from 'components/helpers/table';

// Import table helpers
import { toggleCurrentSelection } from './chackbox_table_helpers/index';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications/index';
import CustomPagination from 'components/utilities/table/pagination/CustomPagination';

class ReactServerSideTableCheckbox extends React.PureComponent {
	static propTypes = {
		fetchResourcesAction: PropTypes.func.isRequired,
		resources: PropTypes.shape({
			data: PropTypes.array,
			updateCheckedItems: PropTypes.object,
			options: PropTypes.shape({
				pages: PropTypes.number,
				filters: PropTypes.object,
				sorted: PropTypes.object,
				page: PropTypes.number,
				total_results: PropTypes.number
			}),
			loading: PropTypes.bool
		}).isRequired,
		notificationUpdateSuccessTxt: PropTypes.string,
		notificationUpdateErrorTxt: PropTypes.string,
		itemId: PropTypes.number,
		defaultSorted: PropTypes.array,
		isReadOnly: PropTypes.bool.isRequired,
		tableType: PropTypes.string,
		t: PropTypes.func.isRequired,
		columns: PropTypes.array.isRequired,
		resourcesForFetchAction: PropTypes.object,
		updateCheckedItems: PropTypes.shape({
			success: PropTypes.bool,
			error: PropTypes.bool
		}),
		keyType: PropTypes.string,
		clearButtonTxt: PropTypes.string,
		keyField: PropTypes.string
	};

	static defaultProps = {
		selectItemColumns: [],
		defaultSorted: [],
		resources: {
			updateCheckedItems: {
				success: false,
				error: false
			}
		},
		notificationUpdateSuccessTxt: '',
		notificationUpdateErrorTxt: '',
		itemId: null,
		tableType: ''
	};

	state = {
		selection: [],
		selectAll: false,
		type: undefined,
		platform: undefined,
		errors: {},
		defSorted: prepareDefaultSortedData(
			this.props.defaultSorted,
			this.props.resources?.options?.sorted
		)
	};

	componentDidUpdate(prevProps) {
		const { updateCheckedItems } = this.props.resources;

		const {
			resources: { updateCheckedItems: prevPropsUpdateCheckedItems }
		} = prevProps;

		// Compare prevProps success to current prop success
		if (prevPropsUpdateCheckedItems.success !== updateCheckedItems.success) {
			this.fetchTableDataHandler();
		}
		// Compare prevProps error to current prop error
		if (prevPropsUpdateCheckedItems.error !== updateCheckedItems.error) {
			this.updateCheckedItemsHandler();
		}
	}

	fetchTableDataHandler = () => {
		// Fetch table resources
		this.refReactTable.wrappedInstance.fireFetchData();

		// Add success notification
		notificationHandler(
			'Updated',
			this.props.notificationUpdateSuccessTxt,
			'success'
		);

		// Clear selection
		this.clearAllSelection();
	};

	// Fetch table data
	handleOnFetchData = async (state) => {
		const {
			fetchResourcesAction,
			itemId,
			resourcesForFetchAction,
			tableType,
			resources: {
				options: { sorted: reduxSorted }
			}
		} = this.props;

		// Create options
		const options = createTableOptions(state, reduxSorted, tableType);

		// Fetch resources
		fetchResourcesAction(options, itemId, resourcesForFetchAction);

		// Update local state with selectAll value set to false
		this.setState({ selectAll: false });
	};

	// Toggle selection
	toggleSelection = (key, shift, row) => {
		let { selection, selectAll } = this.state;
		// Get new selected items, and selectAll value
		const { newSelection, newSelectAll } = toggleCurrentSelection({
			row,
			selection: [...selection],
			selectAll
		});
		// Update state data
		this.setState({ selectAll: newSelectAll, selection: newSelection });
	};

	handleGetTrProp = (state, rowInfo) => {
		if (
			rowInfo !== undefined &&
			rowInfo.row._original[`${this.props.keyType}`]
		) {
			return {
				className: '-blacklist'
			};
		} else {
			return {};
		}
	};
	render() {
		const {
			columns,
			t,
			resources: {
				data,
				options: { pages, filters, page, total_results: totalResults },
				loading
			}
		} = this.props;

		// Define page size
		const pageSize = data.length > 0 ? null : 4;

		const checkboxProps = {
			selectAll: this.state.selectAll,
			isSelected: this.isSelected,
			toggleSelection: this.toggleSelection,
			toggleAll: this.toggleAll,
			keyField: this.props.keyField,
			keyStyle: this.props.keyType,
			selectType: 'checkbox'
		};

		return (
			<div className="table-select">
				<ReactTable
					PaginationComponent={(props) => (
						<CustomPagination tableProps={props} totalResults={totalResults} />
					)}
					ref={(refReactTable) => (this.refReactTable = refReactTable)}
					manual
					data={data}
					columns={[...columns]}
					pages={pages}
					page={page}
					filtered={filters}
					showPageSizeOptions={false}
					pageSize={pageSize}
					loading={loading}
					defaultSorted={this.state.defSorted}
					noDataText={t('common:no_data')}
					previousText={t('common:table_buttons.prev')}
					nextText={t('common:table_buttons.next')}
					className="-striped -select"
					onFetchData={this.handleOnFetchData}
					{...checkboxProps}
					getTrProps={this.handleGetTrProp}
					showPagination={!!pages}
					showPaginationTop={!!pages}
				/>
			</div>
		);
	}
}

export default withTranslation()(ReactServerSideTableCheckbox);
