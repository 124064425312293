import React from 'react';
import i18n from 'i18next';

// Import tabs component
import Configuration from '../../Configuration';

export const configurationTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <Configuration />
	}
];
