// Import translation
import i18n from 'i18next';

export const assetGroupsTranslationValidate = ({ metadata }) => {
	const errors = { metadata: {} };

	if (metadata) {
		const { title, description } = metadata;

		// ------------------ title ------------------
		if (!title) {
			errors.metadata.title = i18n.t('asset_groups:validation.title');
		}

		// ------------------ description ------------------
		if (!description) {
			errors.metadata.description = i18n.t(
				'asset_groups:validation.description'
			);
		}
	}

	return errors;
};
