// Import translation
import i18n from 'i18next';

export default ({ file, language }) => {
	const errors = {};

	// ------------------ Language ------------------
	if (!language) {
		errors.language = i18n.t(
			'common:material_subtitles.validation.language_required'
		);
	}

	// ------------------ File ------------------
	if (!file) {
		errors.file = i18n.t('common:material_subtitles.validation.file_required');
	}

	return errors;
};
