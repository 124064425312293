import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Info } from 'components/utilities';

// Import components
import {
	InputLabel,
	CheckboxLabel,
	TextareaLabel,
	SelectLabel
} from 'components/common/inputs';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = (props) => {
	const { submitting, isEdit, checkedPermissions } = props;
	const renderFields = (item, index) => {
		// some fields have isVisible flag. If it's undefined, create and set flag to true
		if (!item.hasOwnProperty('isVisible')) {
			item.isVisible = true;
		}

		switch (item.type) {
			case 'textarea':
				return (
					item.isVisible && (
						<React.Fragment key={`${item.name}-${index}`}>
							<Info content={item.info} modalWidth={item.modalWidth}>
								<Field
									disabled={submitting}
									name={item.name}
									type="textarea"
									placeholder={item.label}
									label={item.label}
									id={item.name}
									required={item.required}
									component={TextareaLabel}
								/>
							</Info>
							<div className="hr-line-dashed">&nbsp;</div>
						</React.Fragment>
					)
				);

			case 'number':
				return (
					item.isVisible && (
						<React.Fragment key={`${item.name}-${index}`}>
							<Field
								disabled={submitting}
								name={item.name}
								type="number"
								placeholder={item.label}
								label={item.label}
								id={item.name}
								// We have only zero or positive value by default
								min={item.min ?? 0}
								required={item.required}
								component={InputLabel}
							/>
							<div className="hr-line-dashed">&nbsp;</div>
						</React.Fragment>
					)
				);

			case 'checkbox':
				return (
					item.isVisible && (
						<React.Fragment key={`${item.name}-${index}`}>
							<Field
								disabled={submitting || item.disabled}
								name={item.name}
								type="checkbox"
								placeholder={item.label}
								label={item.label}
								fieldInfo={item.fieldInfo}
								id={item.name}
								component={CheckboxLabel}
							/>
							<div className="hr-line-dashed">&nbsp;</div>
						</React.Fragment>
					)
				);

			case 'select':
				return (
					item.isVisible && (
						<React.Fragment key={`${item.name}-${index}`}>
							<Info content={item.info}>
								<Field
									disabled={submitting}
									name={item.name}
									type="select"
									placeholder={item.label}
									label={item.label}
									id={item.name}
									required={item.required}
									component={SelectLabel}
								>
									<option value="" disabled>
										{item.label}
									</option>
									{props[item.name].map((item) => (
										<option key={item.name} value={item.id}>
											{item.name}
										</option>
									))}
								</Field>
							</Info>
							<div className="hr-line-dashed">&nbsp;</div>
						</React.Fragment>
					)
				);

			default:
				return (
					item.isVisible && (
						<React.Fragment key={`${item.name}-${index}`}>
							{(isEdit || item.isEdit) && (
								<React.Fragment>
									<Info content={item.info}>
										<Field
											disabled={submitting}
											name={item.name}
											type={item.type}
											placeholder={item.label}
											label={item.label}
											id={item.name}
											required={item.required}
											component={InputLabel}
										/>
									</Info>
									<div className="hr-line-dashed">&nbsp;</div>
								</React.Fragment>
							)}
						</React.Fragment>
					)
				);
		}
	};

	return (
		<React.Fragment>
			{BASE_FIELDS(checkedPermissions).map(renderFields)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	checkedPermissions: PropTypes.object.isRequired
};

export default Fields;
