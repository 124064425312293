import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	columns: [],
	deleteItem: {
		success: false,
		error: false
	},
	options: {
		pages: 0,
		page: 0,
		total_results: 0,
		startIndex: 0,
		sorted: undefined,
		filters: {
			query: '',
			subtype: 'all',
			status: 'all',
			filterUrlQuery: ''
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ****************** CLEAR DATA ******************
			case types.CLEAR_PANEL_STATE:
				return { ...INITIAL_STATE };

			// *************** FETCH VOD ***************
			case types.FETCH_VOD_LOADING:
				draft.loading = true;
				draft.error = false;
				break;

			case types.FETCH_VOD_SUCCESS:
				draft.loading = false;
				draft.data = action.payload.data;
				draft.columns = action.payload.input.columns;
				draft.options.pages = action.payload.pages;
				draft.options.total_results = action.payload.recordsFiltered;
				draft.options.startIndex = action.payload.startIndex;
				draft.options.page = action.payload.page;
				break;

			case types.FETCH_VOD_ERROR:
				draft.error = action.payload;
				break;

			// *************** DELETE VOD SERIES - TABLE DATA ***************
			case types.DELETE_VOD_SERIES_LOADING:
				draft.deleteItem.success = false;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_VOD_SERIES_SUCCESS:
				draft.deleteItem.success = action.payload;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_VOD_SERIES_ERROR:
				draft.deleteItem.error = action.payload;
				return;

			// *************** DELETE VOD SEASON - TABLE DATA ***************
			case types.DELETE_VOD_SEASON_LOADING:
				draft.deleteItem.success = false;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_VOD_SEASON_SUCCESS:
				draft.deleteItem.success = action.payload;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_VOD_SEASON_ERROR:
				draft.deleteItem.error = action.payload;
				return;

			// *************** DELETE VOD - TABLE DATA ***************
			case types.DELETE_VOD_LOADING:
				draft.deleteItem.success = false;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_VOD_SUCCESS:
				draft.deleteItem.success = action.payload;
				draft.deleteItem.error = false;
				return;

			case types.DELETE_VOD_ERROR:
				draft.deleteItem.error = action.payload;
				return;

			// *************** SET VOD SUBTYPE ***************
			case types.SET_VOD_SUBTYPE:
				draft.options.filters.subtype = action.payload;
				break;

			// *************** SET VOD STATUS ***************
			case types.SET_VOD_STATUS:
				draft.options.filters.status = action.payload;
				break;

			// *************** SAVE SORTING OPTIONS ***************
			case types.SAVE_VOD_TABLE_SORTING:
				draft.options.sorted = action.payload;
				break;

			// *************** SET SEARCH QUERY ***************
			case types.SET_VOD_SEARCH_QUERY:
				draft.options.filters.query = action.payload;
				break;

			// --------------- FILTERS ---------------
			// *************** SET FILTER URL QUERY ***************
			case types.SET_FILTER_URL_QUERY:
				draft.options.filters.filterUrlQuery = action.payload;
				break;

			// *************** CLEAR FILTERS ***************
			case types.CLEAR_FILTERS:
				draft.options.filters.filterUrlQuery = '';
				break;

			default:
				return state;
		}
	});
