import i18n from 'i18next';

export const EVENTS_LIVE_INPUT_FIELDS_DATA = {
	submitUrl: 'products/liveevents',
	fetchUrl: 'products/liveevents',
	cancelButtonPath: 'events_live',
	hasMetadata: false,
	defautlFieldValues: {
		title: '',
		summary_long: '',
		summary_short: '',
		data: {
			sub_title: '',
			sub_summary_long: '',
			sub_summary_short: '',
			scene: '',
			release_description: '',
			country: ''
		}
	},
	inputFields: [
		{
			name: 'title',
			label: i18n.t('events_live:fields.title'),
			type: 'text',
			required: true
		},
		{
			name: 'summary_short',
			label: i18n.t('events_live:fields.summary_short'),
			type: 'textarea',
			required: false
		},
		{
			name: 'summary_long',
			label: i18n.t('events_live:fields.summary_long'),
			type: 'editor',
			required: true,
			disabledAddingImage: true,
			disabledAddingMedia: true
		},
		{
			name: 'data.sub_title',
			label: i18n.t('events_live:fields.sub_title'),
			type: 'text',
			required: false
		},
		{
			name: 'data.sub_summary_short',
			label: i18n.t('events_live:fields.sub_summary_short'),
			type: 'textarea',
			required: false
		},
		{
			name: 'data.sub_summary_long',
			label: i18n.t('events_live:fields.sub_summary_long'),
			type: 'editor',
			required: false,
			disabledAddingImage: true,
			disabledAddingMedia: true
		},
		{
			name: 'data.scene',
			label: i18n.t('events_live:fields.scene'),
			type: 'text',
			required: false
		},
		{
			name: 'data.release_description',
			label: i18n.t('events_live:fields.release_description'),
			type: 'textarea',
			required: false
		},
		{
			name: 'data.country',
			label: i18n.t('events_live:fields.country'),
			type: 'text',
			required: false
		},
		{
			name: 'additional_metadata.sound',
			label: i18n.t('common:form:fields.additional_metadata.sound'),
			type: 'text',
			required: false
		},
		{
			name: 'additional_metadata.subtitles',
			label: i18n.t('common:form:fields.additional_metadata.subtitles'),
			type: 'text',
			required: false
		}
	]
};
