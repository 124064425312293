import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import helpers
import {
	replaceStrDots,
	replaceStr,
	isOboxSystem
} from '../form_helpers/index';

// Import components
import { InputLabel } from 'components/common/inputs';
import { IboxContentTitle } from 'components/common/layout';

// Import variables
import {
	SETTINGS_TO_BLOCK_IN_OBOX,
	SETTINGS_FIELDS_TO_BLOCK_IN_OBOX
} from 'components/helpers/variables';

const Fields = ({ list, isLoaded, submitting }) => {
	// Convert feilds to categories
	const convertToCategories = Object.values(list).reduce(
		(prev, next, index) => {
			// Create category name
			const category = Object.keys(list)[index].split('|')[0];
			// disable some fields for OBOX system
			if (SETTINGS_TO_BLOCK_IN_OBOX.includes(category) && isOboxSystem) {
				return prev;
			}
			// If category does not exist , create one
			else if (!prev[category]) {
				prev[category] = [replaceStrDots(Object.keys(list)[index])];
			} else {
				prev[category].push(replaceStrDots(Object.keys(list)[index]));
			}
			return prev;
		},
		{}
	);

	const renderFields = (field) => {
		const convertedField = replaceStr(field);
		const isInvalidOboxField =
			SETTINGS_FIELDS_TO_BLOCK_IN_OBOX.includes(convertedField) && isOboxSystem;

		return isInvalidOboxField ? null : (
			<Field
				key={convertedField}
				name={`${field}`}
				type="text"
				placeholder={convertedField}
				label={convertedField}
				id={convertedField}
				required={true}
				labelColumn={4}
				inputColumn={6}
				component={InputLabel}
				disabled={submitting}
				parse={(value) => value}
			/>
		);
	};

	return (
		<div className="h-300">
			{Object.entries(convertToCategories).map(([title, fields]) => (
				<IboxContentTitle
					key={title}
					title={replaceStr(title, '')}
					isLoaded={isLoaded}
				>
					<React.Fragment>{fields.map(renderFields)}</React.Fragment>
				</IboxContentTitle>
			))}
		</div>
	);
};

Fields.propTypes = {
	list: PropTypes.object.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired
};

export default Fields;
