// Import translation
import i18n from 'i18next';

export const checkFetchErrors = (error) => {
	if (error.response) {
		return error.response.status === 401 ? false : true;
	}
};

// Capitalize first letter
export const capitalizeText = (text) =>
	text.charAt(0).toUpperCase() + text.slice(1).replace('_', ' ');

// Create query parameters
export const queryParams = (options, tableLength, columns, startIndex) => {
	const {
		sorted: { type },
		searchColumn,
		filtered: { query, filterUrlQuery },
		accessor
	} = options;

	// Check if we want length query in url
	const length = tableLength ? `&length=${tableLength}` : '';

	// Find column's index in columns array
	const index = columns.findIndex((column) => column.name === accessor);
	// Check if column index exists
	const columnIndex = index > 0 ? index : 0;

	// Find column's index in columns array for search in column
	const index_search_column = columns.findIndex(
		(column) => column.name === searchColumn
	);

	// Avoid overwriting of searched value on filters, if filters used search column
	const isSearchColumnUsedOnFilters = filterUrlQuery?.includes(
		`columns[${index_search_column}]`
	);

	const isSearchColumn = searchColumn && !isSearchColumnUsedOnFilters;

	const searchBy = isSearchColumn
		? `columns[${index_search_column}][search][value]=`
		: 'search[value]=';

	return `order[0][column]=${columnIndex}&order[0][dir]=${type}&start=${startIndex}${length}&${searchBy}${query}`;
};

// Create query parameters for promotions
// Promotions need sorting by two columns (but function can accept more)
export const promotionsQueryParams = (
	options,
	tableLength,
	columns,
	startIndex
) => {
	const {
		sorted: { type },
		filtered: { query, search_column },
		accessor,
		defaultSorted,
		currentlySorted
	} = options;

	// Check if we want length query in url
	const length = tableLength ? `&length=${tableLength}` : '';

	// Find column's index in columns array
	const index = columns.findIndex((column) => column.name === accessor);
	// Check if column index exists
	const columnIndex = index > 0 ? index : 0;

	const isPromotionValue = accessor === 'promotion_value';
	const promotionTypeIndex = columns.findIndex(
		({ name }) => name === 'promotion_type'
	);

	// ==== Query for user sort ====
	// We need additional part of query when sort by promotion value
	let columnsUserOrderQuery = isPromotionValue
		? `order[0][column]=${promotionTypeIndex}&order[0][dir]=asc&order[1][column]=${columnIndex}&order[1][dir]=${type}&`
		: `order[0][column]=${columnIndex}&order[0][dir]=${type}&`;

	// Find column's index in columns array for search in column
	const index_search_column = columns.findIndex(
		(column) => column.name === search_column
	);

	const searchBy = search_column
		? `columns[${index_search_column}][search][value]=`
		: 'search[value]=';

	// ======== COLUMN SORT QUERY ========
	// has user sorted table differently
	const isDefaultSort =
		JSON.stringify(defaultSorted) === JSON.stringify(currentlySorted);

	// helper function that searches for column index.
	// returns zero if column not present
	const searchColumnIndex = (defaultColumnName) => {
		const index = columns.findIndex(
			(column) => column.name === defaultColumnName
		);
		return index === -1 ? 0 : index;
	};

	// query for default sort
	const columnsDefaultOrderQuery = defaultSorted.reduce(
		(query, sortingColumn, index) => {
			return (
				query +
				`order[${index}][column]=${searchColumnIndex(
					sortingColumn.id
				)}&order[${index}][dir]=${sortingColumn.desc ? 'desc' : 'asc'}&`
			);
		},
		''
	);

	// final query based on user action
	// if currently sorted column is different from default sort then change url query
	const columnsOrderQuery = isDefaultSort
		? columnsDefaultOrderQuery
		: columnsUserOrderQuery;
	// ======== COLUMN SORT QUERY ========

	return `${columnsOrderQuery}start=${startIndex}${length}&${searchBy}${query}`;
};

// Get column id by its name
export const getColumnId = (columns, columnName) => {
	return columns.findIndex((column) => column.name === columnName);
};

// Get providers name from object list
export const convertProvidersToValuePairs = (providers) =>
	providers.map(({ provider, provider_id }) => ({
		name: provider,
		value: provider_id
	}));

// Get institutions name from object list
export const convertInstitutionsToValuePairs = (institutions) =>
	institutions.map(({ name, uuid }) => ({
		name,
		value: uuid
	}));

// Convert model payments suggestions to key - value pairs and add none value suggestion
export const convertPaymentModelsSuggestions = (paymentModels) => {
	const pairedValues = paymentModels.map((paymentModel) => ({
		name: paymentModel,
		value: paymentModel
	}));

	return [
		...pairedValues,
		{ name: i18n.t('filters:suggestions.none'), value: 'none' }
	];
};

// escape "quotes" characters for csv export
// when there are "quotes" csv export treated it as new column. Applying ""double quoutes"" solves the problem
export const escapeQuotes = (data) => {
	return data.map((item) => {
		//checks if item is an Object
		if (item === Object(item)) {
			Object.keys(item).forEach((key) => {
				if (key && typeof item[key] === 'string') {
					item[key] = item[key].replace(/"/g, `""`);
				}
			});
			return item;
		} else return item;
	});
};

export const convertGenresToArray = (data) => {
	return data.map((item) => {
		if (item.genres && (item.type === 'vod' || item.type === 'channel')) {
			const genres = item.genres.map(({ name }) => name);
			return { ...item, genres };
		} else return item;
	});
};

export const convertStatusToValuePairs = (statusList) => {
	const pairedValues = statusList.map((status) => ({
		name: i18n.t(`vod:select:status.${status}`),
		value: status
	}));

	return pairedValues;
};

export const convertEntriesToValuePairs = (resources) => {
	return resources.map((item) => ({
		name: item[0],
		value: item[1]
	}));
};

export const convertTypeAgreementValues = (resources) => {
	if (resources.length) {
		return resources.map((item) => ({
			name: i18n.t(`agreements:types.${item}`),
			value: item
		}));
	}
};

export const convertRatingValues = (resources) => {
	// In this project value ADULT is useless
	if (resources.length) {
		return resources.filter((item) => item !== 'ADULT');
	}
};
