import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import {
	fetchArticleCover,
	fetchArticlePoster,
	fetchArticleAssetGroups,
	fetchArticleTitle
} from 'store/actions';

// Import helpers
import {
	IMAGE_SIZE_INFO,
	PRODUCT_IMAGES_REQUIREMENTS
} from 'components/helpers/info_helpers/info_texts';

// Import components
import Image from 'components/utilities/images/Image';
import AssetGroups from 'components/views/asset_groups/AssetGroups';
import InfoTitleCollapse from 'components/utilities/info/InfoTitleCollapse';

// Import translation
import { useTranslation } from 'react-i18next';

const ArticlesImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<>
			<InfoTitleCollapse
				title={IMAGE_SIZE_INFO}
				requirements={PRODUCT_IMAGES_REQUIREMENTS}
			/>
			<Image
				title={t('common:images.cover')}
				fetchResourcesAction={(id) => fetchArticleCover(id)(dispatch)}
				collectionType="cover"
				mediaType="article"
				storePathSelector="articles"
				productSubtype="articles"
			/>
			<Image
				title={t('common:images.poster')}
				fetchResourcesAction={(id) => fetchArticlePoster(id)(dispatch)}
				collectionType="poster"
				mediaType="article"
				storePathSelector="articles"
				productSubtype="articles"
			/>
			<Image
				title={t('common:images.title')}
				fetchResourcesAction={(id) => fetchArticleTitle(id)(dispatch)}
				collectionType="title"
				mediaType="article"
				storePathSelector="articles"
				productSubtype="articles"
			/>
			<AssetGroups
				fetchResourcesAction={(id) => fetchArticleAssetGroups(id)(dispatch)}
				mediaType="article"
				storePathSelector="articles"
				productSubtype="articles"
			/>
		</>
	);
};

export default ArticlesImages;
