import React from 'react';

// Import translation
import i18n from 'i18next';

// Import components
import { DeleteCell } from './components';

export const createColumns = (handleSelectItem, canEditVideo) => [
	{
		title: i18n.t('common:material_subtitles.table_columns.language'),
		dataIndex: 'language',
		key: 'language'
	},
	{
		title: i18n.t('common:material_subtitles.table_columns.filename'),
		dataIndex: 'filename',
		key: 'filename',
		render: (text, record, index) => (
			<a target="_blank" rel="noopener noreferrer" href={record.url}>
				{record.filename}
			</a>
		)
	},

	{
		title: i18n.t('common:table_columns.delete'),
		dataIndex: 'delete',
		key: 'delete',
		width: 70,
		align: 'center',
		// render: canEditVideo && RenderCell(handleSelectItem, canEditVideo)
		render: (text, record, index) => (
			<DeleteCell
				handleSelectItem={handleSelectItem}
				canEditVideo={canEditVideo}
				record={record}
			/>
		)
	}
];
