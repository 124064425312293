import React from 'react';
import { number } from 'prop-types';

// Import styled components
import {
	StyledIconAgeRating0,
	StyledIconAgeRating7,
	StyledIconAgeRating12,
	StyledIconAgeRating16,
	StyledIconAgeRating18
} from './styles';

const AgeRatingIcon = ({ rating }) => {
	switch (rating) {
		case 0:
			return <StyledIconAgeRating0 aria-hidden="true" />;
		case 7:
			return <StyledIconAgeRating7 aria-hidden="true" />;
		case 12:
			return <StyledIconAgeRating12 aria-hidden="true" />;
		case 16:
			return <StyledIconAgeRating16 aria-hidden="true" />;
		case 18:
			return <StyledIconAgeRating18 aria-hidden="true" />;
		default:
			return null;
	}
};

AgeRatingIcon.propTypes = {
	rating: number.isRequired
};

export default AgeRatingIcon;
