// Import translation
import i18n from 'i18next';

// Import variables
import { LINK_REGEX } from 'components/helpers/variables';

export default ({ title, group, url_pc, url_mobile }) => {
	const errors = {};

	// ------------------ title ------------------
	if (!title) {
		errors.title = i18n.t('banners:validation.title');
	}

	// ------------------ group ------------------
	if (!group) {
		errors.group = i18n.t('banners:validation.group');
	}

	// ------------------ url pc ------------------
	if (url_pc && !LINK_REGEX.test(url_pc)) {
		errors.url_pc = i18n.t('banners:validation.url_pc_error');
	}

	// ------------------ url_mobile ------------------
	if (url_mobile && !LINK_REGEX.test(url_mobile)) {
		errors.url_mobile = i18n.t('banners:validation.url_mobile_error');
	}

	return errors;
};
