import React from 'react';
import { number, string, func, object, bool } from 'prop-types';

// Import styles
import { Figure, Image } from '../styles';

const GalleryItem = ({
	width,
	height,
	url,
	cursor,
	onItemClick,
	isFullscreen
}) => {
	return (
		<Figure isFullscreen={isFullscreen} width={width} height={height}>
			<Image
				src={url}
				cursor={cursor}
				onClick={onItemClick}
				isFullscreen={isFullscreen}
			/>
		</Figure>
	);
};

GalleryItem.propTypes = {
	width: number,
	height: number,
	url: string,
	cursor: string,
	onItemClick: func,
	metadata: object,
	isFullscreen: bool
};

export default GalleryItem;
