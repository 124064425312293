import React from 'react';
import PropTypes from 'prop-types';

// Import select options
import { select_packet_types as type } from 'components/helpers/packets';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS, VISIBLE_FIELDS } from './input_fields';

const Fields = ({
	submitting,
	isEdit,
	checkedPermissions,
	currentValues,
	productTypes
}) => {
	const selectResources = {
		type,
		product_type: !currentValues.all_products ? [] : productTypes,
		external_ids: []
	};

	const setProductTypeDefaultValue = () => {
		if (!currentValues.all_products) return null;
		if (currentValues.product_type) return currentValues.product_type;
		else return selectResources['product_type'][0];
	};

	return (
		<>
			{BASE_FIELDS({
				isEdit,
				checkedPermissions,
				currentValues,
				setProductTypeDefaultValue
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			{VISIBLE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	checkedPermissions: PropTypes.object.isRequired,
	currentValues: PropTypes.object.isRequired,
	productTypes: PropTypes.array.isRequired
};

export default Fields;
