import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchDocument } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const DocumentsForm = ({
	history,
	fetchDocument,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'documents',
			redirectPath: 'documents'
		});

	return (
		// Dispatch fetchDocument actions in in documents_form
		<FormTemplate
			fetchResourceToEdit={fetchDocument}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={{ content: 'value', ...initialValues }}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					validate={validate}
					decorators={decorators}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields values={values} submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path="documents"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

DocumentsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchDocument: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ documents: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default connect(mapStateToProps, { fetchDocument })(
	withRouter(DocumentsForm)
);
