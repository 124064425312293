import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { Input } from 'antd';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

const InputLabel = ({
	input,
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	children,
	fieldInfo = null,
	meta: { touched, error, submitError },
	requiredField = false,
	...inputProps
}) => {
	// Render field info if there aren't any errors
	const renderFieldInfo = () => {
		if (touched && (error || submitError)) return null;
		if (fieldInfo) {
			return (
				<span className="input-info m-b-sm">
					<i className="fa fa-info-circle m-r-sm"></i>
					{fieldInfo}
				</span>
			);
		}
	};

	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label} {requiredField && REQUIRED_MARK}
			</Label>
			<Col sm={inputColumn}>
				<Input id={id} {...input} {...inputProps} />
				{touched && (error || submitError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error d-block"
					>
						{error || submitError}
					</span>
				)}
				{renderFieldInfo()}
			</Col>
			{children}
		</FormGroup>
	);
};

InputLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	id: PropTypes.string.isRequired,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	children: PropTypes.object,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string,
	requiredField: PropTypes.bool
};

export default InputLabel;
