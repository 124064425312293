import { useParams } from 'react-router-dom';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import helpers
import {
	defaultFormValues,
	convertFormValues,
	ELEMENT_TYPES,
	getConvertedResources
} from './helpers';

const { LINK } = ELEMENT_TYPES;

const useMenuForm = ({
	history,
	initialValues,
	isSubmenu = false,
	fetchSectionsList,
	fetchProductsGenresList,
	fetchSectionGroupList,
	sectionsList,
	productsGenresList,
	sectionGroupList
}) => {
	const { parent_id } = useParams();

	const redirectPath = isSubmenu ? `menus/edit/${parent_id}/submenu` : 'menus';

	const { model_type } = initialValues;

	const { sections, productsGenres, sectionGroup } = getConvertedResources({
		sectionsList,
		productsGenresList,
		sectionGroupList
	});

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const resources = convertFormValues({ values, parent_id });

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'menus',
			redirectPath
		});
	};

	const formInitialValues = {
		...defaultFormValues,
		...initialValues,
		element_type: model_type || LINK
	};

	const fetchFormResources = isSubmenu
		? [fetchProductsGenresList, fetchSectionsList]
		: [fetchSectionGroupList];

	return {
		sections,
		productsGenres,
		sectionGroup,
		onSubmit: handleOnSubmit,
		formInitialValues,
		fetchFormResources,
		redirectPath,
		parent_id,
		isSubmenu
	};
};

export default useMenuForm;
