import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	setEventLivePacketsAssignedQuery,
	fetchEventLivePacketsAssigned
} from 'store/actions';

// Import columns
import { createColumns } from './events_live_packets_assigned_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

const EventsLivePacketsAssigned = ({
	error,
	setEventLivePacketsAssignedQuery,
	fetchEventLivePacketsAssigned,
	resources,
	match: {
		params: { id }
	}
}) => {
	return (
		<PanelPageTemplate
			itemId={id}
			title="Lista"
			error={error}
			type="packets_assigned"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchEventLivePacketsAssigned}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setEventLivePacketsAssignedQuery}
					value={resources.options.filters.query}
				/>
			</div>
		</PanelPageTemplate>
	);
};

EventsLivePacketsAssigned.propTypes = {
	error: PropTypes.bool.isRequired,
	setEventLivePacketsAssignedQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchEventLivePacketsAssigned: PropTypes.func.isRequired
};

const mapStateToProps = ({ events_live }) => {
	return {
		error: events_live.packets_assigned.error,
		resources: events_live.packets_assigned
	};
};

export default compose(
	connect(mapStateToProps, {
		setEventLivePacketsAssignedQuery,
		fetchEventLivePacketsAssigned
	}),
	withRouter
)(EventsLivePacketsAssigned);
