/**
 * Function that converts document list from api to specific format needed for
 * select field combobox;
 * @param {array} documentsList - array of documents from api
 * @returns {array} - [{name: "title (alias)", value: alias}, ...]
 */
export const convertDocumentsList = (documentsList) => {
	return documentsList.map(({ alias, title }) => {
		const name = `${title} (${alias})`;
		return { name, value: alias };
	});
};
