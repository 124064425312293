import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

// Import components
import {
	Ibox,
	IboxContentBase,
	IboxTitle,
	Wraper
} from 'components/common/layout';
import ProductGlobalLimitForm from './product_global_limit_form/ProductGlobalLimitForm';

// Import columns
import { columns } from './product_global_limit_columns';

// Import hooks
import { useProductGlobalLimit } from './useProductGlobalLimit';

const ProductGlobalLimit = ({ productType }) => {
	const { t } = useTranslation();

	const { limits } = useProductGlobalLimit();

	return (
		<Ibox>
			<Wraper>
				<IboxTitle title={t('common:tabs.global_limit')} />
				<IboxContentBase>
					<Table dataSource={limits} columns={columns} pagination={false} />
				</IboxContentBase>
			</Wraper>
			<ProductGlobalLimitForm limits={limits} productType={productType} />
		</Ibox>
	);
};

ProductGlobalLimit.propTypes = {
	productType: PropTypes.string.isRequired
};

export default ProductGlobalLimit;
