import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setGeoblockGroupsQuery,
	fetchGeoblockGroups,
	deleteGeoblockGroup,
	createFiltersSuggestions
} from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './geoblock_groups_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const GeoblockGroups = ({
	error,
	setGeoblockGroupsQuery,
	fetchGeoblockGroups,
	deleteGeoblockGroup,
	resources,
	createFiltersSuggestions,
	filters
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	// execute filters logic
	useFilterLogic({
		createFiltersSuggestions,
		filters
	});

	return (
		// Dispatch fetchGeoblockGroups, deleteGeoblockGroup action in geoblock_groups
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="geoblock_groups"
			addLinkText={t('geoblock_groups:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchGeoblockGroups}
			deleteItemAction={deleteGeoblockGroup}
			notificationDeleteSuccessTxt={t(
				'geoblock_groups:notification.delete_success'
			)}
			notificationDeleteErrorTxt={t(
				'geoblock_groups:notification.delete_error'
			)}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setGeoblockGroupsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

GeoblockGroups.propTypes = {
	error: PropTypes.bool.isRequired,
	setGeoblockGroupsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchGeoblockGroups: PropTypes.func.isRequired,
	deleteGeoblockGroup: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object
};

const mapStateToProps = ({ geoblock_groups: { table } }) => {
	return {
		error: table.error,
		resources: table,
		filters: {}
	};
};

export default connect(mapStateToProps, {
	setGeoblockGroupsQuery,
	fetchGeoblockGroups,
	deleteGeoblockGroup,
	createFiltersSuggestions
})(GeoblockGroups);
