import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchEpisode,
	fetchCategories,
	fetchProviders,
	fetchInstitutionsList,
	fetchTagsList
} from 'store/actions';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import ProductizationNote from 'components/utilities/info/ProductizationNote';

// Import helpers
import {
	vodCategoriesOptions,
	convertDataToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';
import {
	getInstitutionName,
	convertInstitutionsToValuePairs
} from 'components/helpers/institutions_helpers/helpers';
import { convertProvidersToId } from 'components/views/vod/helpers/helpers';
import { convertDurationTime } from 'components/helpers/convert';

// Import variables
import { DURATION_CONVERT_TYPES } from 'components/helpers/variables';

const { HMS_TO_SECONDS, SECONDS_TO_HMS } = DURATION_CONVERT_TYPES;

const EpisodeForm = ({
	history,
	fetchEpisode,
	fetchCategories,
	fetchProviders,
	error,
	isLoaded,
	initialValues,
	genresList,
	providersList,
	paymentsModelsList,
	fetchInstitutionsList,
	institutionsList,
	fetchTags,
	tagsList
}) => {
	useEffect(() => {
		// Fetch categories with default params to feed genres combobox
		fetchCategories(vodCategoriesOptions, null, null);
		fetchProviders();
		// Fetch tags list
		fetchTagsList();
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	let seasonId = localStorage.getItem('season');
	const redirectPath = seasonId
		? `vod/season/edit/${seasonId}/episodes`
		: 'vod';

	const {
		metadata,
		genres,
		subtype,
		products_group_uuid,
		tags
	} = initialValues;
	const { actors, writers, directors, producers, duration, rating } = metadata;

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { genres, products_group_uuid, tags, metadata } = values;

		// selected genres mapped to array of ids
		const genresId = convertToIds(genresList, genres);

		// selected tags mapped to array of ids
		const tagsId = convertToIds(tagsList, tags);

		// convert duration from sec to min
		const durationInSeconds = convertDurationTime({
			duration: metadata.duration,
			type: HMS_TO_SECONDS
		});

		// resources to submit
		const resources = {
			...values,
			metadata: {
				...metadata,
				duration: durationInSeconds,
				sub_category: metadata.sub_category,
				rating: parseInt(metadata.rating, 10),
				additional_metadata: metadata.additional_metadata || []
			},
			genres: genresId,
			products_group_uuid: products_group_uuid || null,
			tags: tagsId
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'vod/episodes',
			redirectPath,
			apiSlug: 'update',
			notificationName: 'episode'
		});
	};

	// array of genres names that will be passed to multi-select
	// both initial values and data for combobox
	const genresNames = convertDataToNames(genres);
	const genresListNames = convertDataToNames(genresList);

	// arrays of provider names and id's passed to suggestion combobox for form fields
	const providersId = convertProvidersToId(providersList);

	// array of tags names that will be passed to multi-select
	// both initial values and data for combobox
	const tagsNames = convertDataToNames(tags || []);
	const tagsListNames = convertDataToNames(tagsList);

	// convert duration from sec to hms
	const durationInHms = convertDurationTime({
		duration,
		type: SECONDS_TO_HMS
	});

	// get institution name based on institution id
	const institutionsNames = convertInstitutionsToValuePairs(institutionsList);
	// if we have products_group_uuid find institution on list,
	// otherwise select first institution from list
	const productGroupName = products_group_uuid
		? getInstitutionName(products_group_uuid, institutionsList)
		: institutionsNames[0];

	// form initial values
	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		metadata: {
			...defaultFieldValues.metadata,
			...metadata,
			duration: durationInHms,
			category: metadata?.category || '',
			rating: rating ? '' + rating : '0', //fast way to convert value to string
			additional_data: {
				...defaultFieldValues.metadata.additional_data,
				...metadata.additional_data
			},
			additional_metadata: {
				...defaultFieldValues.metadata.additional_metadata,
				...metadata.additional_metadata
			}
		},
		genres: genresNames,
		tags: tagsNames,
		products_group_uuid: productGroupName?.value,
		subtype: subtype || defaultFieldValues.subtype
	};

	return (
		// Dispatch fetchEpisode actions in episode_form
		<FormTemplate
			fetchFormResources={[fetchInstitutionsList]}
			fetchResourceToEdit={fetchEpisode}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<>
					<ProductizationNote />
					<Form
						initialValues={formInitialValues}
						validate={validate}
						decorators={decorators}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({
							handleSubmit,
							submitting,
							values,
							form: { change }
						}) => (
							<form onSubmit={handleSubmit}>
								<Fields
									currentValues={values}
									isEdit={isEdit}
									submitting={submitting}
									changeFieldValue={change}
									actors={actors}
									writers={writers}
									directors={directors}
									producers={producers}
									genres={genresListNames}
									subtype={subtype}
									providersId={providersId}
									paymentsModelsList={paymentsModelsList}
									institutions={institutionsNames}
									checkedPermissions={checkedPermissions}
									tags={tagsListNames}
								/>
								<FormButtons
									isButtonDisabled={submitting || error}
									path={redirectPath}
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				</>
			)}
		</FormTemplate>
	);
};

EpisodeForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchEpisode: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	fetchProviders: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	genresList: PropTypes.array.isRequired,
	providersList: PropTypes.array.isRequired,
	paymentsModelsList: PropTypes.array.isRequired,
	institutionsList: PropTypes.array.isRequired,
	fetchInstitutionsList: PropTypes.func.isRequired,
	fetchTagsList: PropTypes.func.isRequired
};

const mapStateToProps = ({
	vod: { episode_form },
	categories: {
		table: { data }
	},
	providers,
	dictionary
}) => ({
	initialValues: episode_form.edit,
	error: episode_form.error,
	isLoaded: episode_form.isLoaded,
	genresList: data,
	providersList: providers.table.data,
	paymentsModelsList: dictionary.paymentModels,
	institutionsList: dictionary.institutionsList,
	tagsList: dictionary.tagsList
});

export default compose(
	connect(mapStateToProps, {
		fetchEpisode,
		fetchCategories,
		fetchProviders,
		fetchInstitutionsList,
		fetchTagsList
	}),
	withRouter
)(EpisodeForm);
