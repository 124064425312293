import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

const Fields = ({ submitting, isEdit, checkedPermissions }) => {
	return (
		<>
			{BASE_FIELDS({
				isEdit,
				checkedPermissions
			}).map((item, index) => renderFields({ item, index, submitting }))}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	checkedPermissions: PropTypes.object.isRequired
};

export default Fields;
