// Import translation
import i18n from 'i18next';

export default ({ name, slug, description, url }) => {
	const errors = {};

	// ------------------ name ------------------
	if (!name) {
		errors.name = i18n.t('institutions:validation.name');
	}

	// ------------------ slug ------------------
	if (!slug) {
		errors.slug = i18n.t('institutions:validation.slug');
	}

	// ------------------ description ------------------
	if (!description) {
		errors.description = i18n.t('institutions:validation.description');
	}

	if (url) {
		const urlRegex = /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
		const isUrlValid = urlRegex.test(url);

		if (!isUrlValid) {
			errors.url = i18n.t('institutions:validation.url_format');
		}
	}

	return errors;
};
