import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { getUploadedProductMaterials, transcodeAsset } from 'store/actions';
import _ from 'lodash';

// Import hooks
import useAudioLocation from 'hooks/useAudioLocation';

const useUploadMaterialsTable = ({ modelType }) => {
	const { id } = useParams();
	const { pathname } = useLocation();

	// REDUX
	const dispatch = useDispatch();
	const { data, error, status } = useSelector(
		({ product_upload_material }) =>
			product_upload_material.uploaded_materials_table
	);

	const { locationType, isAudio } = useAudioLocation();

	const handleDataChange = () =>
		getUploadedProductMaterials({ id, modelType, locationType })(dispatch);

	const handleAssetTranscode = async (assetId, videoType) => {
		await transcodeAsset(assetId, videoType, id)(dispatch);
	};

	const shouldUpdate = _.isEmpty(data);
	useEffect(() => {
		shouldUpdate &&
			getUploadedProductMaterials({ id, modelType, locationType })(dispatch);
		// eslint-disable-next-line
	}, [status]);

	const isSeries = pathname.includes('series');

	return {
		tableResources: data,
		tableError: error,
		tableStatus: status,
		dataChange: handleDataChange,
		transcodeAsset: handleAssetTranscode,
		isSeries,
		isAudio
	};
};

export default useUploadMaterialsTable;
