import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Import fields
import {
	RANGE_FIELDS,
	PERIOD_FIELDS,
	getLimitFields,
	PAYMENT_METHODS_FIELDS
} from './input_fields';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import logic
import useFields from './useFields';

// Import components
import { IboxContentTitle } from 'components/common/layout';

const Fields = ({ submitting }) => {
	const { t } = useTranslation();
	const { limitLabel, selectResources, showLimits } = useFields();

	const handleRenderFields = (item, index) =>
		renderFields({ item, index, submitting, selectResources });

	const limitFields = getLimitFields({ limitLabel, showLimits });

	return (
		<>
			<IboxContentTitle
				title={t('prices:fields.range_title')}
				classNameContent="pb-0"
			>
				{RANGE_FIELDS.map(handleRenderFields)}
			</IboxContentTitle>
			<IboxContentTitle
				title={t('prices:fields.pament_method_title')}
				classNameContent="pb-0"
			>
				{PAYMENT_METHODS_FIELDS.map(handleRenderFields)}
			</IboxContentTitle>
			<IboxContentTitle
				title={t('prices:fields.period_title')}
				classNameContent="pb-0"
			>
				{PERIOD_FIELDS.map(handleRenderFields)}
			</IboxContentTitle>
			<IboxContentTitle
				title={t('prices:fields.limit_title')}
				classNameContent="pb-0"
			>
				{limitFields.map(handleRenderFields)}
			</IboxContentTitle>
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
