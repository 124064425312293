// ******************** VOD ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_VOD_VISIBILITY_TABLE_LOADING =
	'FETCH_VOD_VISIBILITY_TABLE_LOADING';
export const FETCH_VOD_VISIBILITY_TABLE_SUCCESS =
	'FETCH_VOD_VISIBILITY_TABLE_SUCCESS';
export const FETCH_VOD_VISIBILITY_TABLE_ERROR =
	'FETCH_VOD_VISIBILITY_TABLE_ERROR';
// -------------------- DELETE --------------------
export const DELETE_VOD_VISIBILITY_LOADING = 'DELETE_VOD_VISIBILITY_LOADING';
export const DELETE_VOD_VISIBILITY_SUCCESS = 'DELETE_VOD_VISIBILITY_SUCCESS';
export const DELETE_VOD_VISIBILITY_ERROR = 'DELETE_VOD_VISIBILITY_ERROR';

// -------------------- FORM --------------------
export const FETCH_VOD_VISIBILITY_FORM_LOADING =
	'FETCH_VOD_VISIBILITY_FORM_LOADING';
export const FETCH_VOD_VISIBILITY_FORM_SUCCESS =
	'FETCH_VOD_VISIBILITY_FORM_SUCCESS';
export const FETCH_VOD_VISIBILITY_FORM_ERROR =
	'FETCH_VOD_VISIBILITY_FORM_ERROR';

// ******************** CHANNEL ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_CHANNEL_VISIBILITY_TABLE_LOADING =
	'FETCH_CHANNEL_VISIBILITY_TABLE_LOADING';
export const FETCH_CHANNEL_VISIBILITY_TABLE_SUCCESS =
	'FETCH_CHANNEL_VISIBILITY_TABLE_SUCCESS';
export const FETCH_CHANNEL_VISIBILITY_TABLE_ERROR =
	'FETCH_CHANNEL_VISIBILITY_TABLE_ERROR';
// -------------------- DELETE --------------------
export const DELETE_CHANNEL_VISIBILITY_LOADING =
	'DELETE_CHANNEL_VISIBILITY_LOADING';
export const DELETE_CHANNEL_VISIBILITY_SUCCESS =
	'DELETE_CHANNEL_VISIBILITY_SUCCESS';
export const DELETE_CHANNEL_VISIBILITY_ERROR =
	'DELETE_CHANNEL_VISIBILITY_ERROR';

// -------------------- FORM --------------------
export const FETCH_CHANNEL_VISIBILITY_FORM_LOADING =
	'FETCH_CHANNEl_VISIBILITY_FORM_LOADING';
export const FETCH_CHANNEL_VISIBILITY_FORM_SUCCESS =
	'FETCH_CHANNEl_VISIBILITY_FORM_SUCCESS';
export const FETCH_CHANNEL_VISIBILITY_FORM_ERROR =
	'FETCH_CHANNEl_VISIBILITY_FORM_ERROR';

// ******************** ARTICLE ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_ARTICLE_VISIBILITY_TABLE_LOADING =
	'FETCH_ARTICLE_VISIBILITY_TABLE_LOADING';
export const FETCH_ARTICLE_VISIBILITY_TABLE_SUCCESS =
	'FETCH_ARTICLE_VISIBILITY_TABLE_SUCCESS';
export const FETCH_ARTICLE_VISIBILITY_TABLE_ERROR =
	'FETCH_ARTICLE_VISIBILITY_TABLE_ERROR';
// -------------------- DELETE --------------------
export const DELETE_ARTICLE_VISIBILITY_LOADING =
	'DELETE_ARTICLE_VISIBILITY_LOADING';
export const DELETE_ARTICLE_VISIBILITY_SUCCESS =
	'DELETE_ARTICLE_VISIBILITY_SUCCESS';
export const DELETE_ARTICLE_VISIBILITY_ERROR =
	'DELETE_ARTICLE_VISIBILITY_ERROR';

// -------------------- FORM --------------------
export const FETCH_ARTICLE_VISIBILITY_FORM_LOADING =
	'FETCH_ARTICLE_VISIBILITY_FORM_LOADING';
export const FETCH_ARTICLE_VISIBILITY_FORM_SUCCESS =
	'FETCH_ARTICLE_VISIBILITY_FORM_SUCCESS';
export const FETCH_ARTICLE_VISIBILITY_FORM_ERROR =
	'FETCH_ARTICLE_VISIBILITY_FORM_ERROR';

// ******************** EVENT_LIVE ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_EVENT_LIVE_VISIBILITY_TABLE_LOADING =
	'FETCH_EVENT_LIVE_VISIBILITY_TABLE_LOADING';
export const FETCH_EVENT_LIVE_VISIBILITY_TABLE_SUCCESS =
	'FETCH_EVENT_LIVE_VISIBILITY_TABLE_SUCCESS';
export const FETCH_EVENT_LIVE_VISIBILITY_TABLE_ERROR =
	'FETCH_EVENT_LIVE_VISIBILITY_TABLE_ERROR';
// -------------------- DELETE --------------------
export const DELETE_EVENT_LIVE_VISIBILITY_LOADING =
	'DELETE_EVENT_LIVE_VISIBILITY_LOADING';
export const DELETE_EVENT_LIVE_VISIBILITY_SUCCESS =
	'DELETE_EVENT_LIVE_VISIBILITY_SUCCESS';
export const DELETE_EVENT_LIVE_VISIBILITY_ERROR =
	'DELETE_EVENT_LIVE_VISIBILITY_ERROR';

// -------------------- FORM --------------------
export const FETCH_EVENT_LIVE_VISIBILITY_FORM_LOADING =
	'FETCH_EVENT_LIVE_VISIBILITY_FORM_LOADING';
export const FETCH_EVENT_LIVE_VISIBILITY_FORM_SUCCESS =
	'FETCH_EVENT_LIVE_VISIBILITY_FORM_SUCCESS';
export const FETCH_EVENT_LIVE_VISIBILITY_FORM_ERROR =
	'FETCH_EVENT_LIVE_VISIBILITY_FORM_ERROR';

// ******************** PACKET ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_PACKET_VISIBILITY_TABLE_LOADING =
	'FETCH_PACKET_VISIBILITY_TABLE_LOADING';
export const FETCH_PACKET_VISIBILITY_TABLE_SUCCESS =
	'FETCH_PACKET_VISIBILITY_TABLE_SUCCESS';
export const FETCH_PACKET_VISIBILITY_TABLE_ERROR =
	'FETCH_PACKET_VISIBILITY_TABLE_ERROR';
// -------------------- DELETE --------------------
export const DELETE_PACKET_VISIBILITY_LOADING =
	'DELETE_PACKET_VISIBILITY_LOADING';
export const DELETE_PACKET_VISIBILITY_SUCCESS =
	'DELETE_PACKET_VISIBILITY_SUCCESS';
export const DELETE_PACKET_VISIBILITY_ERROR = 'DELETE_PACKET_VISIBILITY_ERROR';

// -------------------- FORM --------------------
export const FETCH_PACKET_VISIBILITY_FORM_LOADING =
	'FETCH_PACKET_VISIBILITY_FORM_LOADING';
export const FETCH_PACKET_VISIBILITY_FORM_SUCCESS =
	'FETCH_PACKET_VISIBILITY_FORM_SUCCESS';
export const FETCH_PACKET_VISIBILITY_FORM_ERROR =
	'FETCH_PACKET_VISIBILITY_FORM_ERROR';

// ******************** PASS ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_PASS_VISIBILITY_TABLE_LOADING =
	'FETCH_PASS_VISIBILITY_TABLE_LOADING';
export const FETCH_PASS_VISIBILITY_TABLE_SUCCESS =
	'FETCH_PASS_VISIBILITY_TABLE_SUCCESS';
export const FETCH_PASS_VISIBILITY_TABLE_ERROR =
	'FETCH_PASS_VISIBILITY_TABLE_ERROR';
// -------------------- DELETE --------------------
export const DELETE_PASS_VISIBILITY_LOADING = 'DELETE_PASS_VISIBILITY_LOADING';
export const DELETE_PASS_VISIBILITY_SUCCESS = 'DELETE_PASS_VISIBILITY_SUCCESS';
export const DELETE_PASS_VISIBILITY_ERROR = 'DELETE_PASS_VISIBILITY_ERROR';

// -------------------- FORM --------------------
export const FETCH_PASS_VISIBILITY_FORM_LOADING =
	'FETCH_PASS_VISIBILITY_FORM_LOADING';
export const FETCH_PASS_VISIBILITY_FORM_SUCCESS =
	'FETCH_PASS_VISIBILITY_FORM_SUCCESS';
export const FETCH_PASS_VISIBILITY_FORM_ERROR =
	'FETCH_PASS_VISIBILITY_FORM_ERROR';
