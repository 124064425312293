// Import translation
import i18n from 'i18next';

// Promotions select values to proper select fields
// Hardcoded based on postman dictionary
export const select_promotion_triggers = [
	{
		name: i18n.t('promotions:form:select_values:trigger.auto'),
		value: 'auto'
	},
	{
		name: i18n.t('promotions:form:select_values:trigger.code'),
		value: 'code'
	}
];

export const select_promotion_types = [
	{
		name: i18n.t('promotions:form:select_values:promotion_type.percentage'),
		value: 'percentage'
	},
	{
		name: i18n.t('promotions:form:select_values:promotion_type.discount'),
		value: 'discount'
	},
	{
		name: i18n.t('promotions:form:select_values:promotion_type.static_price'),
		value: 'static_price'
	}
];

export const select_product_specification = [
	{
		name: i18n.t(
			'promotions:form:select_values:product_specification.all_products'
		),
		value: 'all_products'
	},
	{
		name: i18n.t(
			'promotions:form:select_values:product_specification.products_list'
		),
		value: 'products_list'
	}
];

export const select_user_specification = [
	{
		name: i18n.t('promotions:form:select_values:user_specification.all_users'),
		value: 'all_users'
	},
	{
		name: i18n.t(
			'promotions:form:select_values:user_specification.packets_list'
		),
		value: 'packets_list'
	},
	{
		name: i18n.t('promotions:form:select_values:user_specification.users_list'),
		value: 'users_list'
	},
	{
		name: i18n.t(
			'promotions:form:select_values:user_specification.products_list'
		),
		value: 'products_list'
	}
];

export const select_entry_condition_products_specification = [
	{
		name: i18n.t(
			'promotions:form:select_values:entry_condition_products_specification.inherited'
		),
		value: 'inherited'
	},
	{
		name: i18n.t(
			'promotions:form:select_values:entry_condition_products_specification.all_products'
		),
		value: 'all_products'
	},
	{
		name: i18n.t(
			'promotions:form:select_values:entry_condition_products_specification.products_list'
		),
		value: 'products_list'
	}
];
