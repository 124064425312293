import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';
import { Input } from 'antd';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

const TextareaLabel = ({
	input,
	id,
	label,
	rows,
	labelColumn = 2,
	inputColumn = 10,
	resize = 'vertical',
	meta: { touched, error, submitError },
	fieldInfo = null,
	warningInfo = false,
	requiredField = false,
	...inputProps
}) => {
	const { t } = useTranslation();

	return (
		<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label} {requiredField && REQUIRED_MARK}
			</Label>
			<Col sm={inputColumn}>
				<Input.TextArea id={id} {...input} {...inputProps} />
				{touched && (error || submitError) && (
					<span
						data-testid={`textarea-error-${input.name}`}
						className="input-error d-block"
					>
						{error || submitError}
					</span>
				)}
				{warningInfo && (
					<span className="input-warning">
						{t('common:form.fields.validation.value_too_long')}
					</span>
				)}
				{fieldInfo && (
					<span className="input-info m-b-sm">
						<i className="fa fa-info-circle m-r-sm"></i>
						{fieldInfo}
					</span>
				)}
			</Col>
		</FormGroup>
	);
};

TextareaLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	rows: PropTypes.string,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	resize: PropTypes.string,
	meta: PropTypes.object,
	fieldInfo: PropTypes.string,
	requiredField: PropTypes.bool
};

export default TextareaLabel;
