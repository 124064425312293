import services from 'services/services';
import * as types from '../../types';
import { setRequestUrl } from 'store/actions';

// Import helpers
import {
	checkFetchErrors,
	queryParams
} from '../../helpers_actions/convert_helpers';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// ******************** FETCH EPISODES - TABLE DATA ********************
export const fetchEpisodes = (options, id, resources) => async (
	dispatch,
	getState
) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_EPISODES_LOADING,
			payload: true
		});

		const {
			vod: {
				episodes_table: {
					columns,
					options: {
						filters: { filterUrlQuery },
						startIndex: startTableIndex,
						page
					}
				}
			}
		} = getState();

		const startIndex =
			options.page === page ? startTableIndex : options.startIndex;

		const urlParams =
			options === null
				? ''
				: queryParams(options, TABLE_LENGTH, columns, startIndex);

		// filer vod list for audio or vod only
		const audioQuery = `is_audio=${resources?.isAudio ? '1' : '0'}&`;

		const url = `/vod/${id}/list?${audioQuery}${filterUrlQuery}${urlParams}`;

		const { data } = await services.get(url);

		// Calculate pages
		const pages = Math.ceil(data.recordsFiltered / TABLE_LENGTH);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_EPISODES_SUCCESS,
			payload: { ...data, pages, page: options.page, startIndex }
		});

		// save request url in redux
		setRequestUrl(url)(dispatch);
	} catch (error) {
		dispatch({
			type: types.FETCH_EPISODES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** DETACH EPISODES ********************
export const detachEpisode = (id) => async (dispatch, getState) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.DETACH_EPISODE_LOADING,
			payload: true
		});

		// need parent season id to provide to endpoint
		const {
			vod: {
				episodes_table: { parentSeasonID }
			}
		} = getState();

		// list of episodes to detach
		const data = { episodes: [id] };

		await services.put(`/vod/season/${parentSeasonID}/detachEpisodes`, data);

		// Dispatch an action with data
		dispatch({
			type: types.DETACH_EPISODE_SUCCESS,
			payload: data
		});
	} catch (error) {
		// Dispatch an action with error set to true
		dispatch({
			type: types.DETACH_EPISODE_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};

// ******************** SET PARENT SEASON ID ********************
export const setParentEpisodeId = (id) => ({
	type: types.SET_PARENT_SEASON_ID,
	payload: id
});

// ******************** SET SEARCH QUERY ********************
export const setEpisodesQuery = (query) => ({
	type: types.SET_EPISODES_SEARCH_QUERY,
	payload: query
});
