import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import helrpes
import { assetTabs } from './helpers';
import { getCancelButtonPath } from '../asset_groups_tabs/helpers';

const AssetTabs = () => {
	const { languages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);

	const {
		edit: { metadata }
	} = useSelector(({ asset_groups }) => asset_groups.asset_details);

	const { productSubtype, model_id } = useParams();

	const cancelButtonPath = getCancelButtonPath({ productSubtype, model_id });

	return <Tabs tabs={assetTabs(languages, cancelButtonPath, metadata)} />;
};

export default AssetTabs;
