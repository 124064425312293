import _ from 'lodash';

// Import helpers
import { convertItemDateProperties } from 'components/helpers/convert';
import { DATE_NAMES } from 'components/helpers/variables';

export const convertApiResponse = (response) => {
	const { data: payload } = response;

	let convertedData = payload;

	const isPayloadArray = Array.isArray(payload);

	const isPayloadHasDataWithArray = Array.isArray(payload?.data);

	const isPayloadHasDateProperties = DATE_NAMES.some((dateName) =>
		payload?.hasOwnProperty(dateName)
	);

	const isPayloadHasDataWithDateProperties = DATE_NAMES.some((dateName) =>
		payload?.data?.hasOwnProperty(dateName)
	);

	if (isPayloadArray) {
		convertedData = payload.map((item) => {
			if (!_.isArray(item) && _.isObject(item)) {
				return convertItemDateProperties({ item, isUTC: true });
			}

			return item;
		});
	} else if (isPayloadHasDataWithArray) {
		const data = payload.data.map((item) =>
			convertItemDateProperties({ item, isUTC: true })
		);
		convertedData = { ...payload, data };
	} else if (isPayloadHasDateProperties) {
		convertedData = convertItemDateProperties({
			item: payload,
			isUTC: true
		});
	} else if (isPayloadHasDataWithDateProperties) {
		const data = convertItemDateProperties({
			item: payload.data,
			isUTC: true
		});
		convertedData = { ...payload, data };
	}

	return { ...response, data: convertedData };
};
