import React from 'react';
import { oneOfType, arrayOf, node, bool } from 'prop-types';

// Import styles
import { StyledButton } from './styles';

const CommonButton = ({ children, isPrime }) => {
	return <StyledButton prime={isPrime ? 1 : 0}>{children}</StyledButton>;
};

CommonButton.defaultProps = {
	isPrime: true
};

CommonButton.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	isPrime: bool
};

export default CommonButton;
