import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchAssetDetails } from 'store/actions';
import services from 'services/services';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import MetadataFields from 'components/views/asset_groups/metadata_fields/MetadataFields';

// Import utilities
import { createFormNotification } from 'components/utilities/notifications';

const AssetGroupsForm = ({
	history,
	fetchAssetDetails,
	error,
	isLoaded,
	initialValues,
	match: {
		params: { id: assetId }
	},
	cancelButtonPath,
	isCreate = false
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = async (values) => {
		const { filename, metadata } = values;

		try {
			await services.post(`/assets/${assetId}/update`, { filename, metadata });
			// open notification
			createFormNotification(isCreate, 'asset_metadata', 'success');
			// redirect to product assets
			history.push(`/panel/${cancelButtonPath}`);
		} catch (error) {
			// open notification
			createFormNotification(isCreate, 'asset_metadata', 'error');
		}
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchAssetDetails}
			error={error}
			isLoaded={isLoaded}
		>
			{({ decorators }) => (
				<Form
					initialValues={initialValues}
					decorators={decorators}
					onSubmit={handleOnSubmit}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<MetadataFields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting || error}
								path={cancelButtonPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

AssetGroupsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchAssetDetails: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	match: PropTypes.object.isRequired,
	cancelButtonPath: PropTypes.string.isRequired,
	isCreate: PropTypes.bool
};

const mapStateToProps = ({ asset_groups: { asset_details } }) => {
	return {
		initialValues: asset_details.edit,
		error: asset_details.error,
		isLoaded: asset_details.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchAssetDetails }),
	withRouter
)(AssetGroupsForm);
