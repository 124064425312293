import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'group_name',
		type: 'text',
		placeholder: i18n.t('limits:fields.group_name'),
		label: i18n.t('limits:fields.group_name'),
		id: 'group_name',
		requiredField: true
	},
	{
		name: 'limit',
		type: 'number',
		placeholder: i18n.t('limits:fields.limit'),
		label: i18n.t('limits:fields.limit'),
		id: 'limit',
		requiredField: true
	}
];
