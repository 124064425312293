import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';

// Import helrpes
import { vodCreateTabs } from './helpers/index';

const VodCreateTabs = () => {
	return <Tabs tabs={vodCreateTabs} />;
};

export default VodCreateTabs;
