import { useEffect, useReducer } from 'react';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import services from 'services/services';

// Import state, reducer and actions
import {
	tabReducer,
	initialState,
	updateChildData,
	updateParentData,
	updateBaseParentData,
	updateLoading
} from './tabs_reducer';

// Import helpers
import {
	getLocationType,
	getLocationSubtype
} from 'components/helpers/convert';
import {
	convertLocationType,
	getName,
	checkProperType,
	checkProperSubtype,
	checkForEpg
} from './helpers/helpers';

/**
 * Custom hook to store logic for Tabs
 */
const useTabsLogic = () => {
	const { url, path } = useRouteMatch();

	const [state, dispatch] = useReducer(tabReducer, initialState);

	const { id } = useParams();
	const { pathname } = useLocation();

	// checking if location is in epg tabs (needed to show program title in epg metadata form)
	const isEpg = checkForEpg(pathname);

	// checking for location type (packet | vod | channel)
	const locationType = getLocationType(pathname);
	const convertedLocationType = convertLocationType(locationType, isEpg);

	// checking for location subtype (vod | series | season | episode)
	const locationSubtype = getLocationSubtype(pathname);

	// check if product type is eligible for displaing its name over tabs
	const properType = checkProperType(convertedLocationType);

	// check if product subtype is eligible for displaying web link next to tab breadcrumbs title
	const properSubtype = checkProperSubtype(locationSubtype);

	// check if user is not in create form
	const isCreateForm = (pathname) =>
		pathname.split('/').some((element) => element === 'create');

	// fetching product data and extracting name from its data - for displaying it above tabs
	useEffect(() => {
		if (properType && !isCreateForm(pathname)) {
			(async () => {
				const { data: childData } = await services.get(
					`${convertedLocationType}/${id}`
				);
				const childName = getName(childData, convertedLocationType);
				updateChildData({
					childName,
					childId: id,
					childSubtype: childData.subtype,
					childActive: childData.active
				})(dispatch);

				// get parent name and id
				if (childData.parent_uuid) {
					const { data: parentData } = await services.get(
						`${convertedLocationType}/${childData.parent_uuid}`
					);
					const parentName = getName(parentData, convertedLocationType);
					updateParentData({
						parentName,
						parentId: childData.parent_uuid,
						parentSubtype: parentData.subtype,
						parentIsAudio: parentData.is_audio
					})(dispatch);

					// gate base parent name and id
					if (parentData.parent_uuid) {
						const { data: baseParentData } = await services.get(
							`${convertedLocationType}/${parentData.parent_uuid}`
						);
						const baseParentName = getName(
							baseParentData,
							convertedLocationType
						);
						updateBaseParentData({
							baseParentName,
							baseParentId: parentData.parent_uuid,
							baseParentSubtype: baseParentData.subtype,
							baseParentIsAudio: baseParentData.is_audio
						})(dispatch);
					}
				}
				// set loading to false only when all elements are fetched
				updateLoading()(dispatch);
			})();
		}
		// eslint-disable-next-line
	}, []);

	return {
		url,
		path,
		id,
		loading: state.loading,
		child: state.child,
		parent: state.parent,
		baseParent: state.baseParent,
		properSubtype,
		properType
	};
};

export default useTabsLogic;
