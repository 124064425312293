import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

// Import components
import VodInfo from './components/VodInfo';
import ArticleInfo from './components/ArticleInfo';
import LiveEventInfo from './components/LiveEventInfo';
import SeriesInfo from './components/SeriesInfo';

// Import hooks
import useProductPreview from './useProductPreview';

// Import helpers
import { GLOBAL_PRODUCT_TYPES } from 'components/helpers/variables';

// Import templates
import ProductPreviewTemplate from './templates/ProductPreviewTemplate';
import { Layout } from './Layout';

// Import vendors
import './vendors/vendor_styles';

const { VOD, EVENT_LIVE, ARTICLE, SERIES } = GLOBAL_PRODUCT_TYPES;

const ProductPreview = () => {
	const { id, type } = useParams();

	useProductPreview({ id, type });

	const { isLoaded } = useSelector(({ product_preview }) => product_preview);

	const renderProductPreview = () => {
		switch (type) {
			case VOD:
				return <VodInfo />;

			case SERIES:
				return <SeriesInfo />;

			case ARTICLE:
				return <ArticleInfo />;

			case EVENT_LIVE:
				return <LiveEventInfo />;

			default:
				return null;
		}
	};

	return (
		<ProductPreviewTemplate>
			{isLoaded && (
				<Layout type={type} id={id}>
					{renderProductPreview()}
				</Layout>
			)}
		</ProductPreviewTemplate>
	);
};

export default ProductPreview;
