import { useSelector } from 'react-redux';

const useSubInformations = () => {
	const { metadata } = useSelector(
		({ product_preview }) => product_preview.data
	);

	const subTitle = metadata?.sub_title;
	const subSummaryLong = metadata?.sub_summary_long;
	const subSummaryShort = metadata?.sub_summary_short;

	const showInformations = !!subTitle || !!subSummaryLong || !!subSummaryShort;

	return { showInformations };
};

export default useSubInformations;
