import React from 'react';

// Import styles
import { Section } from './styles';

// Import logic
import useDescriptionSection from './useDescriptionSection';

// Import components
import Description from '../Description';
import ProductGallery from '../product_gallery';

const DescriptionSection = () => {
	const {
		sectionHeight,
		setSectionHeight,
		isArticle,
		isColumnsGrid
	} = useDescriptionSection();

	return (
		<Section isArticle={isArticle} isColumnsGrid={isColumnsGrid}>
			<ProductGallery onHeightChange={setSectionHeight} />
			<Description isColumnsGrid={isColumnsGrid} maxHeight={sectionHeight} />
		</Section>
	);
};

export default DescriptionSection;
