import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import select options
import {
	handicap_helper_type,
	rating_category_recommendation,
	checkboxValues
} from 'components/utilities/product_metric/common_select_values/select_type_options';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

const Fields = ({ submitting }) => {
	const selectResources = {
		'metadata.directors': [],
		'metadata.actors': [],
		'metadata.additional_data.handicap_helper_type': handicap_helper_type,
		'metadata.writers': [],
		'metadata.additional_data.rating_category_recommendation': rating_category_recommendation,
		'metadata.additional_data.payment': checkboxValues,
		'metadata.additional_data.exclusive_license': checkboxValues,
		'metadata.additional_metadata.authors': [],
		'metadata.additional_metadata.lead': [],
		'metadata.additional_metadata.dramaturgy': [],
		'metadata.additional_metadata.composition': []
	};

	return (
		<React.Fragment>
			<div className="hr-line-solid">&nbsp;</div>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
