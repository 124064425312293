import React from 'react';
import { useLocation } from 'react-router-dom';

// Import components
import UploadMaterial from 'components/utilities/upload_material/UploadMaterial';

const VodUploadMaterial = () => {
	const { pathname } = useLocation();

	const isSeries = pathname.includes('series');

	return (
		<UploadMaterial
			modelType="vod"
			fileTypeSelectorVisible={false}
			transcodeMain={!isSeries}
			transcodePreview={true}
		/>
	);
};

export default VodUploadMaterial;
