import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import actions
import { fetchAffiliatePartnerCodes } from 'store/actions';

// Import components
import AffiliatePartnerCodesList from './affiliate_partner_codes_list/AffiliatePartnerCodesList';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const AffiliatePartnersCodeForm = ({
	history,
	fetchAffiliatePartnerCodes,
	error,
	isLoaded
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ itemID }) => async (resources) => {
		await submitForm({
			history,
			isEdit: false,
			itemID,
			resources,
			api: 'affiliate',
			redirectPath: 'affiliate_partners',
			apiSlug: 'codes'
		});

		fetchAffiliatePartnerCodes(itemID);
	};

	return (
		<FormTemplate
			fetchResourceToEdit={fetchAffiliatePartnerCodes}
			error={error}
			isLoaded={isLoaded}
			iboxContentTitle={t('affiliate_partners:form_title.codes')}
		>
			{({ itemID, decorators }) => (
				<>
					<Form
						validate={validate}
						decorators={decorators}
						onSubmit={handleOnSubmit({ itemID })}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Fields submitting={submitting} />
								<FormButtons
									isButtonDisabled={submitting || error}
									path="affiliate_partners"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
					<AffiliatePartnerCodesList itemID={itemID} />
				</>
			)}
		</FormTemplate>
	);
};

AffiliatePartnersCodeForm.propTypes = {
	history: PropTypes.object.isRequired,
	fetchAffiliatePartnerCodes: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ affiliate_partners: { form } }) => {
	return {
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchAffiliatePartnerCodes
	}),
	withRouter
)(AffiliatePartnersCodeForm);
