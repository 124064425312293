import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../theme/GlobalStyle';
import { pureCkfTheme } from '../theme/pure_ckf_theme/pureCkfTheme';

const ProductPreviewTemplate = ({ children }) => {
	return (
		<ThemeProvider theme={pureCkfTheme}>
			<GlobalStyle />
			{children}
		</ThemeProvider>
	);
};

ProductPreviewTemplate.propTypes = {
	children: PropTypes.element.isRequired
};

export default ProductPreviewTemplate;
