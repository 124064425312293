import React from 'react';
import { useSelector } from 'react-redux';

// Import styles
import { Info, Divider } from './styles';

// Import helpers
import { formatDateToLocale } from './helpers';

const ArticleAuthors = () => {
	const {
		metadata: { authors, created_at }
	} = useSelector(({ product_preview }) => product_preview.data);

	const articleDate = formatDateToLocale({ date: created_at });

	const isAuthors = authors.length;

	const showDivider = isAuthors && articleDate;

	return (
		<Info>
			{isAuthors && <span>{authors.join(',')}</span>}
			{showDivider && <Divider isLightBackground opacity={0.5} />}
			{articleDate && <span>{articleDate}</span>}
		</Info>
	);
};

export default ArticleAuthors;
