import React from 'react';

// Import translation
import i18n from 'i18next';

// Import convert
import { convertPrice, convertPriceModel } from 'components/helpers/convert';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('prices:table_columns.since'),
		accessor: 'since',
		width: 150
	},
	{
		Header: i18n.t('prices:table_columns.till'),
		accessor: 'till',
		width: 150
	},
	{
		Header: i18n.t('prices:table_columns.price'),
		accessor: 'price',
		width: 150,
		Cell: ({
			row: {
				_original: { price }
			}
		}) => convertPrice(price)
	},
	{
		Header: i18n.t('prices:table_columns.period'),
		accessor: 'period'
	},
	{
		Header: i18n.t('prices:table_columns.periodUnit'),
		accessor: 'period_unit',
		minWidth: 150,

		Cell: ({
			row: {
				_original: { period_unit }
			}
		}) => period_unit && i18n.t(`prices:periods.${period_unit}`)
	},
	{
		Header: i18n.t('prices:table_columns.model'),
		accessor: 'since_first_access',
		minWidth: 200,
		Cell: ({
			row: {
				_original: { since_first_access }
			}
		}) => convertPriceModel(since_first_access)
	},
	{
		Header: i18n.t('prices:table_columns.views_limit'),
		accessor: 'views_limit',
		minWidth: 150,
		Cell: ({
			row: {
				_original: { views_limit }
			}
		}) => <span>{views_limit ?? i18n.t('common:none')}</span>
	},
	{
		Header: i18n.t('prices:table_columns.payment_method'),
		accessor: 'payment_method',
		minWidth: 200,
		Cell: ({
			row: {
				_original: { payment_method }
			}
		}) => i18n.t(`prices:payment_methods.${payment_method}`)
	},
	{
		Header: i18n.t('prices:table_columns.created_at'),
		accessor: 'created_at',
		width: 150
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(id)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
