// Import translation
import i18n from 'i18next';

export const institutionsTranslationValidate = ({ name }) => {
	const errors = {};

	// ------------------ name ------------------
	if (!name) {
		errors.name = i18n.t('institutions:validation.name');
	}

	return errors;
};
