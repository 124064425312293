// ******************** PACKETS DATA TABLE ********************
export const FETCH_PACKETS_LOADING = 'FETCH_PACKETS_LOADING';
export const FETCH_PACKETS_SUCCESS = 'FETCH_PACKETS_SUCCESS';
export const FETCH_PACKETS_ERROR = 'FETCH_PACKETS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_PACKETS_SEARCH_QUERY = 'SET_PACKETS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_PACKETS_TABLE_SORTING = 'SAVE_PACKETS_TABLE_SORTING';

// ******************** DELETE PACKET ********************
export const DELETE_PACKET_LOADING = 'DELETE_PACKET_LOADING';
export const DELETE_PACKET_SUCCESS = 'DELETE_PACKET_SUCCESS';
export const DELETE_PACKET_ERROR = 'DELETE_PACKET_ERROR';

// ******************** PACKET ********************
export const FETCH_PACKET_LOADING = 'FETCH_PACKET_LOADING';
export const FETCH_PACKET_SUCCESS = 'FETCH_PACKET_SUCCESS';
export const FETCH_PACKET_ERROR = 'FETCH_PACKET_ERROR';

// ******************** FILES ********************
// -------------------- COVER --------------------
export const FETCH_PACKET_COVER_FILES_LOADING =
	'FETCH_PACKET_COVER_FILES_LOADING';
export const FETCH_PACKET_COVER_FILES_SUCCESS =
	'FETCH_PACKET_COVER_FILES_SUCCESS';
export const FETCH_PACKET_COVER_FILES_ERROR = 'FETCH_PACKET_COVER_FILES_ERROR';
// -------------------- COVER_LARGE --------------------
export const FETCH_PACKET_COVER_LARGE_FILES_LOADING =
	'FETCH_PACKET_COVER_LARGE_FILES_LOADING';
export const FETCH_PACKET_COVER_LARGE_FILES_SUCCESS =
	'FETCH_PACKET_COVER_LARGE_FILES_SUCCESS';
export const FETCH_PACKET_COVER_LARGE_FILES_ERROR =
	'FETCH_PACKET_COVER_LARGE_FILES_ERROR';
// -------------------- COVER_SMALL --------------------
export const FETCH_PACKET_COVER_SMALL_FILES_LOADING =
	'FETCH_PACKET_COVER_SMALL_FILES_LOADING';
export const FETCH_PACKET_COVER_SMALL_FILES_SUCCESS =
	'FETCH_PACKET_COVER_SMALL_FILES_SUCCESS';
export const FETCH_PACKET_COVER_SMALL_FILES_ERROR =
	'FETCH_PACKET_COVER_SMALL_FILES_ERROR';
// -------------------- COVER_DETAIL --------------------
export const FETCH_PACKET_COVER_DETAIL_FILES_LOADING =
	'FETCH_PACKET_COVER_DETAIL_FILES_LOADING';
export const FETCH_PACKET_COVER_DETAIL_FILES_SUCCESS =
	'FETCH_PACKET_COVER_DETAIL_FILES_SUCCESS';
export const FETCH_PACKET_COVER_DETAIL_FILES_ERROR =
	'FETCH_PACKET_COVER_DETAIL_FILES_ERROR';
// -------------------- COVER_DETAIL_LARGE --------------------
export const FETCH_PACKET_COVER_DETAIL_LARGE_FILES_LOADING =
	'FETCH_PACKET_COVER_DETAIL_LARGE_FILES_LOADING';
export const FETCH_PACKET_COVER_DETAIL_LARGE_FILES_SUCCESS =
	'FETCH_PACKET_COVER_DETAIL_LARGE_FILES_SUCCESS';
export const FETCH_PACKET_COVER_DETAIL_LARGE_FILES_ERROR =
	'FETCH_PACKET_COVER_DETAIL_LARGE_FILES_ERROR';
// -------------------- POSTER --------------------
export const FETCH_PACKET_POSTER_FILES_LOADING =
	'FETCH_PACKET_POSTER_FILES_LOADING';
export const FETCH_PACKET_POSTER_FILES_SUCCESS =
	'FETCH_PACKET_POSTER_FILES_SUCCESS';
export const FETCH_PACKET_POSTER_FILES_ERROR =
	'FETCH_PACKET_POSTER_FILES_ERROR';
// -------------------- TITLE --------------------
export const FETCH_PACKET_TITLE_FILES_LOADING =
	'FETCH_PACKET_TITLE_FILES_LOADING';
export const FETCH_PACKET_TITLE_FILES_SUCCESS =
	'FETCH_PACKET_TITLE_FILES_SUCCESS';
export const FETCH_PACKET_TITLE_FILES_ERROR = 'FETCH_PACKET_TITLE_FILES_ERROR';

// ******************** PRODUCTS ********************
export const FETCH_PACKET_PRODUCTS_LOADING = 'FETCH_PACKET_PRODUCTS_LOADING';
export const FETCH_PACKET_PRODUCTS_SUCCESS = 'FETCH_PACKET_PRODUCTS_SUCCESS';
export const FETCH_PACKET_PRODUCTS_ERROR = 'FETCH_PACKET_PRODUCTS_ERROR';
// ******************** MATERIALS ********************
// -------------------- FETCH --------------------
export const FETCH_PACKET_MATERIALS_LOADING = 'FETCH_PACKET_MATERIALS_LOADING';
export const FETCH_PACKET_MATERIALS_SUCCESS = 'FETCH_PACKET_MATERIALS_SUCCESS';
export const FETCH_PACKET_MATERIALS_ERROR = 'FETCH_PACKET_MATERIALS_ERROR';
// -------------------- UPDATE --------------------
export const UPDATE_PACKET_MATERIALS = 'UPDATE_PACKET_MATERIALS';
export const UPDATE_PACKET_MATERIALS_ERROR = 'UPDATE_PACKET_MATERIALS_ERROR';
// -------------------- DELETE --------------------
export const DELETE_PACKET_MATERIALS = 'DELETE_PACKET_MATERIALS';
export const DELETE_PACKET_MATERIALS_ERROR = 'DELETE_PACKET_MATERIALS_ERROR';
// ******************** PRICES ********************
// -------------------- FETCH --------------------
export const FETCH_PACKET_PRICES_LOADING = 'FETCH_PACKET_PRICES_LOADING';
export const FETCH_PACKET_PRICES_SUCCESS = 'FETCH_PACKET_PRICES_SUCCESS';
export const FETCH_PACKET_PRICES_ERROR = 'FETCH_PACKET_PRICES_ERROR';
// -------------------- DELETE --------------------
export const DELETE_PACKET_PRICE_LOADING = 'DELETE_PACKET_PRICE_LOADING';
export const DELETE_PACKET_PRICE_SUCCESS = 'DELETE_PACKET_PRICE_SUCCESS';
export const DELETE_PACKET_PRICE_ERROR = 'DELETE_PACKET_PRICE_ERROR';
// ******************** AVAILABILITY ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_PACKET_AVAILABILITY_TABLE_LOADING =
	'FETCH_PACKET_AVAILABILITY_TABLE_LOADING';
export const FETCH_PACKET_AVAILABILITY_TABLE_SUCCESS =
	'FETCH_PACKET_AVAILABILITY_TABLE_SUCCESS';
export const FETCH_PACKET_AVAILABILITY_TABLE_ERROR =
	'FETCH_PACKET_AVAILABILITY_TABLE_ERROR';
// -------------------- DELETE --------------------
export const DELETE_PACKET_AVAILABILITY_LOADING =
	'DELETE_PACKET_AVAILABILITY_LOADING';
export const DELETE_PACKET_AVAILABILITY_SUCCESS =
	'DELETE_PACKET_AVAILABILITY_SUCCESS';
export const DELETE_PACKET_AVAILABILITY_ERROR =
	'DELETE_PACKET_AVAILABILITY_ERROR';
// -------------------- FORM --------------------
// -------------------- FETCH --------------------
export const FETCH_PACKET_AVAILABILITY_FORM_LOADING =
	'FETCH_PACKET_AVAILABILITY_FORM_LOADING';
export const FETCH_PACKET_AVAILABILITY_FORM_SUCCESS =
	'FETCH_PACKET_AVAILABILITY_FORM_SUCCESS';
export const FETCH_PACKET_AVAILABILITY_FORM_ERROR =
	'FETCH_PACKET_AVAILABILITY_FORM_ERROR';
// ******************** PACKETS ASSIGNED ********************
// -------------------- FETCH PACKETS ASSIGNED - TABLE DATA --------------------
export const FETCH_COLLECTION_PACKETS_ASSIGNED_LOADING =
	'FETCH_VOD_PACKETS_ASSIGNED_LOADING';
export const FETCH_COLLECTION_PACKETS_ASSIGNED_SUCCESS =
	'FETCH_VOD_PACKETS_ASSIGNED_SUCCESS';
export const FETCH_COLLECTION_PACKETS_ASSIGNED_ERROR =
	'FETCH_VOD_PACKETS_COLLECTION_ASSIGNED_ERROR';
export const FETCH_COLLECTION_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR =
	'FETCH_VOD_PACKETS_ASSIGNED_PAYMENT_MODEL_TYPE_ERROR';

// -------------------- SET SEARCH QUERY --------------------
export const SET_COLLECTION_PACKETS_ASSIGNED_SEARCH_QUERY =
	'SET_VOD_PACKETS_ASSIGNED_SEARCH_QUERY';
