import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	size,
	productTextColor,
	productBackgroundColor,
	customMinResponsive,
	customResponsive
} from '../../../styles';

export const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	${productBackgroundColor}
`;

export const Figure = styled.figure`
	${flex()}
	flex-direction: column;
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	border-radius: 5px;

	${({ isFullscreen }) =>
		isFullscreen &&
		css`
			&,
			img {
				border-radius: 0;
			}
		`}
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	border-radius: 5px;
	cursor: ${({ cursor }) => cursor};
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};

	${({ isFullscreen }) =>
		isFullscreen &&
		customResponsive(
			size.medium,
			css`
				object-fit: contain;
			`
		)}
`;

export const Informations = styled.div`
	width: 100%;
	height: 3rem;
	font-size: ${({ isFullscreen }) => (isFullscreen ? '1.6rem' : '1.2rem')};

	${productTextColor}
	${productBackgroundColor}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ isFullscreen }) => (isFullscreen ? '2.4rem' : '1.8rem')};
			height: 8rem;
		`
	)}

	${({ isFullscreen, theme }) =>
		isFullscreen &&
		css`
			font-weight: ${theme.font.normal};
			padding: 0 2rem;
		`}
`;

export const Content = styled.div`
	${flex({ jc: 'space-between', ai: 'flex-start' })}
	flex-wrap: wrap;
`;

export const InfoBox = styled.div`
	${flex()}
`;

export const Title = styled.p`
	text-align: right;
`;

export const Author = styled.p`
	opacity: ${({ isLightBackground }) => (isLightBackground ? 0.5 : 1)};
	font-size: ${({ isFullscreen }) => (isFullscreen ? '1.6rem' : '1.2rem')};
	${flex()}

	${({ isFullscreen, theme }) =>
		isFullscreen &&
		css`
			font-weight: ${theme.font.medium};
			height: ${({ isFullscreen }) => (isFullscreen ? '2.5rem' : 'unset')};
		`};

	${customMinResponsive(
		size.medium,
		css`
			font-size: 1.8rem;
			height: ${({ isFullscreen }) => (isFullscreen ? '3.5rem' : 'unset')};
		`
	)}
`;

export const Divider = styled.span`
	margin: 0 4px;
`;

export const FullscreenWrapper = styled.section`
	height: 100vh;
	width: 100vw;
	padding: 2.8rem 1.6rem;

	${customMinResponsive(
		size.medium,
		css`
			padding: 6.7rem 16.3rem;
		`
	)};
`;
