import i18n from 'i18next';

export const BASE_FIELDS = ({ limitTypeChange, currentValues }) => [
	{
		name: 'limit_type',
		label: i18n.t('global_limit:form:fields.limit_type'),
		type: 'select',
		requiredField: true,
		onChangeCallback: limitTypeChange
	},
	{
		name: 'limit_size',
		label: i18n.t('global_limit:form:fields.limit_size'),
		type: 'number',
		requiredField: true,
		disabled: !currentValues.limit_type
	}
];
