import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchOperatorRoles,
	fetchOperator,
	fetchOperatorInstitutions
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import helpers
import { convertInstitutionsToValuePairs } from 'store/actions/helpers_actions/convert_helpers';

const OperatorsForm = ({
	history,
	fetchOperatorRoles,
	fetchOperator,
	error,
	isLoaded,
	initialValues,
	roles,
	fetchOperatorInstitutions,
	institutions
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { products_group_uuid } = values;

		if (!products_group_uuid) {
			// set products_group_uuid value to null
			values.products_group_uuid = null;
		}

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'operator',
			redirectPath: 'operators'
		});
	};

	// get institution name based on institution id
	const institutionsNames = convertInstitutionsToValuePairs(institutions);

	const formInitialValues = {
		roles: [],
		...initialValues
	};

	return (
		// Dispatch fetchOperatorRoles, fetchOperator actions in in operators_form
		<FormTemplate
			fetchFormResources={[fetchOperatorRoles, fetchOperatorInstitutions]}
			fetchResourceToEdit={fetchOperator}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, decorators }) => (
				<Form
					initialValues={formInitialValues}
					validate={(values) => validate({ ...values, isEdit })}
					decorators={decorators}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								currentValues={values}
								roles={roles}
								institutions={institutionsNames}
								submitting={submitting}
								isEdit={isEdit}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="operators"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

OperatorsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchOperatorRoles: PropTypes.func.isRequired,
	fetchOperator: PropTypes.func.isRequired,
	roles: PropTypes.array.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	fetchOperatorInstitutions: PropTypes.func.isRequired,
	institutions: PropTypes.array.isRequired
};

const mapStateToProps = ({ operators: { form } }) => {
	return {
		initialValues: form.edit,
		roles: form.roles,
		error: form.error,
		isLoaded: form.isLoaded,
		institutions: form.institutions
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchOperatorRoles,
		fetchOperator,
		fetchOperatorInstitutions
	}),
	withRouter
)(OperatorsForm);
