import i18n from 'i18next';

// Import helpers
import { checkDecimalPlaces, isExternalProvider } from '../helpers';

export default ({
	range,
	base_amount,
	period,
	period_unit,
	payment_method,
	external_id
}) => {
	const errors = {};

	// ------------------ Range ------------------
	if (!range || !range.length) {
		errors.range = i18n.t('prices:validation.range_required');
	}
	// ------------------ Price netto ------------------
	if (isNaN(base_amount)) {
		errors.base_amount = i18n.t('prices:validation.price_required');
	} else if (base_amount <= 0) {
		errors.base_amount = i18n.t('prices:validation.price_length');
	} else if (checkDecimalPlaces(base_amount) >= 3) {
		errors.base_amount = i18n.t('prices:validation.price_step');
	}

	// ------------------ Period ------------------
	if (!period) {
		errors.period = i18n.t('prices:validation.period_required');
	}

	// ------------------ Period unit ------------------
	if (!period_unit) {
		errors.period_unit = i18n.t('prices:validation.periodUnit_required');
	}

	// ------------------ Payment method ------------------
	if (!payment_method) {
		errors.payment_method = i18n.t('prices:validation.payment_method_required');
	}

	// ------------------ Provider Product ID ------------------
	if (!external_id && isExternalProvider(payment_method)) {
		errors.external_id = i18n.t('prices:validation.external_id_required');
	}

	return errors;
};
