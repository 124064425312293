import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Import translation
import { useTranslation } from 'react-i18next';

// Import actions
import * as actions from 'store/actions';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import helpers
import { convertFormData } from './form_helpers/index';

// Import components
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const ConfigurationForm = ({ history, list, isLoaded }) => {
	const handleOnSubmit = async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			itemID: '',
			isEdit: true,
			resources: convertFormData(values),
			api: 'settings',
			redirect: false,
			redirectToEdit: false
		});

	const { t } = useTranslation();
	return (
		<Form
			initialValues={list}
			onSubmit={handleOnSubmit}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Fields submitting={submitting} list={list} isLoaded={isLoaded} />

					{isLoaded && (
						<FormButtons
							cancelButton={false}
							isButtonDisabled={submitting}
							path="configuration"
							buttonText={t('common:buttons.submit')}
						/>
					)}
				</form>
			)}
		/>
	);
};

ConfigurationForm.propTypes = {
	history: PropTypes.object.isRequired
};

const mapStateToProps = ({ configuration: { form } }) => {
	return {
		list: form.list.data,
		isLoaded: form.list.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, actions),
	withRouter
)(ConfigurationForm);
