import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';

// Import helrpes
import { tagsTabs } from './helpers/index';

const TagsTabs = () => {
	return <Tabs tabs={tagsTabs} />;
};

export default TagsTabs;
