import React from 'react';
import {
	string,
	number,
	object,
	func,
	oneOfType,
	node,
	arrayOf,
	shape,
	bool
} from 'prop-types';
import { Upload, Button, Icon } from 'antd';
import { Col, FormGroup, Label } from 'reactstrap';

// Import translation
import { useTranslation } from 'react-i18next';

// Import hooks
import useInputFileLabel from './useInputFileLabel';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

const InputFileLabel = ({
	children,
	input: { value, onChange, ...input },
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	meta: { touched, error, submitError, submitSucceeded },
	requiredField = false,
	...inputProps
}) => {
	const { t } = useTranslation();
	const { uploadProps } = useInputFileLabel({ onChange, submitSucceeded });

	return (
		<FormGroup
			data-testid={`form-group-${input.name}`}
			row
			className={`${touched && error && 'has-error'}`}
		>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label} {requiredField && REQUIRED_MARK}
			</Label>
			<Col sm={inputColumn}>
				<Upload {...uploadProps} {...inputProps}>
					<Button>
						<Icon type="upload" /> {t('common:form.file')}
					</Button>
				</Upload>
				{touched && (error || submitError) && (
					<span
						data-testid={`input-error-${input.name}`}
						className="input-error d-block"
					>
						{error || submitError}
					</span>
				)}
			</Col>
			{children}
		</FormGroup>
	);
};

InputFileLabel.propTypes = {
	input: shape({
		value: oneOfType([string, object]).isRequired,
		onChange: func.isRequired
	}),
	label: string.isRequired,
	id: string.isRequired,
	labelColumn: number,
	inputColumn: number,
	children: oneOfType([node, arrayOf(node)]),
	meta: shape({
		touched: bool.isRequired,
		error: string,
		submitError: bool,
		submitSucceeded: bool
	}),
	requiredField: bool
};

export default InputFileLabel;
