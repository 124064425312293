import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';

const useFields = ({ geoblockGroups, countries }) => {
	const { change } = useForm();
	const { submitting, values } = useFormState();

	const selectResources = {
		geoblock_groups: geoblockGroups,
		countries
	};

	// add all the countries that the group contains as 'countries' field values
	const handleGeoblockGroupsChange = (idsArray) => {
		const newCountries = geoblockGroups
			.filter(({ value }) => idsArray.some((id) => id === value))
			.map(({ countries }) => countries)
			.flat();

		// remove duplicates
		const newValue = [...new Set(newCountries)];

		change('countries', newValue);
	};

	// remove group from 'geoblock_groups' field values if user deletes all the countries the group contains
	const handleCountriesChange = () => {
		const { geoblock_groups, countries } = values;

		geoblockGroups
			.filter(({ value }) => geoblock_groups.some((id) => id === value))
			.forEach((group) => {
				const isCountryRemoved = !group.countries.every((code) =>
					countries.includes(code)
				);

				if (isCountryRemoved) {
					const newValue = geoblock_groups.filter((id) => id !== group.id);
					change('geoblock_groups', newValue);
				}
			});
	};

	useEffect(() => {
		handleCountriesChange();
		// eslint-disable-next-line
	}, [values.countries]);

	return {
		selectResources,
		submitting,
		geoblockGroupsChange: handleGeoblockGroupsChange
	};
};

useFields.propTypes = {
	geoblockGroups: PropTypes.array.isRequired,
	countries: PropTypes.array.isRequired
};

export default useFields;
