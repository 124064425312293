import styled, { css } from 'styled-components';

// Import styles
import { transitionMain } from '../../styles';

export const ArrowController = styled.button`
	position: absolute;
	bottom: 0;
	z-index: 10;
	height: 100%;
	width: max-content;
	display: flex !important;

	&:hover,
	&:focus {
		cursor: pointer;

		svg {
			path {
				fill: ${({ theme }) => theme.primaryBlueLight};
			}
		}
	}

	&.slick-disabled,
	&::before {
		display: none !important;
	}

	svg {
		display: block;
		height: ${({ slideHeight }) => slideHeight}px;
		width: 40px;

		path {
			${transitionMain}
		}
	}

	${({ isPrevArrow }) =>
		isPrevArrow
			? css`
					left: 0;
					svg {
						margin-left: 1rem;
					}
			  `
			: css`
					right: 0;
					svg {
						margin-right: 1rem;
					}
			  `};
`;
