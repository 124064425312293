import i18n from 'i18next';

export const INSTITUTIONS_FILTER_FIELDS = [
	{
		name: 'name',
		label: i18n.t('filters:fields.name'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'slug',
		label: i18n.t('filters:fields.slug'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'contact',
		label: i18n.t('filters:fields.contact'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
