import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import select options
import { select_types } from './select_type_options';

// Import hooks
import useFields from './useFields';

const Fields = ({ submitting, currentValues, limits }) => {
	const { limitTypeChange } = useFields(limits);

	const selectResources = {
		limit_type: select_types
	};

	return BASE_FIELDS({ limitTypeChange, currentValues }).map((item, index) =>
		renderFields({ item, index, submitting, selectResources })
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool,
	limits: PropTypes.object.isRequired,
	currentValues: PropTypes.object.isRequired
};

export default Fields;
