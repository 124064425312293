// Import translation
import i18n from 'i18next';

export default ({ metadata }) => {
	const errors = { metadata: {} };

	// ------------------ title ------------------
	if (!metadata?.title) {
		errors.metadata.title = i18n.t('banners:validation.title');
	}

	return errors;
};
