import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchEventLive,
	fetchCategories,
	fetchInstitutionsList,
	fetchTagsList
} from 'store/actions';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import InfoTitle from 'components/utilities/info/InfoTitle';

// Import helpers
import {
	vodCategoriesOptions,
	convertDataToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';
import {
	getInstitutionName,
	convertInstitutionsToValuePairs
} from 'components/helpers/institutions_helpers/helpers';
import { convertDurationTime } from 'components/helpers/convert';

// Import variables
import { DURATION_CONVERT_TYPES } from 'components/helpers/variables';

const { HMS_TO_SECONDS, SECONDS_TO_HMS } = DURATION_CONVERT_TYPES;

const EventsLiveForm = ({
	history,
	fetchEventLive,
	fetchCategories,
	fetchInstitutionsList,
	error,
	isLoaded,
	initialValues,
	genresList = [],
	paymentsModelsList,
	institutionsList,
	fetchTagsList,
	tagsList
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		// Fetch categories with default params to feed genres combobox
		fetchCategories(vodCategoriesOptions, null, null);
		// Fetch tags list
		fetchTagsList();
		// eslint-disable-next-line
	}, []);

	const { genres, data, products_group_uuid, tags, rating } = initialValues;

	const { actors, writers, directors, producers, duration } = data;

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { genres, data, sub_category, tags, rating } = values;

		// selected genres mapped to array of ids
		const genres_ids = convertToIds(genresList, genres);

		// selected tags mapped to array of ids
		const tagsId = convertToIds(tagsList, tags);

		// convert duration from sec to min
		const durationInSeconds = convertDurationTime({
			duration: data.duration,
			type: HMS_TO_SECONDS
		});

		const resources = {
			...values,
			genres_ids,
			data: {
				...data,
				duration: durationInSeconds
			},
			sub_category: sub_category,
			tags_ids: tagsId,
			rating: parseInt(rating, 10)
		};

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'products/liveevents',
			redirectPath: 'events_live'
		});
	};

	// array of genres names that will be passed to multi-select
	// both initial values and data for combobox
	const genresNames = convertDataToNames(genres);
	const genresListNames = convertDataToNames(genresList);

	// array of tags names that will be passed to multi-select
	// both initial values and data for combobox
	const tagsNames = convertDataToNames(tags || []);
	const tagsListNames = convertDataToNames(tagsList);

	// convert duration from sec to hms
	const durationInHms = convertDurationTime({
		duration,
		type: SECONDS_TO_HMS
	});

	// get institution name based on institution id
	const institutionsNames = convertInstitutionsToValuePairs(institutionsList);
	// if we have products_group_uuid find institution on list,
	// otherwise select first institution from list
	const productGroupName = products_group_uuid
		? getInstitutionName(products_group_uuid, institutionsList)
		: institutionsNames[0];

	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		data: {
			...defaultFieldValues.data,
			...data,
			duration: durationInHms,
			short_summary: '',
			title_org: '',
			sub_summary: ''
		},
		genres: genresNames,
		products_group_uuid: productGroupName?.value,
		tags: tagsNames,
		rating: rating ? '' + rating : '0', //fast way to convert value to string
		additional_data: {
			...defaultFieldValues.additional_data,
			...initialValues.additional_data
		},
		additional_metadata: {
			...defaultFieldValues.additional_metadata,
			...initialValues.additional_metadata
		}
	};

	return (
		// Dispatch fetchEventLive actions in in events_live_form
		<FormTemplate
			fetchFormResources={[fetchInstitutionsList]}
			fetchResourceToEdit={fetchEventLive}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<>
					<InfoTitle
						title={t('common:info.title_required_fields')}
						className="mb-5"
					/>
					<Form
						initialValues={formInitialValues}
						validate={validate}
						decorators={decorators}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({
							handleSubmit,
							submitting,
							values,
							form: { change }
						}) => (
							<form onSubmit={handleSubmit}>
								<Fields
									currentValues={values}
									submitting={submitting}
									changeFieldValue={change}
									isEdit={isEdit}
									checkedPermissions={checkedPermissions}
									genres={genresListNames}
									actors={actors || []}
									writers={writers || []}
									directors={directors || []}
									producers={producers || []}
									paymentsModelsList={paymentsModelsList}
									institutions={institutionsNames}
									tags={tagsListNames}
								/>

								<FormButtons
									isButtonDisabled={submitting || error}
									path="events_live"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				</>
			)}
		</FormTemplate>
	);
};

EventsLiveForm.propTypes = {
	history: PropTypes.object.isRequired,
	fetchEventLive: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	initialValues: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	genresList: PropTypes.array.isRequired,
	paymentsModelsList: PropTypes.array.isRequired,
	institutionsList: PropTypes.array.isRequired,
	fetchInstitutionsList: PropTypes.func.isRequired,
	fetchTagsList: PropTypes.func.isRequired
};

const mapStateToProps = ({
	events_live: { form },
	categories: {
		table: { data }
	},
	dictionary
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		genresList: data,
		paymentsModelsList: dictionary.paymentModels,
		institutionsList: dictionary.institutionsList,
		tagsList: dictionary.tagsList
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchEventLive,
		fetchCategories,
		fetchInstitutionsList,
		fetchTagsList
	}),
	withRouter
)(EventsLiveForm);
