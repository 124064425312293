import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setIstitutionsQuery,
	fetchInstitutions,
	createFiltersSuggestions
} from 'store/actions';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './institutions_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Institutions = ({
	error,
	setIstitutionsQuery,
	fetchInstitutions,
	createFiltersSuggestions,
	filters,
	resources
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	// execute filters logic
	useFilterLogic({
		createFiltersSuggestions,
		filters
	});

	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="institutions"
			addLinkText={t('institutions:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchInstitutions}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setIstitutionsQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Institutions.propTypes = {
	error: PropTypes.bool.isRequired,
	setIstitutionsQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchInstitutions: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		error: state.institutions.table.error,
		resources: state.institutions.table,
		filters: {}
	};
};

export default connect(mapStateToProps, {
	setIstitutionsQuery,
	fetchInstitutions,
	createFiltersSuggestions
})(Institutions);
