import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import {
	BASE_FIELDS,
	AGREEMENTS_INAPPROPRIATE_CONTENT_FIELDS,
	PROMOTING_EUROPEAN_PRODUCTIONS_FIELDS
} from './input_fields';
import { AGREEMENTS_ADDITIONAL_FIELDS } from 'components/utilities/form/form_additional_data_agreements_fields/form_additional_data_agreements_input_fields';

// Import utilities
import { renderFields } from 'components/utilities/form/renderFields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import select options
import { select_event_live_types as type } from 'components/helpers/variables';
import { select_EU_promotion_types } from 'components/views/vod/helpers/common_select_type_options';

import {
	select_products_category as category,
	select_products_sub_category as sub_category
} from 'components/helpers/products_category_sub_category_variables';
import { rating_select_values } from 'components/helpers/common_select_data_variables';

// Import hooks
import useFields from './useFields';

const Fields = ({
	actors,
	writers,
	directors,
	currentValues,
	submitting,
	changeFieldValue,
	genres,
	paymentsModelsList,
	checkedPermissions,
	institutions,
	producers,
	isEdit,
	tags
}) => {
	const { t } = useTranslation();
	const selectResources = {
		type,
		genres,
		category,
		sub_category: sub_category[currentValues.category] || [],
		'data.actors': actors,
		'data.writers': writers,
		'data.directors': directors,
		'data.producers': producers,
		'additional_metadata.dramaturgy': [],
		'additional_metadata.libretto': [],
		'additional_metadata.choreography': [],
		'additional_metadata.lead': [],
		'additional_metadata.curatorial_care': [],
		'additional_metadata.conducting': [],
		'additional_metadata.composition': [],
		'additional_metadata.authors': [],
		'additional_data.promoted_product': select_EU_promotion_types || [],
		payment_models: paymentsModelsList,
		products_group_uuid: institutions,
		rating: rating_select_values,
		tags
	};

	const { isChatIdVisible, chatEnabledChange } = useFields();

	return (
		<React.Fragment>
			{BASE_FIELDS({
				isEdit,
				checkedPermissions,
				changeFieldValue,
				currentValues,
				isChatIdVisible,
				chatEnabledChange
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			<h3>{t('common:form:field_groups.additional_data_agreements')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{AGREEMENTS_ADDITIONAL_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			<h3>{t('common:form:field_groups.inappropriate_content')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{AGREEMENTS_INAPPROPRIATE_CONTENT_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
			<h3>{t('common:form:field_groups.promoting_european_productions')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{PROMOTING_EUROPEAN_PRODUCTIONS_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	currentValues: PropTypes.object,
	submitting: PropTypes.bool.isRequired,
	changeFieldValue: PropTypes.func.isRequired,
	isEdit: PropTypes.bool.isRequired,
	genres: PropTypes.array,
	actors: PropTypes.array,
	writers: PropTypes.array,
	directors: PropTypes.array,
	produced_by: PropTypes.array,
	paymentsModelsList: PropTypes.array,
	institutions: PropTypes.array.isRequired,
	tags: PropTypes.array
};

export default Fields;
