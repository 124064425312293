import React from 'react';

// Import translation
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('vod:seasons:table_columns.number'),
		accessor: 'number',
		width: 120
	},
	{
		Header: i18n.t('vod:seasons:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { uuid, title, subtype, is_audio }
			}
		}) => setFormLink({ uuid, type: subtype, title, is_audio })
	},
	{
		Header: i18n.t('vod:seasons:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('vod:seasons:table_columns.updated_at'),
		accessor: 'updated_at'
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(uuid)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
