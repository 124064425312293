import React from 'react';
import { oneOfType, arrayOf, node, string, bool, array } from 'prop-types';

// Import styles
import { Title, InfoElementContainer } from './styles';
import TextEllipsis from './TextEllipsis';

const InfoGroup = ({
	title,
	shouldEllipsis = false,
	tooltipText = '',
	children
}) => (
	<InfoElementContainer>
		<Title>{title}:</Title>
		<TextEllipsis
			shouldEllipsis={shouldEllipsis}
			tooltipText={tooltipText}
			isProductDetails
		>
			{children}
		</TextEllipsis>
	</InfoElementContainer>
);

InfoGroup.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	title: string.isRequired,
	shouldEllipsis: bool,
	tooltipText: oneOfType([string, array]).isRequired
};

export default InfoGroup;
