import React from 'react';
import { Trans } from 'react-i18next';

// Import components
import SignOut from './SignOut';
import ChangeLanguage from 'components/utilities/translation/ChangeLanguage';

const TopHeader = () => (
	<div className="row border-bottom">
		<nav
			className="navbar navbar-static-top"
			role="navigation"
			style={{ marginBottom: 0 }}
		>
			<img src="/images/logo.png" alt="Logo" style={{ height: 20 }} />
			<p className="mb-0 ml-3">
				<Trans
					i18nKey="header:project_name"
					values={{
						name: process.env.REACT_APP_PROJECT_NAME,
						env: process.env.REACT_APP_ENV,
						version: process.env.REACT_APP_VERSION
					}}
				/>
			</p>
			<div className="d-flex justify-content-end navbar-buttons">
				<ChangeLanguage />
				<SignOut />
			</div>
		</nav>
	</div>
);

export default TopHeader;
