import styled, { css } from 'styled-components';

// Import styles
import { flex, customMinResponsive, size } from '../../../styles';

export const BasicContainer = styled.div`
	${flex({ jc: 'start' })}
	flex-wrap: wrap;
	z-index: 2;
	margin-bottom: 2rem;

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: 3.3rem;
		`
	)}
`;

export const Divider = styled.span`
	display: inline-block;
	height: 1.2rem;
	width: 2px;
	border-radius: 4px;
	margin: 0 0.5rem;
	z-index: 2;
	opacity: ${({ opacity }) => opacity || 1};

	${customMinResponsive(
		size.medium,
		css`
			height: 1.6rem;
			margin: 0 1rem;
		`
	)}

	${({ theme, isLightBackground }) =>
		isLightBackground
			? css`
					background-color: ${theme.product.lightBackground.color};
			  `
			: css`
					background-color: ${theme.product.coverBackground.color};
			  `};
`;

export const Text = styled.span`
	display: inline-block;
	color: ${({ theme }) => theme.white};
	font-size: 1.2rem;
	line-height: 1.4rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: 1.75rem;
			line-height: 2.1rem;
		`
	)}
`;
