import React from 'react';
import { useSelector } from 'react-redux';
import Tabs from 'components/utilities/tabs/Tabs';

// Import helrpes
import { institutionsTabs } from './helpers';

const InstitutionsTabs = () => {
	const { languages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);

	return <Tabs tabs={institutionsTabs(languages)} />;
};

export default InstitutionsTabs;
