import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchMainPrices, deleteMainPrice } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from 'components/helpers/columns/prices';

// Import components
import { TableTemplate } from 'components/common/templates';

const MainPrices = ({
	error,
	fetchMainPrices,
	deleteMainPrice,
	resources,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	return (
		// Dispatch fetchMainPrices, deleteMainPrice action in main_prices
		<TableTemplate
			itemId={id}
			error={error}
			type={`main/${id}/price`}
			typeText="prices"
			addLinkText={t('prices:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchMainPrices}
			deleteItemAction={deleteMainPrice}
			notificationDeleteSuccessTxt={t('prices:notification.delete_success')}
			notificationDeleteErrorTxt={t('prices:notification.delete_error')}
		></TableTemplate>
	);
};

MainPrices.propTypes = {
	error: PropTypes.bool.isRequired,
	resources: PropTypes.object.isRequired,
	fetchMainPrices: PropTypes.func.isRequired,
	deleteMainPrice: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ main: { prices } }) => ({
	error: prices.error,
	resources: prices
});

export default compose(
	connect(mapStateToProps, { fetchMainPrices, deleteMainPrice }),
	withRouter
)(MainPrices);
