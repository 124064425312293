import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';
import {
	AGREEMENTS_ADDITIONAL_FIELDS_METADATA,
	AGREEMENTS_INAPPROPRIATE_CONTENT_FIELDS,
	PROMOTING_EUROPEAN_PRODUCTIONS_FIELDS
} from 'components/utilities/form/form_additional_data_agreements_fields/form_additional_data_agreements_input_fields';

// Import utilities
import { renderFields } from 'components/utilities/form/renderFields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import select options
import {
	select_vod_subtypes as subtype,
	select_is_audio
} from './select_type_options';
import { select_EU_promotion_types } from 'components/views/vod/helpers/common_select_type_options';

import {
	select_products_category as category,
	select_products_sub_category as sub_category
} from 'components/helpers/products_category_sub_category_variables';
import { rating_select_values } from 'components/helpers/common_select_data_variables';

const Fields = ({
	currentValues,
	changeFieldValue,
	submitting,
	genres,
	paymentsModelsList,
	checkedPermissions,
	institutions,
	isEdit,
	tags
}) => {
	const { t } = useTranslation();
	const selectResources = {
		'metadata.actors': [],
		'metadata.writers': [],
		'metadata.directors': [],
		'metadata.rating': rating_select_values,
		'metadata.producers': [],
		'metadata.category': category,
		'metadata.sub_category':
			sub_category[currentValues.metadata.category] || [],
		'metadata.additional_metadata.dramaturgy': [],
		'metadata.additional_metadata.libretto': [],
		'metadata.additional_metadata.choreography': [],
		'metadata.additional_metadata.lead': [],
		'metadata.additional_metadata.curatorial_care': [],
		'metadata.additional_metadata.conducting': [],
		'metadata.additional_metadata.composition': [],
		'metadata.additional_metadata.authors': [],
		'metadata.additional_data.promoted_product':
			select_EU_promotion_types || [],
		subtype,
		genres,
		payment_models: paymentsModelsList,
		products_group_uuid: institutions,
		is_audio: select_is_audio,
		tags
	};

	return (
		<React.Fragment>
			<div className="hr-line-solid">&nbsp;</div>
			{BASE_FIELDS({
				isEdit,
				checkedPermissions,
				currentValues,
				changeFieldValue
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			<h3>{t('common:form:field_groups.additional_data_agreements')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{AGREEMENTS_ADDITIONAL_FIELDS_METADATA.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			<h3>{t('common:form:field_groups.inappropriate_content')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{AGREEMENTS_INAPPROPRIATE_CONTENT_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
			<h3>{t('common:form:field_groups.promoting_european_productions')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{PROMOTING_EUROPEAN_PRODUCTIONS_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	genres: PropTypes.array,
	currentValues: PropTypes.object,
	submitting: PropTypes.bool.isRequired,
	changeFieldValue: PropTypes.func.isRequired,
	paymentsModelsList: PropTypes.array,
	checkedPermissions: PropTypes.object.isRequired,
	institutions: PropTypes.array.isRequired,
	isEdit: PropTypes.bool.isRequired,
	tags: PropTypes.array.isRequired
};

export default Fields;
