import i18next from 'i18next';

export const createMaterialOptions = (values, assetData) => {
	const { format, asset_url, drm_key, iv, platform } = values;

	// check if there is iv. If undefined set empty string
	const isIv = iv || '';

	const isAssetData = assetData
		? { ...assetData.params, iv: isIv }
		: { seed: '', keyId: '', iv: isIv };
	const params = JSON.stringify(isAssetData);

	const options = {
		format,
		url: asset_url,
		params,
		platform: mapDefaultValue(platform)
	};
	// only send drm_key if it's typed, if it's not edited do not send it
	if (drm_key) {
		options.drm_key = drm_key;
	}

	return options;
};

export const createInitialValues = (isEdit, assetData, assetsFormats) =>
	isEdit
		? {
				format: assetData.format,
				asset_url: assetData.src,
				drm_key: null,
				iv: assetData.params.iv,
				platform: assetData.platform || MATERIAL_PLATFORM_TYPES.default
		  }
		: {
				format: assetsFormats[0],
				asset_url: '',
				drm_key: null,
				iv: '',
				platform: MATERIAL_PLATFORM_TYPES.default
		  };

export const convertAssets = ({ assets, assetId, select_types }) => {
	// video asset resources that are being edited
	const assetData = assets.find((asset) => asset.id === assetId);

	// checking for avalible formats for form options
	// product assets formats
	const assetsFormats = select_types.map(({ name }) => name);
	// formats of product assets that are in the table
	const videoAssetsFormats = assets.map(({ format }) => format);

	return {
		assetData,
		assetsFormats,
		videoAssetsFormats
	};
};

// material platform types - default material platform value
//  is null and needs to be mapped to "DEFAULT"
export const MATERIAL_PLATFORM_TYPES = {
	default: i18next.t('common:default_material_platform')
};

const mapDefaultValue = (platform) =>
	platform === MATERIAL_PLATFORM_TYPES.default ? null : platform;
