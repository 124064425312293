import i18n from 'i18next';
import _ from 'lodash';

// Import helpers
import {
	convertValueToString,
	convertArrayToValuePairsByKeyNames
} from 'components/helpers/convert';

const NAME = 'name';
const ID = 'id';
const LABEL = 'label';

export const ELEMENT_TYPES = {
	SECTION: 'section',
	GENRE: 'genre',
	PAGE: 'page',
	LINK: 'link',
	EPG: 'epg'
};

export const FIELDS_NAMES = {
	LINK: 'link',
	MODEL_TYPE: 'model_type',
	MODEL_ID: 'model_id',
	NAME: 'name',
	ORDER: 'order',
	IS_MAIN_PAGE: 'is_main_page',
	ELEMENT_NAME: 'element_name',
	PLATFORM_GROUP: 'platform_group',
	ELEMENT_TYPE: 'element_type'
};

export const menuModelTypes = [
	{ name: i18n.t('menu:model_types.page'), value: ELEMENT_TYPES.PAGE },
	{ name: i18n.t('menu:model_types.link'), value: ELEMENT_TYPES.LINK },
	{ name: i18n.t('menu:model_types.epg'), value: ELEMENT_TYPES.EPG }
];

export const submenuModelTypes = [
	{ name: i18n.t('menu:model_types.link'), value: ELEMENT_TYPES.LINK },
	{ name: i18n.t('menu:model_types.section'), value: ELEMENT_TYPES.SECTION },
	{ name: i18n.t('menu:model_types.genre'), value: ELEMENT_TYPES.GENRE }
];

export const apiLinks = {
	section: '/section/list',
	genre: '/products/genres/list',
	page: '/section/group/list'
};

export const defaultFormValues = {
	model_id: null,
	model_type: null,
	parent_id: null,
	element_type: 'link',
	platform_group: 'web',
	active: true
};

export const convertFormValues = ({ values, parent_id }) => {
	const { element_type } = values;

	const resources = {};

	if (parent_id) {
		resources.parent_id = parseInt(parent_id);
	}

	if (element_type !== ELEMENT_TYPES.LINK) {
		// the backend does not accept an empty string
		resources.link = '_';
	}

	return { ...values, ...resources };
};

export const plaformGroups = [
	{ name: 'Smart TV', value: 'smart_tv' },
	{ name: 'Mobile', value: 'mobile' },
	{ name: 'Web', value: 'web' }
];

export const PLATFORM_TYPE = {
	smart_tv: 'STV',
	mobile: 'MOBILE',
	web: 'WEB'
};

const filterSectionGroup = ({ sectionGroup, platform }) => {
	const filteredPages = sectionGroup.filter(({ value }) =>
		value.includes(platform)
	);
	return _.uniqBy(filteredPages, 'value');
};

export const getModelIds = ({
	element_type,
	platform,
	sections,
	productsGenres,
	sectionGroup
}) => {
	switch (element_type) {
		case ELEMENT_TYPES.SECTION:
			return convertValueToString(sections);

		case ELEMENT_TYPES.GENRE:
			return convertValueToString(productsGenres);

		case ELEMENT_TYPES.PAGE:
			return filterSectionGroup({ sectionGroup, platform });

		default:
			return [];
	}
};

export const getConvertedResources = ({
	sectionsList,
	productsGenresList,
	sectionGroupList
}) => {
	const sections = convertArrayToValuePairsByKeyNames(
		sectionsList,
		NAME,
		ID,
		LABEL
	);
	const productsGenres = convertArrayToValuePairsByKeyNames(
		productsGenresList,
		NAME,
		ID
	);
	const sectionGroup = convertArrayToValuePairsByKeyNames(
		sectionGroupList,
		NAME,
		LABEL
	);

	return { sections, productsGenres, sectionGroup };
};
