import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Import components
import { Wraper } from 'components/common/layout';
import Loader from 'components/common/loaders/Loader';
import { UploadImage } from 'components/common/inputs';
import ImagePreview from 'components/utilities/images/ImagePreview';

// Import logic
import useAssetGroups from './useAssetGroups';

const AssetGroups = (props) => {
	const { t } = useTranslation();

	const {
		productId,
		isLoaded,
		error,
		linkText,
		redirectLink,
		mediaType,
		conditionalClassName,
		loadingClassName,
		hasAssets,
		assetGroupAssets,
		productSubtype,
		assetGroup,
		collection,
		collectionId,
		uploadSuccess,
		assetRemove
	} = useAssetGroups(props);

	return (
		<Wraper lg="12" error={error}>
			<div className="ibox">
				<div className="ibox-title align-items-center">
					<h5>{t('common:images.gallery')}</h5>
					{isLoaded && (
						<Link className="btn__link" to={redirectLink}>
							{linkText}
						</Link>
					)}
				</div>
				<div
					className={`ibox-content ${conditionalClassName} ${loadingClassName}`}
				>
					<Loader isLoaded={isLoaded}>
						<div className={`h-150 d-flex flex-wrap ${conditionalClassName}`}>
							{hasAssets &&
								assetGroupAssets.map(({ id, url }) => (
									<ImagePreview
										key={id}
										assetId={id}
										assetSource={url}
										mediaType={mediaType}
										productSubtype={productSubtype}
										productId={productId}
										collection={collection}
										assetRemove={assetRemove}
									/>
								))}
							{assetGroup && (
								<UploadImage
									collectionId={collectionId}
									mediaType={mediaType}
									modelId={productId}
									successCallback={uploadSuccess}
								/>
							)}
						</div>
					</Loader>
				</div>
			</div>
		</Wraper>
	);
};

AssetGroups.propTypes = {
	title: PropTypes.string,
	fetchResourcesAction: PropTypes.func.isRequired,
	mediaType: PropTypes.string.isRequired,
	storePathSelector: PropTypes.string.isRequired,
	darkBackground: PropTypes.bool,
	customId: PropTypes.string,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	})
};

export default withRouter(AssetGroups);
