const { useState, useEffect } = require('react');

const useInputFileLabel = ({ onChange, submitSucceeded }) => {
	const [uploadState, setUploadState] = useState([]);

	useEffect(() => {
		// To clear after succeeded of submit
		if (submitSucceeded) setUploadState([]);
	}, [submitSucceeded]);

	const uploadProps = {
		onRemove: () => {
			setUploadState([]);
			onChange([]);
			return { fileList: [] };
		},
		beforeUpload: (file) => {
			setUploadState([file]);
			onChange([file]);
			return false;
		},
		fileList: uploadState,
		maxCount: 1
	};

	return { uploadProps };
};

export default useInputFileLabel;
