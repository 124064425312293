import { combineReducers } from 'redux';

// Import reducers
import vod from './vod/vod_product_visibility_reducer';
import channel from './channel/channel_product_visibility_reducer';
import article from './article/article_product_visibility_reducer';
import event_live from './event_live/event_live_product_visibility_reducer';
import packet from './packet/packet_visibility_reducer';
import pass from './pass/pass_product_visibility_reducer';

export default combineReducers({
	vod,
	channel,
	article,
	event_live,
	packet,
	pass
});
