// Import translation
import i18n from 'i18next';

export default ({ limit_type }) => {
	const errors = {};

	// ------------------ LIMIT TYPE ------------------
	if (!limit_type) {
		errors.limit_type = i18n.t('global_limit:validation.limit_type_required');
	}

	return errors;
};
