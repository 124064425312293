import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { Select } from 'antd';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

const SelectLabel = ({
	input,
	id,
	label,
	labelColumn = 2,
	inputColumn = 10,
	children,
	meta: { touched, error, submitError },
	fieldInfo = null,
	onChangeCallback = () => {},
	requiredField = false,
	...inputProps
}) => {
	const handleOnChange = (event) => {
		input.onChange(event);
		onChangeCallback(event);
	};

	return (
		<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label} {requiredField && REQUIRED_MARK}
			</Label>
			<Col sm={inputColumn}>
				<Select
					invalid={touched && (error || submitError) ? true : false}
					type="select"
					style={{ width: '100%' }}
					className="m-b-sm"
					{...input}
					{...inputProps}
					onChange={handleOnChange}
				>
					{children}
				</Select>
				{touched && (error || submitError) && (
					<span className="input-error d-block">{error || submitError}</span>
				)}
				{fieldInfo && (
					<span className="input-info m-b-sm">
						<i className="fa fa-info-circle m-r-sm"></i>
						{fieldInfo}
					</span>
				)}
			</Col>
		</FormGroup>
	);
};

SelectLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	inputColumn: PropTypes.number,
	labelColumn: PropTypes.number,
	meta: PropTypes.object,
	requiredField: PropTypes.bool,
	onChangeCallback: PropTypes.func
};

export default SelectLabel;
