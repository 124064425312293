import i18n from 'i18next';

export const PAYMENT_METHODS_FIELDS = [
	// PRICE NETTO
	{
		// WE USE THIS FIELDS TO STORE OF PRICE NETTO
		name: 'base_amount',
		id: 'price',
		type: 'number',
		placeholder: i18n.t('prices:fields.base_amount'),
		label: i18n.t('prices:fields.base_amount'),
		required: true,
		min: 0,
		step: 0.01,
		requiredField: true,
		labelColumn: 3,
		inputColumn: 9
	},
	// PRICE BRUTTO
	{
		name: 'price',
		id: 'price',
		type: 'number',
		placeholder: i18n.t('prices:fields.price'),
		label: i18n.t('prices:fields.price'),
		required: true,
		disabled: true,
		min: 0,
		step: 0.01,
		requiredField: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'payment_method',
		id: 'payment_method',
		label: i18n.t('prices:fields.payment_method'),
		placeholder: i18n.t('prices:fields.payment_method'),
		type: 'select',
		mode: '',
		required: true,
		requiredField: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'external_id',
		type: 'text',
		placeholder: i18n.t('prices:fields.external_id'),
		label: i18n.t('prices:fields.external_id'),
		id: 'external_id',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];

export const getLimitFields = ({ limitLabel, showLimits }) => [
	{
		name: 'since_first_access',
		id: 'since_first_access',
		label: i18n.t('prices:fields.model'),
		placeholder: i18n.t('prices:fields.model'),
		type: 'select',
		mode: '',
		required: false,
		disabled: !showLimits,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'views_limit',
		type: 'number',
		placeholder: limitLabel,
		label: limitLabel,
		id: 'views_limit',
		min: 0,
		disabled: !showLimits,
		labelColumn: 3,
		inputColumn: 9
	}
];

export const PERIOD_FIELDS = [
	{
		name: 'period',
		id: 'period',
		type: 'number',
		placeholder: i18n.t('prices:fields.period'),
		label: i18n.t('prices:fields.period'),
		required: true,
		min: 1,
		requiredField: true,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'period_unit',
		id: 'period_unit',
		label: i18n.t('prices:fields.periodUnit'),
		placeholder: i18n.t('prices:fields.periodUnit'),
		type: 'select',
		mode: '',
		required: true,
		requiredField: true,
		labelColumn: 3,
		inputColumn: 9
	}
];

export const RANGE_FIELDS = [
	{
		name: 'range',
		label: i18n.t('availability:fields.range'),
		id: 'range',
		type: 'range',
		placeholder: [
			i18n.t('availability:fields.since'),
			i18n.t('availability:fields.till')
		],
		required: true,
		showTime: true,
		rangeFormat: 'YYYY-MM-DD HH:mm:ss',
		requiredField: true,
		labelColumn: 3,
		inputColumn: 9
	}
];
