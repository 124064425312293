import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setSectionsGroupsType } from 'store/actions';
import { Radio } from 'antd';

//  Import helpers
import { PLAYER_TYPES } from 'components/helpers/section_groups_helpers/helpers';

// Import translation
import { useTranslation } from 'react-i18next';

const SectionsGroupsFilters = ({ setSectionsGroupsType }) => {
	const { t } = useTranslation();

	const [playerType, setPlayerType] = useState(PLAYER_TYPES.all);

	const handlePlayerChange = ({ target: { value } }) => {
		// set value in state for Player Radio Group
		setPlayerType(value);
		// dispatch filter types action
		setSectionsGroupsType({
			systemType: 'all',
			playerType: value
		});
	};

	return (
		<div className="m-r-md">
			<p>{t('sections_groups:radio_buttons.select_type')}</p>
			<Radio.Group
				onChange={handlePlayerChange}
				value={playerType}
				className="m-b-md"
			>
				{Object.values(PLAYER_TYPES).map((type) => (
					<Radio key={type} value={type}>
						{t(`sections_groups:radio_buttons.${type.toLowerCase()}`)}
					</Radio>
				))}
			</Radio.Group>
		</div>
	);
};

export default connect(null, { setSectionsGroupsType })(SectionsGroupsFilters);
