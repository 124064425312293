import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'alias',
		type: 'text',
		placeholder: i18n.t('documents:fields.alias_placeholder'),
		label: i18n.t('documents:fields.alias_label'),
		id: 'alias',
		requiredField: true
	},
	{
		name: 'title',
		type: 'text',
		placeholder: i18n.t('documents:fields.title_placeholder'),
		label: i18n.t('documents:fields.title_label'),
		id: 'title',
		requiredField: true
	},
	{
		name: 'content',
		type: 'editor',
		id: 'content',
		requiredField: true
	}
];
