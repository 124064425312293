import React from 'react';
import { Link } from 'react-router-dom';
// Import translation
import i18n from 'i18next';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('packets:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { uuid, title }
			}
		}) => <Link to={`/panel/packets/edit/${uuid}/metadata`}>{title}</Link>
	},
	{
		Header: i18n.t('packets:table_columns.products_group_name'),
		accessor: 'products_group_name'
	},
	{
		Header: i18n.t('packets:table_columns.order'),
		accessor: 'order',
		width: 100
	},
	{
		Header: i18n.t('packets:table_columns.created_at'),
		accessor: 'created_at'
	},
	{
		Header: i18n.t('packets:table_columns.updated_at'),
		accessor: 'updated_at'
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(uuid)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
