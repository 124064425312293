// Import translation
import i18n from 'i18next';

// Packets types options for select type field in provisioning, main and collections
export const select_packet_types = [
	{
		name: 'PROVISION',
		value: 'provision'
	},
	{
		name: i18n.t('packets:packets_types.collection'),
		value: 'collection'
	},
	{
		name: 'MAIN',
		value: 'main'
	}
];
