import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import services from 'services/services';

// Import utilities
import { notificationHandler } from 'components/utilities/notifications';

const collection = 'gallery';

const useAssetGroups = ({
	fetchResourcesAction,
	mediaType,
	storePathSelector,
	customId = null,
	match: {
		params: { id }
	},
	productSubtype,
	isDarkBackground
}) => {
	const { t } = useTranslation();
	const collectionTypes = useSelector(
		({ dictionary }) => dictionary.assetsCollectionTypes
	);

	const collectionId = Object.keys(collectionTypes).find(
		(key) => collectionTypes[key] === collection
	);

	// get id from url or set custom id
	const productId = customId ? customId : id;

	const { data, isLoaded, error } = useSelector(
		(state) => state[storePathSelector].files.asset_group
	);

	const assetGroup = data?.find(({ id }) => id);

	const assetGroupId = assetGroup?.id;

	const assetGroupAssets = assetGroup?.assets;

	const hasAssets = assetGroupAssets?.length > 0;

	const linkText = assetGroup
		? t('asset_groups:buttons.edit')
		: t('asset_groups:buttons.add');

	const redirectBaseLink = `/panel/asset_groups/${mediaType}/${productSubtype}/${productId}`;

	const redirectLink = assetGroup
		? `${redirectBaseLink}/edit/${assetGroupId}/metadata`
		: `${redirectBaseLink}/create/metadata`;

	const conditionalClassName = isDarkBackground ? 'dark-background-logo' : '';

	const loadingClassName = !isLoaded ? 'sk-loading' : '';

	const handleFetchResources = () => fetchResourcesAction(productId);

	const handleNotification = ({ title, description, type }) => {
		notificationHandler(title, description, type, 8);
	};

	const handleUploadSuccess = async ({ id }) => {
		try {
			await services.put(`asset_groups/${assetGroupId}/asset/${id}`);

			handleNotification({
				title: t('asset_groups:notification.asset_add_success_title'),
				description: t('asset_groups:notification.asset_add_success'),
				type: 'success'
			});

			handleFetchResources();
		} catch (error) {
			handleNotification({
				title: t('common:error'),
				description: t('asset_groups:notification.asset_add_error'),
				type: 'error'
			});
		}
	};

	const handleRemoveSuccess = async ({ assetId }) => {
		handleNotification({
			title: t('asset_groups:notification.asset_remove_success_title'),
			description: t('asset_groups:notification.asset_remove_success'),
			type: 'success'
		});
		handleFetchResources();
		await services.delete(`/assets/${assetId}`);
	};

	const handleAssetRemove = async ({ assetId }) => {
		try {
			if (assetId) {
				const { data } = await services.delete(
					`/asset_groups/${assetGroupId}/asset/${assetId}`
				);

				data?.success && handleRemoveSuccess({ assetId });
			}
		} catch (error) {
			handleNotification({
				title: t('common:error'),
				description: t('asset_groups:notification.asset_remove_error'),
				type: 'error'
			});
		}
	};

	useEffect(() => {
		handleFetchResources();
		// eslint-disable-next-line
	}, []);

	return {
		productId,
		data,
		isLoaded,
		error,
		assetGroup,
		assetGroupId,
		linkText,
		redirectLink,
		mediaType,
		conditionalClassName,
		loadingClassName,
		hasAssets,
		assetGroupAssets,
		productSubtype,
		collection,
		collectionId,
		fetchResources: handleFetchResources,
		uploadSuccess: handleUploadSuccess,
		assetRemove: handleAssetRemove
	};
};

export default useAssetGroups;
