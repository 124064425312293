import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';
import { AGREEMENTS_ADDITIONAL_FIELDS } from 'components/utilities/form/form_additional_data_agreements_fields/form_additional_data_agreements_input_fields';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import select options
import {
	select_products_category as category,
	select_products_sub_category as sub_category
} from 'components/helpers/products_category_sub_category_variables';
import { rating_select_values } from 'components/helpers/common_select_data_variables';
import { select_articles_types } from './select_type_options';

const Fields = ({
	currentValues,
	submitting,
	changeFieldValue,
	isEdit,
	authors,
	genres,
	checkedPermissions,
	paymentsModelsList,
	institutions,
	tags
}) => {
	const { t } = useTranslation();

	const selectResources = {
		type: select_articles_types,
		genres,
		'data.authors': authors,
		category,
		sub_category: sub_category[currentValues.category] || [],
		payment_models: paymentsModelsList,
		products_group_uuid: institutions,
		tags,
		rating: rating_select_values
	};

	return (
		<React.Fragment>
			{BASE_FIELDS({
				isEdit,
				checkedPermissions,
				changeFieldValue,
				currentValues
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			<h3>{t('common:form:field_groups.additional_data_agreements')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{AGREEMENTS_ADDITIONAL_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</React.Fragment>
	);
};

Fields.propTypes = {
	currentValues: PropTypes.object,
	submitting: PropTypes.bool.isRequired,
	changeFieldValue: PropTypes.func.isRequired,
	isEdit: PropTypes.bool.isRequired,
	authors: PropTypes.array,
	genres: PropTypes.array,
	paymentsModelsList: PropTypes.array,
	institutions: PropTypes.array.isRequired,
	tags: PropTypes.array.isRequired
};

export default Fields;
