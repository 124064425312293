import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setMenuQuery,
	fetchMenu,
	deleteMenu,
	createFiltersSuggestions
} from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import columns
import { createColumns } from './menu_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import helpers
import { plaformGroups } from './menu_form/helpers';

const Menu = ({
	error,
	setMenuQuery,
	fetchMenu,
	deleteMenu,
	resources,
	createFiltersSuggestions,
	filters
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	const filtersMenu = { ...filters, platform_group: plaformGroups };

	// execute filters logic
	useFilterLogic({
		createFiltersSuggestions,
		filters: filtersMenu
	});

	return (
		// Dispatch fetchMenu, deleteMenu action in menu_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="menus"
			addLinkText={t('menu:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchMenu}
			deleteItemAction={deleteMenu}
			notificationDeleteSuccessTxt={t('menu:notification.delete_success')}
			notificationDeleteErrorTxt={t('menu:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setMenuQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Menu.propTypes = {
	error: PropTypes.bool.isRequired,
	setMenuQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchMenu: PropTypes.func.isRequired,
	deleteMenu: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
};

const mapStateToProps = ({ menu: { table } }) => {
	return {
		error: table.error,
		resources: table,
		filters: {}
	};
};

export default connect(mapStateToProps, {
	setMenuQuery,
	fetchMenu,
	deleteMenu,
	createFiltersSuggestions
})(Menu);
