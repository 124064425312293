import { SYSTEM_TYPES } from 'components/helpers/variables';

export const PLAYER_TYPES = {
	all: 'ALL',
	stv: 'STV',
	mobile: 'MOBILE',
	web: 'WEB'
};

export const SECTION_GROUPS_SYSTEM_TYPES = {
	all: 'all',
	...SYSTEM_TYPES
};
