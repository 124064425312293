import React from 'react';
import i18n from 'i18next';

// Import components
import EventsLiveForm from 'components/views/events_live/events_live_form/EventsLiveForm';
import EventsLiveImages from 'components/views/events_live/events_live_file/EventsLiveImages';
import EventsLiveMaterials from 'components/views/events_live/events_live_materials/EventsLiveMaterials';
import EventsLiveAvailability from 'components/views/events_live/events_live_availability/EventsLiveAvailability';
import EventsLivePrices from 'components/views/events_live/events_live_prices/EventsLivePrices';
import Payments from 'components/views/payments/Payments';
import EventsLivePacketsAssigned from 'components/views/events_live/events_live_packets_assigned/EventsLivePacketsAssigned';
import ProductVisibility from 'components/utilities/product_visibility/table/ProductVisibility';
import ProductMetricFormOther from 'components/utilities/product_metric/other_type_product/ProductMetricFormOther';
import ProductGeoblockForm from 'components/views/geoblock_groups/ProductGeoblockForm/ProductGeoblockForm';
import EventsLiveUploadMaterial from 'components/views/events_live/events_live_materials/EventsLiveUploadMaterial';
import ActivityProduct from 'components/views/activity_product/ActivityProduct';
import ProductGlobalLimit from 'components/utilities/product_global_limit/ProductGlobalLimit';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { EVENTS_LIVE_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { eventsLiveTranslationValidate } from 'components/utilities/content_translation/validation';

// Import variables
import {
	GLOBAL_LIMIT_PRODUCT_TYPES,
	GLOBAL_PRODUCT_TYPES
} from 'components/helpers/variables';
import { METRIC_PRODUCT_TYPES } from 'components/utilities/product_metric/helpers/helpers';

const { EVENT_LIVE } = GLOBAL_PRODUCT_TYPES;

const eventsLiveBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <EventsLiveForm />
	},
	{
		name: i18n.t('common:tabs.metric'),
		path: 'metric',
		disableOnCreate: true,
		component: (
			<ProductMetricFormOther
				productTypeData={METRIC_PRODUCT_TYPES.EVENT_LIVE}
			/>
		)
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <EventsLiveImages />
	},
	{
		name: i18n.t('common:tabs.upload_materials'),
		path: 'upload_materials',
		disableOnCreate: true,
		component: <EventsLiveUploadMaterial />,
		isDisabled: !(process.env.REACT_APP_UPLOAD_MATERIALS_TAB === 'true')
	},
	{
		name: i18n.t('common:tabs.materials'),
		path: 'materials',
		disableOnCreate: true,
		component: <EventsLiveMaterials />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <EventsLiveAvailability />
	},
	{
		name: i18n.t('common:tabs.visibility'),
		path: 'visibility',
		disableOnCreate: true,
		component: <ProductVisibility productType={EVENT_LIVE} />
	},
	{
		name: i18n.t('common:tabs.prices'),
		path: 'prices',
		disableOnCreate: true,
		component: <EventsLivePrices />
	},
	{
		name: i18n.t('common:tabs.payments'),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.packets_assigned'),
		path: 'packets_assigned',
		disableOnCreate: true,
		component: <EventsLivePacketsAssigned />
	},
	{
		name: i18n.t('common:tabs.geoblock'),
		path: 'geoblock',
		disableOnCreate: true,
		component: <ProductGeoblockForm />
	},
	{
		name: i18n.t('common:tabs.activity'),
		path: 'activity_product',
		disableOnCreate: true,
		component: <ActivityProduct />
	},
	{
		name: i18n.t('common:tabs.global_limit'),
		path: 'global_limit',
		disableOnCreate: true,
		component: (
			<ProductGlobalLimit productType={GLOBAL_LIMIT_PRODUCT_TYPES.LIVE_EVENT} />
		)
	}
];

export const eventsLiveTabs = (contentTranslationLanguages) => [
	...eventsLiveBasicTabs,
	...generateContentTranslationsTabs(
		EVENTS_LIVE_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		eventsLiveTranslationValidate
	)
];
