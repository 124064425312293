import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	transitionMain,
	customMinResponsive,
	size
} from '../../../styles';

// Import icons
import { ReactComponent as VodHeartIcon } from '../../../assets/icons/vod_heart.svg';
import { ReactComponent as IconReminderSVG } from '../../../assets/icons/ic_reminder.svg';
import { ReactComponent as IconPlay } from '../../../assets/icons/vod_play.svg';

const iconsCommonStyles = css`
	margin-right: 8px;
	height: 100%;
`;

const btnMediumResponsive = css`
	font-size: 1.9rem;
	padding: 0 2.8rem;

	${({ width }) =>
		width &&
		css`
			width: ${width}px;
			padding: 0;
		`}

	${({ loading }) =>
		loading === 1 &&
		css`
			&:not(button) {
				flex: 1;
			}
		`}
`;

export const buttonsCommonStyles = css`
	font-size: 1.6rem;
	color: ${({ theme }) => theme.black};
	background-color: ${({ theme }) => theme.product.buttons.secondary};
	height: 4rem;
	border: 2px solid ${({ theme }) => theme.product.buttons.secondary};
	border-radius: 6px;
	cursor: pointer;
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 1.6rem;
	padding: 0 0.5rem;
	position: relative;
	${flex()}
	${transitionMain}

	${({ prime }) =>
		prime === 1 &&
		css`
			background-color: ${({ theme }) => theme.product.buttons.primary};
			border-color: ${({ theme }) => theme.product.buttons.primary};
		`}

	&:disabled {
		cursor: not-allowed;
		color: ${({ theme }) => theme.black};
	}

	&:hover {
		outline: none;
		border-color: ${({ theme }) => theme.primaryBlueLight};
		color: ${({ theme }) => theme.black};
		box-shadow: 0 0 4px ${({ theme }) => theme.primaryBlueLight};
	}

	&:focus-visible {
		outline: none;
		border-color: ${({ theme }) => theme.primaryBlueLight};
		color: ${({ theme }) => theme.black};
		box-shadow: 0 0 4px ${({ theme }) => theme.primaryBlueLight};
		background-color: ${({ theme }) => theme.primaryBlueLight};
	}

	${customMinResponsive(size.medium, btnMediumResponsive)}
`;

export const VerticalBar = styled.span`
	margin: 0 0.7rem;
`;

export const StyledButton = styled.button`
	${buttonsCommonStyles}
`;

export const IconFavorite = styled(VodHeartIcon)`
	${iconsCommonStyles}
`;

export const IconReminder = styled(IconReminderSVG)`
	${iconsCommonStyles}
`;

export const PlayIcon = styled(IconPlay)`
	${iconsCommonStyles}
`;
