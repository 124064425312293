import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchArticle,
	fetchCategories,
	fetchInstitutionsList,
	fetchTagsList
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import InfoTitle from 'components/utilities/info/InfoTitle';

// Import helpers
import {
	vodCategoriesOptions,
	convertDataToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';
import {
	getInstitutionName,
	convertInstitutionsToValuePairs
} from 'components/helpers/institutions_helpers/helpers';

const ArticlesForm = ({
	history,
	fetchArticle,
	fetchCategories,
	fetchInstitutionsList,
	error,
	isLoaded,
	initialValues,
	genresList = [],
	paymentsModelsList,
	institutionsList,
	fetchTagsList,
	tagsList
}) => {
	useEffect(() => {
		// Fetch categories with default params to feed genres combobox
		fetchCategories(vodCategoriesOptions, null, null);
		// Fetch tags list
		fetchTagsList();
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	const {
		genres,
		data,
		products_group_uuid,
		tags,
		rating,
		category,
		additional_data
	} = initialValues;
	const { authors } = data;

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const {
			genres,
			sub_category,
			tags,
			rating,
			products_group_uuid,
			data
		} = values;
		const { title_org, content } = data;

		// selected genres mapped to array of ids
		const genresId = convertToIds(genresList, genres);

		// selected tags mapped to array of ids
		const tagsId = convertToIds(tagsList, tags);

		const resources = {
			...values,
			data: {
				...data,
				title_org: title_org || '',
				content: content || ''
			},
			products_group_uuid: products_group_uuid || null,
			genres_ids: genresId,
			sub_category: sub_category,
			tags_ids: tagsId,
			rating: parseInt(rating, 10)
		};

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'products/articles',
			redirectPath: 'articles'
		});
	};

	// array of genres names that will be passed to multi-select
	// both initial values and data for combobox
	const genresNames = convertDataToNames(genres);
	const genresListNames = convertDataToNames(genresList);

	// array of tags names that will be passed to multi-select
	// both initial values and data for combobox
	const tagsNames = convertDataToNames(tags || []);
	const tagsListNames = convertDataToNames(tagsList);

	// get institution name based on institution id
	const institutionsNames = convertInstitutionsToValuePairs(institutionsList);
	// if we have products_group_uuid find institution on list,
	// otherwise select first institution from list
	const productGroupName = products_group_uuid
		? getInstitutionName(products_group_uuid, institutionsList)
		: institutionsNames[0];

	// form initial values
	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		category: category || '',
		rating: rating ? '' + rating : '0', //fast way to convert value to string
		genres: genresNames,
		products_group_uuid: productGroupName?.value,
		tags: tagsNames,
		data: {
			...defaultFieldValues.data,
			...data
		},
		additional_data: {
			...defaultFieldValues.additional_data,
			...additional_data
		}
	};

	return (
		// Dispatch fetchArticle actions in in articles_form
		<FormTemplate
			fetchFormResources={[fetchInstitutionsList]}
			fetchResourceToEdit={fetchArticle}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<>
					<InfoTitle
						title={t('common:info.title_required_fields')}
						className="mb-5"
					/>
					<Form
						initialValues={formInitialValues}
						validate={validate}
						decorators={decorators}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({
							handleSubmit,
							submitting,
							values,
							form: { change }
						}) => (
							<form onSubmit={handleSubmit}>
								<Fields
									currentValues={values}
									submitting={submitting}
									changeFieldValue={change}
									isEdit={isEdit}
									authors={authors || []}
									genres={genresListNames}
									checkedPermissions={checkedPermissions}
									paymentsModelsList={paymentsModelsList}
									institutions={institutionsNames}
									tags={tagsListNames}
								/>

								<FormButtons
									isButtonDisabled={submitting || error}
									path="articles"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				</>
			)}
		</FormTemplate>
	);
};

ArticlesForm.propTypes = {
	history: PropTypes.object.isRequired,
	fetchArticle: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	initialValues: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	genresList: PropTypes.array.isRequired,
	paymentsModelsList: PropTypes.array.isRequired,
	institutionsList: PropTypes.array.isRequired,
	fetchInstitutionsList: PropTypes.func.isRequired,
	fetchTagsList: PropTypes.func.isRequired
};

const mapStateToProps = ({
	articles: { form },
	categories: {
		table: { data }
	},
	dictionary
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		genresList: data,
		paymentsModelsList: dictionary.paymentModels,
		institutionsList: dictionary.institutionsList,
		tagsList: dictionary.tagsList
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchArticle,
		fetchCategories,
		fetchInstitutionsList,
		fetchTagsList
	}),
	withRouter
)(ArticlesForm);
