import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { successCodeRegex } from './helpers';

const StatusCode = ({ statusCode }) => {
	const isSuccess = successCodeRegex.test(statusCode);

	return <div style={{ color: isSuccess ? 'green' : 'red' }}>{statusCode}</div>;
};

StatusCode.propTypes = {
	statusCode: PropTypes.string.isRequired
};

export default StatusCode;
