import React from 'react';
import { Link } from 'react-router-dom';
import { string, number, bool } from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

const SubtitlesHeader = ({
	productId,
	videoId,
	canEditVideo,
	subtitleLocationType
}) => {
	const { t } = useTranslation();

	return (
		<div className="d-flex align-items-center justify-content-between">
			<h5 style={{ fontSize: '14px', margin: 0 }}>
				{t('common:material_subtitles.table_title')}
			</h5>
			{canEditVideo && (
				<Link
					to={`/panel/${subtitleLocationType}/${productId}/materials/${videoId}/subtitles/create`}
					className={'ant-btn btn__link'}
				>
					<i className={`fa fa-plus`} />
				</Link>
			)}
		</div>
	);
};

SubtitlesHeader.propTypes = {
	productId: string.isRequired,
	videoId: number.isRequired,
	canEditVideo: bool.isRequired,
	subtitleLocationType: string.isRequired
};

export default SubtitlesHeader;
