// Import translation
import i18n from 'i18next';

const LINKS_CATEGORY = {
	products: i18n.t('nav:title.products'),
	users: i18n.t('nav:title.users'),
	analitics: i18n.t('nav:title.analitics'),
	content: i18n.t('nav:title.content'),
	ingester: i18n.t('nav:title.ingester'),
	administration: i18n.t('nav:title.administration')
};

const showCategoryTitle = (links, index) => {
	const currentLink = links[index];
	const previousLink = links[index - 1];

	const currentLinkCategory = currentLink?.category;
	const previousLinkCategory = previousLink?.category;

	if (currentLinkCategory !== previousLinkCategory) {
		return LINKS_CATEGORY[currentLinkCategory];
	}

	return null;
};

export default showCategoryTitle;
