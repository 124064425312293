import services from 'services/services';
import * as types from '../types';

// Import helpers
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

// ******************** FETCH REPORTS URL ********************
export const fetchReportsUrl = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_REPORTS_URL_LOADING
		});

		const { data } = await services.get('/reports/link');

		dispatch({
			type: types.FETCH_REPORTS_URL_SUCCESS,
			payload: data.link
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_REPORTS_URL_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
