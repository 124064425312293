import moment from 'moment';

// Import variables
import { DATE_FORMATS } from 'components/helpers/variables';

const { UTC_DATE_FORMAT } = DATE_FORMATS;

export const getFormatedDate = (date, formatString) => {
	if (date && formatString) {
		return moment(date).format(formatString);
	}

	return '';
};

export const convertDateToMoment = (date) => moment(date);

export const convertDatesToRange = (start, end) => {
	const since = convertDateToMoment(start);
	const till = convertDateToMoment(end);

	return [since, till];
};

export const convertRangeToDates = (range) => {
	const [start, end] = range;
	const since = getFormatedDate(start, UTC_DATE_FORMAT);
	const till = getFormatedDate(end, UTC_DATE_FORMAT);
	return [since, till];
};
