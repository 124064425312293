import React from 'react';
import i18n from 'i18next';

// Import components
import AssetForm from '../../asset_form/AssetForm';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { ASSETS_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { assetGroupsTranslationValidate } from 'components/utilities/content_translation/validation';

const basicAssetTabs = (cancelButtonPath) => [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <AssetForm cancelButtonPath={cancelButtonPath} />
	}
];

export const assetTabs = (
	contentTranslationLanguages,
	cancelButtonPath,
	metadata
) => {
	return !metadata
		? basicAssetTabs(cancelButtonPath)
		: [
				...basicAssetTabs(cancelButtonPath),
				...generateContentTranslationsTabs(
					ASSETS_INPUT_FIELDS_DATA({ cancelButtonPath }),
					contentTranslationLanguages,
					assetGroupsTranslationValidate
				)
		  ];
};
