import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

// Import actions
import {
	fetchAffiliatePartnerCodes,
	deleteAffiliatePartnerCode
} from 'store/actions';

// Import styles
import { Wrapper, CodeWrapper, CodeValue } from './styles';

// Import component
import { IboxContentBase } from 'components/common/layout';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { messageConfirmDeleteHandler } from 'components/utilities/message';

const AffiliatePartnerCodesList = ({
	codes,
	itemID,
	deleteAffiliatePartnerCode,
	fetchAffiliatePartnerCodes,
	isLoaded
}) => {
	const { t } = useTranslation();

	const handleCodeDelete = (code) => {
		const callback = async (itemID, code) => {
			await deleteAffiliatePartnerCode(itemID, code);
			fetchAffiliatePartnerCodes(itemID);
		};

		messageConfirmDeleteHandler(
			'affiliate_partners_code',
			itemID,
			callback,
			code
		);
	};

	const renderCodes = () => {
		return codes.length > 0 ? (
			codes.map(({ code }) => {
				return (
					<CodeWrapper key={code}>
						<CodeValue>{code}</CodeValue>
						<Button
							className="m-b-md"
							disabled={false}
							onClick={() => handleCodeDelete(code)}
						>
							{t('affiliate_partners:buttons.delete_code')}
						</Button>
					</CodeWrapper>
				);
			})
		) : (
			<div> {t('affiliate_partners:fields_info.code_lack')}</div>
		);
	};

	return (
		<IboxContentBase itemID={itemID}>
			<h4>{t('affiliate_partners:fields.codes_info_title')}</h4>
			{isLoaded && <Wrapper>{renderCodes()}</Wrapper>}
		</IboxContentBase>
	);
};

AffiliatePartnerCodesList.propTypes = {
	fetchAffiliatePartnerCodes: PropTypes.func.isRequired,
	deleteAffiliatePartnerCode: PropTypes.func.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	codes: PropTypes.array,
	itemId: PropTypes.string
};

const mapStateToProps = ({ affiliate_partners: { form } }) => {
	return {
		isLoaded: form.isLoaded,
		codes: form.codes
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchAffiliatePartnerCodes,
		deleteAffiliatePartnerCode
	}),
	withRouter
)(AffiliatePartnerCodesList);
