import React from 'react';
import { useSelector } from 'react-redux';

// Import styles
import { Container } from './styles';

// Import helpers
import { getProductTypeAlias } from '../../helpers';

// Import components
import InfoItem from './InfoItem';

const InfoAboveTitle = () => {
	const { provider, metadata, ...rest } = useSelector(
		({ product_preview }) => product_preview.data
	);

	const productTypeAlias = getProductTypeAlias(rest);

	const category = metadata?.category;

	return (
		<Container>
			<InfoItem text={productTypeAlias} showDivider={false} />

			<InfoItem text={category} />

			<InfoItem text={provider} />
		</Container>
	);
};

export default InfoAboveTitle;
