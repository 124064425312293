import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setSubmenuQuery,
	fetchSubmenu,
	deleteSubmenu,
	createFiltersSuggestions
} from 'store/actions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import columns
import { createColumns } from './submenu_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const Submenu = ({
	error,
	setSubmenuQuery,
	fetchSubmenu,
	deleteSubmenu,
	resources,
	createFiltersSuggestions,
	filters
}) => {
	const { t } = useTranslation();
	const { id: parent_id } = useParams();

	const { columns } = resources;

	// execute filters logic
	useFilterLogic({
		createFiltersSuggestions,
		filters
	});

	const handleFetchResourcesAction = (options) => {
		fetchSubmenu({ options, parent_id });
	};

	return (
		// Dispatch fetchSubmenu, deleteSubmenu action in menu_panel
		<PanelPageTemplate
			title="Lista"
			error={error}
			type={`menus/${parent_id}`}
			typeText="menus"
			addLinkText={t('menu:buttons.submenu_add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={handleFetchResourcesAction}
			deleteItemAction={deleteSubmenu}
			notificationDeleteSuccessTxt={t('menu:notification.delete_success')}
			notificationDeleteErrorTxt={t('menu:notification.delete_error')}
			linkParams={{ state: { parent_id } }}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setSubmenuQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Submenu.propTypes = {
	error: PropTypes.bool.isRequired,
	setSubmenuQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchSubmenu: PropTypes.func.isRequired,
	deleteSubmenu: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
};

const mapStateToProps = ({ menu: { submenu_table } }) => {
	return {
		error: submenu_table.error,
		resources: submenu_table,
		filters: {}
	};
};

export default connect(mapStateToProps, {
	setSubmenuQuery,
	fetchSubmenu,
	deleteSubmenu,
	createFiltersSuggestions
})(Submenu);
