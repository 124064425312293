import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { METADATA_FIELDS } from './input_fields';

const MetadataFields = ({ submitting }) => {
	return (
		<>
			{METADATA_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
		</>
	);
};

MetadataFields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isAssetGroupsForm: PropTypes.bool
};

export default MetadataFields;
