import i18n from 'i18next';

export const INSTITUTIONS_INPUT_FIELDS_DATA = {
	submitUrl: 'productsgroup',
	fetchUrl: 'productsgroup',
	cancelButtonPath: 'institutions',
	hasMetadata: false,
	defautlFieldValues: {
		name: '',
		description: '',
		contact: ''
	},
	inputFields: [
		{
			name: 'name',
			label: i18n.t('institutions:fields.name'),
			type: 'text',
			required: true
		},
		{
			name: 'description',
			label: i18n.t('institutions:fields.description'),
			type: 'editor',
			required: false
		},
		{
			name: 'contact',
			label: i18n.t('institutions:fields.contact'),
			type: 'text',
			required: false
		}
	]
};
