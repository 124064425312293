import React from 'react';
import { Link } from 'react-router-dom';
// Import translation
import i18n from 'i18next';

export const createColumns = (handleSelectItem, isReadOnly) => [
	{
		Header: i18n.t('events_live:table_columns.title'),
		accessor: 'title',
		minWidth: 300,
		Cell: ({
			row: {
				_original: { uuid, title }
			}
		}) => <Link to={`/panel/events_live/edit/${uuid}/metadata`}>{title}</Link>
	},
	{
		Header: i18n.t('vod:table_columns.products_group_name'),
		accessor: 'products_group_name',
		name: 'products_group_name',
		width: 150
	},
	//  ======= TEMPORARILY HIDDEN =======
	// {
	// 	Header: i18n.t('vod:table_columns.rating'),
	// 	accessor: 'rating',
	// 	width: 110
	// },
	// {
	// 	Header: i18n.t('vod:table_columns.payment_models'),
	// 	accessor: 'payment_models',
	// 	sortable: false,
	// 	width: 160,
	// 	Cell: ({
	// 		row: {
	// 			_original: { payment_models }
	// 		}
	// 	}) =>
	// 		payment_models ? (
	// 			<span>{payment_models.join(', ')}</span>
	// 		) : (
	// 			<span>---</span>
	// 		)
	// },
	//  ======= TEMPORARILY HIDDEN =======
	{
		Header: i18n.t('events_live:table_columns.created_at'),
		accessor: 'created_at',
		width: 150
	},
	{
		Header: i18n.t('events_live:table_columns.category'),
		accessor: 'category',
		width: 110
	},
	{
		Header: i18n.t('events_live:table_columns.sub_category'),
		accessor: 'sub_category',
		width: 350,
		Cell: ({
			row: {
				_original: { sub_category }
			}
		}) => <span>{sub_category?.join(', ')}</span>
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid }
			}
		}) =>
			!isReadOnly && (
				<button
					onClick={() => handleSelectItem(uuid)}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];
