import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import { fetchInstitutionLogo, fetchInstitutionCover } from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitleCollapse from 'components/utilities/info/InfoTitleCollapse';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import {
	IMAGE_SIZE_INFO,
	INSTITUTIONS_IMAGES_REQUIREMENTS
} from 'components/helpers/info_helpers/info_texts';

const InstitutionsImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<>
			<InfoTitleCollapse
				title={IMAGE_SIZE_INFO}
				requirements={INSTITUTIONS_IMAGES_REQUIREMENTS}
			/>
			<Image
				title={t('common:images.cover')}
				fetchResourcesAction={(id) => fetchInstitutionCover(id)(dispatch)}
				collectionType="cover"
				mediaType="productsgroup"
				storePathSelector="institutions"
				productSubtype="institutions"
			/>
			<Image
				title={t('common:images.logo')}
				fetchResourcesAction={(id) => fetchInstitutionLogo(id)(dispatch)}
				collectionType="logo"
				mediaType="productsgroup"
				storePathSelector="institutions"
				productSubtype="institutions"
			/>
		</>
	);
};

export default InstitutionsImages;
