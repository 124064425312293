import { useSelector } from 'react-redux';

// Import context
import useRouteContext from 'components/routes/context/useRouteContext';

const usePermissionsCheck = () => {
	const { permissions } = useRouteContext();
	const { roles } = useSelector(({ auth }) => auth.authenticate);

	const canActivate = roles.some((role) =>
		permissions.canActivate.includes(role)
	);

	const canActivateProduct = roles.some((role) =>
		permissions.canActivateProduct.includes(role)
	);

	const canCreateContent = roles.some((role) =>
		permissions.canCreateContent.includes(role)
	);

	const canDeleteAssets = roles.some((role) =>
		permissions.canDeleteAssets.includes(role)
	);

	const checkedPermissions = {
		// can user activate products (shows/hides active checkbox button)
		canActivate,
		// can user create new content (vod, series, documents, institutions, etc...)
		canCreateContent,
		// can user delete assets
		canDeleteAssets,
		// can user activate products (shows/hides active checkbox button)
		canActivateProduct
	};

	return { checkedPermissions };
};

export default usePermissionsCheck;
