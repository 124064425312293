import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

// Import utilites
import FetchMultiselectLabel from 'components/common/inputs/input-label/multiselect/FetchMultiselectLabel';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { EPISODES_MODAL_SEARCH_LENGTH } from 'components/helpers/variables';

const Fields = ({ submitting, isAudio }) => {
	const parentInstitutionId = useSelector(
		({ vod: { season_form } }) => season_form.edit?.products_group_uuid
	);

	// filter vod list for parent institution
	const institutionQuery = parentInstitutionId
		? `productsGroupUuid=${parentInstitutionId}&`
		: '';

	// filer vod list for audio or vod only
	const audioQuery = `is_audio=${isAudio ? '1' : '0'}&`;

	const { t } = useTranslation();

	const url = `/vod/newList?${institutionQuery}${audioQuery}order[0][column]=4&order[0][dir]=asc&start=0&length=${EPISODES_MODAL_SEARCH_LENGTH}&subtype=vod`;

	return (
		<Field
			key="episodes-1"
			disabled={submitting}
			name="episodes"
			type="select"
			mode="multiple"
			placeholder="label"
			label="label"
			id="episodes"
			required={false}
		>
			{({ input: { name, value, onChange } }) => (
				<FetchMultiselectLabel
					name={name}
					value={value}
					onChange={onChange}
					placeholder={t('vod:episodes:form.select_episodes')}
					url={url}
				/>
			)}
		</Field>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isAudio: PropTypes.bool.isRequired
};

export default Fields;
