import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { string, func } from 'prop-types';

const useImagePreview = ({
	assetId,
	assetRemove = () => {},
	mediaType,
	productSubtype,
	productId,
	collection
}) => {
	const history = useHistory();

	const [previewVisible, setPreviewVisible] = useState(false);

	const handleCloseModal = () => setPreviewVisible(false);

	const handleOpenModal = () => setPreviewVisible(true);

	const handleAssetRemove = () => assetRemove({ assetId });

	const handleRedirectToEdit = () => {
		const redirectPath = `/panel/asset/${mediaType}/${productSubtype}/${productId}/edit/${collection}/${assetId}/metadata`;
		history.push(redirectPath);
	};

	return {
		previewVisible,
		closeModal: handleCloseModal,
		openModal: handleOpenModal,
		assetRemove: handleAssetRemove,
		redirectToEdit: handleRedirectToEdit
	};
};

useImagePreview.propTypes = {
	assetId: string.isRequired,
	assetRemove: func.isRequired,
	mediaType: string.isRequired,
	productSubtype: string.isRequired,
	productId: string.isRequired,
	collection: string.isRequired
};

export default useImagePreview;
