import React from 'react';
import PropTypes from 'prop-types';

// Import components
import Upload from 'components/utilities/upload_material/upload/Upload';
import UploadMaterialsTable from './table/UploadMaterialsTable';
import AssetsList from './assets_list/AssetsList';

const UploadMaterial = ({
	modelType,
	fileTypeSelectorVisible,
	transcodeMain,
	transcodePreview
}) => {
	return (
		<>
			<Upload
				modelType={modelType}
				fileTypeSelectorVisible={fileTypeSelectorVisible}
			/>
			<UploadMaterialsTable
				modelType={modelType}
				transcodeMain={transcodeMain}
				transcodePreview={transcodePreview}
			/>
			<AssetsList />
		</>
	);
};

UploadMaterial.propTypes = {
	modelType: PropTypes.string.isRequired,
	fileTypeSelectorVisible: PropTypes.bool,
	transcodeMain: PropTypes.bool,
	transcodePreview: PropTypes.bool
};

UploadMaterial.defaultProps = {
	fileTypeSelectorVisible: false,
	transcodeMain: false,
	transcodePreview: false
};

export default UploadMaterial;
