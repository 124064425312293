import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	setActivityOperatorQuery,
	setActivityOperator,
	fetchActivitiesOperator,
	createFiltersSuggestions,
	dictionaryModelTypes,
	clearPanelState
} from 'store/actions';

// Import columns
import { createColumns } from './activity_operator_columns';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

const ActivityOperator = ({
	error,
	setActivityOperatorQuery,
	setActivityOperator,
	fetchActivitiesOperator,
	createFiltersSuggestions,
	clearPanelState,
	resources,
	dictionaryModelTypes,
	filters
}) => {
	const { id } = useParams();

	useEffect(() => {
		clearPanelState();
		setActivityOperator(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	// Actions to fetch data for filters
	const filterActions = {
		dictionaryModelTypes
	};

	// execute filters logic
	useFilterLogic({
		filterActions,
		createFiltersSuggestions,
		filters
	});

	const { columns } = resources;
	return (
		<PanelPageTemplate
			title="Lista"
			error={error}
			type="activity"
			resources={resources}
			columns={createColumns}
			links
			fetchResourcesAction={fetchActivitiesOperator}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setActivityOperatorQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

ActivityOperator.propTypes = {
	error: PropTypes.bool.isRequired,
	setActivityOperatorQuery: PropTypes.func.isRequired,
	setActivityOperator: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchActivitiesOperator: PropTypes.func.isRequired
};

const mapStateToProps = ({ operators: { tableActivity }, dictionary }) => {
	return {
		error: tableActivity.error,
		resources: tableActivity,
		filters: { model_type: dictionary.model_types }
	};
};

export default connect(mapStateToProps, {
	setActivityOperatorQuery,
	setActivityOperator,
	fetchActivitiesOperator,
	createFiltersSuggestions,
	dictionaryModelTypes,
	clearPanelState
})(ActivityOperator);
