import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styles
import { TabButton } from './styles';

const TabInfo = () => {
	const { t } = useTranslation();

	return <TabButton>{t('product_preview:buttons.info')}</TabButton>;
};

export default TabInfo;
