import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import actions
import {
	fetchSettingsWatermark,
	dictionaryCustomAssetTypes
} from 'store/actions';

// Import components
import Image from 'components/utilities/images/Image';
import { Spin } from 'antd';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import {
	IMAGE_SIZE_INFO,
	WATERMARK_IMAGE_REQUIREMENTS
} from 'components/helpers/info_helpers/info_texts';
import InfoTitleCollapse from 'components/utilities/info/InfoTitleCollapse';

const WatermarkSettings = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {
		dictionaryCustomAssetTypes()(dispatch);
		// eslint-disable-next-line
	}, []);
	const { customAssetTypes } = useSelector((state) => state.dictionary);

	const watermarkMediaType = Object.keys(customAssetTypes)[0];
	const watermarkCustomId = Object.values(customAssetTypes)[0];

	return (
		<>
			<InfoTitleCollapse
				title={`${IMAGE_SIZE_INFO}`}
				requirements={WATERMARK_IMAGE_REQUIREMENTS}
			/>
			{Object.entries(customAssetTypes).length ? (
				<Image
					title={t('common:images.watermark')}
					fetchResourcesAction={(id) =>
						fetchSettingsWatermark(id, watermarkMediaType)(dispatch)
					}
					collectionType="logo"
					productSubtype="watermark"
					mediaType={watermarkMediaType}
					storePathSelector="settings"
					customId={watermarkCustomId}
					isDarkBackground={true}
				/>
			) : (
				<div className="d-flex justify-content-center">
					<Spin size="large" />
				</div>
			)}
		</>
	);
};

export default WatermarkSettings;
