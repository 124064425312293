import i18n from 'i18next';

export const select_vod_types = [
	{
		name: 'VOD',
		value: 'vod'
	}
];

export const select_vod_subtypes = [
	{
		name: i18n.t('vod:select.subtypes.series'),
		value: 'series'
	},
	{
		name: i18n.t('vod:select.subtypes.vod'),
		value: 'vod'
	},
	{
		name: i18n.t('vod:select.subtypes.season'),
		value: 'season'
	},
	{
		name: i18n.t('vod:select.subtypes.episode'),
		value: 'episode'
	}
];

export const select_is_audio = [
	{ name: 'VIDEO', value: false },
	{ name: 'AUDIO', value: true }
];

export const select_EU_promotion_types = [
	{
		name: i18n.t('vod:select.EU_promotion.eu'),
		value: 'eu'
	},
	{
		name: i18n.t('vod:select.EU_promotion.pl_eu'),
		value: 'pl_eu'
	}
];
