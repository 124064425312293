import React from 'react';

// Import translation
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// render properly formated name of subtype
const renderVodSubtype = (subtype, isAudio) => {
	switch (subtype) {
		case 'vod':
			return i18n.t(`vod:select:subtypes.${isAudio ? 'audio' : 'vod'}`);
		case 'series':
			return i18n.t('vod:select:subtypes.series');
		case 'season':
			return i18n.t('vod:select:subtypes.season');
		case 'episode':
			return i18n.t('vod:select:subtypes.episode');

		default:
			return subtype;
	}
};

export const createColumns = (handleSelectItem, isReadOnly, isAudio) => [
	{
		Header: i18n.t('channels:table_columns.logo_url'),
		accessor: 'images',
		sortable: false,
		width: 100,
		Cell: ({
			row: {
				_original: { uuid, image, subtype, is_audio }
			}
		}) => (
			<React.Fragment>
				{image ? (
					setFormLink({ uuid, type: subtype, image, is_audio })
				) : (
					<span>---</span>
				)}
			</React.Fragment>
		)
	},
	{
		Header: i18n.t('vod:table_columns.title'),
		accessor: 'title',
		minWidth: 300,
		Cell: ({
			row: {
				_original: { uuid, subtype, title, status, is_audio }
			}
		}) => setFormLink({ uuid, type: subtype, title, status, is_audio })
	},
	{
		Header: i18n.t('vod:table_columns.products_group_name'),
		accessor: 'products_group_name',
		width: 150
	},
	{
		Header: i18n.t('vod:table_columns.status'),
		accessor: 'status',
		width: 150,
		Cell: ({
			row: {
				_original: { status }
			}
		}) => i18n.t(`vod:select:status.${status}`)
	},
	{
		Header: i18n.t('vod:table_columns.created_at'),
		accessor: 'created_at',
		width: 150
	},
	{
		Header: i18n.t('vod:table_columns.subtype'),
		accessor: 'subtype',
		width: 100,
		Cell: ({
			row: {
				_original: { subtype }
			}
		}) => renderVodSubtype(subtype, isAudio)
	},
	{
		Header: i18n.t('vod:table_columns.category'),
		accessor: 'category',
		width: 110
	},
	{
		Header: i18n.t('vod:table_columns.sub_category'),
		accessor: 'sub_category',
		width: 300,
		Cell: ({
			row: {
				_original: { sub_category }
			}
		}) => <span>{sub_category?.join(', ')}</span>
	},
	{
		Header: i18n.t('common:table_columns.delete'),
		accessor: 'delete',
		width: 60,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid, subtype, status }
			}
		}) =>
			!isReadOnly &&
			status !== 'deleted' && (
				<button
					onClick={() => handleSelectItem(uuid, { type: subtype })}
					className="btn btn-xs btn-primary"
				>
					<i className="fa fa-trash" />
				</button>
			)
	}
];

export const selectOptionColumns = [
	{
		header: i18n.t('common:search_box.all_columns'),
		name: ''
	},
	{
		header: i18n.t('vod:table_columns.title'),
		name: 'title'
	},
	{
		header: i18n.t('vod:table_columns.products_group_name'),
		name: 'products_group_name'
	},
	{
		header: i18n.t('vod:table_columns.rating'),
		name: 'rating'
	},
	{
		header: i18n.t('vod:table_columns.genres'),
		name: 'genres'
	},
	{
		header: i18n.t('vod:table_columns.status'),
		name: 'status'
	},
	{
		header: i18n.t('vod:table_columns.subtype'),
		name: 'subtype'
	}
];
