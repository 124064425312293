import { combineReducers } from 'redux';
// Import reducers
import table from './menu_panel_reducer';
import form from './menu_form_reducer';
import submenu_table from './submenu_panel_reducer';
import submenu_form from './submenu_form_reducer';

export default combineReducers({
	table,
	form,
	submenu_table,
	submenu_form
});
