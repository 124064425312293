import React from 'react';
import PropTypes from 'prop-types';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import helpers
import { renderFields } from 'components/utilities/form/renderFields';

const Fields = ({ submitting }) => {
	return (
		<>
			{BASE_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired
};

export default Fields;
