import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import { CSVLink } from 'react-csv';
import { Spin } from 'antd';

//inline styles are used in this component because I don't fucking know
// from where that bottom margin is coming from in <p></p>
function CSVForm({
	data,
	disabled,
	loading = false,
	dirty = false,
	prepareDataFunction,
	additionalResources
}) {
	const { t } = useTranslation();

	if (disabled) {
		return null;
	}
	if (loading) {
		return (
			<div className="csv__form">
				<Spin size="small" />
				<p className="m-l-sm" style={{ marginBottom: 0 }}>
					{t('csv:loading')}
				</p>
			</div>
		);
	}
	if (dirty) {
		return (
			<div className="csv__form">
				<p style={{ marginBottom: 0 }}>{t('csv:save_form_data')}</p>
			</div>
		);
	}

	const preparedData = prepareDataFunction({ data, additionalResources });

	const fileTitle = data?.metadata?.title || data.title || 'file';
	const formattedFileTitle = fileTitle.replace(/\./g, '_');

	return (
		<div className="csv__form">
			{data && (
				<CSVLink
					data={preparedData}
					filename={`${formattedFileTitle}-form`}
					separator={';'}
				>
					<i className="fa fa-download m-r-sm"></i>
					{t('csv:download_form')}
				</CSVLink>
			)}
		</div>
	);
}

CSVForm.propTypes = {
	data: PropTypes.any,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	dirty: PropTypes.bool,
	prepareDataFunction: PropTypes.func,
	additionalResources: PropTypes.any
};

export default CSVForm;
