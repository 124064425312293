import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import logic
import useFields from './useFields';

const Fields = (props) => {
	const { submitting, selectResources, ...rest } = useFields(props);

	return BASE_FIELDS(rest).map((item, index) =>
		renderFields({ item, index, submitting, selectResources })
	);
};

Fields.propTypes = {
	parentId: PropTypes.string,
	sections: PropTypes.array,
	productsGenres: PropTypes.array,
	sectionGroup: PropTypes.array,
	isSubmenu: PropTypes.bool
};

export default Fields;
