import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, productTextColor, size } from '../../../styles';

export const Wrapper = styled.article`
	margin-bottom: 3.6rem;
	margin-right: 1.7rem;

	${customMinResponsive(
		size.medium,
		css`
			margin-right: 7.8rem;
			margin-bottom: 6.7rem;
		`
	)}
`;

export const Title = styled.h2`
	font-size: 2.6rem;
	line-height: 2.5rem;
	margin-bottom: 2.1rem;
	font-weight: ${({ theme }) => theme.font.bold};
	${productTextColor}

	${customMinResponsive(
		size.medium,
		css`
			font-size: 4.8rem;
			line-height: 5.7rem;
			margin-bottom: 3rem;
		`
	)}
`;

export const ShortDescription = styled.h3`
	font-size: 1.4rem;
	line-height: 2.1rem;
	margin-bottom: 3rem;
	${productTextColor}

	${customMinResponsive(
		size.medium,
		css`
			font-size: 2.4rem;
			line-height: 3.6rem;
		`
	)}
`;

export const Description = styled.div`
	font-size: 1.4rem;
	line-height: 2.1rem;
	font-weight: ${({ theme }) => theme.font.normal};
	${productTextColor}

	${customMinResponsive(
		size.medium,
		css`
			font-size: 2.4rem;
			line-height: 3.6rem;
		`
	)}
`;
