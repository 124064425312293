import useAudioLocation from 'hooks/useAudioLocation';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	getUploadUrl,
	confirmUpload,
	clearUploadedMaterialsTable
} from 'store/actions';

const useUpload = (modelType) => {
	const { id } = useParams();

	const { isAudio } = useAudioLocation();
	const vodType = isAudio ? 'audio' : 'video';

	const defaultCollection = modelType === 'vod' ? vodType : 'video';

	// STATE
	const [files, setFiles] = useState([]);
	const [fileName, setFileName] = useState('');
	const [isPromptVisible, setIsPromptVisible] = useState(false);
	const [collection, setCollection] = useState(defaultCollection);

	// REDUX
	const dispatch = useDispatch();
	const { status, assetId, uploadUrl } = useSelector(
		({ product_upload_material }) => product_upload_material.get_url
	);

	const handleGetUploadUrl = async () =>
		await getUploadUrl(id, collection, modelType)(dispatch);

	const handleUploadComplete = async (error) => {
		if (error) {
			return null;
		} else {
			await confirmUpload(assetId, fileName);
			setIsPromptVisible(false);
			clearUploadedMaterialsTable()(dispatch);
		}
	};

	const handleOnUpdateFiles = (files) => {
		setFiles(files);
		setFileName(files[0].filename);
		setIsPromptVisible(true);
	};

	const handleChangeCollection = ({ target: { value } }) =>
		setCollection(value);

	const collectionsTypes = ['video', 'audio'];

	return {
		files,
		uploadComplete: handleUploadComplete,
		getUploadUrl: handleGetUploadUrl,
		updateFiles: handleOnUpdateFiles,
		status,
		uploadUrl,
		isPromptVisible,
		collectionsTypes,
		handleChangeCollection
	};
};

export default useUpload;
