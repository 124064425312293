// ******************** PASSES DATA TABLE ********************
export const FETCH_PASSES_LOADING = 'FETCH_PASSES_LOADING';
export const FETCH_PASSES_SUCCESS = 'FETCH_PASSES_SUCCESS';
export const FETCH_PASSES_ERROR = 'FETCH_PASSES_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_PASSES_SEARCH_QUERY = 'SET_PASSES_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_PASSES_TABLE_SORTING = 'SAVE_PASSES_TABLE_SORTING';

// ******************** DELETE ARTICLE ********************
export const DELETE_PASS_LOADING = 'DELETE_PASS_LOADING';
export const DELETE_PASS_SUCCESS = 'DELETE_PASS_SUCCESS';
export const DELETE_PASS_ERROR = 'DELETE_PASS_ERROR';

// ******************** PASS ********************
export const FETCH_PASS_LOADING = 'FETCH_PASS_LOADING';
export const FETCH_PASS_SUCCESS = 'FETCH_PASS_SUCCESS';
export const FETCH_PASS_ERROR = 'FETCH_PASS_ERROR';

// ******************** FILES ********************
// -------------------- COVER --------------------
export const FETCH_PASS_COVER_FILES_LOADING = 'FETCH_PASS_COVER_FILES_LOADING';
export const FETCH_PASS_COVER_FILES_SUCCESS = 'FETCH_PASS_COVER_FILES_SUCCESS';
export const FETCH_PASS_COVER_FILES_ERROR = 'FETCH_PASS_COVER_FILES_ERROR';

// ******************** AVAILABILITY ********************
// -------------------- TABLE --------------------
// -------------------- FETCH --------------------
export const FETCH_PASS_AVAILABILITY_TABLE_LOADING =
	'FETCH_PASS_AVAILABILITY_TABLE_LOADING';
export const FETCH_PASS_AVAILABILITY_TABLE_SUCCESS =
	'FETCH_PASS_AVAILABILITY_TABLE_SUCCESS';
export const FETCH_PASS_AVAILABILITY_TABLE_ERROR =
	'FETCH_PASS_AVAILABILITY_TABLE_ERROR';

// -------------------- DELETE --------------------
export const DELETE_PASS_AVAILABILITY_LOADING =
	'DELETE_PASS_AVAILABILITY_LOADING';
export const DELETE_PASS_AVAILABILITY_SUCCESS =
	'DELETE_PASS_AVAILABILITY_SUCCESS';
export const DELETE_PASS_AVAILABILITY_ERROR = 'DELETE_PASS_AVAILABILITY_ERROR';

// -------------------- FORM --------------------
// -------------------- FETCH --------------------
export const FETCH_PASS_AVAILABILITY_FORM_LOADING =
	'FETCH_PASS_AVAILABILITY_FORM_LOADING';
export const FETCH_PASS_AVAILABILITY_FORM_SUCCESS =
	'FETCH_PASS_AVAILABILITY_FORM_SUCCESS';
export const FETCH_PASS_AVAILABILITY_FORM_ERROR =
	'FETCH_PASS_AVAILABILITY_FORM_ERROR';

// ******************** PRICES ********************
// -------------------- FETCH --------------------
export const FETCH_PASS_PRICES_LOADING = 'FETCH_PASS_PRICES_LOADING';
export const FETCH_PASS_PRICES_SUCCESS = 'FETCH_PASS_PRICES_SUCCESS';
export const FETCH_PASS_PRICES_ERROR = 'FETCH_PASS_PRICES_ERROR';

// -------------------- DELETE --------------------
export const DELETE_PASS_PRICE_LOADING = 'DELETE_PASS_PRICE_LOADING';
export const DELETE_PASS_PRICE_SUCCESS = 'DELETE_PASS_PRICE_SUCCESS';
export const DELETE_PASS_PRICE_ERROR = 'DELETE_PASS_PRICE_ERROR';
