import i18n from 'i18next';

export const BASE_FIELDS = [
	{
		name: 'name',
		type: 'text',
		placeholder: i18n.t('agreements:fields.name_placeholder'),
		label: i18n.t('agreements:fields.name_label'),
		id: 'name',
		requiredField: true
	},
	{
		name: 'rank',
		type: 'number',
		placeholder: i18n.t('agreements:fields.rank_placeholder'),
		label: i18n.t('agreements:fields.rank_label'),
		id: 'rank',
		requiredField: true
	},
	{
		name: 'type',
		type: 'select',
		placeholder: i18n.t('agreements:fields.type_placeholder'),
		label: i18n.t('agreements:fields.type_label'),
		id: 'type',
		requiredField: true
	},
	{
		name: 'obligatory',
		type: 'checkbox',
		placeholder: i18n.t('agreements:fields.required_placeholder'),
		label: i18n.t('agreements:fields.required_label'),
		id: 'required'
	}
];
