import { combineReducers } from 'redux';
// Import reducers
import table from './passes_panel_reducer';
import form from './passes_form_reducer';
import files from './passes_files_reducer';
import availability from './passes_availabilty/passes_availability_reducer';
import prices from './passes_prices_reducer';

export default combineReducers({
	table,
	form,
	files,
	availability,
	prices
});
