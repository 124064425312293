import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchInstitution,
	fetchDocumentsList,
	clearState
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import helpers
import { convertDocumentsList } from './helpers';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const InstitutionsForm = ({
	history,
	fetchInstitution,
	fetchDocumentsList,
	error,
	isLoaded,
	initialValues,
	clearState,
	documentsList
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const resources = { ...values };

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'productsgroup',
			redirectPath: 'institutions'
		});
	};

	const documentsAliases = convertDocumentsList(documentsList);

	return (
		<FormTemplate
			fetchFormResources={[clearState, fetchDocumentsList]}
			fetchResourceToEdit={fetchInstitution}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<Form
					initialValues={initialValues}
					validate={(values) => validate({ ...values, isEdit })}
					decorators={decorators}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								currentValues={values}
								submitting={submitting}
								checkedPermissions={checkedPermissions}
								documentsList={documentsAliases}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="institutions"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

InstitutionsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchInstitution: PropTypes.func.isRequired,
	fetchDocumentsList: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	documentsList: PropTypes.array.isRequired
};

const mapStateToProps = ({
	institutions: { form },
	dictionary: { documentsList }
}) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded,
		documentsList
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchInstitution,
		fetchDocumentsList,
		clearState
	}),
	withRouter
)(InstitutionsForm);
