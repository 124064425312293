// Import variables
import {
	GLOBAL_PRODUCT_TYPES,
	PAYMENT_TYPES
} from 'components/helpers/variables';

/**
 * Function converts type from url (react router) to proper type used in request body (that is accepted by API).
 * Api only accept 'packet' as type. Application url can have 'main' or 'packets' (needed for proper authorization).
 * Api only accept 'channel' as type. Application url can have 'channels' (needed for proper authorization).
 * Possibly more in future.
 */
export const convertProductType = (productType) => {
	switch (productType) {
		case 'packets':
		case 'main':
			return GLOBAL_PRODUCT_TYPES.PACKET;
		case 'channels':
			return GLOBAL_PRODUCT_TYPES.CHANNEL;
		case 'articles':
			return GLOBAL_PRODUCT_TYPES.ARTICLE;
		case 'events_live':
		case 'event_live': //used in product_visibility_table.js as redux type is single
			return 'live_event';
		case 'passes':
			return GLOBAL_PRODUCT_TYPES.PASS;
		default:
			return productType;
	}
};

// function that checks number of decimal places in number
export const checkDecimalPlaces = (value) => {
	const decimals = value.toString().split('.')[1];
	if (value && Math.floor(value) !== value)
		return decimals ? decimals.length || 0 : 0;
	return 0;
};

export const isExternalProvider = (method) =>
	!method.includes(PAYMENT_TYPES.BLUEMEDIA) &&
	method !== PAYMENT_TYPES.EXTERNAL_CFM;
