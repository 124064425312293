import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setPassesQuery,
	fetchPasses,
	deletePass,
	createFiltersSuggestions,
	fetchInstitutionsList
} from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './passes_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';
import FilterButton from 'components/common/buttons/FilterButton';

// Import hooks
import useFilterLogic from 'hooks/useFilterLogic';

const Passes = ({
	error,
	setPassesQuery,
	fetchPasses,
	deletePass,
	resources,
	createFiltersSuggestions,
	fetchInstitutionsList,
	filters
}) => {
	const { t } = useTranslation();

	const { columns } = resources;

	// Actions to fetch data for filters
	const filterActions = {
		fetchInstitutionsList
	};

	useFilterLogic({
		filterActions,
		filters,
		createFiltersSuggestions
	});

	return (
		<PanelPageTemplate
			error={error}
			type="passes"
			addLinkText={t('passes:buttons.add')}
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchPasses}
			deleteItemAction={deletePass}
			notificationDeleteSuccessTxt={t('passes:notification.delete_success')}
			notificationDeleteErrorTxt={t('passes:notification.delete_error')}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setPassesQuery}
					value={resources.options.filters.query}
				/>
				<FilterButton tableColumns={columns} />
			</div>
		</PanelPageTemplate>
	);
};

Passes.propTypes = {
	error: PropTypes.bool.isRequired,
	setPassesQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchPasses: PropTypes.func.isRequired,
	deletePass: PropTypes.func.isRequired,
	createFiltersSuggestions: PropTypes.func.isRequired,
	fetchInstitutionsList: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		error: state.passes.table.error,
		resources: state.passes.table,
		filters: {
			institutions: state.dictionary.institutionsList
		}
	};
};

export default connect(mapStateToProps, {
	setPassesQuery,
	createFiltersSuggestions,
	fetchPasses,
	deletePass,
	fetchInstitutionsList
})(Passes);
