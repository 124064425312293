import React from 'react';

// Import translation
import i18n from 'i18next';

export const algorithm_info = (
	<>
		<div>{i18n.t('algorithm_info:title')}</div>
		<ul>
			<li>
				<strong>types ({i18n.t('algorithm_info:product_type_tip')})</strong> -{' '}
				{i18n.t('algorithm_info:type')}
			</li>
			<li>
				<strong>days</strong> - {i18n.t('algorithm_info:days')}
			</li>
			<li>
				<strong>actor ({i18n.t('algorithm_info:perform_tip')})</strong> -{' '}
				{i18n.t('algorithm_info:actor')}
			</li>
			<li>
				<strong>director ({i18n.t('algorithm_info:directors_tip')})</strong> -{' '}
				{i18n.t('algorithm_info:director')}
			</li>
			<li>
				<strong>category_slugs ({i18n.t('algorithm_info:genre_tip')})</strong> -{' '}
				{i18n.t('algorithm_info:category_slugs')}
			</li>
			<li>
				<strong>
					packet_uuids ({i18n.t('algorithm_info:uuid_packet_tip')}) -{' '}
				</strong>
				{i18n.t('algorithm_info:packet_uuids')}
			</li>
			<li>
				<strong>limit</strong> - {i18n.t('algorithm_info:limit')}
			</li>
			<li>
				<strong>
					products_group_slug ({i18n.t('algorithm_info:institution_tip')})
				</strong>{' '}
				- {i18n.t('algorithm_info:products_group_slug')}
			</li>
			<li>
				<strong>countries ({i18n.t('algorithm_info:country_tip')})</strong> -{' '}
				{i18n.t('algorithm_info:countries')}
			</li>
			<li>
				<strong>genre ({i18n.t('algorithm_info:category_tip')})</strong> -{' '}
				{i18n.t('algorithm_info:genres')}
			</li>
			<li>
				<strong>
					sub_genres ({i18n.t('algorithm_info:sub_category_tip')})
				</strong>{' '}
				- {i18n.t('algorithm_info:sub_genres')}
			</li>
			<li>
				<strong>rating ({i18n.t('algorithm_info:rating_tip')})</strong> -{' '}
				{i18n.t('algorithm_info:rating')}
			</li>
			<li>
				<strong>rating_operator</strong> -{' '}
				{i18n.t('algorithm_info:rating_operator')}
			</li>
			<li>
				<strong>tags</strong> - {i18n.t('algorithm_info:tags')}
			</li>
			<li>
				<strong>is_audio</strong> - {i18n.t('algorithm_info:is_audio')}
			</li>
		</ul>
		<div style={{ marginBottom: '1rem' }}>
			{i18n.t('algorithm_info:format')}
			<br />
			{i18n.t('algorithm_info:example')}
			<br />
			<em>&#123;</em>
			<br />
			<em> "types":["vod","series","article","live_event"],</em>
			<br />
			<em> "days":12,</em>
			<br />
			<em> "actor": "Daniel Olbrychski",</em>
			<br />
			<em> "director": "Andrzej Wajda",</em>
			<br />
			<em> "category_slugs": ["komedia", "opera"],</em>
			<br />
			<em>
				"packet_uuids": ["70e76c02-47da-4a71-be52-315e8e5fa8ad",
				"3c6a5fff-f874-4be5-b4dc-01db5efbdf6b"],
			</em>
			<br />
			<em> "limit":100,</em>
			<br />
			<em> "countries": ["Polska", "Niemcy"],</em>
			<br />
			<em> "genre": "Teatr"</em>
			<br />
			<em> "sub_genres": ["komedie"]</em>
			<br />
			<em> "products_group_slug": "centrum_kultury_filmowej",</em>
			<br />
			<em> "rating": 12,</em>
			<br />
			<em> "rating_operator": ">",</em>
			<br />
			<em> "tags": ["tag1", "tag2"],</em>
			<br />
			<em> "is_audio": false</em>
			<br />
			<em>&#125;</em>
		</div>
		<div>{i18n.t('algorithm_info:warning')}</div>
		<div>{i18n.t('algorithm_info:empty_algorithm')}</div>
		<div>{i18n.t('algorithm_info:empty_braces')}</div>
	</>
);
