import styled, { css } from 'styled-components';

// Import styles
import { transitionMain, customMinResponsive, size } from '../../../styles';

export const TabButton = styled.button`
	cursor: pointer;
	font-size: 1.4rem;
	margin-right: 2.3rem;
	margin-bottom: 3.4rem;
	border: none;
	background: transparent;
	color: ${({ theme }) => theme.white};
	${transitionMain}

	&:focus-visible,
	&:hover {
		outline: none;
		color: ${({ theme }) => theme.primaryBlueLight};
	}

	${customMinResponsive(
		size.medium,
		css`
			font-size: 1.8rem;
			margin-right: 3rem;
		`
	)}
`;
