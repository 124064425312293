import styled from 'styled-components';

import { flex } from 'components/views/product_preview/styles/Mixin';

export const Wrapper = styled.div`
	${flex({ jc: 'flex-start' })}
	width: 100%;
	flex-wrap: wrap;
	margin-top: 3rem;
`;

export const CodeWrapper = styled.div`
	${flex({ jc: 'flex-start', fd: 'column', ai: 'center' })}
	min-width: 20rem;
	margin: 0.75rem 2rem;
	border-radius: 0.5rem;
	padding: 1rem 2rem;
	border: 1px dashed ${({ theme }) => theme.dashedLine};
`;

export const CodeValue = styled.p`
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
`;
