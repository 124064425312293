// ******************** MENU DATA TABLE ********************
export const FETCH_MENU_LOADING = 'FETCH_MENU_LOADING';
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';
export const FETCH_MENU_ERROR = 'FETCH_MENU_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_MENU_SEARCH_QUERY = 'SET_MENU_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_MENU_TABLE_SORTING = 'SAVE_MENU_TABLE_SORTING';

// ******************** DELETE MENU_ITEM ********************
export const DELETE_MENU_ITEM_LOADING = 'DELETE_MENU_ITEM_LOADING';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';
export const DELETE_MENU_ITEM_ERROR = 'DELETE_MENU_ITEM_ERROR';

// ******************** MENU_ITEM ********************
export const FETCH_MENU_ITEM_LOADING = 'FETCH_MENU_ITEM_LOADING';
export const FETCH_MENU_ITEM_SUCCESS = 'FETCH_MENU_ITEM_SUCCESS';
export const FETCH_MENU_ITEM_ERROR = 'FETCH_MENU_ITEM_ERROR';

// ******************** SUBMENU DATA TABLE ********************
export const FETCH_SUBMENU_LOADING = 'FETCH_SUBMENU_LOADING';
export const FETCH_SUBMENU_SUCCESS = 'FETCH_SUBMENU_SUCCESS';
export const FETCH_SUBMENU_ERROR = 'FETCH_SUBMENU_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_SUBMENU_SEARCH_QUERY = 'SET_SUBMENU_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_SUBMENU_TABLE_SORTING = 'SAVE_SUBMENU_TABLE_SORTING';

// ******************** DELETE SUBMENU_ITEM ********************
export const DELETE_SUBMENU_ITEM_LOADING = 'DELETE_SUBMENU_ITEM_LOADING';
export const DELETE_SUBMENU_ITEM_SUCCESS = 'DELETE_SUBMENU_ITEM_SUCCESS';
export const DELETE_SUBMENU_ITEM_ERROR = 'DELETE_SUBMENU_ITEM_ERROR';

// ******************** SUBMENU_ITEM ********************
export const FETCH_SUBMENU_ITEM_LOADING = 'FETCH_SUBMENU_ITEM_LOADING';
export const FETCH_SUBMENU_ITEM_SUCCESS = 'FETCH_SUBMENU_ITEM_SUCCESS';
export const FETCH_SUBMENU_ITEM_ERROR = 'FETCH_SUBMENU_ITEM_ERROR';
