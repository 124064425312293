import React from 'react';
import { bool, array, object } from 'prop-types';
import { rating_select_values } from 'components/helpers/common_select_data_variables';
// Import fields
import { BASE_FIELDS } from './input_fields';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

const Fields = ({
	submitting,
	genres,
	casts,
	platforms,
	checkedPermissions
}) => {
	const selectResources = {
		'data.casts': casts,
		platforms,
		genres,
		rating: rating_select_values
	};

	return (
		<>
			{BASE_FIELDS(checkedPermissions).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: bool.isRequired,
	genres: array,
	casts: array.isRequired,
	platforms: array.isRequired,
	checkedPermissions: object.isRequired
};

export default Fields;
