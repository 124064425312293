import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';

// Import helrpes
import { packetsTabs } from './helpers/index';

const PacketsTabs = () => {
	const { languages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);

	return <Tabs tabs={packetsTabs(languages)} />;
};

export default PacketsTabs;
