import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import throttle from 'lodash/throttle';
import { func, number } from 'prop-types';
import { checkProductSubtype } from 'components/views/product_preview/helpers';
import { useParams } from 'react-router';

// Import redux actions
import { fetchAssetGroup, fetchProductPreviewAssetGroups } from 'store/actions';

// Import helpers
import { getDataSortedById } from '../helpers';

const useGallery = ({
	onHeightChange = () => {},
	setSmallGalleryIndex = () => {},
	smallGalleryIndex,
	resolutionHeight = 0.565,
	descriptionHeight = 60,
	isFullscreen
}) => {
	const dispatch = useDispatch();

	const { id } = useParams();

	// refs
	const galleryRef = useRef(null);

	// state
	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(smallGalleryIndex);

	// redux state
	const {
		subtype,
		images: { gallery }
	} = useSelector(({ product_preview }) => product_preview.data);

	const { asset_group } = useSelector(
		({ product_preview_files }) => product_preview_files
	);
	// Gallery metadata
	const { metadata } = useSelector(
		({ asset_groups }) => asset_groups.form.edit
	);

	const assetGroup = asset_group?.find(({ id }) => id);

	// Gallery id
	const assetGroupId = assetGroup?.id;

	// variables
	const galleryHeight = containerHeight - descriptionHeight;
	const smallGalleryHeight = containerWidth * resolutionHeight;

	const assetHeight = isFullscreen ? galleryHeight : smallGalleryHeight;

	const showGallery = gallery?.data?.length;

	const { isArticle } = checkProductSubtype(subtype);

	// helpers
	const handleSetContainerWidth = () => {
		if (galleryRef.current) {
			const { offsetWidth, offsetHeight } = galleryRef.current;
			setContainerWidth(offsetWidth);
			setContainerHeight(offsetHeight);
		}
	};

	const handleSetCurrentIndex = (index) => {
		setCurrentIndex(index);
		setSmallGalleryIndex(index);
	};

	const throttleHandleResize = throttle(handleSetContainerWidth, 500);

	useEffect(() => {
		handleSetContainerWidth();
		// eslint-disable-next-line
	}, [galleryRef.current]);

	useEffect(() => {
		// To receive gallery id
		fetchProductPreviewAssetGroups(id)(dispatch);

		window.addEventListener('resize', throttleHandleResize);

		return () => {
			window.removeEventListener('resize', throttleHandleResize);
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		onHeightChange(assetHeight);
		// eslint-disable-next-line
	}, [assetHeight]);

	useEffect(() => {
		// To receive metadata of gallery
		assetGroupId && fetchAssetGroup(assetGroupId)(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetGroupId]);

	const galleryData = {
		...gallery,
		data: getDataSortedById(gallery.data)
	};

	return {
		gallery: galleryData,
		metadata,
		galleryRef,
		containerWidth,
		assetHeight,
		showGallery,
		currentIndex,
		isArticle,
		setCurrentIndex: handleSetCurrentIndex
	};
};

useGallery.propTypes = {
	onHeightChange: func,
	smallGalleryIndex: number,
	setSmallGalleryIndex: func,
	resolutionHeight: number
};

export default useGallery;
