import i18n from 'i18next';

export const COMMON_VOD_INPUT_FIELDS_DATA = {
	submitUrl: 'vod',
	fetchUrl: 'vod',
	cancelButtonPath: 'vod',
	hasMetadata: true,
	defautlFieldValues: {
		title: '',
		summary_short: '',
		summary_long: '',
		sub_title: '',
		sub_summary_short: '',
		sub_summary_long: '',
		release_description: '',
		country_of_origin: ''
	},
	inputFields: [
		{
			name: 'title',
			label: i18n.t('vod:form:fields.title'),
			type: 'text',
			required: true
		},
		{
			name: 'summary_short',
			label: i18n.t('vod:form:fields.summary_short'),
			type: 'textarea',
			required: true
		},
		{
			name: 'summary_long',
			label: i18n.t('vod:form:fields.summary_long'),
			type: 'editor',
			required: false,
			disabledAddingImage: true,
			disabledAddingMedia: true
		},
		{
			name: 'sub_title',
			label: i18n.t('vod:form:fields.sub_title'),
			type: 'text',
			required: false
		},
		{
			name: 'sub_summary_short',
			label: i18n.t('vod:form:fields.sub_summary_short'),
			type: 'textarea',
			required: false
		},
		{
			name: 'sub_summary_long',
			label: i18n.t('vod:form:fields.sub_summary_long'),
			type: 'editor',
			required: false,
			disabledAddingImage: true,
			disabledAddingMedia: true
		},
		{
			name: 'release_description',
			label: i18n.t('vod:form:fields.release_description'),
			type: 'text',
			required: false
		},
		{
			name: 'country_of_origin',
			label: i18n.t('vod:form:fields.country_of_origin'),
			type: 'text',
			required: false
		},
		{
			name: 'additional_metadata.sound',
			label: i18n.t('common:form:fields.additional_metadata.sound'),
			type: 'text',
			required: false
		},
		{
			name: 'additional_metadata.subtitles',
			label: i18n.t('common:form:fields.additional_metadata.subtitles'),
			type: 'text',
			required: false
		}
	]
};
