// Import translation
import i18n from 'i18next';

export const AGREEMENTS_ADDITIONAL_FIELDS_METADATA = [
	{
		name:
			'metadata.additional_data.agreement_inconsistent_with_the_regulations',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_inconsistent_with_the_regulations'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'metadata.additional_data.agreement_property_rights',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_property_rights'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'metadata.additional_data.agreement_offensive',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_offensive'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'metadata.additional_data.agreement_pornographic',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_pornographic'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'metadata.additional_data.agreement_violence',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_violence'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'metadata.additional_data.agreement_hate',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_hate'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'metadata.additional_data.agreement_moral_standards',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_moral_standards'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'metadata.additional_data.agreement_CKF_Warsaw',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_CKF_Warsaw'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'metadata.additional_data.agreement_human_and_animals_organs',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_human_and_animals_organs'
		),
		type: 'checkbox-inverted',
		required: false
	}
];

export const AGREEMENTS_ADDITIONAL_FIELDS = [
	{
		name: 'additional_data.agreement_inconsistent_with_the_regulations',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_inconsistent_with_the_regulations'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'additional_data.agreement_property_rights',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_property_rights'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'additional_data.agreement_offensive',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_offensive'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'additional_data.agreement_pornographic',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_pornographic'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'additional_data.agreement_violence',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_violence'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'additional_data.agreement_hate',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_hate'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'additional_data.agreement_moral_standards',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_moral_standards'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'additional_data.agreement_CKF_Warsaw',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_CKF_Warsaw'
		),
		type: 'checkbox-inverted',
		required: false
	},
	{
		name: 'additional_data.agreement_human_and_animals_organs',
		label: i18n.t(
			'common:form:fields.additional_data.agreements.agreement_human_and_animals_organs'
		),
		type: 'checkbox-inverted',
		required: false
	}
];

export const AGREEMENTS_INAPPROPRIATE_CONTENT_FIELDS = [
	{
		name: 'metadata.additional_data.inappropriate_content.violence',
		label: i18n.t('common:form:fields.inappropriate_content.violence'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	},
	{
		name: 'metadata.additional_data.inappropriate_content.sex',
		label: i18n.t('common:form:fields.inappropriate_content.sex'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	},
	{
		name: 'metadata.additional_data.inappropriate_content.vulgarisms',
		label: i18n.t('common:form:fields.inappropriate_content.vulgarisms'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	},
	{
		name: 'metadata.additional_data.inappropriate_content.drugs',
		label: i18n.t('common:form:fields.inappropriate_content.drugs'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	},
	{
		name: 'metadata.additional_data.inappropriate_content.lack',
		label: i18n.t('common:form:fields.inappropriate_content.lack'),
		type: 'checkbox',
		labelColumn: 6,
		inputColumn: 4
	}
];

export const PROMOTING_EUROPEAN_PRODUCTIONS_FIELDS = [
	{
		name: 'metadata.additional_data.promoted_product',
		label: i18n.t('vod:form:fields.promoted_product'),
		type: 'select',
		required: false,
		allowClear: true
	}
];
