// Import translation
import i18n from 'i18next';

export default ({ firstname, lastname, country, birthdate }) => {
	const errors = {};

	// ------------------ Firstname ------------------
	if (!firstname) {
		errors.firstname = i18n.t('user_data:validation.firstname_required');
	}
	// ------------------ Lastname ------------------
	if (!lastname) {
		errors.lastname = i18n.t('user_data:validation.lastname_required');
	}
	// ------------------ Country ------------------
	if (!country) {
		errors.country = i18n.t('user_data:validation.country_required');
	}
	// ------------------ Birthdate ------------------
	if (!birthdate) {
		errors.birthdate = i18n.t('user_data:validation.birthdate_required');
	}

	return errors;
};
