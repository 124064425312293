import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchSeason,
	fetchCategories,
	fetchProviders,
	fetchInstitutionsList,
	fetchTagsList,
	fetchSeries
} from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import ProductizationNote from 'components/utilities/info/ProductizationNote';

// Import helpers
import {
	vodCategoriesOptions,
	convertDataToNames,
	convertToIds
} from 'components/helpers/genres_helpers/helpers';
import { convertInstitutionsToValuePairs } from 'components/helpers/institutions_helpers/helpers';
import { getInitialValues } from './initial_values';
import { convertProvidersToId } from 'components/views/vod/helpers/helpers';
import { convertDurationTime } from 'components/helpers/convert';

// Import variables
import { DURATION_CONVERT_TYPES } from 'components/helpers/variables';

// Import hooks
import useAudioLocation from 'hooks/useAudioLocation';

const { HMS_TO_SECONDS } = DURATION_CONVERT_TYPES;

const SeasonForm = ({
	history,
	fetchSeason,
	fetchCategories,
	fetchProviders,
	fetchSeries,
	error,
	isSeasonLoaded,
	isSeriesLoaded,
	initialValues,
	valuesFromSeries,
	genresList,
	providersList,
	paymentsModelsList,
	fetchInstitutionsList,
	institutionsList,
	location: { state },
	match: {
		params: { id }
	},
	fetchTagsList,
	tagsList
}) => {
	useEffect(() => {
		if (id) localStorage.setItem('season', id);
		// Fetch categories with default params to feed genres combobox
		fetchCategories(vodCategoriesOptions, null, null);
		fetchProviders();
		// Fetch tags list
		fetchTagsList();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const { parent_uuid } = initialValues;

		const seriesId = parent_uuid || state?.seriesID;

		if (seriesId) {
			//Fetch data from parent series
			fetchSeries(seriesId);
		}
		// eslint-disable-next-line
	}, [initialValues, state]);

	const { t } = useTranslation();

	const { isAudio } = useAudioLocation();

	let seriesId = localStorage.getItem('series');
	const redirectPath = seriesId ? `vod/series/edit/${seriesId}/seasons` : 'vod';

	const { metadata, status, subtype } = initialValues;
	const { actors, writers, directors, producers } = metadata;

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { genres, products_group_uuid, tags, metadata } = values;

		// selected genres mapped to array of ids
		const genresId = convertToIds(genresList, genres);

		// selected tags mapped to array of ids
		const tagsId = convertToIds(tagsList, tags);

		// convert duration from hms to sec
		const durationInSeconds = convertDurationTime({
			duration: metadata.duration,
			type: HMS_TO_SECONDS
		});

		// check if there is state and seriesID variable - fixes undefined error
		let path = state && state.seriesID ? state.seriesID : '';

		// seriesID is passed from series/seasons table
		// it's needed to assing created season to specified series
		// if seriesID is nonexistent there empty string is returned
		const createApi = `vod/series/${path}/season`;
		const updateApi = 'vod/season';

		const seasonApi = isEdit ? createApi : updateApi;

		// resources to submit
		const resources = {
			...values,
			metadata: {
				...metadata,
				duration: durationInSeconds,
				sub_category: metadata.sub_category,
				rating: parseInt(metadata.rating, 10),
				additional_metadata: metadata.additional_metadata || [],
				title_org: metadata.title
			},
			genres: genresId,
			products_group_uuid: products_group_uuid || null,
			tags: tagsId
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: seasonApi,
			redirectPath,
			apiSlug: 'update',
			notificationName: 'season'
		});
	};

	const genresListNames = convertDataToNames(genresList);
	const providersId = convertProvidersToId(providersList);
	const tagsListNames = convertDataToNames(tagsList);
	// get institution name based on institution id
	const institutionsNames = convertInstitutionsToValuePairs(institutionsList);

	const isLoaded = isSeasonLoaded && isSeriesLoaded;

	return (
		// Dispatch fetchSeason actions in season_form
		<FormTemplate
			fetchFormResources={[fetchInstitutionsList]}
			fetchResourceToEdit={fetchSeason}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<>
					<ProductizationNote />
					<Form
						initialValues={getInitialValues({
							isEdit,
							initialValues,
							valuesFromSeries,
							institutionsList,
							isAudio
						})}
						validate={validate}
						decorators={decorators}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({
							handleSubmit,
							submitting,
							values,
							form: { change }
						}) => (
							<form onSubmit={handleSubmit}>
								<Fields
									currentValues={values}
									isEdit={isEdit}
									submitting={submitting}
									changeFieldValue={change}
									actors={actors}
									writers={writers}
									directors={directors}
									producers={producers}
									genres={genresListNames}
									status={status}
									subtype={subtype}
									providersId={providersId}
									paymentsModelsList={paymentsModelsList}
									institutions={institutionsNames}
									checkedPermissions={checkedPermissions}
									tags={tagsListNames}
								/>
								<FormButtons
									isButtonDisabled={submitting || error}
									path={redirectPath}
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				</>
			)}
		</FormTemplate>
	);
};

SeasonForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	valuesFromSeries: PropTypes.object.isRequired,
	fetchSeason: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	fetchProviders: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isSeasonLoaded: PropTypes.bool.isRequired,
	isSeriesLoaded: PropTypes.bool.isRequired,
	genresList: PropTypes.array.isRequired,
	providersList: PropTypes.array.isRequired,
	paymentsModelsList: PropTypes.array.isRequired,
	institutionsList: PropTypes.array.isRequired,
	fetchInstitutionsList: PropTypes.func.isRequired,
	location: PropTypes.shape({
		state: PropTypes.object
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string })
	}),
	fetchTagsList: PropTypes.func.isRequired,
	fetchSeries: PropTypes.func.isRequired
};

const mapStateToProps = ({
	vod: { season_form, series_form },
	categories: {
		table: { data }
	},
	providers,
	dictionary
}) => ({
	initialValues: season_form.edit,
	valuesFromSeries: series_form.edit,
	error: season_form.error,
	isSeasonLoaded: season_form.isLoaded,
	isSeriesLoaded: series_form.isLoaded,
	genresList: data,
	providersList: providers.table.data,
	paymentsModelsList: dictionary.paymentModels,
	institutionsList: dictionary.institutionsList,
	tagsList: dictionary.tagsList
});

export default compose(
	connect(mapStateToProps, {
		fetchSeason,
		fetchCategories,
		fetchProviders,
		fetchInstitutionsList,
		fetchTagsList,
		fetchSeries
	}),
	withRouter
)(SeasonForm);
