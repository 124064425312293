import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSectionsAssigned } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import components
import ProductsSelect from 'components/utilities/products_select/ProductsSelect';

// Import products tabs
import { section_assigned } from 'components/utilities/products_select/helpers/tabs';

const SectionsAssigned = (props) => {
	const { t } = useTranslation();
	const { data, error, isLoaded, fetchSectionsAssigned } = props;
	const tabs = [section_assigned];

	const createSubmitData = (selectedData) => {
		const selection = { ids: [] };

		selection.ids = selectedData.map((item, index) => ({
			id: item.id,
			order: index + 1
		}));

		return selection;
	};

	return (
		<ProductsSelect
			api="/section/group"
			apiSlug="syncSections"
			title={t('sections_groups:sections.title')}
			tabs={tabs}
			openModalText={t('sections_groups:sections.add_sections')}
			buttonSubmitText={t('common:buttons.submit')}
			notificationName="section"
			sortableType="list"
			initialData={data}
			error={error}
			isLoaded={isLoaded}
			fetchResourcesAction={fetchSectionsAssigned}
			createSubmitData={createSubmitData}
		/>
	);
};

SectionsAssigned.propTypes = {
	data: PropTypes.array.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	fetchSectionsAssigned: PropTypes.func.isRequired
};

const mapStateToProps = ({ sections_groups: { sections_assigned } }) => ({
	data: sections_assigned.data,
	isLoaded: sections_assigned.isLoaded,
	error: sections_assigned.error
});

export default connect(mapStateToProps, { fetchSectionsAssigned })(
	SectionsAssigned
);
