// Import translation
import i18n from 'i18next';

export default ({ name }) => {
	const errors = {};

	// ------------------ name ------------------
	if (!name) {
		errors.name = i18n.t('tags:validation.name');
	}

	return errors;
};
