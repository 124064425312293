// Import translation
import i18n from 'i18next';

export default ({
	title,
	summary_long,
	rating,
	category,
	sub_category,
	products_group_uuid
}) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('articles:validation.title');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('articles:validation.title_length');
	}
	// ------------------ summary_long ------------------
	if (!summary_long) {
		errors.summary_long = i18n.t('articles:validation.summary_long');
	}
	// ------------------ Rating ------------------
	// rating can be 0
	if (rating === undefined || rating === null) {
		errors.rating = i18n.t('vod:form:validation.rating');
	}
	// ------------------ category ------------------
	if (!category) {
		errors.category = i18n.t('articles:validation.category');
	}
	// ------------------ sub_category ------------------
	if (!sub_category?.length) {
		errors.sub_category = i18n.t('articles:validation.sub_category');
	}
	// ------------------ Institution ------------------
	if (!products_group_uuid) {
		errors.products_group_uuid = i18n.t('articles:validation.institution');
	}

	return errors;
};
