// Import translation
import i18n from 'i18next';

export default ({ name, label, algorithm_id, main_limit, type }) => {
	const errors = {};

	// ------------------ Label ------------------
	if (!label) {
		errors.label = i18n.t('sections:validation.label_required');
	} else if (label.length < 2 || label.length > 200) {
		errors.label = i18n.t('sections:validation.label_length');
	}
	// ------------------ Algorithm_id ------------------
	if (!algorithm_id) {
		errors.algorithm_id = i18n.t('sections:validation.algorithm_id_required');
	}
	// ------------------ Main_limit ------------------
	if (!main_limit) {
		errors.main_limit = i18n.t('sections:validation.main_limit_required');
	} else if (parseInt(main_limit, 10) <= 0) {
		errors.main_limit = i18n.t('sections:validation.main_limit_length');
	}
	// ------------------ Type ------------------
	if (!type) {
		errors.type = i18n.t('sections:validation.type_required');
	} else if (type.length < 2 || type.length > 200) {
		errors.type = i18n.t('sections:validation.type_length');
	}

	return errors;
};
