import React from 'react';
import { Progress } from 'reactstrap';

// Import translation
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// renders progress bar inside progress column
const renderProgress = (progress) => {
	let label = `${progress} %`;
	let color = 'success';

	return (
		<Progress color={color} value={progress} className="table-progress-bar">
			{label}
		</Progress>
	);
};

export const createColumns = () => [
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { product_uuid, title }
			}
		}) => setFormLink({ uuid: product_uuid, title })
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.priority'),
		accessor: 'priority',
		width: 80
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.status'),
		accessor: 'status',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.original_file_name'),
		accessor: 'original_file_name'
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.file_size'),
		accessor: 'file_size',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.type'),
		accessor: 'type'
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.progress'),
		accessor: 'progress',
		Cell: ({
			row: {
				_original: { progress }
			}
		}) => renderProgress(progress)
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.info'),
		accessor: 'info',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.updated_at'),
		accessor: 'updated_at'
	}
];
