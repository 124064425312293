import React from 'react';
import { useDispatch } from 'react-redux';

// Import actions
import {
	fetchVodCover,
	fetchVodPoster,
	fetchVodTitle,
	fetchVodAssetGroups
} from 'store/actions';

// Import helpers
import {
	IMAGE_SIZE_INFO,
	PRODUCT_IMAGES_REQUIREMENTS
} from 'components/helpers/info_helpers/info_texts';

// Import components
import Image from 'components/utilities/images/Image';
import InfoTitleCollapse from 'components/utilities/info/InfoTitleCollapse';
import AssetGroups from 'components/views/asset_groups/AssetGroups';

// Import translation
import { useTranslation } from 'react-i18next';

const VodImages = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<>
			<InfoTitleCollapse
				title={IMAGE_SIZE_INFO}
				requirements={PRODUCT_IMAGES_REQUIREMENTS}
			/>
			<Image
				title={t('common:images.cover')}
				fetchResourcesAction={(id) => fetchVodCover(id)(dispatch)}
				collectionType="cover"
				mediaType="vod"
				storePathSelector="vod"
				productSubtype="vod"
			/>
			<Image
				title={t('common:images.poster')}
				fetchResourcesAction={(id) => fetchVodPoster(id)(dispatch)}
				collectionType="poster"
				mediaType="vod"
				storePathSelector="vod"
				productSubtype="vod"
			/>
			<Image
				title={t('common:images.title')}
				fetchResourcesAction={(id) => fetchVodTitle(id)(dispatch)}
				collectionType="title"
				mediaType="vod"
				storePathSelector="vod"
				productSubtype="vod"
			/>
			<AssetGroups
				fetchResourcesAction={(id) => fetchVodAssetGroups(id)(dispatch)}
				mediaType="vod"
				storePathSelector="vod"
				productSubtype="vod"
			/>
		</>
	);
};

export default VodImages;
