import React from 'react';
import PropTypes from 'prop-types';
import {
	Col,
	FormGroup,
	Label,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText
} from 'reactstrap';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

const InputLabelAppend = ({
	input,
	id,
	label,
	inputColumn = '10',
	children,
	sufix = '$',
	meta: { touched, error },
	requiredField = false,
	...inputProps
}) => {
	return (
		<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
			<Label for={id} sm={2} className="col-form-label col-form-label-lg">
				{label} {requiredField && REQUIRED_MARK}
			</Label>
			<Col sm={inputColumn}>
				<InputGroup>
					<Input
						invalid={touched && error ? true : false}
						valid={touched && !error ? true : false}
						id={id}
						{...input}
						{...inputProps}
					/>
					<InputGroupAddon addonType="append">
						<InputGroupText>{sufix}</InputGroupText>
					</InputGroupAddon>
				</InputGroup>
				{touched && error && (
					<span className="input-error d-block">{error}</span>
				)}
			</Col>
			{children}
		</FormGroup>
	);
};

InputLabelAppend.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	inputColumn: PropTypes.string,
	children: PropTypes.object.isRequired,
	sufix: PropTypes.string,
	meta: PropTypes.object,
	requiredField: PropTypes.bool
};

export default InputLabelAppend;
