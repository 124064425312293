import React from 'react';
import { Basic } from './Info/Basic';

// Import components
import InfoAboveTitle from './InfoAboveTitle';
import Title from './Title';
import SummaryShort from './SummaryShort';
import InfoTabButtons from './InfoTabButtons/InfoTabButtons';
import Details from './Details';
import DescriptionSection from './DescriptionSection';
import SubInformations from './SubInformations';

const LiveEventInfo = () => {
	return (
		<>
			<InfoAboveTitle />

			<Title />

			<Basic />

			<SummaryShort />

			<InfoTabButtons />

			<Details />

			<DescriptionSection />

			<SubInformations />
		</>
	);
};

export default LiveEventInfo;
