import React from 'react';
import { useSelector } from 'react-redux';

// STYLES
import { Root } from './styles';

// COMPONENTS
import { FavouriteButton, PlayButton, RemindersButton } from '../Buttons';

// Import helpers
import { checkProductSubtype } from 'components/views/product_preview/helpers';

function InfoTabButtons() {
	const { data } = useSelector(({ product_preview }) => product_preview);
	const { subtype } = data;

	const { isSeries, isLiveEvent } = checkProductSubtype(subtype);

	return (
		<Root isSeries={isSeries}>
			{!isLiveEvent && <PlayButton />}
			{!isLiveEvent && <FavouriteButton />}
			{isLiveEvent && <RemindersButton />}
		</Root>
	);
}

export default InfoTabButtons;
