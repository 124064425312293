import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';

// Import defaultValues
import { defaultFieldValues, BASE_FIELDS } from './fields/input_fields';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';
import CSVForm from 'components/utilities/csv/form/CSVForm';

// Import helpers
import { convertDurationTime } from 'components/helpers/convert';
import { convertDataToIDS } from 'components/helpers/genres_helpers/helpers';
import { prepareMetricCSVData } from '../helpers/helpers';

// Import variables
import { DURATION_CONVERT_TYPES } from 'components/helpers/variables';

const { HMS_TO_SECONDS, SECONDS_TO_HMS } = DURATION_CONVERT_TYPES;

const ProductMetricFormVod = ({ history, productTypeData }) => {
	const { t } = useTranslation();

	// state change needed to trigger refetch data in Form Templates
	const [formToggle, setFormToggle] = useState(true);

	const { submitData, action, reduxPath } = productTypeData;

	// REDUX
	const dispatch = useDispatch();
	const { edit: initialValues, error, isLoaded } = useSelector(
		(state) => state[reduxPath.type][reduxPath.formType]
	);

	const { metadata, genres, tags } = initialValues;
	const additionalData = initialValues?.metadata?.additional_data;
	const additionalMetadata = initialValues?.metadata?.additional_metadata;

	const prepareAdditionalData = () => {
		if (additionalData?.length === 0 || !additionalData) {
			return defaultFieldValues.metadata.additional_data;
		} else {
			return additionalData;
		}
	};
	const prepareAdditionalMetadata = () => {
		if (additionalMetadata?.length === 0 || !additionalMetadata) {
			return defaultFieldValues.metadata.additional_metadata;
		} else {
			return additionalMetadata;
		}
	};

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const { metadata } = values;

		// convert duration from sec to min
		const durationInSeconds = convertDurationTime({
			duration: metadata.duration,
			type: HMS_TO_SECONDS
		});

		// selected genres mapped to array of ids
		const genresIds = convertDataToIDS(genres);

		// selected tags mapped to array of ids
		const tagsIds = convertDataToIDS(tags);

		// resources to submit
		const resources = {
			...initialValues,
			...values,
			genres: genresIds,
			metadata: {
				...metadata,
				duration: durationInSeconds
			},
			tags: tagsIds
		};

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: submitData.submitAPI,
			redirectPath: submitData.redirectPath,
			apiSlug: submitData.apiSlug
		});

		setFormToggle((prev) => !prev);
	};

	// convert duration from sec to hms
	const durationInHms = convertDurationTime({
		duration: metadata.duration,
		type: SECONDS_TO_HMS
	});

	// form initial values
	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		metadata: {
			...metadata,
			duration: durationInHms,
			additional_data: prepareAdditionalData(),
			additional_metadata: prepareAdditionalMetadata()
		}
	};

	const handleFetchResources = (...rest) => action(...rest)(dispatch);

	return (
		// Dispatch fetchVodDetails actions in vod_form
		<FormTemplate
			fetchResourceToEdit={handleFetchResources}
			error={error}
			isLoaded={isLoaded}
			triggerDataRefetch={formToggle}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, dirty }) => (
						<>
							<CSVForm
								data={formInitialValues}
								loading={!isLoaded}
								dirty={dirty}
								prepareDataFunction={prepareMetricCSVData}
								additionalResources={{ fieldsData: BASE_FIELDS }}
							/>
							<form onSubmit={handleSubmit} decorators={decorators}>
								<Fields
									isEdit={isEdit}
									submitting={submitting}
									checkedPermissions={checkedPermissions}
								/>
								<FormButtons
									isButtonDisabled={submitting || error}
									path="vod"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						</>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ProductMetricFormVod.propTypes = {
	history: PropTypes.object.isRequired,
	productTypeData: PropTypes.object.isRequired
};

export default withRouter(ProductMetricFormVod);
