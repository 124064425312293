// ******************** TAGS DATA TABLE ********************
export const FETCH_TAGS_LOADING = 'FETCH_TAGS_LOADING';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';

// ******************** SET SEARCH QUERY ********************
export const SET_TAGS_SEARCH_QUERY = 'SET_TAGS_SEARCH_QUERY';

// ******************** SAVE SORTING OPTIONS  ********************
export const SAVE_TAGS_TABLE_SORTING = 'SAVE_TAGS_TABLE_SORTING';

// ******************** DELETE TAGS_ITEM ********************
export const DELETE_TAGS_ITEM_LOADING = 'DELETE_TAGS_ITEM_LOADING';
export const DELETE_TAGS_ITEM_SUCCESS = 'DELETE_TAGS_ITEM_SUCCESS';
export const DELETE_TAGS_ITEM_ERROR = 'DELETE_TAGS_ITEM_ERROR';

// ******************** TAGS_ITEM ********************
export const FETCH_TAGS_ITEM_LOADING = 'FETCH_TAGS_ITEM_LOADING';
export const FETCH_TAGS_ITEM_SUCCESS = 'FETCH_TAGS_ITEM_SUCCESS';
export const FETCH_TAGS_ITEM_ERROR = 'FETCH_TAGS_ITEM_ERROR';
