import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPromotion } from 'store/actions';
import moment from 'moment';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { convertFormSubmitValues, convertFormInitialValues } from '../helpers';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import defaultValues
import { defaultFieldValues } from './fields/input_fields';

const PromotionsForm = ({
	history,
	fetchPromotion,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	//  -------------- INITIAL VALUES ---------------
	// evaluate initial values
	let entryConditionInitialValues = { entry_condition: false };
	let promotionPeriodInitialValues = defaultFieldValues.promotion_period;
	if (initialValues.entry_condition) {
		const {
			entry_condition: {
				purchases_threshold,
				products_specification,
				in_ttl,
				limit
			}
		} = initialValues;
		entryConditionInitialValues = {
			entry_condition: true,
			entry_condition_purchases_threshold: purchases_threshold,
			entry_condition_products_specification: products_specification,
			entry_condition_in_ttl: in_ttl,
			entry_condition_limit: limit
		};
	}
	if (initialValues.since && initialValues.till) {
		promotionPeriodInitialValues = [
			moment(initialValues.since),
			moment(initialValues.till)
		];
	}

	const formInitialValues = {
		...defaultFieldValues,
		...initialValues,
		...entryConditionInitialValues,
		promotion_value: convertFormInitialValues(
			initialValues.promotion_type,
			initialValues.promotion_value
		),
		promotion_period: promotionPeriodInitialValues
	};

	//  -------------- SUBMIT --------------
	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const {
			promotion_type,
			promotion_value,
			entry_condition,
			entry_condition_purchases_threshold,
			entry_condition_products_specification,
			entry_condition_in_ttl,
			entry_condition_limit,
			trigger,
			code,
			promotion_period,
			limit,
			description
		} = values;

		let sinceDateEvaluation;
		let tillDateEvaluation;

		if (promotion_period?.length) {
			// Promotion period evaluation
			sinceDateEvaluation = moment(promotion_period[0]).format(
				'YYYY-MM-DD HH:mm:ss'
			);
			tillDateEvaluation = moment(promotion_period[1]).format(
				'YYYY-MM-DD HH:mm:ss'
			);

			delete values.promotion_period;
		}

		// Promotion code evaluation
		let codeEvaluation = code;
		if (trigger === 'auto') codeEvaluation = null;

		//	Entry condition evaluation.
		//  If entry_condition is true apply proper values to entry condition object
		//  Delete values associeted with entry condition from values object
		let entryConditionEvaluation = null;
		if (entry_condition) {
			entryConditionEvaluation = {
				purchases_threshold: parseInt(entry_condition_purchases_threshold, 10),
				products_specification: entry_condition_products_specification,
				in_ttl: entry_condition_in_ttl
					? parseInt(entry_condition_in_ttl, 10)
					: null,
				limit: entry_condition_limit
					? parseInt(entry_condition_limit, 10)
					: null
			};
		}

		delete values.entry_condition_purchases_threshold;
		delete values.entry_condition_products_specification;
		delete values.entry_condition_in_ttl;
		delete values.entry_condition_limit;

		// apply proper date format to since and till, convert promotion value to proper unit
		// apply converted data
		const resources = {
			...values,
			since: sinceDateEvaluation || null,
			till: tillDateEvaluation || null,
			promotion_value: convertFormSubmitValues(promotion_type, promotion_value),
			entry_condition: entryConditionEvaluation,
			code: codeEvaluation,
			limit: limit ? limit : null,
			description: description || null
		};

		// reload form after editing promotion (check if item id is accesible, before reloading)
		// do not reload when creating promotion
		const reloadForm = () => {
			if (itemID) fetchPromotion(itemID);
			else return null;
		};

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'promotions',
			redirectPath: 'promotions',
			callback: reloadForm
		});
	};

	return (
		// Dispatch fetchPromotion actions in in promotions_form
		<FormTemplate
			fetchResourceToEdit={fetchPromotion}
			error={error}
			isLoaded={isLoaded}
		>
			{({ isEdit, itemID, checkedPermissions, decorators }) => (
				<Form
					initialValues={formInitialValues}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					validate={validate}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit} decorators={decorators}>
							<Fields
								submitting={submitting}
								isEdit={isEdit}
								currentValues={values}
								checkedPermissions={checkedPermissions}
							/>
							<FormButtons
								isButtonDisabled={submitting || error}
								path="promotions"
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

PromotionsForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchPromotion: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ promotions: { form } }) => {
	return {
		initialValues: form.edit,
		error: form.error,
		isLoaded: form.isLoaded
	};
};

export default compose(
	connect(mapStateToProps, { fetchPromotion }),
	withRouter
)(PromotionsForm);
