import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { SUCCEEDED, FAILED } from './helpers';

const UploadStatus = ({ uploaded_at }) => (
	<span>{uploaded_at ? SUCCEEDED : FAILED}</span>
);

UploadStatus.propTypes = {
	uploaded_at: PropTypes.string.isRequired
};

export default UploadStatus;
