import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Form } from 'react-final-form';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import utilities
import { submitForm } from 'components/utilities/form';
import { useDispatch } from 'react-redux';

// Import redux actions
import { fetchProductGlobalLimit } from 'store/actions';

// Validate
import validate from './validation';

const ProductGlobalLimitForm = ({ limits, productType }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		const resources = {
			...values,
			limit_size: values?.limit_size || null,
			product_type: productType
		};

		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'statistics/products',
			apiSlug: 'limits'
		});

		// We need fresh data of limits after submit
		fetchProductGlobalLimit(itemID)(dispatch);
	};

	return (
		<FormTemplate iboxContentTitle={t('global_limit:change_limit')}>
			{({ isEdit, itemID, decorators }) => (
				<Form
					validate={validate}
					decorators={decorators}
					onSubmit={handleOnSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields
								submitting={submitting}
								limits={limits}
								currentValues={values}
							/>
							<FormButtons
								isButtonDisabled={submitting}
								path="global_limit"
								buttonText={t('common:buttons.submit')}
								cancelButton={false}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

ProductGlobalLimitForm.propTypes = {
	limits: PropTypes.object.isRequired,
	productType: PropTypes.string.isRequired
};

export default ProductGlobalLimitForm;
