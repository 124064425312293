import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { TimePicker } from 'antd';
import moment from 'moment';

// Import helpers
import { REQUIRED_MARK } from 'components/helpers/variables';

const TimePickerLabel = ({
	input,
	id,
	label,
	placeholder = '',
	labelColumn = 2,
	inputColumn = 10,
	meta: { touched, error, submitError },
	requiredField = false,
	...inputProps
}) => {
	const value = input.value ? moment(input.value, 'HH:mm:ss') : null;

	return (
		<FormGroup row className={`${touched && error ? 'has-error' : ''} `}>
			<Label
				for={id}
				sm={labelColumn}
				className="col-form-label col-form-label-lg"
			>
				{label} {requiredField && REQUIRED_MARK}
			</Label>
			<Col sm={inputColumn}>
				<TimePicker
					{...input}
					{...inputProps}
					value={value}
					id={id}
					placeholder={placeholder}
				/>
				{touched && (error || submitError) && (
					<div>
						<span
							data-testid={`input-error-${input.name}`}
							className="input-error d-block"
						>
							{error || submitError}
						</span>
					</div>
				)}
			</Col>
		</FormGroup>
	);
};

TimePickerLabel.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	labelColumn: PropTypes.number,
	inputColumn: PropTypes.number,
	showTime: PropTypes.bool,
	format: PropTypes.string,
	meta: PropTypes.object,
	requiredField: PropTypes.bool
};

export default TimePickerLabel;
