import * as types from '../types';

// Import helpers
import { convertDataToNames } from 'components/helpers/genres_helpers/helpers';
import {
	convertProvidersToValuePairs,
	convertPaymentModelsSuggestions,
	convertStatusToValuePairs,
	convertEntriesToValuePairs,
	convertInstitutionsToValuePairs,
	convertTypeAgreementValues,
	convertRatingValues
} from 'store/actions/helpers_actions/convert_helpers';

// ******************** SET TABLE COLUMNS ********************
export const setTableColumns = (columns) => (dispatch) =>
	dispatch({
		type: types.SET_TABLE_COLUMNS,
		payload: columns
	});

// ******************** RESET FILTER ********************
export const resetTableFilters = ({ previousLocation, currentLocation }) => (
	dispatch
) => {
	// check the type in pathname of location (vod | season | packets | subscribers ...)
	let prevType = previousLocation.split('/')[2];
	if (prevType === 'vod') {
		if (previousLocation.split('/')[3])
			prevType = previousLocation.split('/')[3];
	}
	const currentLocationToArray = currentLocation.split('/');
	const currType = currentLocationToArray[2];

	const isPayments =
		currentLocationToArray[currentLocationToArray.lenght - 1] === 'payments';

	// if types are different reset table columns filters
	return (previousLocation && prevType !== currType) || isPayments
		? dispatch({
				type: types.FILTER_RESET,
				payload: null
		  })
		: null;
};

// ******************** SET FILTER URL QUERY ********************
export const setFilterUrlQuery = (urlQuery) => (dispatch) =>
	dispatch({
		type: types.SET_FILTER_URL_QUERY,
		payload: urlQuery
	});

// ******************** SET FILTER FORM VALUES ********************
export const setFilterFormValues = (values) => (dispatch) =>
	dispatch({
		type: types.SET_FILTER_FORM_VALUES,
		payload: values
	});

// ******************** CLEAR FILTERS ********************
export const clearFilters = () => (dispatch) =>
	dispatch({
		type: types.CLEAR_FILTERS,
		payload: null
	});

// ******************** CREATE FILTERS SUGGESTIONS ********************
export const createFiltersSuggestions = (filters) => (dispatch) => {
	const filtersSuggestions = { ...filters };

	if (filters.hasOwnProperty('genres')) {
		filtersSuggestions.genres = convertDataToNames(filters.genres);
	}
	if (filters.hasOwnProperty('providers')) {
		filtersSuggestions.provider_id = convertProvidersToValuePairs(
			filters.providers
		);
		delete filtersSuggestions.providers;
	}
	if (filters.hasOwnProperty('institutions')) {
		filtersSuggestions.products_group_uuid = convertInstitutionsToValuePairs(
			filters.institutions
		);
		delete filtersSuggestions.institutions;
	}
	if (filters.hasOwnProperty('payment_models')) {
		filtersSuggestions.payment_models = convertPaymentModelsSuggestions(
			filters.payment_models
		);
	}
	if (filters.hasOwnProperty('status')) {
		filtersSuggestions.status = convertStatusToValuePairs(filters.status);
	}
	// Because other filters have their own statuses that can be converted
	if (filters.hasOwnProperty('statusPayment')) {
		filtersSuggestions.status = filters.statusPayment;
		delete filtersSuggestions.statusPayment;
	}
	if (filters.hasOwnProperty('source')) {
		filtersSuggestions.source = convertEntriesToValuePairs(filters.source);
	}
	// Because other filters have own types, and we don't want to convert them
	if (filters.hasOwnProperty('agreementTypes')) {
		filtersSuggestions.type = convertTypeAgreementValues(
			filters.agreementTypes
		);
		delete filtersSuggestions.agreementTypes;
	}
	if (filters.hasOwnProperty('rating')) {
		filtersSuggestions.rating = convertRatingValues(filters.rating);
	}

	return dispatch({
		type: types.CREATE_FILTERS_SUGGESTIONS,
		payload: filtersSuggestions
	});
};

// ******************** SET FILTERS DISABLE ********************
export const setFiltersDisable = (value) => (dispatch) => {
	dispatch({
		type: types.SET_FILTERS_DISABLE,
		payload: value
	});
};
