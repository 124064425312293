/**
 * Function return correct path based on type of id (payments | subscribers | vod | articles | events_live | packets | passes)
 * @param {string} type - payments | subscribers | vod | articles | events_live | packets | passes
 * @param {string} id - id of subscriber | product
 * @returns {string}
 */
export const setUrl = (type, id) => {
	switch (type) {
		case 'subscribers':
			return id ? `user_id=${id}&` : '';

		case 'vod':
		case 'audio':
		case 'articles':
		case 'events_live':
		case 'packets':
		case 'passes':
			return `product_uuid=${id}&`;

		default:
			return '';
	}
};
