import {
	fetchVodAvailabilityForm,
	fetchChannelAvailabilityForm,
	fetchPacketAvailabilityForm,
	fetchMainAvailabilityForm,
	fetchArticleAvailabilityForm,
	fetchEventLiveAvailabilityForm,
	fetchPassAvailabilityForm
} from 'store/actions';

/**
 * Function that returns proper action for Form Template based on product type
 * @param {string} type - product type (channel | vod | packet | main)
 * @returns {function} - action creator for Form Template
 */
export const setProperAction = (type) => {
	switch (type) {
		case 'channels':
			return fetchChannelAvailabilityForm;
		case 'vod':
			return fetchVodAvailabilityForm;
		case 'packets':
			return fetchPacketAvailabilityForm;
		case 'main':
			return fetchMainAvailabilityForm;
		case 'articles':
			return fetchArticleAvailabilityForm;
		case 'events_live':
			return fetchEventLiveAvailabilityForm;
		case 'passes':
			return fetchPassAvailabilityForm;
		default:
			break;
	}
};
