// Import translation
import i18n from 'i18next';

// Import helpers
import { checkPinFormat } from 'components/helpers/validation_helpers';

export default ({
	parental_control_pin
	// payment_pin,
	// account_pin,
	// adult_access_pin,
	// npvr_storage_limit
}) => {
	const errors = {};

	const isPCPinValid =
		parental_control_pin && checkPinFormat(parental_control_pin);
	// const isPaymentPinValid = payment_pin && checkPinFormat(payment_pin);
	// const isAccountPinValid = account_pin && checkPinFormat(account_pin);
	// const isAdultAccessPinValid =
	// 	adult_access_pin && checkPinFormat(adult_access_pin);

	// ------------------ Parental_control_pin ------------------
	if (!parental_control_pin) {
		errors.parental_control_pin = i18n.t(
			'subscribers:validation.parental_control_pin_required'
		);
	} else if (!isPCPinValid) {
		errors.parental_control_pin = i18n.t('common:validation.pin_format');
	}
	/* ====== We don't need following validation of fields in this project ====== */

	// // ------------------ Payment_pin ------------------
	// if (!payment_pin) {
	// 	errors.payment_pin = i18n.t('subscribers:validation.payment_pin_required');
	// } else if (!isPaymentPinValid) {
	// 	errors.payment_pin = i18n.t('common:validation.pin_format');
	// }

	// // ------------------ Account pin ------------------
	// if (!account_pin) {
	// 	errors.account_pin = i18n.t('subscribers:validation.account_pin_required');
	// } else if (!isAccountPinValid) {
	// 	errors.account_pin = i18n.t('common:validation.pin_format');
	// }
	// // ------------------ Adult access pin ------------------
	// if (!adult_access_pin) {
	// 	errors.adult_access_pin = i18n.t(
	// 		'subscribers:validation.adult_access_pin_required'
	// 	);
	// } else if (!isAdultAccessPinValid) {
	// 	errors.adult_access_pin = i18n.t('common:validation.pin_format');
	// }
	// // ------------------ Npvr_storage_limit ------------------
	// if (!npvr_storage_limit) {
	// 	errors.npvr_storage_limit = i18n.t(
	// 		'subscribers:validation.npvr_storage_limit_required'
	// 	);
	// }

	return errors;
};
