import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchSubscriber } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

const SubscribersRegisterForm = ({
	history,
	fetchSubscriber,
	error,
	isLoaded,
	initialValues
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) =>
		// Submit the form with field values
		await submitForm({
			history,
			isEdit,
			itemID,
			resources: values,
			api: 'subscriber'
		});

	const formInitialValues = {
		...initialValues,
		credits_limit: 0
	};

	return (
		<>
			<FormTemplate
				fetchResourceToEdit={fetchSubscriber}
				error={error}
				isLoaded={isLoaded}
			>
				{({ isEdit, itemID, decorators }) => (
					<Form
						initialValues={formInitialValues}
						validate={validate}
						decorators={decorators}
						onSubmit={handleOnSubmit({ isEdit, itemID })}
						render={({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit}>
								<Fields isEdit={isEdit} submitting={submitting} />
								<FormButtons
									isButtonDisabled={submitting || error}
									path="subscribers"
									buttonText={t('common:buttons.submit')}
								/>
							</form>
						)}
					/>
				)}
			</FormTemplate>
		</>
	);
};

SubscribersRegisterForm.propTypes = {
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	fetchSubscriber: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = ({ subscribers: { form } }) => ({
	initialValues: form.edit,
	error: form.error,
	isLoaded: form.isLoaded
});

export default compose(
	connect(mapStateToProps, { fetchSubscriber }),
	withRouter
)(SubscribersRegisterForm);
