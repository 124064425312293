import i18n from 'i18next';

export const defaultFieldValues = {
	active: false,
	products_group_uuid: null,
	title: '',
	summary_short: '',
	type: 'pass',
	subtype: 'pass'
};

export const BASE_FIELDS = ({ isEdit, checkedPermissions }) => [
	{
		name: 'active',
		label: i18n.t('passes:fields.active'),
		type: 'checkbox',
		disabled: !checkedPermissions.canActivateProduct
	},
	{
		name: 'type',
		label: i18n.t('passes:fields.type'),
		type: 'select',
		requiredField: true,
		disabled: true
	},
	{
		name: 'products_group_uuid',
		label: i18n.t('passes:fields.products_group_uuid'),
		type: 'select',
		disabled: !isEdit || !checkedPermissions.canActivate
	},
	{
		name: 'data.promotion_id',
		label: i18n.t('passes:fields.promotion'),
		type: 'select',
		requiredField: true
	},
	{
		name: 'title',
		label: i18n.t('passes:fields.title'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'summary_short',
		label: i18n.t('passes:fields.summary_short'),
		type: 'textarea',
		requiredField: true
	},
	{
		name: 'data.created_at',
		label: i18n.t('passes:fields.created_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	},
	{
		name: 'data.updated_at',
		label: i18n.t('passes:fields.updated_at'),
		type: 'text',
		disabled: true,
		isVisible: !isEdit
	}
];
