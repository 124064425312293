// Import translation
import i18n from 'i18next';

export default ({ title, description, short_description, slug }) => {
	const errors = {};

	// ------------------ Title ------------------
	if (!title) {
		errors.title = i18n.t('provisioning:validation.title');
	} else if (title.length < 2 || title.length > 200) {
		errors.title = i18n.t('provisioning:validation.title_length');
	}
	// ------------------ Description ------------------
	if (!description) {
		errors.description = i18n.t('provisioning:validation.description');
	}
	// ------------------ Description short ------------------
	if (!short_description) {
		errors.short_description = i18n.t(
			'provisioning:validation.short_description'
		);
	}
	// ------------------ Slug ------------------
	if (!slug) {
		errors.slug = i18n.t('provisioning:validation.slug');
	}

	return errors;
};
