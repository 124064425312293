import * as types from '../../types';

// Import variables
import { GLOBAL_PRODUCT_TYPES } from 'components/helpers/variables';

const { CHANNEL, ARTICLE, EVENT_LIVE, PACKET, PASS } = GLOBAL_PRODUCT_TYPES;

export const setActionType = (productType) => {
	switch (productType) {
		case CHANNEL:
			return {
				tableLoading: types.FETCH_CHANNEL_VISIBILITY_TABLE_LOADING,
				tableSuccess: types.FETCH_CHANNEL_VISIBILITY_TABLE_SUCCESS,
				tableError: types.FETCH_CHANNEL_VISIBILITY_TABLE_ERROR,
				deleteLoading: types.DELETE_CHANNEL_VISIBILITY_LOADING,
				deleteSuccess: types.DELETE_CHANNEL_VISIBILITY_SUCCESS,
				deleteError: types.DELETE_CHANNEL_VISIBILITY_ERROR,
				formLoading: types.FETCH_CHANNEL_VISIBILITY_FORM_LOADING,
				formSuccess: types.FETCH_CHANNEL_VISIBILITY_FORM_SUCCESS,
				formError: types.FETCH_CHANNEL_VISIBILITY_FORM_ERROR
			};
		case ARTICLE:
			return {
				tableLoading: types.FETCH_ARTICLE_VISIBILITY_TABLE_LOADING,
				tableSuccess: types.FETCH_ARTICLE_VISIBILITY_TABLE_SUCCESS,
				tableError: types.FETCH_ARTICLE_VISIBILITY_TABLE_ERROR,
				deleteLoading: types.DELETE_ARTICLE_VISIBILITY_LOADING,
				deleteSuccess: types.DELETE_ARTICLE_VISIBILITY_SUCCESS,
				deleteError: types.DELETE_ARTICLE_VISIBILITY_ERROR,
				formLoading: types.FETCH_ARTICLE_VISIBILITY_FORM_LOADING,
				formSuccess: types.FETCH_ARTICLE_VISIBILITY_FORM_SUCCESS,
				formError: types.FETCH_ARTICLE_VISIBILITY_FORM_ERROR
			};
		case EVENT_LIVE:
			return {
				tableLoading: types.FETCH_EVENT_LIVE_VISIBILITY_TABLE_LOADING,
				tableSuccess: types.FETCH_EVENT_LIVE_VISIBILITY_TABLE_SUCCESS,
				tableError: types.FETCH_EVENT_LIVE_VISIBILITY_TABLE_ERROR,
				deleteLoading: types.DELETE_EVENT_LIVE_VISIBILITY_LOADING,
				deleteSuccess: types.DELETE_EVENT_LIVE_VISIBILITY_SUCCESS,
				deleteError: types.DELETE_EVENT_LIVE_VISIBILITY_ERROR,
				formLoading: types.FETCH_EVENT_LIVE_VISIBILITY_FORM_LOADING,
				formSuccess: types.FETCH_EVENT_LIVE_VISIBILITY_FORM_SUCCESS,
				formError: types.FETCH_EVENT_LIVE_VISIBILITY_FORM_ERROR
			};
		case PASS:
			return {
				tableLoading: types.FETCH_PASS_VISIBILITY_TABLE_LOADING,
				tableSuccess: types.FETCH_PASS_VISIBILITY_TABLE_SUCCESS,
				tableError: types.FETCH_PASS_VISIBILITY_TABLE_ERROR,
				deleteLoading: types.DELETE_PASS_VISIBILITY_LOADING,
				deleteSuccess: types.DELETE_PASS_VISIBILITY_SUCCESS,
				deleteError: types.DELETE_PASS_VISIBILITY_ERROR,
				formLoading: types.FETCH_PASS_VISIBILITY_FORM_LOADING,
				formSuccess: types.FETCH_PASS_VISIBILITY_FORM_SUCCESS,
				formError: types.FETCH_PASS_VISIBILITY_FORM_ERROR
			};
		case PACKET:
			return {
				tableLoading: types.FETCH_PACKET_VISIBILITY_TABLE_LOADING,
				tableSuccess: types.FETCH_PACKET_VISIBILITY_TABLE_SUCCESS,
				tableError: types.FETCH_PACKET_VISIBILITY_TABLE_ERROR,
				deleteLoading: types.DELETE_PACKET_VISIBILITY_LOADING,
				deleteSuccess: types.DELETE_PACKET_VISIBILITY_SUCCESS,
				deleteError: types.DELETE_PACKET_VISIBILITY_ERROR,
				formLoading: types.FETCH_PACKET_VISIBILITY_FORM_LOADING,
				formSuccess: types.FETCH_PACKET_VISIBILITY_FORM_SUCCESS,
				formError: types.FETCH_PACKET_VISIBILITY_FORM_ERROR
			};

		default:
			return {
				tableLoading: types.FETCH_VOD_VISIBILITY_TABLE_LOADING,
				tableSuccess: types.FETCH_VOD_VISIBILITY_TABLE_SUCCESS,
				tableError: types.FETCH_VOD_VISIBILITY_TABLE_ERROR,
				deleteLoading: types.DELETE_VOD_VISIBILITY_LOADING,
				deleteSuccess: types.DELETE_VOD_VISIBILITY_SUCCESS,
				deleteError: types.DELETE_VOD_VISIBILITY_ERROR,
				formLoading: types.FETCH_VOD_VISIBILITY_FORM_LOADING,
				formSuccess: types.FETCH_VOD_VISIBILITY_FORM_SUCCESS,
				formError: types.FETCH_VOD_VISIBILITY_FORM_ERROR
			};
	}
};
