import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from '../styles';

const GradientBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: ${({ containerScreenPercent }) => `${containerScreenPercent}`}vh;
	overflow: hidden;
	z-index: ${({ zIndex = 2 }) => zIndex};

	&:before,
	&:after {
		display: block;
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&:before {
		background: radial-gradient(circle at top left, #070606, transparent 50%);
	}

	&:after {
		height: ${({ mobileHeight }) =>
			mobileHeight ? `${mobileHeight}vh` : '65vh'};
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 1) 5%,
			rgba(0, 0, 0, 0) 30%,
			rgba(0, 0, 0, 0) 100%
		);

		${customMinResponsive(
			size.medium,
			css`
				height: 100%;
			`
		)}
	}
`;

export default GradientBg;
