import { useEffect } from 'react';
import services from 'services/services';
import { useDispatch, useSelector } from 'react-redux';
import { clearPanelState, fetchSubscriber } from 'store/actions';

// Import utilities
import { messageConfirmClearHandler } from 'components/utilities/message/confirm_message';
import { notificationHandler } from 'components/utilities/notifications';

// Import translation
import { useTranslation } from 'react-i18next';

// Import variables
import { SUBSCRIBER_SOURCE_TYPES } from 'components/helpers/variables';

const useSubscribersPackets = ({ id }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		fetchSubscriber(id)(dispatch);
		// eslint-disable-next-line
	}, []);

	const subscriberData = useSelector(
		({ subscribers }) => subscribers.form.edit
	);

	// conditions are written this way to always hide button and table until data is fetched
	let showClearPermissionsButton = false;
	let showSubscriptionsTable = false;
	if (subscriberData?.source) {
		if (subscriberData?.source === SUBSCRIBER_SOURCE_TYPES.PURE_OTT) {
			showSubscriptionsTable = true;
		} else {
			showClearPermissionsButton = true;
		}
	}

	const clearPermissions = async (subscriberId) => {
		try {
			// clear permissions request
			await services.post(`/subscriber/esales/cache/${subscriberId}/reset`);

			// show success notification
			notificationHandler(
				t('messages:notifications.commissioned'),
				t('messages:notifications.refresh_subscriber_permissions_success')
			);

			// reload table
			clearPanelState();
		} catch (error) {
			// show error notification
			notificationHandler(
				t('messages:notifications.error'),
				t('common:errors.common_error_message'),
				'error'
			);
		}
	};

	const handleClearPermissions = () => {
		messageConfirmClearHandler(id, clearPermissions);
	};

	return {
		clearPermissions: handleClearPermissions,
		showClearPermissionsButton,
		showSubscriptionsTable
	};
};

export default useSubscribersPackets;
