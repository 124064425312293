import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

export const createProvisioningColumns = () => [
	{
		Header: i18n.t('subscribers:table_columns.provisioning_title'),
		accessor: 'title',
		sortable: false,
		Cell: ({
			row: {
				_original: { uuid, title }
			}
		}) => <Link to={`/panel/provisioning/edit/${uuid}/metadata`}>{title}</Link>
	}
];

export const createSubscriptionsColumns = () => [
	{
		Header: i18n.t('subscribers:table_columns.subscriptions_title'),
		accessor: 'product_title',
		sortable: false,
		Cell: ({
			row: {
				_original: { product_uuid, product_title }
			}
		}) => (
			<Link to={`/panel/main/edit/${product_uuid}/metadata`}>
				{product_title}
			</Link>
		)
	}
];
