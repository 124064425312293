import i18n from 'i18next';

export const METADATA_FIELDS = [
	{
		name: 'metadata.title',
		label: i18n.t('asset_groups:fields.title'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.author',
		label: i18n.t('asset_groups:fields.author'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.description',
		label: i18n.t('asset_groups:fields.description'),
		type: 'textarea',
		required: false
	},
	{
		name: 'metadata.source',
		label: i18n.t('asset_groups:fields.source'),
		type: 'text',
		required: false
	}
];
