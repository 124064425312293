import React, { useMemo } from 'react';
import createDecorator from 'final-form-focus';
import { useParams, useLocation } from 'react-router-dom';

// Import permissions
import usePermissionsCheck from 'hooks/usePermissionsCheck';

const useForm = ({
	title = '',
	fetchResourceToEdit = () => {},
	fetchFormResources = [],
	fetchActionResources,
	triggerDataRefetch
}) => {
	const { id } = useParams();
	const { pathname } = useLocation();

	// Get pathname
	const isEdit = pathname.split('/').includes('create');

	// Check the params id
	const itemID = id || null;

	// Handle fetch form resources
	const handleFetchResources = async () => {
		// Fetch fag category
		// Dispatch an action
		if (fetchFormResources.length > 0) {
			// eslint-disable-next-line
			for (const fetchResource of fetchFormResources) {
				await fetchResource();
			}
		}
		// If there is resource edit request
		// Dispatch an action
		!isEdit && fetchResourceToEdit(itemID, fetchActionResources);
	};

	React.useEffect(() => {
		// Fetch resources
		handleFetchResources();
		// eslint-disable-next-line
	}, [triggerDataRefetch]);

	// Define breadcrumbs
	const checkBreadcrumbs = isEdit ? 'Create' : 'Edit';
	const breadcrumbs = [`${title} List`, `${checkBreadcrumbs} ${title}`];

	// Form decorators
	const decorators = useMemo(() => {
		const focusOnErrors = createDecorator();
		return [focusOnErrors];
	}, []);

	const { checkedPermissions } = usePermissionsCheck();

	return {
		breadcrumbs,
		isEdit,
		itemID,
		checkedPermissions,
		decorators
	};
};

export default useForm;
