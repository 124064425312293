import i18n from 'i18next';

export const ARTICLES_INPUT_FIELDS_DATA = {
	submitUrl: 'products/articles',
	fetchUrl: 'products/articles',
	cancelButtonPath: 'articles',
	hasMetadata: false,
	defautlFieldValues: {
		title: '',
		summary_long: '',
		summary_short: '',
		data: {
			sub_title: '',
			sub_summary_long: '',
			sub_summary_short: ''
		}
	},
	inputFields: [
		{
			name: 'title',
			label: i18n.t('articles:fields.title'),
			type: 'text',
			required: true
		},
		{
			name: 'summary_short',
			label: i18n.t('articles:fields.summary_short'),
			type: 'textarea',
			required: false
		},
		{
			name: 'summary_long',
			label: i18n.t('articles:fields.summary_long'),
			type: 'editor',
			required: true,
			disabledAddingImage: true,
			disabledAddingMedia: true
		},
		{
			name: 'data.sub_title',
			label: i18n.t('articles:fields.sub_title'),
			type: 'text',
			required: false
		},
		{
			name: 'data.sub_summary_short',
			label: i18n.t('articles:fields.sub_summary_short'),
			type: 'textarea',
			required: false
		},
		{
			name: 'data.sub_summary_long',
			label: i18n.t('articles:fields.sub_summary_long'),
			type: 'editor',
			required: false,
			disabledAddingImage: true,
			disabledAddingMedia: true
		}
	]
};
