import { useEffect, useState } from 'react';
import services from 'services/services';
import moment from 'moment';

// Import helpers
import { convertUTCDateToLocalDate } from 'components/helpers/convert';

/**
 * Custom hook that prepares initial dates for price form based
 * on product availability since and till values
 */
const usePriceDefaultValues = ({ productType, itemID }) => {
	const [initialDates, setInitialDates] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	// Function that fetches and converts data to
	const prepareInitialDates = async () => {
		try {
			setIsLoading(true);
			// fetch data
			const {
				data: { data }
			} = await services.get(
				`/timetables/availability/listByProduct/${productType}/${itemID}`
			);

			setIsLoading(false);
			// if there is data convert it and save in state
			if (data.length) {
				const lastAddedAvailability = data[data.length - 1];
				const { since, till } = convertRawDates(lastAddedAvailability);

				setInitialDates([since, till]);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	// convert data to object with local date of since and local date of till
	const convertRawDates = (data) => {
		const { since, till } = data;

		const convertedData = {
			since: moment(convertUTCDateToLocalDate(since)),
			till: moment(convertUTCDateToLocalDate(till))
		};

		return convertedData;
	};

	useEffect(() => {
		prepareInitialDates();
		// eslint-disable-next-line
	}, []);

	return { initialDates, isLoading };
};

export default usePriceDefaultValues;
