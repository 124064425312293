import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

import useFields from './useFields';

const Fields = (props) => {
	const { selectResources, submitting, geoblockGroupsChange } = useFields(
		props
	);

	return (
		<>
			{BASE_FIELDS({ geoblockGroupsChange }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	geoblockGroups: PropTypes.array.isRequired,
	countries: PropTypes.array.isRequired
};

export default Fields;
