import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchProductVisibility, deleteProductVisibility } from 'store/actions';

// Import translation
import { useTranslation } from 'react-i18next';

// Import columns
import { createColumns } from './product_visibility_columns';

// Import components
import { TableTemplate } from 'components/common/templates';

// Import hooks
import useProductVisibility from './useProductVisibility';

const ProductVisibility = ({
	productType,
	fetchProductVisibility,
	deleteProductVisibility,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	const {
		fetchActionResources,
		deleteActionResources,
		tableResources,
		error
	} = useProductVisibility(productType);

	return (
		<TableTemplate
			itemId={id}
			error={error}
			type={`${productType}/${id}/visibility`}
			typeText="visibility"
			addLinkText={t('visibility:buttons.add')}
			resources={tableResources}
			columns={createColumns}
			fetchResourcesAction={fetchProductVisibility}
			resourcesForFetchAction={fetchActionResources}
			deleteItemAction={deleteProductVisibility}
			resourcesForDeleteAction={deleteActionResources}
			notificationDeleteSuccessTxt={t('visibility:notification.delete_success')}
			notificationDeleteErrorTxt={t('visibility:notification.delete_error')}
		/>
	);
};

ProductVisibility.propTypes = {
	productType: PropTypes.string.isRequired,
	fetchProductVisibility: PropTypes.func.isRequired,
	deleteProductVisibility: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

export default compose(
	connect(null, {
		fetchProductVisibility,
		deleteProductVisibility
	}),
	withRouter
)(ProductVisibility);
