import i18n from 'i18next';

export const MENU_INPUT_FIELDS_DATA = {
	submitUrl: 'menus',
	fetchUrl: 'menus',
	cancelButtonPath: 'menus',
	hasMetadata: false,
	defautlFieldValues: {
		name: ''
	},
	inputFields: [
		{
			name: 'name',
			label: i18n.t('menu:fields.name'),
			type: 'text',
			required: true
		}
	]
};
