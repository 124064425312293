// ************* FILTERS *************
// hardcoded data for filters suggestions
// ------------ INGESTER ------------
export const encodingStatus = [
	'STATUS_WAITING',
	'STATUS_READY',
	'STATUS_QUEUED',
	'STATUS_PROCESSING',
	'STATUS_SUCCESS',
	'STATUS_FINISHED',
	'STATUS_FAILED'
];

export const encryptionType = ['PLAYREADY', 'FAIRPLAY'];

export const ingestionAssetStatus = [
	'STATUS_NEW',
	'STATUS_READY',
	'STATUS_QUEUED',
	'STATUS_PROCESSING',
	'STATUS_DOWNLOADING',
	'STATUS_UPLOADING',
	'STATUS_TRANSFER_FINISHED',
	'STATUS_SUCCESS',
	'STATUS_REPLACED',
	'STATUS_FAILED',
	'STATUS_FAILED_FINAL'
];

export const ingestionTaskStatus = [
	'STATUS_NEW',
	'STATUS_READY_UPDATE',
	'STATUS_PROCESSING',
	'STATUS_SUCCESS',
	'STATUS_OMITTED',
	'STATUS_QUEUED',
	'STATUS_FAILED',
	'STATUS_FAILED_FINAL'
];

export const MCStatus = [
	'TO_ENCODE',
	'ENCODING',
	'ENCODED',
	'CANCELLED',
	'UNKNOWN'
];

export const assetsType = ['Movie', 'Poster', 'Preview'];
