import services from 'services/services';
import * as types from '../types';

// Import helpers
import { convertDataToMetadata } from './helpers';
import { checkFetchErrors } from '../helpers_actions/convert_helpers';

export const createProductPreviewData = (data) => async (dispatch) => {
	dispatch({
		type: types.CREATE_PRODUCT_PREVIEW_DATA,
		payload: convertDataToMetadata(data)
	});
};

// ******************** FETCH PRODUCT PREVIEW ASSET GROUPS ********************
export const fetchProductPreviewAssetGroups = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_PRODUCT_PREVIEW_ASSET_GROUP_LOADING
		});

		const url = `/asset_groups/list?model_id=${id}&with_assets=1`;

		const {
			data: { data }
		} = await services.get(url);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_PRODUCT_PREVIEW_ASSET_GROUP_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_PRODUCT_PREVIEW_ASSET_GROUP_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
