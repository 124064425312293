import i18n from 'i18next';

// Import helpers
import { filterOption } from 'components/helpers/fields';

export const BASE_FIELDS = ({ geoblockGroupsChange }) => [
	{
		name: 'name',
		label: i18n.t('geoblock_groups:fields.name'),
		type: 'text',
		requiredField: true
	},
	{
		name: 'geoblock_groups',
		label: i18n.t('geoblock_groups:fields.geoblock_groups'),
		type: 'custom-names',
		mode: 'multiple',
		showSearch: true,
		labelColumn: 2,
		inputColumn: 10,
		optionFilterProp: 'children',
		filterOption,
		onChangeCallback: geoblockGroupsChange
	},
	{
		name: 'countries',
		label: i18n.t('geoblock_groups:fields.countries'),
		type: 'custom-names',
		requiredField: true,
		mode: 'multiple',
		showSearch: true,
		labelColumn: 2,
		inputColumn: 10,
		optionFilterProp: 'children',
		filterOption
	}
];
