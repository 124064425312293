import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Spinner } from 'reactstrap';

/**
 * Function component that renders info about queued and in progress materials
 * in ingester. It shows checkmark if there are no materials being progressed.
 * It shows dedicated spinner in other case.
 * @param {number} number
 * @param {string} color
 * @param {string} label
 * @returns {*}
 */
const StatNumber = ({ number, color, label }) => {
	const showSpinner =
		number === 0 ? (
			<i className="fa fa-check m-r-sm stat-icon" />
		) : (
			<Spinner color={color} />
		);

	return (
		<div>
			{showSpinner}
			<span className="stat-label m-l-sm">{`${label}:`}</span>
			<span className="stat-label stat-label__number m-l-sm">{number}</span>
		</div>
	);
};

StatNumber.propTypes = {
	number: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired
};

export default StatNumber;
