import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	fetchEventLiveMaterials,
	updateEventLiveMaterials,
	updateEventLiveMaterialsError,
	deleteEventLiveMaterials,
	deleteEventLiveMaterialsError,
	addVideoToEventLive
} from 'store/actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import helpers
import { EVENTS_LIVE_MATERIALS_LIMIT } from 'components/helpers/variables';

// Import columns
import { createColumns } from 'components/utilities/table/materials_table/materials_columns';

// Import components
import MaterialsTable from 'components/utilities/table/materials_table/MaterialsTable';
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import { Empty } from 'antd';
import EventsLiveAddMaterialsButton from 'components/common/buttons/add_materials_buttons/EventsLiveAddMaterialsButton';

// Import translation
import { useTranslation } from 'react-i18next';

const EventsLiveMaterials = ({
	fetchEventLiveMaterials,
	updateEventLiveMaterials,
	updateEventLiveMaterialsError,
	deleteEventLiveMaterials,
	deleteEventLiveMaterialsError,
	addVideoToEventLive,
	error,
	isLoaded,
	main,
	preview,
	match
}) => {
	// fetch channel materials on mount
	const uuid = match.params.id;
	React.useEffect(() => {
		// Fetch resources
		fetchEventLiveMaterials(uuid);
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	// disable Add Video button if there is already main video created
	const isMainDisabled = main.length !== 0;

	return (
		<Wraper lg="12" error={error}>
			<Ibox>
				<EventsLiveAddMaterialsButton
					isLoaded={isLoaded}
					isMainDisabled={isMainDisabled}
					uuid={uuid}
					addVideoToEventLive={addVideoToEventLive}
				/>
				<IboxTitle title={t('common:materials_table.title')} />
				<IboxContentBase isLoaded={isLoaded}>
					<div className="h-150">
						{isLoaded &&
							(main.length || preview.length ? (
								<React.Fragment>
									{main.map((resource) => (
										<MaterialsTable
											key={resource.id}
											columns={createColumns}
											resources={resource}
											updateMaterials={updateEventLiveMaterials}
											updateMaterialsError={updateEventLiveMaterialsError}
											deleteMaterials={deleteEventLiveMaterials}
											deleteMaterialsError={deleteEventLiveMaterialsError}
											materialsLimit={EVENTS_LIVE_MATERIALS_LIMIT}
										/>
									))}
									{preview.map((resource) => (
										<MaterialsTable
											key={resource.id}
											columns={createColumns}
											resources={resource}
											updateMaterials={updateEventLiveMaterials}
											updateMaterialsError={updateEventLiveMaterialsError}
											deleteMaterials={deleteEventLiveMaterials}
											deleteMaterialsError={deleteEventLiveMaterialsError}
											materialsLimit={EVENTS_LIVE_MATERIALS_LIMIT}
										/>
									))}
								</React.Fragment>
							) : (
								<Empty description={t('common:no_data')} className="m-t-md" />
							))}
					</div>
				</IboxContentBase>
			</Ibox>
		</Wraper>
	);
};

const mapStateToProps = ({ events_live: { materials } }) => {
	return {
		error: materials.error,
		isLoaded: materials.isLoaded,
		main: materials.main.data,
		preview: materials.preview.data
	};
};

EventsLiveMaterials.propTypes = {
	fetchEventLiveMaterials: PropTypes.func.isRequired,
	updateEventLiveMaterials: PropTypes.func.isRequired,
	updateEventLiveMaterialsError: PropTypes.func.isRequired,
	deleteEventLiveMaterials: PropTypes.func.isRequired,
	deleteEventLiveMaterialsError: PropTypes.func.isRequired,
	addVideoToEventLive: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	main: PropTypes.array.isRequired,
	preview: PropTypes.array.isRequired,
	match: PropTypes.object.isRequired
};

export default compose(
	connect(mapStateToProps, {
		fetchEventLiveMaterials,
		updateEventLiveMaterials,
		updateEventLiveMaterialsError,
		deleteEventLiveMaterials,
		deleteEventLiveMaterialsError,
		addVideoToEventLive
	}),
	withRouter
)(EventsLiveMaterials);
