import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import fields
import { BASE_FIELDS, SOCIAL_MEDIA_FIELDS } from './input_fields';
import { renderFields } from 'components/utilities/form/renderFields';

// Import select types
import { documents_select_types } from './select_type_options';

const Fields = ({
	submitting,
	checkedPermissions,
	currentValues,
	documentsList
}) => {
	const { t } = useTranslation();

	const selectResources = {
		'additional_data.statute_alias': [
			...documents_select_types,
			...documentsList
		]
	};

	return (
		<>
			{BASE_FIELDS({ checkedPermissions, currentValues }).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
			<h3>{t('common:form:field_groups.additional_data_social_media')}</h3>
			<div className="hr-line-solid">&nbsp;</div>
			{SOCIAL_MEDIA_FIELDS.map((item, index) =>
				renderFields({ item, index, submitting })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	checkedPermissions: PropTypes.object.isRequired,
	currentValues: PropTypes.object.isRequired
};

export default Fields;
