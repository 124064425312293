import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { dictionaryPeriodUnit } from 'store/actions';

// Import helpers
import { convertRangeToDates } from 'components/helpers';
import { convertPriceToCents } from 'components/helpers/convert';
import { convertProductType } from 'components/common/templates/helpers';

// Import utilities
import { submitForm } from 'components/utilities/form';

// Validate
import validate from './validation';

// Import components
import Loader from 'components/common/loaders/Loader';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import hooks
import usePriceDefaultValues from './usePriceDefaultValues';

// Import defaultValues
import { defaultFieldValues } from './helpers';

// Import variables
import { CORE_PATHS, PAYMENT_TYPES } from 'components/helpers/variables';

// Import utilities
import { useForm } from 'components/utilities/form';

const { MAIN } = CORE_PATHS;
const { BLUEMEDIA, SUBSCRIPTION_BLUEMEDIA } = PAYMENT_TYPES;

const PriceFormTemplate = ({
	history,
	match: {
		params: { type, id: itemID }
	},
	prevPath,
	dictionaryPeriodUnit
}) => {
	const { t } = useTranslation();

	const productType = convertProductType(type);
	const isPacketMain = type === MAIN;

	// prepare initial values for form
	const { initialDates, isLoading } = usePriceDefaultValues({
		itemID,
		productType
	});

	// Remove "/panel/" from prevPath;
	const redirectPath = prevPath.slice(7);

	const { isEdit, decorators } = useForm({
		fetchFormResources: [dictionaryPeriodUnit]
	});

	const handleFormSubmit = async (values) => {
		const { price, period, range, base_amount } = values;

		const [since, till] = convertRangeToDates(range);

		const resources = {
			...values,
			since,
			till,
			base_amount: convertPriceToCents(base_amount),
			price: convertPriceToCents(price),
			period: parseInt(period, 10),
			product_uuid: itemID
		};

		delete resources.range;

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources,
			api: 'timetables/prices',
			redirectPath,
			redirectToPath: true
		});
	};

	const formInitialValues = {
		...defaultFieldValues,
		range: initialDates ?? [],
		product_type: productType,
		payment_method: isPacketMain ? SUBSCRIPTION_BLUEMEDIA : BLUEMEDIA
	};

	return (
		<Container fluid className="bg-white py-5">
			<Loader isLoaded={!isLoading}>
				<Form
					initialValues={formInitialValues}
					validate={validate}
					decorators={decorators}
					onSubmit={handleFormSubmit}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} />

							<FormButtons
								isButtonDisabled={submitting}
								path={redirectPath}
								buttonText={t('common:buttons.submit')}
							/>
						</form>
					)}
				/>
			</Loader>
		</Container>
	);
};

PriceFormTemplate.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ type: PropTypes.string.isRequired })
	}),
	prevPath: PropTypes.string.isRequired,
	dictionaryPeriodUnit: PropTypes.func.isRequired
};

const mapStateToProps = ({ previous_location: { pathname } }) => ({
	prevPath: pathname
});

export default compose(
	connect(mapStateToProps, { dictionaryPeriodUnit }),
	withRouter
)(PriceFormTemplate);
