// Import translation
import i18n from 'i18next';

export default ({ code }) => {
	const errors = {};

	// ------------------ code ------------------
	if (!code) {
		errors.code = i18n.t('affiliate_partners:validation.code');
	} else if (!/^[a-z0-9_]*$/i.test(code)) {
		errors.code = i18n.t('affiliate_partners:validation.code_wrong');
	} else if (code.length > 40) {
		errors.code = i18n.t('affiliate_partners:validation.code_length');
	}

	return errors;
};
