import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProductPreviewData } from 'store/actions';
import {
	getFetchCoverAction,
	getFetchDetailsAction,
	getFetchGalleryAction,
	getFetchPosterAction,
	getFetchTitleAction,
	getNameState
} from './helpers';
import { GLOBAL_PRODUCT_TYPES } from 'components/helpers/variables';

const { SERIES } = GLOBAL_PRODUCT_TYPES;

const useProductPreview = ({ id, type }) => {
	const dispatch = useDispatch();

	const fetchDetailsAction = getFetchDetailsAction(type);
	const fetchCoverAction = getFetchCoverAction(type);
	const fetchTileAction = getFetchTitleAction(type);
	const fetchPosterAction = getFetchPosterAction(type);
	const fetchGalleryAction = getFetchGalleryAction(type);

	const nameState = getNameState(type);

	const { series_form, form, files } = useSelector((state) => state[nameState]);

	const isProductLoaded = form?.isLoaded;
	const isSeriesLoaded = series_form?.isLoaded;

	const fetchAllResources = () => {
		fetchDetailsAction(id)(dispatch);
		fetchCoverAction(id)(dispatch);
		fetchTileAction(id)(dispatch);
		fetchPosterAction(id)(dispatch);
		fetchGalleryAction(id)(dispatch);
	};

	useEffect(() => {
		fetchAllResources();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isProductLoaded || isSeriesLoaded) {
			const isSeries = type === SERIES;
			const productData = isSeries
				? { ...series_form.edit, images: files }
				: { ...form.edit, images: files };

			createProductPreviewData(productData)(dispatch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, series_form, files]);
};

export default useProductPreview;
