// Import translation
import i18n from 'i18next';

export const defaultFieldValues = {
	metadata: {
		title: '',
		directors: [],
		actors: [],
		writers: [],
		summary_short: '',
		summary_long: '',
		additional_data: {
			payment: false,
			exclusive_license: false,
			material_expiration: false
		},
		additional_metadata: {
			authors: [],
			lead: [],
			dramaturgy: [],
			composition: []
		}
	}
};

export const BASE_FIELDS = [
	{
		name: 'metadata.additional_data.licence_agreement_number',
		label: i18n.t('metric:form:fields.licence_agreement_number'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.title',
		label: i18n.t('vod:form:fields.title'),
		type: 'text',
		required: false,
		disabled: true
	},
	{
		name: 'metadata.directors',
		label: i18n.t('vod:form:fields.directors'),
		type: 'multi-select',
		mode: 'tags',
		required: false,
		disabled: true
	},
	{
		name: 'metadata.actors',
		label: i18n.t('vod:form:fields.actors'),
		type: 'multi-select',
		mode: 'tags',
		required: false,
		disabled: true
	},
	{
		name: 'metadata.duration',
		label: i18n.t('vod:form:fields.duration'),
		type: 'time',
		required: false,
		disabled: true
	},
	{
		name: 'metadata.additional_data.premiere',
		label: i18n.t('metric:form:fields.premiere'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.year',
		label: i18n.t('vod:form:fields.year'),
		type: 'text',
		required: false,
		disabled: true
	},
	{
		name: 'metadata.additional_data.handicap_helper_type',
		label: i18n.t('metric:form:fields.handicap_helper_type'),
		type: 'multi-select',
		mode: 'multiple',
		required: false
	},
	{
		name: 'metadata.summary_short',
		label: i18n.t('vod:form:fields.summary_short'),
		type: 'textarea',
		required: false,
		disabled: true
	},
	{
		name: 'metadata.summary_long',
		label: i18n.t('vod:form:fields.summary_long'),
		type: 'editor',
		required: false,
		disabled: true
	},
	{
		name: 'metadata.additional_data.curator',
		label: i18n.t('metric:form:fields.curator'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_metadata.authors',
		label: i18n.t('common:form:fields.additional_metadata.authors'),
		type: 'multi-select',
		mode: 'tags',
		required: false,
		disabled: true,
		dropdownStyle: { display: 'none' }
	},
	{
		name: 'metadata.additional_data.band_name',
		label: i18n.t('metric:form:fields.band_name'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.musicians',
		label: i18n.t('metric:form:fields.musicians'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.conductor',
		label: i18n.t('metric:form:fields.conductor'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.concept',
		label: i18n.t('metric:form:fields.concept'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_metadata.lead',
		label: i18n.t('common:form:fields.additional_metadata.lead'),
		type: 'multi-select',
		mode: 'tags',
		required: false,
		disabled: true,
		dropdownStyle: { display: 'none' }
	},
	{
		name: 'metadata.writers',
		label: i18n.t('vod:form:fields.writers'),
		type: 'multi-select',
		mode: 'tags',
		required: false,
		disabled: true
	},
	{
		name: 'metadata.additional_data.original_book_title',
		label: i18n.t('metric:form:fields.original_book_title'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.original_book_title_author',
		label: i18n.t('metric:form:fields.original_book_title_author'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.text_author',
		label: i18n.t('metric:form:fields.text_author'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.text_author_share',
		label: i18n.t('metric:form:fields.text_author_share'),
		type: 'text',
		required: false,
		fieldInfo: i18n.t('metric:form:fields_info.text_author_share')
	},
	{
		name: 'metadata.additional_data.comment_author',
		label: i18n.t('metric:form:fields.comment_author'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.comment_author_share',
		label: i18n.t('metric:form:fields.comment_author_share'),
		type: 'text',
		required: false,
		fieldInfo: i18n.t('metric:form:fields_info.comment_author_share')
	},
	{
		name: 'metadata.additional_data.dialogue_author',
		label: i18n.t('metric:form:fields.dialogue_author'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.dialogue_author_share',
		label: i18n.t('metric:form:fields.dialogue_author_share'),
		type: 'text',
		required: false,
		fieldInfo: i18n.t('metric:form:fields_info.dialogue_author_share')
	},
	{
		name: 'metadata.additional_data.comment_dialogue_duration',
		label: i18n.t('metric:form:fields.comment_dialogue_duration'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_metadata.dramaturgy',
		label: i18n.t('common:form:fields.additional_metadata.dramaturgy'),
		type: 'multi-select',
		mode: 'tags',
		required: false,
		disabled: true,
		dropdownStyle: { display: 'none' }
	},
	{
		name: 'metadata.additional_data.adaptation',
		label: i18n.t('metric:form:fields.adaptation'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.pictures',
		label: i18n.t('metric:form:fields.pictures'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.edit',
		label: i18n.t('metric:form:fields.edit'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.scenography',
		label: i18n.t('metric:form:fields.scenography'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.costumes',
		label: i18n.t('metric:form:fields.costumes'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.music',
		label: i18n.t('metric:form:fields.music'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.song_lyrics_author',
		label: i18n.t('metric:form:fields.song_lyrics_author'),
		type: 'text',
		required: false,
		fieldInfo: i18n.t('metric:form:fields_info.song_lyrics_author')
	},
	{
		name: 'metadata.additional_data.choreography_author',
		label: i18n.t('metric:form:fields.choreography_author'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_metadata.composition',
		label: i18n.t('common:form:fields.additional_metadata.composition'),
		type: 'multi-select',
		mode: 'tags',
		required: false,
		disabled: true,
		dropdownStyle: { display: 'none' }
	},
	{
		name: 'metadata.additional_data.material_tracks',
		label: i18n.t('metric:form:fields.material_tracks'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.rating_category_recommendation',
		label: i18n.t('metric:form:fields.rating_category_recommendation'),
		type: 'multi-select',
		mode: null,
		required: false
	},
	{
		name: 'metadata.additional_data.payment',
		label: i18n.t('metric:form:fields.payment'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'metadata.additional_data.payment_price',
		label: i18n.t('metric:form:fields.payment_price'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.exclusive_license',
		label: i18n.t('metric:form:fields.exclusive_license'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'metadata.additional_data.license_start',
		label: i18n.t('metric:form:fields.license_start'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.license_end',
		label: i18n.t('metric:form:fields.license_end'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.material_expiration',
		label: i18n.t('metric:form:fields.material_expiration'),
		type: 'checkbox',
		required: false
	},
	{
		name: 'metadata.additional_data.distributor',
		label: i18n.t('metric:form:fields.distributor'),
		type: 'text',
		required: false
	},
	{
		name: 'metadata.additional_data.producer',
		label: i18n.t('metric:form:fields.producer'),
		type: 'text',
		required: false
	}
];
