import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

// Import components
import ImagePreviewModal from './ImagePreviewModal';

// Import logic
import useImagePreview from './hooks/useImagePreview';

const ImagePreview = ({
	assetSource,
	buttonClass = 'btn btn-white btn-bitbucket',
	collection,
	...restProps
}) => {
	const { t } = useTranslation();

	const {
		previewVisible,
		closeModal,
		openModal,
		assetRemove,
		redirectToEdit
	} = useImagePreview({ ...restProps, collection });

	return (
		<div className="upload-image-preview-wrapper">
			<figure className="upload-image-preview">
				<img src={assetSource} alt="Preview" />
			</figure>
			<div className="d-flex justify-content-around">
				<Tooltip
					placement="top"
					title={t('asset_groups:buttons.metadata_edit')}
				>
					<button className={buttonClass} onClick={redirectToEdit}>
						<i className="fa fa-pencil" aria-hidden="true"></i>
					</button>
				</Tooltip>
				<Tooltip placement="top" title={t('asset_groups:buttons.preview')}>
					<button className={buttonClass} onClick={openModal}>
						<i className="fa fa-eye" aria-hidden="true"></i>
					</button>
				</Tooltip>
				<Tooltip placement="top" title={t('asset_groups:buttons.delete')}>
					<button className={buttonClass} onClick={assetRemove}>
						<i className="fa fa-trash-o" aria-hidden="true"></i>
					</button>
				</Tooltip>
			</div>
			<ImagePreviewModal
				closeModal={closeModal}
				previewVisible={previewVisible}
				previewImage={assetSource}
				collection={collection}
			/>
		</div>
	);
};

export default ImagePreview;
