import { css } from 'styled-components';

export const size = {
	small: 750,
	medium: 900,
	large: 1270,
	big: 2000
};

export const responsive = Object.keys(size).reduce((accumulator, sizeLabel) => {
	accumulator[sizeLabel] = (...args) => css`
		@media (max-width: ${size[sizeLabel] / 16}em) {
			${args}
		}
	`;
	return accumulator;
}, {});

export const customResponsive = (sizeLabel, args) => css`
	@media (max-width: ${sizeLabel / 16}em) {
		${args}
	}
`;

export const customMinResponsive = (sizeLabel, args) => css`
	@media (min-width: ${sizeLabel / 16}em) {
		${args}
	}
`;
