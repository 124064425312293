import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Import components
import { Pagination } from 'antd';

// Import utitities
import CSVExport from 'components/utilities/csv/table/CSVExport';

// Import helpers
import { isCSVExportVisible } from './helpers/helpers';
import { getTableType } from 'components/helpers/convert';

// Import variables
import { TABLE_LENGTH } from 'components/helpers/table';

// Import translation
import { useTranslation } from 'react-i18next';

/**
 * Functional component that returns custom pagination showing total results counter
 * @param {object} tableProps - props from React Table. Required for proper communication between pagination and React Table
 * @param {number} totalResults - total results number from api
 * @returns {*}
 */
const CustomPagination = ({ tableProps, totalResults }) => {
	const { page, onPageChange } = tableProps;

	const { t } = useTranslation();

	const { pathname } = useLocation();

	const [current, setCurrent] = useState(page + 1);

	// creating table type to deterimine if table is valid for showing csv export
	const tableType = getTableType(pathname);

	const handlePageChange = (page) => {
		setCurrent(page);
		onPageChange(page - 1);
	};

	return (
		<div className="pagination-container m-b-sm">
			<Pagination
				current={current}
				total={totalResults}
				onChange={handlePageChange}
				defaultPageSize={TABLE_LENGTH}
			/>
			<div className="pagination-utils-container">
				{isCSVExportVisible(tableType) && <CSVExport />}
				<p className="m-l-md">{`${t(
					'common:pagination.total'
				)} ${totalResults}`}</p>
			</div>
		</div>
	);
};

CustomPagination.propTypes = {
	tableProps: PropTypes.object.isRequired,
	totalResults: PropTypes.number.isRequired
};

export default CustomPagination;
