import React from 'react';
import i18n from 'i18next';

// Import helpers
import { GLOBAL_PRODUCT_TYPES } from 'components/helpers/variables';

// Import utilities
import { PASSES_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields/passes_translation_input_fields';
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';
import { passesTranslationValidate } from 'components/utilities/content_translation/validation/passes_translation_validate';

// Import components
import PassesForm from 'components/views/passes/passes_form/PassesForm';
import PassesImages from 'components/views/passes/passes_file/PassesImages';
import PassesAvailability from 'components/views/passes/passes_availability/PassesAvailability';
import PassesPrices from 'components/views/passes/passes_prices/PassesPrices';
import ProductVisibility from 'components/utilities/product_visibility/table/ProductVisibility';
import Payments from 'components/views/payments/Payments';
import ActivityProduct from 'components/views/activity_product/ActivityProduct';

const { PASS } = GLOBAL_PRODUCT_TYPES;

const passesBasicTabs = [
	{
		name: i18n.t('common:tabs.metadata'),
		path: 'metadata',
		disableOnCreate: false,
		component: <PassesForm />
	},
	{
		name: i18n.t('common:tabs.pictures'),
		path: 'pictures',
		disableOnCreate: true,
		component: <PassesImages />
	},
	{
		name: i18n.t('common:tabs.availability'),
		path: 'availability',
		disableOnCreate: true,
		component: <PassesAvailability />
	},
	{
		name: i18n.t('common:tabs.visibility'),
		path: 'visibility',
		disableOnCreate: true,
		component: <ProductVisibility productType={PASS} />
	},
	{
		name: i18n.t('common:tabs.prices'),
		path: 'prices',
		disableOnCreate: true,
		component: <PassesPrices />
	},
	{
		name: i18n.t('common:tabs.payments'),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.activity'),
		path: 'activity_product',
		disableOnCreate: true,
		component: <ActivityProduct />
	}
];

export const passesTabs = (contentTranslationLanguages) => [
	...passesBasicTabs,
	...generateContentTranslationsTabs(
		PASSES_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		passesTranslationValidate
	)
];
