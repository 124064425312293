import { useFormState, useForm } from 'react-final-form';

// Import helpers
import {
	menuModelTypes,
	submenuModelTypes,
	plaformGroups,
	ELEMENT_TYPES,
	getModelIds,
	PLATFORM_TYPE,
	FIELDS_NAMES
} from '../helpers';

const { LINK, EPG } = ELEMENT_TYPES;

const useFields = ({ parentId, isSubmenu, ...restProps }) => {
	const {
		submitting,
		values: { element_type, platform_group }
	} = useFormState();

	const { change } = useForm();

	const elementTypes = parentId ? submenuModelTypes : menuModelTypes;

	const platform = PLATFORM_TYPE[platform_group];

	const modelIds = getModelIds({ element_type, platform, ...restProps });

	const selectResources = {
		element_type: elementTypes,
		platform_group: plaformGroups,
		model_id: modelIds
	};

	const isElementTypeEpg = element_type === EPG;

	const isLinkFieldDisabled = element_type !== LINK;
	const isPageFieldDisabled = !isLinkFieldDisabled || isElementTypeEpg;

	const handleElementTypeChange = (type) => {
		const isLink = type === LINK;
		const isEpg = type === EPG;

		const modelType = isLink ? null : type;

		// the backend does not accept an empty string
		const link = isLink ? '' : '_';
		const modelId = isEpg ? '_' : null;

		// set values on every element type changes
		change(FIELDS_NAMES.LINK, link);
		change(FIELDS_NAMES.MODEL_TYPE, modelType);
		change(FIELDS_NAMES.MODEL_ID, modelId);
	};

	return {
		submitting,
		selectResources,
		isLinkFieldDisabled,
		isPageFieldDisabled,
		isSubmenu,
		onElementTypeChange: handleElementTypeChange
	};
};

export default useFields;
