import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	fetchSubscriberPackets,
	fetchSubscriberSubscriptions
} from 'store/actions';

// Import columns
import {
	createProvisioningColumns,
	createSubscriptionsColumns
} from './subscribers_packets_columns';

// Import translation
import { useTranslation } from 'react-i18next';

// Import logic
import useSubscribersPackets from './useSubscribersPackets';

// Import components
import { TableTemplate } from 'components/common/templates';
import { Button } from 'reactstrap';

const SubscribersPackets = ({
	error,
	fetchSubscriberPackets,
	fetchSubscriberSubscriptions,
	resources,
	match: {
		params: { id }
	}
}) => {
	const { t } = useTranslation();

	const {
		clearPermissions,
		showClearPermissionsButton,
		showSubscriptionsTable
	} = useSubscribersPackets({ id });
	return (
		<>
			{showClearPermissionsButton && (
				<Button
					style={{ marginLeft: 'auto', display: 'block' }}
					onClick={clearPermissions}
				>
					{t('subscribers:buttons.refresh_permissions')}
				</Button>
			)}

			{/* SUBSCRIPTIONS (MAIN) TABLE */}
			{showSubscriptionsTable && (
				<TableTemplate
					itemId={id}
					error={error}
					resources={resources.subscriptions}
					columns={createSubscriptionsColumns}
					fetchResourcesAction={fetchSubscriberSubscriptions}
				></TableTemplate>
			)}
			{/* PROVISIONING TABLE */}
			<TableTemplate
				itemId={id}
				error={error}
				resources={resources.provisioning}
				columns={createProvisioningColumns}
				fetchResourcesAction={fetchSubscriberPackets}
			></TableTemplate>
		</>
	);
};

SubscribersPackets.propTypes = {
	error: PropTypes.bool,
	resources: PropTypes.object.isRequired,
	subscriberData: PropTypes.object.isRequired,
	fetchSubscriberPackets: PropTypes.func.isRequired,
	fetchSubscriberSubscriptions: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({ id: PropTypes.string.isRequired })
	})
};

const mapStateToProps = ({ subscribers: { packets, form } }) => ({
	error: packets.error,
	resources: packets,
	subscriberData: form.edit
});

export default compose(
	connect(mapStateToProps, {
		fetchSubscriberPackets,
		fetchSubscriberSubscriptions
	}),
	withRouter
)(SubscribersPackets);
