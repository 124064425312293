import i18n from 'i18next';

export const EPG_FILTER_FIELDS = [
	{
		name: 'title',
		label: i18n.t('filters:fields.title'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	},
	{
		name: 'since',
		label: i18n.t('filters:fields.since_field'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'till',
		label: i18n.t('filters:fields.till_field'),
		type: 'date',
		required: false,
		labelColumn: 3,
		inputColumn: 9,
		style: { width: '100%' }
	},
	{
		name: 'category',
		label: i18n.t('filters:fields.genres'),
		type: 'text',
		required: false,
		labelColumn: 3,
		inputColumn: 9
	}
];
