import React from 'react';
// Import router
import { Route, Switch, Redirect } from 'react-router-dom';

// Import HOC
import ProtectedRoute from 'components/HOC/ProtectedRoute';
import UserLogged from 'components/HOC/UserLogged';

// Import Compoents
import LoginPage from 'components/login/LoginPage';
import Main from 'components/layouts/Main';
import NotFound from 'components/layouts/NotFound';
import ProductPreview from 'components/views/product_preview/ProductPreview';

const App = () => (
	<React.Fragment>
		<Switch>
			<Redirect exact from="/" to="/login" />
			<UserLogged path="/login" component={LoginPage} />
			<ProtectedRoute path="/panel" component={Main} />
			<ProtectedRoute
				path="/preview/product/:type/:id"
				component={ProductPreview}
			/>
			<Route component={NotFound} />
		</Switch>
	</React.Fragment>
);

export default App;
