import React from 'react';
import i18n from 'i18next';

// Import tabs component
import WatermarkSettings from '../../settings_file/WatermarkSettings';

export const settingsTabs = [
	{
		name: i18n.t('settings:tabs.watermark'),
		path: 'watermark',
		disableOnCreate: false,
		component: <WatermarkSettings />
	}
];
