import React from 'react';
import { useSelector } from 'react-redux';

// Import styles
import { Title } from './styles';

// Import helpers
import { checkProductSubtype } from '../../helpers';

const SubTitle = () => {
	const {
		subtype,
		metadata: { sub_title }
	} = useSelector(({ product_preview }) => product_preview.data);

	if (!sub_title) return null;

	const { isArticle } = checkProductSubtype(subtype);

	return <Title isLightBackground={isArticle}>{sub_title}</Title>;
};

export default SubTitle;
