export const convertDataToMetadata = (data) => {
	if (data.hasOwnProperty('metadata')) {
		return data;
	}

	return {
		...data,
		metadata: {
			title: data.title,
			hide_title: data.hide_title,
			rating: data.rating,
			year: data.year,
			category: data.category,
			duration: data.duration,
			sub_category: data.sub_category,
			summary_long: data.summary_long,
			release_description: data.release_description,
			additional_data: data.additional_data,
			additional_metadata: data.additional_metadata,
			summary_short: data.summary_short,
			...data.data
		}
	};
};
