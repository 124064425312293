import i18next from 'i18next';

export const select_vod_subtypes = [
	{
		name: i18next.t('vod:subtypes.series'),
		value: 'series'
	},
	{
		name: i18next.t('vod:subtypes.product'),
		value: 'vod'
	}
];

export const select_is_audio = [
	{ name: 'VIDEO', value: 0 },
	{ name: 'AUDIO', value: 1 }
];
