import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import { setCategoriesType } from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import helpers
import {
	LIVE_GENRES_TYPE,
	VOD_GENRES_TYPE
} from 'components/helpers/genres_helpers/helpers';

const CategoryFilters = ({ setCategoriesType, categoryType }) => {
	const { t } = useTranslation();

	// current category type - select VOD or Live
	const [type, setType] = useState(categoryType);

	const handleChange = ({ target: { value } }) => {
		setType(value);
		// Dispatch setCategoriesType action in categories_table
		setCategoriesType(value);
	};

	return (
		<div className="m-r-md">
			<p>{t('categories:category_filters.title')}:</p>
			<Radio.Group onChange={handleChange} value={type}>
				<Radio value={VOD_GENRES_TYPE}>VOD</Radio>
				<Radio value={LIVE_GENRES_TYPE}>Live</Radio>
			</Radio.Group>
		</div>
	);
};

const mapStateToProps = ({ categories: { table } }) => ({
	categoryType: table.options.filters.type
});

export default connect(mapStateToProps, { setCategoriesType })(CategoryFilters);
