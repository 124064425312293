import services from 'services/services';
import * as types from '../../types';

// Import helpers
import { checkFetchErrors } from '../../helpers_actions/convert_helpers';

// ******************** FETCH EVENT LIVE COVER FILES TO EDIT ********************
export const fetchArticleCover = (id) => async (dispatch) => {
	try {
		// Dispatch a loading action
		dispatch({
			type: types.FETCH_ARTICLE_COVER_FILES_LOADING,
			payload: true
		});

		const response = await services.get(
			`/assets/${id}/list?model_type=article&collection=cover`
		);

		// Dispatch an action with data
		dispatch({
			type: types.FETCH_ARTICLE_COVER_FILES_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ARTICLE_COVER_FILES_ERROR,
			payload: checkFetchErrors(error)
		});
	}
};
