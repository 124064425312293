import { useSelector } from 'react-redux';

// import helpers
import { getFilteredMetadata } from 'components/views/product_preview/helpers';

const useDetails = () => {
	const { metadata } = useSelector(
		({ product_preview }) => product_preview.data
	);

	// filter metadata
	const filteredMetadata = getFilteredMetadata({ metadata });

	return { filteredMetadata };
};

export default useDetails;
