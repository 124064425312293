import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Button } from 'reactstrap';

// Import translation
import { useTranslation } from 'react-i18next';

/**
 * Function component responsible of rendering buttonn that cna refresh stats and table data for ingester tables
 * @param {func} refreshTable - function that refreshes table data in Redux
 * @param {func} refreshStats - function that refreshes stats in Redux
 * @returns {*}
 */
const RefreshButton = ({ refreshTable, refreshStats }) => {
	const { t } = useTranslation();

	const handleRefresh = () => {
		refreshTable();
		refreshStats();
	};

	return (
		<Button onClick={handleRefresh}>
			{<i className="fa fa-refresh m-r-sm" />}
			{t('common:buttons.refresh')}
		</Button>
	);
};

RefreshButton.propTypes = {
	refreshTable: PropTypes.func.isRequired,
	refreshStats: PropTypes.func.isRequired
};

export default RefreshButton;
