import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// Import components
import {
	IboxContentTitle,
	Wraper,
	PageHeading
} from 'components/common/layout';
import useForm from 'components/utilities/form/useForm';

const FormTemplate = React.memo((props) => {
	const { t } = useTranslation();
	// Use custom formEffect to fetch reources
	const {
		breadcrumbs,
		isEdit,
		itemID,
		checkedPermissions,
		decorators
	} = useForm({
		title: props.title,
		location: props.location,
		match: props.match,
		fetchFormResources: props.fetchFormResources,
		fetchResourceToEdit: props.fetchResourceToEdit,
		fetchActionResources: props.fetchActionResources,
		triggerDataRefetch: props.triggerDataRefetch
	});

	return (
		<React.Fragment>
			{props.heading && (
				<PageHeading
					title={props.title}
					errors={false}
					breadcrumbs={breadcrumbs}
				/>
			)}
			<Wraper lg="12" error={props.error}>
				<IboxContentTitle
					title={props.iboxContentTitle ?? t('common:form.title')}
					isLoaded={isEdit || props.isLoaded} // If we create, we don't fetch data, so data isn't load
				>
					{props.children({
						breadcrumbs,
						isEdit,
						itemID,
						checkedPermissions,
						decorators
					})}
				</IboxContentTitle>
			</Wraper>
		</React.Fragment>
	);
});

FormTemplate.defaultProps = {
	fetchFormResources: [],
	heading: false,
	fetchResourceToEdit: () => [],
	error: false,
	isLoaded: true,
	fetchActionResources: {}
};

FormTemplate.propTypes = {
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	fetchFormResources: PropTypes.array.isRequired,
	fetchResourceToEdit: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	heading: PropTypes.bool,
	title: PropTypes.string,
	isLoaded: PropTypes.bool.isRequired,
	iboxContentTitle: PropTypes.string,
	fetchActionResources: PropTypes.object,
	triggerDataRefetch: PropTypes.any
};

export default withRouter(FormTemplate);
