import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import CommonButton from './CommonButton';

// Import styles
import { IconReminder } from './styles';

const RemindersButton = () => {
	const { t } = useTranslation();

	return (
		<CommonButton>
			<IconReminder />
			{t('product_preview:buttons.vod_buttons_live_add_reminder')}
		</CommonButton>
	);
};

export default RemindersButton;
