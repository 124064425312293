// Import variables
import { SYSTEM_CUSTOM_NAMES } from 'components/helpers/variables';

// SYSTEMS NAMES MAPPING - FOR DATA TABLES
export const mapSystemName = (system) => {
	const mappedName = SYSTEM_CUSTOM_NAMES[system];
	return mappedName ?? system;
};

// SYSTEMS NAMES MAPPING - FOR FORM SUGGESTIONS SELECT
export const createSystemNamesSuggestions = (systems) => {
	return systems.map((system) => ({
		name: mapSystemName(system),
		value: system
	}));
};

// convert plural values to single (eg packets -> packet)
// add more cases if needed
export const mapPluralToSingle = (value) => {
	switch (value) {
		case 'packets':
			return 'packet';

		default:
			return value;
	}
};

// convert singe values to plural (eg packet -> packets)
// add more cases if needed
export const mapSingleToPlural = (value) => {
	switch (value) {
		case 'packet':
			return 'packets';

		default:
			return value;
	}
};

// AVAILABILITY PRODUCT TYPE MAPPING
export const mapAvailabilityProductTypeName = (type) => {
	switch (type) {
		case 'channel':
			return 'channels';
		case 'packet':
			return 'packets';

		default:
			return type;
	}
};

// VISIBILITY PRODUCT TYPE NAME MAPPING
export const mapVisibilityProductType = (type) => {
	switch (type) {
		case 'live_event':
			return 'event_live';

		default:
			return type;
	}
};
