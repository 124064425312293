import React from 'react';
import { Progress } from 'reactstrap';

// Import translation
import i18n from 'i18next';

// Import helpers
import { setFormLink } from 'components/helpers/link_selector';

// renders progress bar inside progress column. Shows canceled red progress bar
// if operations status is canceled
const renderProgress = (progress, status) => {
	let label = `${progress} %`;
	let color = 'success';

	if (status === 'CANCELLED') {
		label = 'Canceled';
		color = 'danger';
		progress = 100;
	}

	return (
		<Progress color={color} value={progress} className="table-progress-bar">
			{label}
		</Progress>
	);
};

// render repeat encoding button or cancel encoding button based on mc_status
const renderActionButton = (
	handleActionEncoding,
	id,
	status,
	repeatAction,
	cancelAction
) => {
	// repeat encoding button
	if (
		status === 'ENCODED' ||
		status === 'UNKNOWN' ||
		status === 'CANCELLED' ||
		status === null
	) {
		return (
			<button
				onClick={() => handleActionEncoding('repeat', id, repeatAction)}
				className="btn btn-xs btn-warning"
			>
				<i className="fa fa-repeat" />
			</button>
		);
	}
	// cancel encoding button
	else if (status === 'ENCODING' || status === 'TO_ENCODE') {
		return (
			<button
				onClick={() => handleActionEncoding('cancel', id, cancelAction)}
				className="btn btn-xs btn-danger"
			>
				<i className="fa fa-times" />
			</button>
		);
	}
	return null;
};

export const createColumns = ({
	handleActionEncoding,
	isReadOnly,
	repeatEncoding,
	cancelEncoding
}) => [
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.title'),
		accessor: 'title',
		Cell: ({
			row: {
				_original: { product_uuid, title }
			}
		}) => setFormLink({ uuid: product_uuid, title })
	},
	{
		Header: i18n.t('ingester:ingester_assets:table_columns.priority'),
		accessor: 'priority',
		width: 80
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.coder_name'),
		accessor: 'coder_name',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.profile_name'),
		accessor: 'profile_name',
		sortable: false
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.encrypt_type'),
		accessor: 'encrypt_type'
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.mc_task_id'),
		accessor: 'mc_task_id'
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.mc_status'),
		accessor: 'mc_status'
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.mc_progress'),
		accessor: 'mc_progress',
		Cell: ({
			row: {
				_original: { mc_progress, mc_status }
			}
		}) => renderProgress(mc_progress, mc_status)
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.updated_at'),
		accessor: 'updated_at'
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.mc_scheduled_at'),
		accessor: 'mc_scheduled_at'
	},
	{
		Header: i18n.t('ingester:ingester_encoding:table_columns.mc_started_at'),
		accessor: 'mc_started_at'
	},
	{
		Header: i18n.t('common:table_columns.action'),
		accessor: 'repeat_encoding',
		className: 'text-center',
		sortable: false,
		width: 70,
		Cell: ({
			row: {
				_original: { id, mc_status }
			}
		}) =>
			!isReadOnly &&
			renderActionButton(
				handleActionEncoding,
				id,
				mc_status,
				repeatEncoding,
				cancelEncoding
			)
	}
];
