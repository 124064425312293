import { combineReducers } from 'redux';
// Import reducers
import table from './packets_panel_reducer';
import form from './packets_form_reducer';
import files from './packets_files_reducer';
import products from './packets_products_reducer';
import materials from './packets_materials_reducer';
import availability from './packets_availability/packets_availability_reducer';
import prices from './packets_prices_reducer';
import packets_assigned from './collection_packets_assigned_reducer';

export default combineReducers({
	table,
	form,
	files,
	products,
	materials,
	availability,
	prices,
	packets_assigned
});
