import styled, { css } from 'styled-components';

// Import styles
import { productTextColor, customMinResponsive, size } from '../../styles';

export const Root = styled.div`
	z-index: 2;
	display: flex;
	align-items: center;
	margin-bottom: ${({ isArticle }) => (isArticle ? 'unset' : '0.6rem')};

	${customMinResponsive(
		size.medium,
		css`
			margin-bottom: 2.3rem;
		`
	)}

	${({ imageUrl }) =>
		imageUrl &&
		css`
			height: 9rem;
		`}
`;

export const Image = styled.div.attrs(({ imageUrl }) => ({
	style: {
		backgroundImage: `url(${imageUrl})`
	}
}))`
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center left;
	background-size: contain;
	width: 100%;
	height: 100%;
`;

export const TitleText = styled.h2`
	color: ${({ theme }) => theme.vod.font.white};
	font-size: 2.6rem;
	font-weight: ${({ theme }) => theme.font.bold};
	${productTextColor}

	${customMinResponsive(
		size.medium,
		css`
			font-size: 4.8rem;
			line-height: 5.7rem;
		`
	)}
`;
