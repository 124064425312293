import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';
import { useSelector } from 'react-redux';

// Import helrpes
import { menuTabs } from './helpers';

const MainTabs = () => {
	const { languages } = useSelector(
		({ content_translations }) => content_translations.language_list
	);

	return <Tabs tabs={menuTabs({ languages })} />;
};

export default MainTabs;
