import React from 'react';

// Import components
import InfoAboveTitle from './InfoAboveTitle';
import Title from './Title';
import SummaryShort from './SummaryShort';
import ArticleAuthors from './ArticleAuthors/ArticleAuthors';
import DescriptionSection from './DescriptionSection';
import SubInformations from './SubInformations';

const ArticleInfo = () => {
	return (
		<>
			<InfoAboveTitle />

			<Title />

			<ArticleAuthors />

			<SummaryShort />

			<DescriptionSection />

			<SubInformations />
		</>
	);
};

export default ArticleInfo;
