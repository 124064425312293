import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	setCollectionPacketsAssignedQuery,
	fetchCollectionPacketsAssigned
} from 'store/actions';

// Import columns
import { createColumns } from './collection_packets_assigned_columns';

// Import utilities
import Search from 'components/utilities/search/Search';

// Import components
import { PanelPageTemplate } from 'components/common/templates';

const CollectionPacketsAssigned = ({
	error,
	setCollectionPacketsAssignedQuery,
	fetchCollectionPacketsAssigned,
	resources,
	match: {
		params: { id }
	}
}) => {
	return (
		<PanelPageTemplate
			itemId={id}
			title="Lista"
			error={error}
			type="packets_assigned"
			resources={resources}
			columns={createColumns}
			fetchResourcesAction={fetchCollectionPacketsAssigned}
		>
			<div className="d-flex justify-content-end">
				<Search
					setQueryValue={setCollectionPacketsAssignedQuery}
					value={resources.options.filters.query}
				/>
			</div>
		</PanelPageTemplate>
	);
};

CollectionPacketsAssigned.propTypes = {
	error: PropTypes.bool.isRequired,
	setCollectionPacketsAssignedQuery: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	fetchCollectionPacketsAssigned: PropTypes.func.isRequired
};

const mapStateToProps = ({ packets }) => {
	return {
		error: packets.packets_assigned.error,
		resources: packets.packets_assigned
	};
};

export default compose(
	connect(mapStateToProps, {
		setCollectionPacketsAssignedQuery,
		fetchCollectionPacketsAssigned
	}),
	withRouter
)(CollectionPacketsAssigned);
