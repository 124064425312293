import { useState } from 'react';

const useProductGallery = () => {
	const [smallGalleryIndex, setSmallGalleryIndex] = useState(0);

	return {
		smallGalleryIndex,
		setSmallGalleryIndex
	};
};

export default useProductGallery;
