import { useForm } from 'react-final-form';

// Import helpers
import { LIMIT_FIELDS, LIMIT_TYPES } from '../helpers';

const { PURCHASES, VIEWS } = LIMIT_TYPES;
const { LIMIT_SIZE, LIMIT_TYPE } = LIMIT_FIELDS;

const useFields = (limits) => {
	const { change } = useForm();

	const handleLimitTypeChange = (limitType) => {
		switch (limitType) {
			case VIEWS:
				change(LIMIT_TYPE, limitType);
				change(LIMIT_SIZE, limits[0]?.limit_size);
				break;

			case PURCHASES:
				change(LIMIT_TYPE, limitType);
				change(LIMIT_SIZE, limits[1]?.limit_size);
				break;

			default:
				change(LIMIT_TYPE, limitType);
				change(LIMIT_SIZE, null);
				break;
		}
	};

	return {
		limitTypeChange: handleLimitTypeChange
	};
};

export default useFields;
