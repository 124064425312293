import React from 'react';
import Tabs from 'components/utilities/tabs/Tabs';

import { geoblockBasicTabs } from './helpers';

const GeolockTabs = () => {
	return <Tabs tabs={geoblockBasicTabs} />;
};

export default GeolockTabs;
