import i18n from 'i18next';

// Vod images info text
export const IMAGE_SIZE_INFO = i18n.t('info_text:image_size');

export const WATERMARK_IMAGE_REQUIREMENTS = [
	{ text: i18n.t('common:info.watermark'), header: true },
	{ text: i18n.t('common:info.watermark_format') },
	{ text: i18n.t('common:info.watermark_size') }
];

// Packets - (provisioning | packets | main) - images info text and requirements
export const PACKETS_IMAGE_SIZE_INFO = i18n.t('info_text:packets_image_size');
export const PROVISIONING_IMAGES_REQUIREMENTS = [
	{ text: i18n.t('provisioning:info.cover') },
	{ text: i18n.t('provisioning:info.cover_large') },
	{ text: i18n.t('provisioning:info.cover_small') },
	{ text: i18n.t('provisioning:info.cover_detail') },
	{ text: i18n.t('provisioning:info.cover_detail_large') }
];
export const PACKETS_IMAGES_REQUIREMENTS = [
	{ text: i18n.t('packets:info.cover') },
	{ text: i18n.t('packets:info.cover_large') }
];
export const MAIN_IMAGES_REQUIREMENTS = [
	{ text: i18n.t('main:info.cover') },
	{ text: i18n.t('main:info.cover_large') },
	{ text: i18n.t('main:info.cover_small') },
	{ text: i18n.t('main:info.cover_detail') },
	{ text: i18n.t('main:info.cover_detail_large') }
];
export const PRODUCT_IMAGES_REQUIREMENTS = [
	{ text: i18n.t('common:info.images'), header: true },
	{ text: i18n.t('common:info.images_format') },
	{ text: i18n.t('common:info.images_size') },
	{ text: i18n.t('common:info.tt'), header: true },
	{ text: i18n.t('common:info.tt_format') },
	{ text: i18n.t('common:info.tt_size') }
];

export const INSTITUTIONS_IMAGES_REQUIREMENTS = [
	{ text: i18n.t('common:info.background'), header: true },
	{ text: i18n.t('common:info.images_format') },
	{ text: i18n.t('common:info.images_size') },
	{ text: i18n.t('common:info.logo'), header: true },
	{ text: i18n.t('common:info.logo_format') },
	{ text: i18n.t('common:info.logo_size') }
];
