import React from 'react';

// Import components
import UploadMaterial from 'components/utilities/upload_material/UploadMaterial';

const ArticlesUploadMaterial = () => {
	return (
		<UploadMaterial
			modelType="article"
			fileTypeSelectorVisible={true}
			transcodeMain={true}
			transcodePreview={false}
		/>
	);
};

export default ArticlesUploadMaterial;
