import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

// Import context
import {
	MaterialsProvider,
	MaterialsContext
} from 'components/utilities/table/materials_table/materials_context/MaterialsContext';

// import components
import MaterialsHeader from './MaterialsHeader';

// Import helpers
import { locale } from 'components/helpers/table';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';
import usePermissionsCheck from 'hooks/usePermissionsCheck';

const MaterialsTable = ({
	columns,
	resources,
	updateMaterials,
	updateMaterialsError,
	deleteMaterials,
	deleteMaterialsError
}) => {
	// check if user have rights to create and edit forms
	const { canEditVideo } = useAuthCheck();
	// check if user can delete assets
	const {
		checkedPermissions: { canDeleteAssets }
	} = usePermissionsCheck();

	const materialsHeader = () => (
		<MaterialsHeader resources={resources} canEditVideo={canEditVideo} />
	);

	return (
		<MaterialsProvider
			resources={resources}
			updateMaterials={updateMaterials}
			updateMaterialsError={updateMaterialsError}
			deleteMaterials={deleteMaterials}
			deleteMaterialsError={deleteMaterialsError}
		>
			<MaterialsContext.Consumer>
				{({ deleteAsset, editAsset }) => (
					<React.Fragment>
						{resources.length !== 0 && (
							<Table
								rowKey={(record) => record.id}
								dataSource={resources.assets}
								columns={columns({
									deleteAsset,
									type: resources.type,
									editAsset,
									canEditVideo,
									canDeleteAssets
								})}
								title={materialsHeader}
								bordered
								pagination={false}
								size="small"
								className="m-b-lg material-table"
								locale={locale}
							/>
						)}
					</React.Fragment>
				)}
			</MaterialsContext.Consumer>
		</MaterialsProvider>
	);
};

MaterialsTable.propTypes = {
	columns: PropTypes.func.isRequired,
	resources: PropTypes.object.isRequired,
	updateMaterials: PropTypes.func.isRequired,
	updateMaterialsError: PropTypes.func.isRequired,
	deleteMaterials: PropTypes.func.isRequired,
	deleteMaterialsError: PropTypes.func.isRequired
};

export default MaterialsTable;
