// Import translation
import i18n from 'i18next';

// Import helpers text
import { algorithm_info } from 'components/helpers/algorithm';

export const BASE_FIELDS = [
	{
		name: 'algorithm_config',
		label: i18n.t('promotions:form:fields.algorithm_config'),
		type: 'textarea',
		required: false,
		info: algorithm_info,
		modalWidth: 700
	}
];
