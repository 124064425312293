import { BLUE_ONLINE_ROLES } from 'components/helpers/roles_variables';

const { ADMINISTRATOR, REDACTOR, EDITOR, HELP_DESK } = BLUE_ONLINE_ROLES;

export const AVAILABILITY = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const VISIBILITY = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const PRICES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const PROMOTIONS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const CHANNELS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR],
	canDeleteAssets: [ADMINISTRATOR]
};
export const ARTICLES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const EVENTS_LIVE = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const PASSES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const PROVISIONING = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const COLLECTIONS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const PACKETS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const LIMITS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const PAYMENTS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const REPORTS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR]
};
export const SUBSCRIBERS = {
	allowedRoles: [ADMINISTRATOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const DEVICES = {
	allowedRoles: [ADMINISTRATOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const CATEGORIES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const DOCUMENTS = {
	allowedRoles: [ADMINISTRATOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const AGREEMENTS = {
	allowedRoles: [ADMINISTRATOR, HELP_DESK],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const VOD_SERIES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const VOD_SEASONS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const VOD_EPISODES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const VOD = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const AUDIO_SERIES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const AUDIO_SEASONS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const AUDIO_EPISODES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const AUDIO = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const SECTION_GROUPS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const SECTIONS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const BANNERS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const MASS_CHANGES = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const ASSET_GROUPS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR, EDITOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const INGESTER_ASSETS = {
	allowedRoles: [],
	canActivate: [],
	canActivateProduct: [],
	canCreateContent: [],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const INGESTER_ENCODING = {
	allowedRoles: [],
	canActivate: [],
	canActivateProduct: [],
	canCreateContent: [],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const VIDEO_ASSETS_PARAMS = {
	allowedRoles: [],
	canActivate: [],
	canActivateProduct: [],
	canCreateContent: [],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const OPERATORS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const GEOLOCK = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
// export const TRACKINGS = {
// 	allowedRoles: [ADMINISTRATOR, REDACTOR],
// 	canActivate: [ADMINISTRATOR, REDACTOR],
// 	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
// 	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
// };
export const SETTINGS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const CONFIGURATION = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const NOTIFICATIONS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const INSTITUTIONS = {
	allowedRoles: [ADMINISTRATOR, EDITOR],
	canActivate: [ADMINISTRATOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const AFFILIATE_PARTNERS = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR],
	canDeleteAssets: [ADMINISTRATOR]
};
export const TAGS = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, REDACTOR],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const MENU = {
	allowedRoles: [ADMINISTRATOR, REDACTOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
export const ACTIVITY = {
	allowedRoles: [ADMINISTRATOR],
	canActivate: [ADMINISTRATOR, REDACTOR],
	canActivateProduct: [ADMINISTRATOR],
	canCreateContent: [ADMINISTRATOR, EDITOR, REDACTOR, HELP_DESK],
	canDeleteAssets: [ADMINISTRATOR, REDACTOR]
};
