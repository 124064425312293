import React from 'react';
import i18n from 'i18next';

// Import components
import SeriesForm from 'components/views/vod/vod_series/series_form/SeriesForm';
import Seasons from 'components/views/vod/vod_seasons/seasons_table/Seasons';
import VodPrices from 'components/views/vod/vod_prices/VodPrices';
import VodAvailability from 'components/views/vod/vod_availability/VodAvailability';
import Payments from 'components/views/payments/Payments';
import SeriesImages from 'components/views/vod/vod_file/SeriesImages';
import VodPacketsAssigned from 'components/views/vod/vod_packets_assigned/VodPacketsAssigned';
import ProductVisibility from 'components/utilities/product_visibility/table/ProductVisibility';
import ProductMetricFormVod from 'components/utilities/product_metric/vod_type_products/ProductMetricFormVod';
import ProductGeoblockForm from 'components/views/geoblock_groups/ProductGeoblockForm/ProductGeoblockForm';
import VodMaterials from 'components/views/vod/common/materials/VodMaterials';
import VodUploadMaterial from 'components/views/vod/common/materials/VodUploadMaterial';
import ActivityProduct from 'components/views/activity_product/ActivityProduct';
import ProductGlobalLimit from 'components/utilities/product_global_limit/ProductGlobalLimit';

// Import utilities
import { generateContentTranslationsTabs } from 'components/utilities/content_translation/tabs/content_translations_tabs';

// import content translation fields
import { COMMON_VOD_INPUT_FIELDS_DATA } from 'components/utilities/content_translation/input_fields';

// Validate
import { commonVodTranslationValidate } from 'components/utilities/content_translation/validation';

// Import variables
import {
	GLOBAL_LIMIT_PRODUCT_TYPES,
	GLOBAL_PRODUCT_TYPES
} from 'components/helpers/variables';
import { METRIC_PRODUCT_TYPES } from 'components/utilities/product_metric/helpers/helpers';

const { VOD } = GLOBAL_PRODUCT_TYPES;

export const seriesBasicTabs = [
	{
		name: i18n.t(`common:tabs.metadata`),
		path: 'metadata',
		disableOnCreate: false,
		component: <SeriesForm />
	},
	{
		name: i18n.t('common:tabs.metric'),
		path: 'metric',
		disableOnCreate: true,
		component: (
			<ProductMetricFormVod productTypeData={METRIC_PRODUCT_TYPES.SERIES} />
		)
	},
	{
		name: i18n.t(`common:tabs.pictures`),
		path: 'pictures',
		disableOnCreate: true,
		component: <SeriesImages />
	},
	{
		name: i18n.t('common:tabs.upload_materials'),
		path: 'upload_materials',
		disableOnCreate: true,
		component: <VodUploadMaterial />,
		isDisabled: !(process.env.REACT_APP_UPLOAD_MATERIALS_TAB === 'true')
	},
	{
		name: i18n.t('common:tabs.materials'),
		path: 'materials',
		disableOnCreate: true,
		component: <VodMaterials />
	},
	{
		name: i18n.t(`common:tabs.availability`),
		path: 'availability',
		disableOnCreate: true,
		component: <VodAvailability />
	},
	{
		name: i18n.t('common:tabs.visibility'),
		path: 'visibility',
		disableOnCreate: true,
		component: <ProductVisibility productType={VOD} />
	},
	{
		name: i18n.t(`common:tabs.prices`),
		path: 'prices',
		disableOnCreate: true,
		component: <VodPrices />
	},
	{
		name: i18n.t(`common:tabs.seasons`),
		path: 'seasons',
		disableOnCreate: true,
		component: <Seasons />
	},
	{
		name: i18n.t(`common:tabs.payments`),
		path: 'payments',
		disableOnCreate: true,
		component: <Payments />
	},
	{
		name: i18n.t('common:tabs.packets_assigned'),
		path: 'packets_assigned',
		disableOnCreate: true,
		component: <VodPacketsAssigned />
	},
	{
		name: i18n.t('common:tabs.geoblock'),
		path: 'geoblock',
		disableOnCreate: true,
		component: <ProductGeoblockForm />
	},
	{
		name: i18n.t('common:tabs.activity'),
		path: 'activity_product',
		disableOnCreate: true,
		component: <ActivityProduct />
	},
	{
		name: i18n.t('common:tabs.global_limit'),
		path: 'global_limit',
		disableOnCreate: true,
		component: (
			<ProductGlobalLimit productType={GLOBAL_LIMIT_PRODUCT_TYPES.SERIES} />
		)
	}
];

export const seriesTabs = (contentTranslationLanguages) => [
	...seriesBasicTabs,
	...generateContentTranslationsTabs(
		COMMON_VOD_INPUT_FIELDS_DATA,
		contentTranslationLanguages,
		commonVodTranslationValidate
	)
];
