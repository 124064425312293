import React from 'react';

// Import styles
import { Wrapper } from './styles';

// Import components
import SubTitle from './SubTitle';
import SubShortDescription from './SubShortDescription';
import SubDescription from './SubDescription';
import useSubInformations from './useSubInformations';

const SubInformations = () => {
	const { showInformations } = useSubInformations();

	if (!showInformations) return null;

	return (
		<Wrapper>
			<SubTitle />
			<SubShortDescription />
			<SubDescription />
		</Wrapper>
	);
};

export default SubInformations;
