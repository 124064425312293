import React from 'react';
import PropTypes from 'prop-types';

// Import utilites
import { renderFields } from 'components/utilities/form/renderFields';

// Import fields
import { BASE_FIELDS } from './input_fields';

// Import select options
import { select_packet_types } from 'components/helpers/packets';

const Fields = ({
	submitting,
	isEdit,
	checkedPermissions,
	institutions,
	currentValues
}) => {
	const selectResources = {
		type: select_packet_types,
		products_group_uuid: institutions
	};

	return (
		<>
			{BASE_FIELDS({
				isEdit,
				checkedPermissions,
				currentValues
			}).map((item, index) =>
				renderFields({ item, index, submitting, selectResources })
			)}
		</>
	);
};

Fields.propTypes = {
	submitting: PropTypes.bool.isRequired,
	isEdit: PropTypes.bool.isRequired,
	checkedPermissions: PropTypes.object.isRequired,
	institutions: PropTypes.array.isRequired,
	currentValues: PropTypes.object.isRequired
};

export default Fields;
