import i18n from 'i18next';

export const BASE_FIELDS = ({ isEdit, allowInstitutionClear }) => [
	{
		name: 'email',
		type: 'email',
		placeholder: i18n.t('operators:fields.email_placeholder'),
		label: i18n.t('operators:fields.email_label'),
		id: 'email',
		requiredField: true
	},
	{
		name: 'firstName',
		type: 'text',
		placeholder: i18n.t('operators:fields.first_name_placeholder'),
		label: i18n.t('operators:fields.first_name_label'),
		id: 'firstName',
		requiredField: true
	},
	{
		name: 'lastName',
		type: 'text',
		placeholder: i18n.t('operators:fields.last_name_placeholder'),
		label: i18n.t('operators:fields.last_name_label'),
		id: 'lastName',
		requiredField: true
	},
	{
		name: 'password',
		type: 'password',
		autoComplete: 'off',
		placeholder: i18n.t('operators:fields.password_placeholder'),
		label: i18n.t('operators:fields.password_label'),
		id: 'password',
		requiredField: isEdit
	},
	{
		name: 'password_confirmation',
		type: 'password',
		autoComplete: 'off',
		placeholder: i18n.t('operators:fields.password_confirmation_placeholder'),
		label: i18n.t('operators:fields.password_confirmation_label'),
		id: 'password_confirmation',
		requiredField: isEdit
	},
	{
		name: 'roles',
		type: 'select',
		mode: 'multiple',
		placeholder: i18n.t('operators:fields.role_placeholder'),
		label: i18n.t('operators:fields.role_label'),
		id: 'roles',
		requiredField: true
	},
	{
		name: 'products_group_uuid',
		type: 'select',
		placeholder: i18n.t('operators:fields.products_group'),
		label: i18n.t('operators:fields.products_group'),
		id: 'products_group_uuid',
		allowClear: allowInstitutionClear
	}
];
